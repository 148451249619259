<template>
    <el-main>
        <el-row>
            <el-col :span="12">
                
            </el-col>
            <el-col :span="12" style="text-align: right">
                
            </el-col>
        </el-row>
        
        <el-row>
            <el-col :span="24">
                Work in progress
            </el-col>
        </el-row>
    </el-main>
</template>
<style>

</style>
<script>
import store from "../../store";

export default {
    components: {},
    props: ['company'],
    data() {
        return {
            subscriptionVisible: false,
            subscriptionsList: [],
            loading: false
        };
    },
    computed: {},
    mounted() {
    },
    methods: {
    }
};
</script>
