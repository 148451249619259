<template>
    <el-main>
        <el-row>
            <el-col :span="12">
                <el-input
                    v-model="search"
                    size="small"
                    placeholder="Search for company"
                    clearable
                /> <p>Showing {{totalRecords}} Dealerships</p>
            </el-col>
            <el-col :span="12" style="text-align:right;">
                Filter Via Company Data Source
                <el-select v-model="company_car_data_source" @input="loadCompanies" clearable>
                    <el-option label="All" value="all" key="all" />
                    <el-option v-for="item in company_car_data_sources" :label="item.name" :value="item.id" :key="item.id" />
                </el-select>
                <el-row :gutter="20" style="margin-top: 10px">
                    <el-col :span="24">
                        <el-button
                            type="primary"
                            @click="newCompanyDealershipVisible=true"
                        >
                            <i class="fa fa-plus"></i> New Dealership
                        </el-button>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-tabs v-model="companies_to_show" @input="loadCompanies">
                    <el-tab-pane v-for="item in company_show_options" :key="item.name" :label="item.label" :name="item.name">
                        <keep-alive>
                            <CompaniesTable
                                :companies="companies"
                                :importingCompanyId="importingCompanyId"
                                :handleViewCompanyDetails="handleViewCompanyDetails"
                                :handleViewUrlGenerator="handleViewUrlGenerator"
                                :handleDealervaultSalesImport="handleDealervaultSalesImport"
                                :handleMap="handleMap"
                                :handleImport="handleImport"
                                :handleHideCompany="handleHideCompany"
                                :handleLatestProcessedFileDownload="handleLatestProcessedFileDownload"
                            />
                        </keep-alive>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>
        <el-pagination
            background
            @current-change="handlePageChange"
            :current-page.sync="currentPage"
            :page-size="10"
            layout="prev, pager, next"
            :total="totalRecords"
        ></el-pagination>
        <el-dialog title="New Dealership" :visible.sync="newCompanyDealershipVisible" :close-on-click-modal="false">
            <CreateCompanyForm v-on:closeDialog="newCompanyDealershipVisible=false" v-on:companyCreated="handleCompanySuccessfullyCreated" />
        </el-dialog>
        <el-dialog title="Dealership Details" :visible.sync="companyDetailsVisible" :close-on-click-modal="false">
            <CompanyForm :company="selectedCompany" v-on:closeDialog="companyDetailsVisible=false" />
        </el-dialog>
        <el-dialog title="Dealership Url" :visible.sync="companyUrlGeneratorVisible" :close-on-click-modal="false">
            <UrlGenerator :company="selectedCompany" />
            <span slot="footer" class="dialog-footer">
                <el-button @click="companyUrlGeneratorVisible = false">Close</el-button>
            </span>
        </el-dialog>
        <el-dialog title="Map Fields" :visible.sync="mapFormVisible" :close-on-click-modal="false">
            <MapCSVForm :company="selectedCompany" v-on:closeDialog="mapFormVisible=false"/>
        </el-dialog>
    </el-main>
</template>
<style>
.el-pagination {
    text-align: center;
    margin-top: 20px;
}
.el-dialog .el-select,
.el-dialog td > .el-input {
    width: 100%;
    margin-top: 6px;
}
.table-company-details td{
    border-bottom: 1px solid #ebeef5;
    padding: 5px;
}
.el-table.admin-companies-table .el-button{
    width: 100px;
    margin-bottom: 5px;
}
.el-table.admin-companies-table .el-button + .el-button {
    margin-left: 0;
}
</style>
<script>
import CreateCompanyForm from './CreateCompanyForm';
import CompanyForm from './CompanyForm';
import MapCSVForm from './MapCSVForm';
import UrlGenerator from './UrlGenerator';
import CompaniesTable from './components/CompaniesTable';

export default {
    data() {
        return {
            companies_to_show: "active",
            company_show_options: [
                {
                    label: "Active",
                    name: "active"
                },
                {
                    label: "Suspended",
                    name: "suspended"
                }
            ],
            uploading: false,
            errors: {},
            selectedCompany: {},
            isProgress: false,
            companies: [],
            search: "",
            totalRecords: 0,
            currentPage: 1,
            isSearching: false,
            mapFormVisible: false,
            companyDetailsVisible: false,
            companyUrlGeneratorVisible: false,
            selectedMapFields: [],
            importingCompanyId:0,
            hidingCompanyId: 0,
            company_car_data_sources: [],
            company_car_data_source: 'all',
            newCompanyDealershipVisible: false
        };
    },
    components: {
        CompanyForm,
        UrlGenerator,
        MapCSVForm,
        CompaniesTable,
        CreateCompanyForm
    },
    mounted() {
        this.loadCompanies();
    },
    methods: {
        loadCompanies() {
            this.axios
                .get("/api/company", {
                    params: {
                        page: this.currentPage,
                        q: this.search,
                        status: this.companies_to_show,
                        company_car_data_source: this.company_car_data_source,
                        order: 'desc',
                    }
                })
                .then(response => {
                    if (response.data.success == true) {
                        this.companies = response.data.data;
                        this.totalRecords = response.data.pagination.total;
                        this.company_car_data_sources = response.data.company_car_data_sources
                    } else {
                        this.$notify({
                            title: "Error",
                            message: "There has been an error getting companies list",
                            type: "error"
                        });
                    }
                });
        },
        handleHideCompany(id) {
            this.hidingCompanyId = id;
            this.axios
                .post("/api/company/" + id + "/hide")
                .then(response => {
                    if (response.data.success == true) {
                        this.$notify({
                            title: "Success",
                            message: response.data.message,
                            type: "success"
                        });
                    } else {
                        this.$notify({
                            title: "Error",
                            message: "Hiding company encountered a probem.",
                            type: "error"
                        });
                    }
                    this.hidingCompanyId = 0;
                    this.loadCompanies();
                });

        },
        handlePageChange(page) {
            this.currentPage = page;
            this.loadCompanies();
        },
        currencyFormat(row, column) {
            return this.$options.filters.currency(row.price);
        },
        handleMap(index, row) {
            this.selectedCompany = row;
            this.mapFormVisible = true;
        },
        handleImport(index, row) {
            this.selectedCompany = row
            this.importingCompanyId = row.company_id

            var companyId = this.selectedCompany.aloware_id
            if(this.selectedCompany.aloware_id == '0') {
                companyId = 'simpid-' + this.selectedCompany.id
            }

            this.axios
                .post("api/company/" + companyId + "/do_import", {})
                .then(response => {

                    this.importingCompanyId = 0

                    if (response.data.success == true) {
                        this.selectedCompany.is_importing = 1

                        this.$notify({
                            title: "Success",
                            message:
                                "Company Import has been added to the queue",
                            type: "success"
                        });

                    } else {
                        this.$notify({
                            title: "Error",
                            message:
                                "There has been an error getting mapping list",
                            type: "error"
                        });
                    }
                });
        },
        handleDealervaultSalesImport(index, row) {
            this.selectedCompany = row

            this.axios
                .post("api/company/" + this.selectedCompany.id + "/dealervault_sales_entries_import", {})
                .then(response => {

                    this.importingCompanyId = 0

                    if (response.data.success == true) {
                        this.selectedCompany.is_importing = 1

                        this.$notify({
                            title: "Success",
                            message:
                                "Company Import has been added to the queue",
                            type: "success"
                        });

                    }
                });
        },
        handleViewCompanyDetails(index, row) {
            this.selectedCompany = row
            this.companyDetailsVisible = true
        },
        handleViewUrlGenerator(index, row) {
            this.selectedCompany = row
            this.companyUrlGeneratorVisible = true
        },
        handleLatestProcessedFileDownload(rowID) {
            this.axios({
                    url: "api/company/" + rowID + "/get_latest_processed_file",
                    method: 'GET',
                    responseType: 'blob',
                }).then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', response.headers.filename);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                });
        },
        handleCompanySuccessfullyCreated() {
            this.newCompanyDealershipVisible = false;
            this.currentPage = 1;
            this.isSearching = true;
            this.loadCompanies();
        }
    },
    watch: {
        search: function(val, oldVal) {
            this.currentPage = 1;
            this.isSearching = true;
            this.loadCompanies();
        }
    }
};
</script>
