<template>
    <el-main
        v-loading="loading"
    >
        <h2
            v-if="company"
        >
            {{ company.name }}
        </h2>
        <disenroll
            v-if="action === 'engagement'"
            :contact="contact"
            :engagement="engagement"
            @engagement-update="handleEngagementUpdate"
        ></disenroll>
    </el-main>
</template>
<script>
import Disenroll from "./engage/actions/Disenroll";

export default {
    name: 'EngageActions',
    components: {
        Disenroll,
    },
    data() {
        return {
            loading: false,
            company: {},
            action: '',
            contact: {},
            engagement: {},
        }
    },
    mounted() {
        this.company = JSON.parse(this.$store.state.currentCompany)
        this.action = JSON.parse(localStorage.getItem('engage-action'))
        this.contact = JSON.parse(localStorage.getItem('contact_info'))
        this.shortcode = JSON.parse(localStorage.getItem('shortcode'))
        this.engagement = JSON.parse(localStorage.getItem('engagement'))

        if(this.company) {
            this.handleSetupComponent()
        }
    },
    methods: {
        handleSetupComponent() {
        },
        async handleEngagementUpdate() {
            this.loading = true

            await this.axios.post(
                `/actions/${this.shortcode}/${this.action}`
            )
                .then(res => {
                    localStorage.setItem('engage-action', null)
                    localStorage.setItem('contact_info', null)
                    localStorage.setItem('shortcode', null)

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: 'Contact has been updated. The page will be redirected.',
                        duration: 10000
                    })

                    setTimeout(function() {
                        location.href = res.data.redirect
                    }, 3000)
                })
                .catch(err => {
                    this.$root.handleErrors(err.response);
                    this.loading = false
                })
        }
    },
}
</script>
