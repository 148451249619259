<template>
    <el-main>
        <el-row>
            <el-col :span="6">
                <el-tag type="info" effect="plain">
                    Found:
                    <strong>{{ totalRecords }}</strong> users
                </el-tag>
            </el-col>
            <el-col :span="6">
                <el-input
                    v-model="search"
                    size="mini"
                    clearable
                    placeholder="Search for user"
                />
            </el-col>
            <el-col :span="6" style="font-size: 14px;padding-left: 20px">
                <el-switch
                    v-model="userType"
                    active-text="With Role"
                    inactive-text="Without Role"
                    active-value="with-role"
                    inactive-value="no-role"
                    @change="handleChangeUserType">
                </el-switch>
            </el-col>
            <el-col :span="6" style="text-align: right">
                <el-button
                        type="primary"
                        @click="handleCreate"
                    >
                        <i class="fa fa-plus"></i> Create
                    </el-button>
            </el-col>
        </el-row>
        <el-table :data="users">
            <el-table-column label="Name" prop="name"></el-table-column>
            <el-table-column label="Email" prop="email"></el-table-column>
            <el-table-column label="Phone" prop="phone"></el-table-column>
            <el-table-column label="Role">
                <template slot-scope="scope">
                    <el-tag v-for="(role,i) in scope.row.role_names" v-bind:key="i">{{role}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="right">
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="success"
                        @click="handleEdit(scope.$index, scope.row)"
                    >
                        Edit
                        <i class="fa fa-edit"></i>
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            background
            @current-change="handlePageChange"
            :current-page.sync="currentPage"
            :page-size="10"
            layout="prev, pager, next"
            :total="totalRecords"
        ></el-pagination>

        <el-dialog title="User" :visible.sync="userFormVisible" :close-on-click-modal="false">
            <UserForm :user="selectedUser" v-on:closeDialog="handleOnCloseUserForm"/>
        </el-dialog>
    </el-main>
</template>
<style>
.el-pagination {
    text-align: center;
    margin-top: 20px;
}
</style>
<script>
import store from "../../store";
import UserForm from "./UserForm.vue";

export default {
    props: {},
    data() {
        return {
            uploading: false,
            errors: {},
            isProgress: false,
            users: [],
            search: "",
            totalRecords: 0,
            currentPage: 1,
            isSearching: false,
            userFormVisible: false,
            userType: 'with-role',
            selectedUser: {},
        };
    },
    computed: {},
    mounted() {
        this.loadUsers();
    },
    methods: {
        loadUsers() {
            this.axios
                .get("api/user", {
                    params: {
                        page: this.currentPage,
                        q: this.search,
                        user_type: this.userType,
                    }
                })
                .then(response => {
                if (response.data.success == true) {
                    this.users = response.data.data;
                    this.totalRecords = response.data.pagination.total;
                }
                else {
                    this.$notify({
                        title: "Error",
                        message: "There has been an error getting users list",
                        type: "error"
                    });
                }
            });
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.loadUsers();
        },
        handleSearch(event) {
            console.log(event);
        },
        handleEdit(index, row) {
            this.selectedUser = row
            this.userFormVisible = true
        },
        handleCreate() {
            this.selectedUser = {}
            this.userFormVisible = true
        },
        handleChangeUserType() {
            this.currentPage = 1;
            this.isSearching = true;
            this.loadUsers();
        },
        handleOnCloseUserForm() {
            this.userFormVisible=false
            this.isSearching = true;
            this.loadUsers();
        }
    },
    watch: {
        search: function (val, oldVal) {
            this.currentPage = 1;
            this.isSearching = true;
            this.loadUsers();
        }
    },
    components: { UserForm }
};
</script>
