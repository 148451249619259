<template>
    <el-table
        :data="partners"
        style="width: 100%"
        v-loading="loading"
    >
        <el-table-column
            type="index"
        >
        </el-table-column>
        <el-table-column
            label="Dealer Id"
            prop="DealerId">
        </el-table-column>
        <el-table-column
            label="Name"
            prop="Name">
        </el-table-column>
        <el-table-column
            label="Platform ID"
            prop="PlatformId">
        </el-table-column>
        <el-table-column
            v-if="withActions"
            label="Actions"
        >
            <template v-slot="props">
                <el-button
                    v-for="(action, key) in actions"
                    :key="key"
                    :type="action.type"
                    :title="action.description"
                    size="mini"
                    @click="emitAction(action, props.row)"
                >{{action.name}}</el-button>
            </template>
        </el-table-column>
        <el-table-column
            label="Details"
            type="expand"
            width="100"
        >
            <template v-slot="props">
                <p
                    v-for="(item, key) in props.row"
                    :key="key"
                ><i>{{`${key}`}}:</i> <strong>{{`${item}`}}</strong></p>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
export default {
    name: 'PartnerDealers',
    props: {
        partners: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            default() {
                return false
            },
        },
        withActions: {
            type: Boolean,
            default() {
                return false
            },
        },
        actions: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {}
    },
    methods: {
        emitAction(action, data) {
            return this.$emit('btn-action', {action, data})
        }
    },
}
</script>
