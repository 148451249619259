<template>
    <el-tabs
        v-model="activeTab"
        type="border-card"
    >
        <el-tab-pane
            label="Partner Dealers"
            name="partner-dealers"
        >
            <el-tag
                v-if="showEnv"
                :type="isProduction ? 'success' : 'warning'"
            >{{isProduction ? 'Production' : 'Sandbox'}}</el-tag>
            <PartnerDealers
                :loading="loading"
                :partners="partners"
            ></PartnerDealers>
        </el-tab-pane>
    </el-tabs>

</template>
<script>
import PartnerDealers from "../components/PartnerDealers"

export default {
    name: 'CallTracking',
    components: {
        PartnerDealers,
    },
    data() {
        return {
            activeTab: 'partner-dealers',
            isProduction: false,
            showEnv: false,
            partners: [],
            loading: false,
        }
    },
    mounted() {
        this.setupComponent()
    },
    methods: {
        setupComponent() {
            this.company = JSON.parse(this.$store.state.currentCompany)
            this.handleGetPartnerDealers()
        },
        async handleGetPartnerDealers() {
            this.loading = true
            const url = `api/company/${this.company.id}/crm-dms-integrations/vs-dealer/integrations/partner-dealers?product=ct`
            await this.axios.get(url)
                .then(res => {
                    this.partners = res.data.data.partners.Items
                    this.isProduction = res.data.data.production
                    this.showEnv = true

                    this.partners = this.partners.sort((a, b) => {
                        const partnerA = a.Name.toLowerCase()
                        const partnerB = b.Name.toLowerCase()

                        if (partnerA < partnerB) {
                            return -1
                        }
                        if (partnerA > partnerB) {
                            return 1
                        }
                        return 0
                    })
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    return this.$root.handleErrors(err.response)
                })
        },
    }
}
</script>
