<template>
    <el-main>
        <el-row>
            <el-col :span="12">
                <el-tag type="info" effect="plain">
                    Found:
                    <strong>{{ totalRecords }}</strong> settings
                </el-tag>
            </el-col>
            </el-col>
            <el-col :span="12" style="text-align: right">
                <el-button
                        type="primary"
                        @click="handleCreate"
                    >
                        <i class="fa fa-plus"></i> Create
                    </el-button>
            </el-col>
        </el-row>
        <el-table :data="settings">
            <el-table-column label="Lead Source">
                <template slot-scope="scope">
                    {{ Object.keys(JSON.parse(scope.row.val))[0] }}
                </template>
            </el-table-column>
            <el-table-column label="Sequence">
                <template slot-scope="scope">
                    {{ JSON.parse(scope.row.val)[Object.keys(JSON.parse(scope.row.val))[0]] }}
                </template>
            </el-table-column>
            <el-table-column align="right">
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="success"
                        @click="handleEdit(scope.$index, scope.row)"
                    >
                        Edit
                        <i class="fa fa-edit"></i>
                    </el-button>

                    <el-popconfirm
                        title="Are you sure you want to delete this?"
                        @confirm="handleDelete(scope.row)">
                        <el-button
                            size="mini"
                            type="danger"
                            slot="reference"
                        >
                            Delete
                            <i class="fa fa-trash"></i>
                        </el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            background
            @current-change="handlePageChange"
            :current-page.sync="currentPage"
            :page-size="10"
            layout="prev, pager, next"
            :total="totalRecords"
        ></el-pagination>

        <el-dialog title="Setting" :visible.sync="settingFormVisible" :close-on-click-modal="false">
            <SettingsForm :setting="selectedSetting" v-on:closeDialog="handleOnCloseSettingForm"/>
        </el-dialog>
    </el-main>
</template>
<style>
.el-pagination {
    text-align: center;
    margin-top: 20px;
}
</style>
<script>
import SettingsForm from "./SettingsForm.vue"

export default {
    props: {},
    data() {
        return {
            uploading: false,
            errors: {},
            isProgress: false,
            settings: [],
            search: "",
            totalRecords: 0,
            currentPage: 1,
            isSearching: false,
            settingFormVisible: false,
            selectedSetting: {},
        };
    },
    computed: {},
    mounted() {
        this.loadSettings()
    },
    methods: {
        loadSettings() {
            this.axios
                .get("api/settings", {
                    params: {
                        page: this.currentPage,
                        name: 'leadsource_to_sequence',
                    }
                })
                .then(response => {
                if (response.data.success == true) {
                    this.settings = response.data.data;
                    this.totalRecords = response.data.pagination.total;
                }
                else {
                    this.$notify({
                        title: "Error",
                        message: "There has been an error getting settings list",
                        type: "error"
                    });
                }
            });
        },
        handlePageChange(page) {
            this.currentPage = page
            this.loadSettings()
        },
        handleSearch(event) {
            console.log(event)
        },
        handleEdit(index, row) {
            row.valArr = {}
            row.valArr['leadsource'] = Object.keys(JSON.parse(row.val))[0]
            row.valArr['sequence'] = JSON.parse(row.val)[Object.keys(JSON.parse(row.val))[0]]

            this.selectedSetting = row
            this.settingFormVisible = true
        },
        handleDelete(row) {
            this.axios
            .delete(`/api/settings/${row.id}`)
            .then(response => {
                if (response.data.success == true) {
                    this.$notify({
                        title: "Success",
                        message: "Record has been successfully deleted",
                        type: "success"
                    });
                    this.loadSettings()
                } else {
                    let errors = response.data.errors.message;
                    let message = "";
                    for (let error in errors) {
                        message = errors[error] + "\n";
                    }
                    this.$notify({
                        title: "Error",
                        message: message,
                        type: "error"
                    });
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        handleCreate() {
            this.selectedSetting = {
                id: 0,
                name: 'leadsource_to_sequence',
                val: {},
                valArr: {
                    leadsource: '',
                    sequence: '',
                },
            }
            this.settingFormVisible = true
        },
        handleChangeSettingType() {
            this.currentPage = 1;
            this.isSearching = true;
            this.loadSettings();
        },
        handleOnCloseSettingForm() {
            this.settingFormVisible=false
            this.isSearching = true;
            this.loadSettings();
        }
    },
    watch: {
        search: function (val, oldVal) {
            this.currentPage = 1;
            this.isSearching = true;
            this.loadSettings();
        }
    },
    components: { SettingsForm }
};
</script>