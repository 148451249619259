<template>
    <el-main style="padding: 0">
            <el-tabs
                v-model="activeTab"
                @tab-click="handleTabClick">
                <el-tab-pane label="General" name="general">
                    <general-settings v-if="activeTab=='general'"></general-settings>
                </el-tab-pane>
                <el-tab-pane label="Webchat" name="webchat">
                    <webchat-settings v-if="activeTab=='webchat'"></webchat-settings>
                </el-tab-pane>
            </el-tabs>
        </div>
    </el-main>
</template>
<script>
import GeneralSettings from './GeneralSettings'
import WebchatSettings from './webchat/Webchat'
export default {
    name: 'Webchat-Settings',
    components: {
        GeneralSettings,
        WebchatSettings,
    },
    data() {
        return {
            company: {},
            activeTab: 'general',
            loading: false,
        }
    },
    mounted() {
        this.company = JSON.parse(this.$store.state.currentCompany)
    },
    methods: {
        handleTabClick(tab) {
            this.activeTab = tab.name
        },
    },
    watch: { },
}
</script>
