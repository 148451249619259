<template>
    <el-tabs
        type="border-card"
        @tab-click="tabClicked"
    >
        <el-tab-pane label="VinSolutions">
            <vin-solutions
            v-if="activeTab === 'VinSolutions'"></vin-solutions>
        </el-tab-pane>
        <el-tab-pane label="CRM/DMS INTEGRATIONS">
            <Integrations
                v-if="activeTab === 'CRM/DMS INTEGRATIONS'"
            ></Integrations>
        </el-tab-pane>
        <el-tab-pane label="CRM INTEGRATION SETTINGS">
            <Settings
                v-if="activeTab === 'CRM INTEGRATION SETTINGS'"
            ></Settings>
        </el-tab-pane>
    </el-tabs>
</template>
<script>
import VinSolutions from "./crm-dms-integrations/VinSolutions/integration-control/VinSolutions"
import Integrations from "./crm-dms-integrations/Integrations.vue";
import Settings from "./crm-dms-integrations/Settings.vue";
export default {
    name: "CrmDmsIntegrations",
    components: {
        VinSolutions,
        Integrations,
        Settings,
    },
    data() {
        return {
            activeTab: ''
        }
    },
    methods: {
        tabClicked(tab) {
            this.activeTab = tab.label
        }
    }
}
</script>
