<template>
    <el-table
        :data="currentTemplates"
        style="width: 100%;"
        height="50vh"
        @row-click="handleTableAction"
        highlight-current-row
    >
        <el-table-column label="Status" width="75px;">
            <template v-slot="props">
                <el-button
                    size="mini"
                    :type="props.row.status ? 'success' : 'danger'"
                    circle
                    disabled
                ></el-button>
            </template>
        </el-table-column>
        <el-table-column
            label="Title"
            prop="title"
        >
        </el-table-column>

        <el-table-column label="Created" align="center">
            <template v-slot="props">
                <span :title="props.row.created_at | timestampFormatDateTime">{{ `${props.row.formatted_created_at} by ${props.row.created_by.name}` }}</span>
            </template>
        </el-table-column>

        <el-table-column label="Updated" align="center">
            <template v-slot="props">
                <span :title="props.row.updated_at | timestampFormatDateTime">{{ `${props.row.formatted_updated_at} by ${props.row.updated_by.name}` }}</span>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import TemplateSelector from "../../webmail/dialogs/TemplateSelectorDialog.vue";

export default {
    name: "SequencesEmailsTemplatesList",
    components: {TemplateSelector},
    props: {
        templates: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            currentTemplates: [],
            buttonActions: [
                {
                    action: 'view',
                    label: 'View',
                    type: 'primary',
                },
                {
                    action: 'edit',
                    label: 'Edit',
                    type: 'info',
                },

            ],
        }
    },
    mounted() {
        this.checkTemplates()
    },
    methods: {
        checkTemplates() {
            this.currentTemplates = this.templates
        },
        handleTableAction(row) {
            this.$emit('selected-row', row)
        },
    },
}
</script>
