<template>
    <el-tabs
        v-model="activeTab"
        type="border-card"
    >
        <el-tab-pane label="IMPORT" name="import">
            <Import
                @upload-success="getToImportList"
            ></Import>
            <hr style="margin: 1rem 0;">
            <ImportList
                :key="importListKey"
                :files-to-import="filesToImport"
                @process-report="handleProcessReport"
            ></ImportList>
        </el-tab-pane>
    </el-tabs>
</template>
<script>
import Import from "./Import";
import ImportList from "./ImportList";

export default {
    name: 'SalesData',
    components: {
        ImportList,
        Import,
    },
    data() {
        return {
            activeTab: 'import',
            company: {},
            filesToImport: [],
            importListKey: 0,
        }
    },
    mounted() {
        this.setupComponent()
    },
    methods: {
        setupComponent() {
            this.company = JSON.parse(this.$store.state.currentCompany)
            this.getToImportList()
            this.handleProcessProgress()
        },
        async getToImportList() {
            await this.axios.get(
                `api/company/${this.company.id}/sales-report/to-import`
            )
                .then(res => {
                    this.filesToImport = res.data.data.to_import
                    this.importListKey++
                })
                .catch(err => {
                    return this.$root.handleErrors(err.response)
                })
        },
        async handleProcessReport(data) {

            await this.axios.post(
                `api/company/${this.company.id}/sales-report/to-process`,
                data
            )
                .then(res => {
                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: 'Process request has been queued.'
                    })
                    return this.getToImportList()
                })
                .catch(err => {
                    return this.$root.handleErrors(err.response)
                })
        },
        handleProcessProgress(){
            Echo.private('process-sales-report')
                .listen('.progress', ev => {
                    this.$store.commit('UpdateSalesReportsProgress', ev)
                })
        }
    }
}
</script>
