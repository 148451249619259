<template>
    <el-main>
        <div v-loading="loading" v-if="loading"></div>
        <div v-else>
            <el-form label-width="150px">
                <el-divider content-position="left">Button Listener</el-divider>
                <el-form-item label="Listen to event">
                    <el-switch v-model="formData.event_listener"></el-switch>
                </el-form-item>
                <el-form-item label="Wait page load">
                    <el-switch v-model="formData.wait_for_element_to_load"></el-switch>
                </el-form-item>
                <el-divider content-position="left">Button Container</el-divider>
                <el-form-item label="Display">
                    <el-select v-model="formData.bcont_display">
                        <el-option label="inline-flex" value="inline-flex"></el-option>
                        <el-option label="grid" value="grid"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Margin (px)">
                    <el-row :gutter="10">
                        <el-col :span="5">
                            <el-input type="number" v-model="formData.bcont_margin_top"></el-input>
                        </el-col>
                        <el-col :span="5">
                            <el-input type="number" v-model="formData.bcont_margin_right"></el-input>
                        </el-col>
                        <el-col :span="5">
                            <el-input type="number" v-model="formData.bcont_margin_bottom"></el-input>
                        </el-col>
                        <el-col :span="5">
                            <el-input type="number" v-model="formData.bcont_margin_left"></el-input>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="Padding (px)">
                    <el-row :gutter="10">
                        <el-col :span="5">
                            <el-input type="number" v-model="formData.bcont_padding_top"></el-input>
                        </el-col>
                        <el-col :span="5">
                            <el-input type="number" v-model="formData.bcont_padding_right"></el-input>
                        </el-col>
                        <el-col :span="5">
                            <el-input type="number" v-model="formData.bcont_padding_bottom"></el-input>
                        </el-col>
                        <el-col :span="5">
                            <el-input type="number" v-model="formData.bcont_padding_left"></el-input>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="Align Items">
                    <el-select v-model="formData.bcont_align_items">
                        <el-option label="left" value="left"></el-option>
                        <el-option label="center" value="center"></el-option>
                        <el-option label="right" value="right"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Z-index">
                    <el-input type="number" v-model="formData.bcont_z_index"></el-input>
                </el-form-item>

                <el-divider content-position="left">Button</el-divider>
                <el-form-item label="Border">
                    <el-row :gutter="10">
                        <el-col :span="5">
                            <el-input type="number" v-model="formData.b_border_width"></el-input>
                        </el-col>
                        <el-col :span="8">
                            <el-select v-model="formData.b_border_style">
                                <el-option label="solid" value="solid"></el-option>
                                <el-option label="dashed" value="dashed"></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="5">
                            <el-color-picker v-model="formData.b_border_color"></el-color-picker>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="BG color">
                    <el-color-picker v-model="formData.b_bgcolor"></el-color-picker>
                </el-form-item>
                <el-form-item label="Text color">
                    <el-color-picker v-model="formData.b_color"></el-color-picker>
                </el-form-item>
                <el-form-item label="Border (hover)">
                    <el-row :gutter="10">
                        <el-col :span="5">
                            <el-input type="number" v-model="formData.b_border_hover_width"></el-input>
                        </el-col>
                        <el-col :span="8">
                            <el-select v-model="formData.b_border_hover_style">
                                <el-option label="solid" value="solid"></el-option>
                                <el-option label="dashed" value="dashed"></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="5">
                            <el-color-picker v-model="formData.b_border_hover_color"></el-color-picker>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="BG color (hover)">
                    <el-color-picker v-model="formData.b_bg_hover_color"></el-color-picker>
                </el-form-item>
                <el-form-item label="Text color (hover)">
                    <el-color-picker v-model="formData.b_hover_color"></el-color-picker>
                </el-form-item>
                <el-form-item label="Padding (px)">
                    <el-row :gutter="10">
                        <el-col :span="5">
                            <el-input type="number" v-model="formData.b_padding_top"></el-input>
                        </el-col>
                        <el-col :span="5">
                            <el-input type="number" v-model="formData.b_padding_right"></el-input>
                        </el-col>
                        <el-col :span="5">
                            <el-input type="number" v-model="formData.b_padding_bottom"></el-input>
                        </el-col>
                        <el-col :span="5">
                            <el-input type="number" v-model="formData.b_padding_left"></el-input>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="Border radius (px)">
                    <el-row :gutter="10">
                        <el-col :span="5">
                            <el-input type="number" v-model="formData.b_radius_topl"></el-input>
                        </el-col>
                        <el-col :span="5">
                            <el-input type="number" v-model="formData.b_radius_topr"></el-input>
                        </el-col>
                        <el-col :span="5">
                            <el-input type="number" v-model="formData.b_radius_bottomr"></el-input>
                        </el-col>
                        <el-col :span="5">
                            <el-input type="number" v-model="formData.b_radius_bottoml"></el-input>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="Font size (px)">
                    <el-input type="number" v-model="formData.b_font_size"></el-input>
                </el-form-item>
                <el-form-item label="Font Family">
                    <el-input v-model="formData.b_font_family"></el-input>
                </el-form-item>
                <el-form-item label="Font weight">
                    <el-select v-model="formData.b_font_weight">
                        <el-option label="normal" value="normal"></el-option>
                        <el-option label="bold" value="bold"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Text transform">
                    <el-select v-model="formData.b_text_transform">
                        <el-option label="uppercase" value="uppercase"></el-option>
                        <el-option label="lowercase" value="lowercase"></el-option>
                        <el-option label="none" value="none"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Box Shadow">
                    <el-input v-model="formData.b_box_shadow"></el-input>
                </el-form-item>

                <el-divider content-position="left">Icon</el-divider>
                <el-form-item label="Width (px)">
                    <el-input type="number" v-model="formData.icon_width"></el-input>
                </el-form-item>
                <el-form-item label="Position">
                    <el-select v-model="formData.icon_position">
                        <el-option label="absolute" value="absolute"></el-option>
                        <el-option label="relative" value="relative"></el-option>
                        <el-option label="fixed" value="fixed"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Left (px)">
                    <el-input type="number" v-model="formData.icon_left"></el-input>
                </el-form-item>
                <el-form-item label="Z-index">
                    <el-input type="number" v-model="formData.icon_z_index"></el-input>
                </el-form-item>
                <el-form-item class="bottom text-right">
                    <el-button class="button" type="primary" @click="handleSubmit" :loading="loading">save</el-button>
                </el-form-item>
            </el-form>
        </div>
    </el-main>
</template>
<script>
import { mapState } from 'vuex'

export default {
    name: 'Webchat-VDP',
    data() {
        return {
            company: {},
            loading: false,
            settingsData: {},
            formData: {
                event_listener: true,
                wait_for_element_to_load: false,
                bcont_display: 'inline-flex',
                bcont_margin_top: 0,
                bcont_margin_right: 0,
                bcont_margin_bottom: 0,
                bcont_margin_left: 5,
                bcont_padding_top: 5,
                bcont_padding_right: 5,
                bcont_padding_bottom: 5,
                bcont_padding_left: 5,
                bcont_align_items: 'center',
                bcont_z_index: 5,
                b_border_width: 0,
                b_border_style: 'solid',
                b_border_color: 'transparent',
                b_bgcolor: '#f2225d',
                b_color: '#ffffff',
                b_border_hover_width: 0,
                b_border_hover_style: 'solid',
                b_border_hover_color: '#ffffff',
                b_bg_hover_color: '#f2225d',
                b_hover_color: '#ffffff',
                b_padding_top: 2,
                b_box_shadow: 'none',
                b_padding_right: 10,
                b_padding_bottom: 1,
                b_padding_left: 15,
                b_radius_topl: 0,
                b_radius_topr: 11,
                b_radius_bottomr: 11,
                b_radius_bottoml: 0,
                b_font_size: 13,
                b_font_family: 'Roboto,sans-serif',
                b_font_weight: 'bold',
                b_text_transform: 'uppercase',
                icon_width: 30,
                icon_position: 'absolute',
                icon_left: -13,
                icon_z_index: 1,
            },
        }
    },
    computed: mapState(['currentCompany']),
    mounted() {
        this.company = JSON.parse(this.$store.state.currentCompany)
        this.getData()
    },
    methods: {
        getData() {
            this.loading = true
            this.axios.get(`/api/company/simpid-${this.company.id}/get-settings/webchat`).then(response => {
                this.loading = false
                if( response.data.data !== '' ) {
                    this.settingsData = response.data.data

                    if( this.settingsData.vdp !== undefined ) {
                        this.formData = this.settingsData.vdp
                    }
                }
            })
        },
        handleSubmit() {
            this.axios.post(`/api/company/simpid-${this.company.id}/set-settings/webchat`, {
                value: {
                    ...this.settingsData,
                    vdp: this.formData
                }
            }).then(response => {
                if (response.data.success == true) {
                    this.$notify({
                        title: "Success",
                        message: "Company Settings has been successfully Updated",
                        type: "success"
                    });
                } else {
                    this.$notify({
                        title: "Error",
                        message: response.data.message,
                        type: "error"
                    });
                }
            })
        },
    },
    watch: {
        currentCompany(newValue, oldValue) {
            this.company = JSON.parse(newValue)
        },
    },
}
</script>
