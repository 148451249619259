<template>
    <el-dialog title="Select Contact" @close="$emit('update:visible', false)" :visible.sync="isVisible" :close-on-click-modal="false" width="60%">
        <div>
            <ContactSearch @onSelectContacts="handleSelectContacts" />
        </div>
        <div slot="footer">
            <el-row>
                <el-col :span="12" style="text-align: left">
                    <el-button @click="isVisible=false">Cancel</el-button>
                </el-col>
                <el-col :span="12" style="text-align: right">
                    <el-button @click="handleSubmit" type="primary"><i class="el-icon-check"></i> Select</el-button>
                </el-col>
            </el-row>
        </div>
    </el-dialog>
</template>
<script>
import store from "../../../store";
import ContactSearch from "./searchContacts/ContactSearch";

export default {
    props: ['visible'],
    components: {
        ContactSearch,
    },
    data() {
        return {
            isVisible: false,
            selectedContacts: [],
            activeTab: 'search'
        };
    },
    computed: {
    },
    mounted() {
        this.company = JSON.parse(store.state.currentCompany)
        this.user = JSON.parse(store.state.currentUser)
    },
    methods: {
        handleSelectContacts(selectedContacts) {
            this.selectedContacts = selectedContacts
        },
        handleSubmit() {
            this.$emit('onSelectContacts', this.selectedContacts)
            this.$emit('update:visible', false)
        }
    },
    watch: {
        visible: function(val, oldVal) {
            this.isVisible = this.visible;
        },
        /*contact: function(val, oldVal) {
            this.selectedContact = this.contact;
        }*/
    }
};
</script>
