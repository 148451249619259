<template>
    <div
        v-loading="loading"
        :element-loading-text="loadingMsg"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        style="width: 100%"
    >
        <el-row
            type="flex"
            justify="space-between"
        >
            <div>
                <div
                    v-if="dealer.page_id"
                >
                    <strong>{{ dealer.name }}</strong>
                     -
                    <a
                        :href="`https://www.facebook.com/${dealer.page_id}`"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {{ dealer.page_id }}
                    </a>
                </div>
            </div>

            <el-form>
                <el-form-item label="Dealers FB Page">
                    <el-select
                        v-model="dealer"
                        :value="dealer"
                        placeholder="Search/Select the Dealer FB Page"
                        filterable
                        clearable
                        remote
                        :remote-method="setPages"
                        value-key="id"
                        @change="handleSelectedPage"
                        @clear="clearAllData"
                        :loading="loadingInput"
                    >
                        <el-option
                            v-for="item in dealers"
                            :key="item.id"
                            :label="`${item.name} - ${item.page_id}`"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row
            type="flex"
            justify="space-between"
        >
            <div
                v-if="dealer.page_id"
            >
                <el-date-picker
                    v-model="filters.date_range"
                    type="daterange"
                    align="center"
                    unlink-panels
                    range-separator="To"
                    start-placeholder="From date"
                    end-placeholder="End date"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
                    format="yyyy-MMM-dd"
                    @change="resetLeads"
                >
                </el-date-picker>
                <el-button
                    v-if="Array.isArray(filters.date_range) && filters.date_range.length"
                    @click="getDataByDateRange"
                    type="primary"
                >Get Data</el-button>
                <strong
                    v-if="showLeadCount"
                    style="margin-left: 1rem;"
                >Lead Count: {{ leadCount }}</strong>
            </div>
        </el-row>
        <el-row
            v-if="leads.length"
            style="margin-top: 2rem;"
        >
            <el-button
                type="success"
                @click="handleExport('EXCEL')"
            >
                EXCEL
            </el-button>
            <el-button
                type="danger"
                @click="handleExport('PDF')"
            >
                PDF
            </el-button>
            <el-button
                type="warning"
                @click="handleExport('to-crm')"
            >
                Resend to CRM
            </el-button>
        </el-row>
        <el-row
            v-if="leads.length"
            style="margin-top: 2rem;"
        >
            <el-col :span="24">
                <FbLeadgenTable
                    :leads="leads"
                    :links="links"
                    :meta="meta"
                    :key="leadsKey"
                    @current-change="clickedPage"
                />
            </el-col>
        </el-row>
    </div>
</template>

<script>
import FbLeadgenTable from "./FbLeadgenTable";

export default {
    name: "FbLeadgen",
    components: {
        FbLeadgenTable
    },
    props: {
        user: {
            type: Object,
            required: true
        },
        company: {
            type: Object,
            required: true
        },
        apiEndpoint: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: true,
            loadingInput: false,
            loadingMsg: 'Fetching data...',
            dealers: [],
            dealer: {
                id: null,
                name: null
            },
            filters: {
                fb_page_id: null,
                company_id: null,
                date_range: [],
                type: ''
            },
            pickerOptions: {
                disabledDate: this.handleDisabledDate,
                onPick: this.handleOnPick,
                shortcuts: [{
                    text: 'Last week',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last month',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                },
                    // {
                    //     text: 'Last 3 months',
                    //     onClick(picker) {
                    //         const end = new Date();
                    //         const start = new Date();
                    //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    //         picker.$emit('pick', [start, end]);
                    //     }
                    // }
                ]
            },
            leadCount: 0,
            showLeadCount: false,
            leads: [],
            leadsKey: 0,
            links: {},
            meta: {},
            leadActionForm: {
                downloadAs: '',
                processFBLead: false
            },
            storedFbPage: {},
        }
    },
    mounted() {
        this.storedFbPage = JSON.parse(localStorage.getItem('selectedFbPage'))
        this.setPages(this.storedFbPage?.name.substring(0, 4)).then(() => this.handleSelectedPage())
    },
    methods: {
        async setPages(q = '') {
            this.loadingInput = true
            await this.axios.get(`${this.apiEndpoint}/subscribed-pages?q=${q}`)
                .then(res => {
                    this.dealers = res.data.data.dealers
                    if(this.storedFbPage && this.dealers.some(d => d.page_id === this.storedFbPage.id)) {
                        this.dealer = this.storedFbPage
                        this.dealer.page_id = this.storedFbPage.page_id
                    }
                    this.finishLoading()
                })
                .catch(err => {
                    this.finishLoading()
                    console.log('setPages - err', err)
                })
        },
        async handleSelectedPage() {
            if(this.dealer.page_id) {
                this.clearAllData()
                this.startLoading('Fetching dealer...')
                await this.axios.get(`${this.apiEndpoint}/subscribed-pages-show?fb_page_id=${this.dealer.page_id}`)
                    .then(res => {
                        this.filters.fb_page_id = res.data.fb_page.page_id
                        this.filters.company_id = res.data.fb_page.company_id
                        this.finishLoading()
                    })
                    .catch(err => {
                        this.finishLoading()
                        console.log('handleSelectedPage - err?', err)
                    })
            }
        },
        async getDataByDateRange(page = 1, data = null) {
            const toPage = typeof page === 'number' ? page : 1
            const sortProp = data && data.hasOwnProperty('prop') ? data.prop : 'created_at'
            const sortOder = data && data.hasOwnProperty('order') && data.order === 'ascending' ? 'asc' : 'desc'

            if(Array.isArray(this.filters.date_range) && this.filters.date_range.length) {
                this.startLoading('Fetching leads...')
                const qs = this.$qs.stringify(this.filters, { allowDots: true })
                await this.axios.get(`${this.apiEndpoint}/subscribed-pages-leads-by-date?${qs}&page=${toPage}&sort=${sortProp}&sort_direction=${sortOder}`)
                    .then(res => {
                        this.leadCount = res.data.meta.total
                        this.leads = res.data.data
                        this.meta = res.data.meta
                        this.links = res.data.links
                        this.showLeadCount = true
                        this.leadsKey++
                        this.finishLoading()
                    })
                    .catch(err => {
                        console.log(err)
                        this.finishLoading()
                        return this.$notify({
                            type: 'error',
                            message: 'Something went wrong, please refresh the page.'
                        })
                    })
            }
        },
        resetLeads(data) {
            if(data === null) {
                this.clearAllData()
            }
            this.leadCount = 0
        },
        clickedPage(data) {
            typeof data === "number" ? this.getDataByDateRange(data) : this.getDataByDateRange(1, data)
        },
        clearAllData() {
            this.showLeadCount = false
            this.count = 0
            this.leads = []
            this.filters.date_range = []
            this.filters.type = ''
        },
        async handleExport(type) {
            const vTypes = ['EXCEL', 'PDF', 'to-crm']

            if(Array.isArray(this.filters.date_range) && this.filters.date_range.length && vTypes.find(vt => vt === type)) {
                this.startLoading('Processing the request...')
                this.filters.type = type
                const qs = this.$qs.stringify(this.filters, { allowDots: true })
                let responseType = type === 'to-crm' ? 'json' : 'arraybuffer'

                await this.axios({
                    url: `${this.apiEndpoint}/subscribed-pages-leads-by-date-export?${qs}`,
                    method: 'GET',
                    responseType
                })
                    .then(res => {
                        this.finishLoading()
                        if(type === 'to-crm') {
                            return this.$notify({
                                type: 'success',
                                title: 'Job has been queued.',
                                message: 'Resending leads to dealerships CRM.'
                            })
                        }

                        const fileURL = window.URL.createObjectURL(new Blob([res.data]))
                        const fileLink = document.createElement('a')
                        fileLink.href = fileURL
                        fileLink.setAttribute('download', res.headers.filename)
                        fileLink.target = '_blank'
                        document.body.appendChild(fileLink)
                        return fileLink.click()
                    })
                    .catch(err => {
                        console.log(err)
                        this.finishLoading()
                        return this.$notify({
                            type: 'error',
                            message: 'Something went wrong, please refresh the page.'
                        })
                    })
            }
        },
        startLoading(msg = '') {
            this.loading = true
            if(msg) {
                this.loadingMsg = msg
            }
        },
        finishLoading() {
            this.loading = false
            this.loadingMsg = 'Fetching data...'
            this.loadingInput = false
        },
        handleDisabledDate(data) {
            return data < Date.parse('09 Aug 2022 00:00:00 GMT')
        },
        handleOnPick(maxDate, minDate) {
            return minDate < Date.parse('09 Aug 2022 00:00:00 GMT')
        },
    },

    watch: {
        'dealer': {
            immediate: false,
            handler: function (n) {
                if(n) {
                    localStorage.removeItem('selectedFbPage')
                    delete n.webhooks
                    localStorage.setItem(
                        'selectedFbPage',
                        JSON.stringify(n)
                    )
                    if(this.selectedRequestCount === 0) {
                        this.handleSelectedPage()
                    }
                }
            }
        }
    }
}
</script>
