<template>
    <div>
        <el-form>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="Name">
                        <el-input v-model="currentDatasource.name"></el-input>
                    </el-form-item>
                    <el-form-item label="Email">
                        <el-input v-model="currentDatasource.email_address"></el-input>
                    </el-form-item>
                    <el-form-item label="Phone Number">
                        <el-input v-model="currentDatasource.phone_number"></el-input>
                    </el-form-item>
                    <el-form-item label="Data Type">
                        <el-select v-model="currentDatasource.data_type">
                            <el-option
                                v-for="item in data_type_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Directory" v-if="currentDatasource.data_type=='2' || currentDatasource.data_type=='3' || currentDatasource.data_type=='5'">
                        <el-input v-model="currentDatasource.directory"></el-input>
                    </el-form-item>
                    <el-form-item label="Dealership Column Header Label" v-if="currentDatasource.data_type=='3'">
                        <el-input v-model="currentDatasource.dealership_column_header"></el-input>
                    </el-form-item>
                    <el-form-item label="Notes">
                        <el-input
                            type="textarea"
                            :rows="2"
                            v-model="currentDatasource.notes">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-button @click="$emit('closeDialog')">Close</el-button>
                </el-col>
                <el-col :span="12" style="text-align: right">
                    <el-button type="primary" @click="handleSubmit">Submit</el-button>
                </el-col>
            </el-row>
        </el-form>

    </div>
</template>

<script>

export default {
    props: ['company_car_data_source'],
    data() {
        return {
            currentDatasource: {
                data_type: 1
            },
            data_type_options: [{
                value: 1,
                label: '1. Single file'
            }, {
                value: 2,
                label: '2. Multiple Dealerships in one dir'
            }, {
                value: 3,
                label: '3. Multiple Dealerships in one file'
            }, {
                value: 4,
                label: '4. Single Dealership, different files'
            }, {
                value: 5,
                label: '5. Multiple Dealership, same inventory'
            }]
        };
    },
    computed: {},
    mounted() {
        this.currentDatasource = this.company_car_data_source
    },
    methods: {
        handleSubmit() {
            if (this.currentDatasource.id !== '') {
                this.axios
                    .put(`/api/company_car_data_source/${this.currentDatasource.id}`, this.currentDatasource)
                    .then(response => {
                        if (response.data.success == true) {
                            this.$notify({
                                title: "Success",
                                message: "Data Source has been successfully Updated",
                                type: "success"
                            });

                            this.$emit('onSuccessfulSubmit');
                        } else {
                            let errors = response.data.errors.message;
                            let message = "";
                            for (let error in errors) {
                                message = errors[error] + "\n";
                            }
                            this.$notify({
                                title: "Error",
                                message: message,
                                type: "error"
                            });
                        }
                    })
                    .catch(err => {
                        this.$root.handleErrors(err.response);
                    });
            } else {
                this.axios
                    .post('/api/company_car_data_source', this.currentDatasource)
                    .then(response => {
                        if (response.data.success == true) {
                            this.$notify({
                                title: "Success",
                                message: "Data Source has been successfully Added",
                                type: "success"
                            });

                            this.$emit('onSuccessfulSubmit');
                        } else {
                            let errors = response.data.errors.message;
                            let message = "";
                            for (let error in errors) {
                                message = errors[error] + "\n";
                            }
                            this.$notify({
                                title: "Error",
                                message: message,
                                type: "error"
                            });
                        }
                    })
                    .catch(err => {
                        this.$root.handleErrors(err.response);
                    });
            }
        }
    },
     watch: {
        company_car_data_source: function(val, oldVal) {
            this.currentDatasource = this.company_car_data_source
        },
     }
};
</script>
