<template>
    <div class="mail-bulk">
        <el-row>
            <el-col :span="24">
                <el-row>
                    <el-col :span="24">
                        <p>Select group of contacts:</p>
                        <el-tabs type="card" v-model="activeTab">
                            <el-tab-pane label="By Tag" name="tag">
                                <ByTag @onSelectContacts="handleSelectContacts" v-if="activeTab =='tag'" />
                            </el-tab-pane>
                            <el-tab-pane label="Date Range" name="daterange">
                                <ByDaterange @onSelectContacts="handleSelectContacts" v-if="activeTab =='daterange'" />
                            </el-tab-pane>
                            <el-tab-pane label="Disposition Status" name="dispositionstatus">
                                <ByDispositionStatus @onSelectContacts="handleSelectContacts" v-if="activeTab =='dispositionstatus'" />
                            </el-tab-pane>
                            <el-tab-pane label="Lead Source" name="leadSource">
                                <ByLeadSource @onSelectContacts="handleSelectContacts" v-if="activeTab =='leadSource'"/>
                            </el-tab-pane>
                            <el-tab-pane label="Exports" name="dmsExport">
                                <ByDmsExport @onSelectContacts="handleSelectContacts" :dmsExportId="dmsExportId" v-if="activeTab =='dmsExport'" />
                            </el-tab-pane>
                            <el-tab-pane label="Imports" name="import">
                                <ByImport @onSelectContacts="handleSelectContacts" :importTagId="importTagId" v-if="activeTab =='import'" />
                            </el-tab-pane>
                            <el-tab-pane label="By Filters" name="filter">
                                <ByFilters @onSelectContacts="handleSelectContacts" v-if="activeTab =='filter'" />
                            </el-tab-pane>
                        </el-tabs>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" style="margin-top: 10px">
                        <el-input v-model="mail.subject" placeholder="Enter Subject" @focus="activeInput = 'subject'"></el-input>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <p>Compose message:</p>

                        <composer-editor
                            ref="composerEditor"
                            :value="mail.message"
                            :key="editorKey"
                            @update="handleEditorUpdate"
                            v-on:onSelectTemplate="handleTemplateSelect"
                            element-loading-spinner="el-icon-loading"
                            element-loading-background="rgba(0, 0, 0, 0.8)"
                            @focus="activeInput = 'message'"
                            @attached-file-list="handleFileAttachments"
                        ></composer-editor>
                        <span v-if="invalidInputs.message" class="el-form-item__error">{{ invalidInputs.message[0] }}</span>

                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24" style="text-align: right;margin-top: 10px">
                <el-button @click="handleSubmit" :disabled="sendingEmail" type="primary">Send <i class="el-icon-position"></i></el-button>
            </el-col>
        </el-row>
    </div>
</template>
<style>

</style>
<script>
import store from "../../store";
import ByTag from "./bulk/ByTag";
import ByDaterange from "./bulk/ByDaterange";
import ByDispositionStatus from "./bulk/ByDispositionStatus";
import ByLeadSource from "./bulk/ByLeadSource";
import ByDmsExport from "./bulk/ByDmsExport";
import ByImport from "./bulk/ByImport";
import ByFilters from "./bulk/ByFilters";

import ComposerEditor from "./composer/ComposerEditor";

export default {
    props: {
        dmsExportId: {
            type: Number,
            default: 0
        }
    },
    components: {
        ByTag,
        ByDaterange,
        ByDispositionStatus,
        ByLeadSource,
        ByDmsExport,
        ByImport,
        ByFilters,

        ComposerEditor,
    },
    data() {
        return {
            company: {},
            user: {},
            selectedContacts: [],
            activeTab: 'tag',
            mail: {
                recipients: [],
                subject: '',
                message: '',
                attachments: []
            },
            sendingEmail: false,
            isDmsExport: false,
            totalDmsExportContacts: 0,
            loadingContacts: false,
            activeInput: null,
            rules: {
                recipients: [
                    { type: 'array', required: true, message: 'The recipients is required.', trigger: 'blur' }
                ],
                subject: [
                    { required: true, message: 'The subject field is required.', trigger: 'blur' }
                ],
                message: [
                    { required: true, message: 'The message field is required.', trigger: 'blur' }
                ],
            },
            invalidInputs: {},
            containerKey: 0,
            editorKey: 0,
            importTagId: 0
        };
    },
    computed: {},
    mounted() {
        this.company = JSON.parse(store.state.currentCompany)
        this.user = JSON.parse(store.state.currentUser)

        if(this.dmsExportId > 0) {
            this.isDmsExport = true
            this.loadDmsExport(this.dmsExportId)
        }
    },
    methods: {
        handleTemplateSelect(template) {
            this.mail.subject = template.subject
        },
        handleSelectContacts(selectedContacts) {
            this.mail.recipients = []
            this.mail.recipients.push(selectedContacts)
        },
        handleSubmit() {
            this.sendingEmail = true
            let formData = new FormData

            for (const [key, value] of Object.entries(this.mail)) {
                if(key === 'recipients') {
                    value.forEach(val => {
                        formData.append('recipients[]', val)
                    })
                } else if (key === 'attachments') {
                    value.forEach(val => {
                        formData.append('attachments[]', JSON.stringify(val))
                    })
                } else {
                    formData.append(key, value)
                }
            }

            setTimeout(() => {
                if(!this.sendingEmail) {
                    return;
                }

                this.sendingEmail = false

                this.$emit('onProgress')

                this.mail = {
                    recipients: [],
                    subject: '',
                    message: '',
                    attachments: [],
                }
                return this.$message({
                        message: 'This bulk email campaign will show in your sent items within 30minutes.',
                        type: 'success'
                    })
            }, 10000);

            this.axios.post(
                `api/company/${this.company.id}/user/${this.user.id}/email/send-bulk`,
                formData
            )
            .then(() => {
                this.sendingEmail = false

                this.$emit('onProgress')

                this.mail = {
                    recipients: [],
                    subject: '',
                    message: '',
                    attachments: [],
                }
                return this.$message({
                        message: 'This bulk email campaign will show in your sent items within 30minutes.',
                        type: 'success'
                    })

            })
            .catch((err) => {
                this.sendingEmail = false

                return this.$notify({
                    title: 'Error',
                    message: 'Something went wrong while sending email.',
                    type: 'error'
                })
            })
        },
        loadDmsExport(dmsExportId) {
            this.dmsExportId = dmsExportId
            this.activeTab = 'dmsExport'
        },
        handleEditorUpdate(value) {
            this.mail.message = value
            this.activeInput = 'message'
        },
        handleFileAttachments(data) {
            this.mail.attachments = data
        }
    },
    watch: {
        dmsExportId: function(val, oldVal) {
            if(parseInt(val) > 0) {
                this.isDmsExport = true
                this.loadDmsExport(this.dmsExportId)
            }
        }
    },
};
</script>
