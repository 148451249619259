<template>
    <el-main>
        <div v-loading="loading" v-if="loading"></div>

        <el-tabs v-model="active_tab">
            <el-tab-pane
                name="Dashboard"
                label="Dashboard"
            >
                <div v-if="!loading">
                    <el-form>
                        <el-form-item label="Enable">
                            <el-switch v-model="formData.enable_integration" @change="handleSubmit"></el-switch>
                        </el-form-item>
                        <div v-if="formData.enable_integration">
                            <el-form-item label="Serial Number">
                                <el-input placeholder="Serial Number" v-model="formData.properties.serial_number"></el-input>
                            </el-form-item>
                            <el-row style="text-align: left; margin-top: 10px">
                                <el-button type="primary" @click="handleSubmit">Submit</el-button>
                            </el-row>
                        </div>
                    </el-form>
                </div>
            </el-tab-pane>
            <el-tab-pane
                name="API Logs"
                label="API Logs"
                v-if="company.pbs_integration"
            >
                <HttpLog
                    v-if="active_tab === 'API Logs'"
                    model_type="pbs"
                    :model_id="company.pbs_integration?.id"
                />
            </el-tab-pane>
        </el-tabs>
    </el-main>
</template>
<script>
import HttpLog from "../components/HttpLog/HttpLog"

export default {
    name: 'Information',
    components: {
        HttpLog,
    },
    data() {
        return {
            company: {},
            formData: {
                enable_integration: false,
                properties: {
                    serial_number: '',
                    agent_owned_contacts_only: '',
                },
            },
            loading: false,
            active_tab: 'Dashboard',
        }
    },
    mounted() {
        this.company = JSON.parse(this.$store.state.currentCompany)
        this.getLatestCompanyIntegration()
    },
    methods: {
        getLatestCompanyIntegration() {
            this.axios.get(`/api/company/simpid-${this.company.id}/integration/pbs`).then(response => {
                if( typeof response.data.status !== undefined ) {
                    this.formData.enable_integration = response.data.status !== 1 ? false : true
                    if( response.data.properties !== null && response.data.properties !== undefined ) {
                        this.formData.properties.serial_number = response.data.properties.serial_number
                        this.formData.properties.agent_owned_contacts_only = response.data.properties.agent_owned_contacts_only
                    }
                }
            })
        },
        handleSubmit() {
            this.axios.post(`api/company/${this.company.id}/integration/pbs`, {
                status: this.formData.enable_integration,
                properties: {
                    serial_number: this.formData.properties.serial_number,
                    agent_owned_contacts_only: this.formData.properties.agent_owned_contacts_only,
                },
            }).then(response => {
                if (response.data.success == true) {
                    this.$notify({
                        title: "Success",
                        message: "Company has been successfully Updated",
                        type: "success"
                    });
                } else {
                    this.$notify({
                        title: "Error",
                        message: response.data.message,
                        type: "error"
                    });
                }
            })
        }
    }
}

</script>
