<template>
    <div>
        <el-pagination
            layout="prev, pager, next"
            :total="emailLogData.total"
            :page-size="+emailLogData.per_page"
            :current-page="emailLogData.current_page"
            :page-count="emailLogData.last_page"
            @current-change="handleCurrentChange"
        >
        </el-pagination>

        <el-table
            :data="emailLogs"
            style="width: 100%"
            stripe
        >
            <el-table-column
                label="Email ID"
            >
                <template v-slot="props">
                    {{ props.row.email_id }}&nbsp;
                    <el-button
                        v-if="!withFilter"
                        :title="`Filter using this email ID: ${props.row.email_id}`"
                        round
                        size="mini"
                        @click="handleFilter('email_id', props.row.email_id)"
                    >
                        <i class="fas fa-filter"></i>
                    </el-button>
                    <el-button
                        v-else
                        :title="`Reset filter`"
                        round
                        size="mini"
                        @click="handleFilter('email_id', '')"
                    >
                        <i class="fas fa-minus-circle"></i>
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column
                label="Status"
                prop="event_status"
            >
            </el-table-column>
            <el-table-column
                label="Lead Phone Number"
            >
                <template v-slot="props">
                    {{ props.row.webhooks[0].contact.phone_number }}
                </template>
            </el-table-column>
            <el-table-column
                label="Date"
            >
                <template v-slot="props">
                    {{ formatDate(props.row.created_at) }}
                </template>
            </el-table-column>
            <el-table-column
                label="Action"
            >
                <template
                    v-slot="props"
                >
                    <el-button
                        size="mini"
                        @click="handleLogDetails(props.row)"
                    >View</el-button>
                </template>

            </el-table-column>
        </el-table>

        <el-pagination
            layout="prev, pager, next"
            :total="emailLogData.total"
            :page-size="+emailLogData.per_page"
            :current-page="emailLogData.current_page"
            :page-count="emailLogData.last_page"
            @current-change="handleCurrentChange"
        >
        </el-pagination>

        <email-log-details
          v-if="showDetails"
          :selected-log="selectedLog"
          :log-status="selectedLogStatus"
          @close-log-details="handleLogDetails"
        ></email-log-details>
    </div>

</template>

<script>
import EmailLogDetails from "./EmailLogDetails";

export default {
    name: "EmailDeliveryLogTable",
    components: {
        EmailLogDetails,
    },
    props: {
        emailLogData: {
            type: Array|Object,
            required: true
        },
        withFilter: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.handleLogData()
    },
    data() {
        return {
            loading: false,
            emailLogs: [],
            showDetails: false,
            selectedLog: {},
            selectedLogStatus: '',
        }
    },
    methods: {
        handleLogData() {
            this.emailLogs = this.emailLogData.data
        },
        handleLogDetails(data) {
            if(data) {
                this.selectedLogStatus = data.event_status
                this.selectedLog = data
            }

          this.showDetails = !this.showDetails
        },
        formatDate(date) {
            return date
        },
        handleCurrentChange(data) {
            return this.$emit('edl-pagination-change', data)
        },
        handleFilter(type, data = {}) {
            this.$emit('edl-email-filter', {type, data})
        }
    },
}

</script>
