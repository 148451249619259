<template>
    <div style="padding-left: 10px;">
        <h3>Leads for {{currentLeadSource.name}} <el-button
                        size="mini"
                        type="deafult"
                        @click="backToLeadSources"
                    >
                        &lt; Back to Lead Sources
                    </el-button></h3>
        <el-row :gutter="20" style="margin-top: 20px">
            <el-col :span="8">
                    <el-input
                        v-model="filters.q"
                        placeholder="Search for Customer"
                        clearable
                    />
                </el-col>
            <el-col :span="12">
                <el-date-picker
                    v-model="filters.daterange"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="-"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    @change="handleDaterangeChange"
                    :clear="handleDaterangeChange"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-col>
            <el-col :span="4" style="text-align: right;">
                <el-button-group>
                    <el-button
                        type="primary"
                        size="small"
                        title="Export to Excel"
                        @click="handleExport('excel')"
                        :loading="btnExcelIsLoading">Excel</el-button>
                    <el-button
                        type="primary"
                        size="small"
                        title="Export to PDF"
                        @click="handleExport('pdf')"
                        :loading="btnPDFIsLoading">PDF</el-button>
                </el-button-group>
            </el-col>
        </el-row>
        <el-table :data="reports">
            <el-table-column label="Name">
                <template slot-scope="scope">
                    <span v-if="scope.row.aloware_contact_url !== ''">
                        <el-link
                            type="primary"
                            :href="scope.row.aloware_contact_url"
                            target="_parent">{{scope.row.name}}
                        </el-link>
                        <br> {{scope.row.contact_number}}
                    </span>
                    <strong v-else>{{scope.row.name}}</strong>
                </template>
            </el-table-column>
            <el-table-column label="Sales Amount" prop="sales" :formatter="currencyFormat">
                <template slot-scope="scope">
                    <span v-if="scope.row.id !== 0">{{scope.row.sales}}</span>
                    <strong v-else>{{scope.row.sales}}</strong>
                </template>
            </el-table-column>
            <el-table-column label="Front Gross" prop="front_gross" :formatter="currencyFormat">
                <template slot-scope="scope">
                    <span v-if="scope.row.id !== 0">{{scope.row.front_gross}}</span>
                    <strong v-else>{{scope.row.front_gross}}</strong>
                </template>
            </el-table-column>
            <el-table-column label="Back Gross" prop="back_gross" :formatter="currencyFormat">
                <template slot-scope="scope">
                    <span v-if="scope.row.id !== 0">{{scope.row.back_gross}}</span>
                    <strong v-else>{{scope.row.back_gross}}</strong>
                </template>
            </el-table-column>
            <el-table-column label="Total Gross" prop="total_gross" :formatter="currencyFormat">
                <template slot-scope="scope">
                    <span v-if="scope.row.id !== 0">{{scope.row.total_gross}}</span>
                    <strong v-else>{{scope.row.total_gross}}</strong>
                </template>
            </el-table-column>
            <el-table-column label="Outbound Calls" prop="outbound_calls" :formatter="currencyFormat">
                <template slot-scope="scope">
                    <span v-if="scope.row.id !== 0">{{scope.row.outbound_calls}}</span>
                    <strong v-else>{{scope.row.outbound_calls}}</strong>
                </template>
            </el-table-column>
            <el-table-column label="Outbound SMS" prop="outbound_texts" :formatter="currencyFormat">
                <template slot-scope="scope">
                    <span v-if="scope.row.id !== 0">{{scope.row.outbound_texts}}</span>
                    <strong v-else>{{scope.row.outbound_texts}}</strong>
                </template>
            </el-table-column>
            <el-table-column label="Delivery Date">
                <template slot-scope="scope">
                    {{scope.row.delivery_date | formatDate}}
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            background
            @current-change="handlePageChange"
            :current-page.sync="currentPage"
            :page-size="10"
            layout="prev, pager, next"
            :total="totalRecords"
        ></el-pagination>
    </div>
</template>
<style>
.el-pagination {
    text-align: center;
    margin-top: 20px;
}
</style>
<script>
export default {
    props: [
        'lead_source',
        'dateRange'
    ],
    data() {
        return {
            errors: {},
            currentLeadSource: {
                id: 0
            },
            isProgress: false,
            reports: [],
            filters: {
                start_date: '',
                end_date: '',
                daterange: '',
                q: '',
                export_type: 'json'
            },
            totalRecords: 0,
            currentPage: 1,
            isSearching: false,
            btnExcelIsLoading: false,
            btnPDFIsLoading: false,
            pickerOptions: {
                shortcuts: [{
                    text: 'Last week',
                    onClick(picker) {
                        const now = new Date();
                        //previous sat
                        const end = new Date(new Date().setDate(now.getDate() - (now.getDay() == 0 ? 7 : now.getDay() + 1)));
                        //last sunday before sat
                        const start = new Date(end.getTime() - (6 * 24 * 60 * 60 * 1000));

                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last Month',
                    onClick(picker) {
                        const now = new Date();
                        const start = new Date(now.getFullYear(),now.getMonth() - 1,1);
                        const end = new Date(now.getFullYear(),now.getMonth(),0);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'This Month',
                    onClick(picker) {
                        const date = new Date();
                        const start = new Date(date.getFullYear(), date.getMonth(), 1);
                        const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last 3 months',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
        };
    },
    computed: {
    },
    beforeMount() {
        if(this.$cookies.isKey('prev_page')) {
            let prev_page = this.$cookies.get('prev_page')

            if(prev_page.page !== 'lead_source') return

            this.currentPage = prev_page.page_num
        }
    },
    mounted() {
        this.setDefaultDaterange();
        this.currentLeadSource = this.lead_source;

        // if(this.$cookies.isKey('prev_page')) {
        //     let prev_page = this.$cookies.get('prev_page')
        //
        //     if(!prev_page.page == 'lead_source') return
        //
        //     this.currentPage = prev_page.page_num
        // }

        this.getLeads();
    },
    methods: {
        getLeads(exportType = 'json') {

            if ( exportType == 'excel' ) {

                this.btnExcelIsLoading = true

            } else if(exportType == 'pdf') {

                this.btnPDFIsLoading = true

            }

            this.axios
                .get("/api/company/" + this.currentLeadSource.company_id + "/lead_source/" + this.currentLeadSource.id + "/report", {
                    params: {
                        page: this.currentPage,
                        q: this.filters.q,
                        export_type: exportType,
                        start_date: this.filters.daterange !== null ? this.$options.filters.toSqlDate(this.filters.daterange[0]) : '',
                        end_date: this.filters.daterange !== null ? this.$options.filters.toSqlDate(this.filters.daterange[1]) : '',
                    }
                })
                .then(response => {
                    if (response.data.success == true) {
                        if ( exportType !== 'json' ) {

                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = response.data.url;
                            a.target = "_blank";
                            document.body.appendChild(a);
                            a.click();

                            if ( exportType == 'excel' ) {

                                this.btnExcelIsLoading = false

                            } else if(exportType == 'pdf') {

                                this.btnPDFIsLoading = false

                            }

                        } else {
                            this.reports = response.data.data;
                            this.totalRecords = response.data.pagination.total;
                        }
                    } else {
                        this.$notify({
                            title: "Error",
                            message:
                                "There has been an error getting reports list",
                            type: "error"
                        });
                    }
                });
        },
        handlePageChange(page) {
            let prev_page = this.$cookies.get('prev_page')
            prev_page.page_num = page

            this.$cookies.remove('prev_page')
            this.$cookies.set('prev_page', prev_page)

            this.currentPage = page;

            this.getLeads();
        },
        currencyFormat(row, column) {
            return this.$options.filters.currency(row[''+ column.property +'']);
        },
        setDefaultDaterange() {
            // set default range: last 3 months
            /* const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90); */

            this.filters.daterange = this.dateRange;
            //this.filters.daterange = [start, end];
        },
        handleDaterangeChange() {
            this.currentPage = 1;
            this.isSearching = true;
            this.getLeads();
        },
        handleExport(exportType) {
            this.getLeads(exportType)
        },
        backToLeadSources () {
            this.$cookies.remove('prev_page')
            this.$emit('close')
        }
    },
    watch: {
        'filters.q': function(val, oldVal) {
            this.currentPage = 1;
            this.isSearching = true;
            this.getLeads();
        },
        'lead_source': function(val, oldVal) {
            this.currentPage = 1;
            this.isSearching = true;

            this.currentLeadSource = this.lead_source;

            this.getLeads();
        },
    }
};
</script>
