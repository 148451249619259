<template>
    <div
        v-loading="loading"
        :element-loading-text="loadingMsg"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        style="width: 100%; margin-top: 1rem;"
    >
        <el-row v-if="!loading">
            <el-col :offset="12" :span="12" style="text-align: right">
                <el-select v-model="company_id"
                       remote
                       filterable
                       clearable
                       :remote-method="loadCompanies"
                       @change="handleCompanyChange"
                       placeholder="All Companies"
                       :loading="loadingSearch"
                >
                    <el-option
                        v-for="item in companies"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-col>
        </el-row>
        <el-row
            type="flex"
            justify="space-between"
            align="bottom"
        >
            <el-col :span="3">
                <el-image v-if="company.logo" :src="company.logo" fit="cover"></el-image>
                <strong style="margin-left: 1rem;">{{ company.name }}</strong>
            </el-col>

            <el-col :span="12">
                <el-row
                    type="flex"
                    justify="end"
                >
                    <el-date-picker
                        v-model="filters.date_range"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="To"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                        :picker-options="pickerOptions"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MMM-dd"
                    >
                    </el-date-picker>
                </el-row>
                <el-row
                    type="flex"
                    justify="end"
                >
                    <el-input
                        v-model.lazy="filters.search"
                        prefix-icon="el-icon-search"
                        placeholder="Search content"
                        clearable
                        style="width: 350px;"
                    ></el-input>
                </el-row>
            </el-col>
        </el-row>
        <el-row v-if="showLogTable" >
            <EmailDeliveryLogTable
                v-loading="tableLoading"
                :key="tableKey"
                :email-log-data="emailLogData"
                :with-filter="withFilter"
                @edl-pagination-change="handlePagination"
                @edl-email-filter="handleFilter"
            ></EmailDeliveryLogTable>
        </el-row>
    </div>
</template>

<script>
import store from "../store";
import EmailDeliveryLogTable from "./email-delivery-log/EmailDeliveryLogTable";

export default {
    name: "EmailDelivery",
    components: {
        EmailDeliveryLogTable
    },
    data() {
        return {
            apiUrl: '',
            loading: true,
            tableLoading: false,
            loadingMsg: 'Retrieving data...',
            loadingSearch: false,
            company_id: null,
            companies: [],
            company: {},
            user: {},
            emailLogData: [],
            showLogTable: false,
            currentPage: 1,
            tableKey: 0,
            filters: {
                date_range: [],
                search: '',
                email_id: '',
            },
            withFilter: false,
            pickerOptions: {
                disabledDate: this.handleDisabledDate,
                onPick: this.handleOnPick,
                // shortcuts: [{
                //     text: 'Last week', todo after 1 week
                //     onClick(picker) {
                //         const end = new Date();
                //         const start = new Date();
                //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                //         picker.$emit('pick', [start, end]);
                //     }
                // }, {
                //     text: 'Last month', // todo after 1mo
                //     onClick(picker) {
                //         const end = new Date();
                //         const start = new Date();
                //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                //         picker.$emit('pick', [start, end]);
                //     }
                // }, {
                //     text: 'Last 3 months', // todo after 3mos
                //     onClick(picker) {
                //         const end = new Date();
                //         const start = new Date();
                //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                //         picker.$emit('pick', [start, end]);
                //     }
                // }]
            },
        }
    },
    watch: {
        'filters_str': {
            immediate: false,
            handler: function(n, o) {
                const self = this
                const newData = JSON.parse(n)
                const oldData = JSON.parse(o)

                if(newData.search !== oldData.search) {
                    this.emailLogData = []
                    this.tableKey = 0
                    this.tableLoading = true
                    setTimeout(function () {
                        self.getDeliveryLog()
                    }, 3000)
                    return
                }
                if(newData.email_id !== oldData.email_id) return

                this.getDeliveryLog()
            },
        },
    },
    computed: {
        filters_str() {
            return JSON.stringify(this.filters)
        }
    },
    mounted() {
        this.loadCompanies()
        Object.assign(this.company, JSON.parse(store.state.currentCompany))
        Object.assign(this.user, JSON.parse(store.state.currentUser))
        this.apiUrl = `/api/company/${this.company.id}/company-adf-email-delivery-log`
        if(this.company.id) {
            this.getDeliveryLog()
        }
    },
    methods: {
        startLoading() {
            this.loading = true
        },
        finishLoading() {
            this.loading = false
            this.tableLoading = false
        },
        resetPage() {
            this.company = {}
            this.showLogTable = false
        },
        async loadCompanies(query) {
            this.loadingSearch = true
            await this.axios
                .get("/api/company-search", {
                    params: {
                        page: 1,
                        q: query
                    }
                })
                .then(response => {
                    if (response.data.success) {
                        this.companies = response.data.data
                    }
                    this.loadingSearch = false
                });
        },
        handleCompanyChange(selectedCompany) {
            this.companies.forEach((e,i) => {
                if( selectedCompany === e.id ) {
                    this.resetPage()
                    this.company = Object.assign(this.company, e)
                    store.commit(
                        "UpdateCurrentCompany",
                        this.company
                    );
                    this.apiUrl = `/api/company/${this.company.id}/company-adf-email-delivery-log`
                    localStorage.removeItem('selectedFbPage')
                    return this.getDeliveryLog()
                }
            });
            return this.finishLoading()
        },
        async getDeliveryLog() {
            this.startLoading()
            this.emailLogData = []
            let qs = `?page=${this.currentPage}`
            qs += (Array.isArray(this.filters.date_range) && this.filters.date_range.length > 0) || this.filters.search || this.filters.email_id ? `&${this.$qs.stringify(this.filters)}` : ''
            await this.axios.get(`${this.apiUrl}${qs}`)
                .then(res => {
                    this.emailLogData = res.data
                    this.showLogTable = true
                    this.filters.email_id = ''
                    ++this.tableKey
                    this.finishLoading()
                })
                .catch(err => {
                    console.log('err?', err)
                    this.finishLoading()
                })
        },
        handlePagination(data) {
            this.currentPage = data
            this.getDeliveryLog()
        },
        handleDisabledDate(data) {
            return data < Date.parse('23 Nov 2022 08:00:00 GMT')
        },
        handleOnPick(maxDate, minDate) {
            return minDate < Date.parse('23 Nov 2022 08:00:00 GMT')
        },
        handleFilter(data) {
            this.withFilter = !!data.data
            this.currentPage = this.withFilter ? 0 : this.currentPage
            this.filters[data.type] = data.data

            return this.getDeliveryLog()
        },
    },
}
</script>

<style scoped>
.el-row {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}
</style>
