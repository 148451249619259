<template>
    <el-main>
        <el-tabs v-model="active_tab">
            <el-tab-pane
                name="Dashboard"
                label="Dashboard"
            >
                <Information
                    v-if="company.vin_solutions && active_tab === 'Dashboard'"
                />
                <Integrate
                    v-if="!company.vin_solutions && active_tab === 'Dashboard'"
                    @integration-success="integrationSuccess"
                    :key="integrate_key"
                />
            </el-tab-pane>
            <el-tab-pane
                name="API Logs"
                label="API Logs"
                v-if="company.vin_solutions"
            >
                <HttpLog
                    v-if="active_tab === 'API Logs'"
                    model_type="vs_dealer"
                    :model_id="company.vin_solutions?.id"
                />
            </el-tab-pane>
        </el-tabs>


    </el-main>
</template>

<script>
import Information from "./Information.vue";
import Integrate from "./Integrate.vue";
import HttpLog from "../components/HttpLog/HttpLog"

export default {
    name: 'VinSolutions',
    components: {
        HttpLog,
        Integrate,
        Information,
    },
    data() {
        return {
            company: {},
            integrate_key: 0,
            active_tab: 'Dashboard',
        }
    },
    mounted() {
        this.company = JSON.parse(this.$store.state.currentCompany)
    },
    methods: {
        integrationSuccess() {
            this.company = JSON.parse(this.$store.state.currentCompany)
            this.integrate_key = this.integrate_key++
        }
    }
}
</script>
