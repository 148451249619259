<template>
    <el-main>
        <el-row style="margin-bottom: 10px">
            <el-col :span="12">
                <el-tag type="info" effect="plain">
                    Found:
                    <strong>{{ totalRecords }}</strong> contacts
                </el-tag>
            </el-col>
            <el-col :span="12" style="text-align: right">
                <company-filter
                    style="margin-top: 1rem;"
                    @change="handleCompanyFilterChange"
                ></company-filter>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="9">
                <el-input
                    v-model="filters.q"
                    clearable
                    placeholder="Search for contact"
                />
            </el-col>
            <el-col :span="6" class="text-center" style="padding-left: 10px">
                    <el-date-picker
                        v-model="filters.daterange"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="-"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                        format="MM-dd-yyyy"
                        @change="handleDaterangeChange"
                        :clear="handleDaterangeChange"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                </el-col>
            <el-col :span="6" style="text-align: right">
            </el-col>
            <el-col :span="3" style="text-align: right">
                
                <!--<el-button-group>
                    <el-button
                        type="primary"
                        size="small"
                        title="Export to Excel"
                        @click="handleExport('excel')"
                        :loading="btnExcelIsLoading">Excel</el-button>
                    <el-button
                        type="primary"
                        size="small"
                        title="Export to PDF"
                        @click="handleExport('pdf')"
                        :loading="btnPDFIsLoading">PDF</el-button>
                </el-button-group>-->
            </el-col>
        </el-row>
        <el-table v-loading="isSearching" :data="contacts">
            <el-table-column label="Name" prop="name"></el-table-column>
            <el-table-column label="Email" prop="email"></el-table-column>
            <el-table-column label="Phone" prop="phone_number"></el-table-column>
            <el-table-column label="Created">
                <template slot-scope="scope">
                    {{scope.row.created_at | timestampFormatDateTime}}
                </template>
            </el-table-column>
            <el-table-column label="Company">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.lead_source !== null">{{ scope.row.lead_source.name }}</el-tag>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <!--<el-table-column align="right">
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="success"
                        @click="handleEdit(scope.$index, scope.row)"
                    >
                        Edit
                        <i class="fa fa-edit"></i>
                    </el-button>
                </template>
            </el-table-column>-->
        </el-table>
        <el-pagination
            background
            @current-change="handlePageChange"
            :current-page.sync="currentPage"
            :page-size="10"
            layout="prev, pager, next"
            :total="totalRecords"
        ></el-pagination>

        <el-dialog title="Import" :visible.sync="importFormVisible" :close-on-click-modal="false">
            <ImportContactsForm v-on:closeDialog="handleOnCloseImportForm"/>
        </el-dialog>
    </el-main>
</template>
<style>
.el-pagination {
    text-align: center;
    margin-top: 20px;
}
</style>
<script>
import store from "../../store";
import ImportContactsForm from "./ImportContactsForm.vue";
import CompanyFilter from "../admin/components/CompanyFilter";

export default {
    props: {
        showDealershipSelector: {
            type: Boolean,
            default: false
        }
    },
    components: {
        ImportContactsForm,
        CompanyFilter
    },
    data() {
        return {
            uploading: false,
            errors: {},
            isProgress: false,
            search: "",
            totalRecords: 0,
            currentPage: 1,
            isSearching: false,
            importFormVisible: false,
            selectedContact: {},
            btnExcelIsLoading: false,
            btnPDFIsLoading: false,
            company_id: 0,
            companies: [],
            contacts: [],
            company: {},
            user: {},
            filters: {
                start_date: '',
                end_date: '',
                daterange: '',
                q: '',
                export_type: 'json'
            },
            pickerOptions: {
                shortcuts: [{
                    text: 'Last week',
                    onClick(picker) {
                        const now = new Date();
                        //previous sat
                        const end = new Date(new Date().setDate(now.getDate() - (now.getDay() == 0 ? 7 : now.getDay() + 1)));
                        //last sunday before sat
                        const start = new Date(end.getTime() - (6 * 24 * 60 * 60 * 1000));

                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last Month',
                    onClick(picker) {
                        const now = new Date();
                        const start = new Date(now.getFullYear(),now.getMonth() - 1,1);
                        const end = new Date(now.getFullYear(),now.getMonth(),0);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'This Month',
                    onClick(picker) {
                        const date = new Date();
                        const start = new Date(date.getFullYear(), date.getMonth(), 1);
                        const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last 3 months',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
        };
    },
    computed: {},
    mounted() {
        this.company = Object.assign(this.company, JSON.parse(store.state.currentCompany));
        this.user = Object.assign(this.user, JSON.parse(store.state.currentUser));

        if( this.showDealershipSelector == true ) {
            this.loadCompanies('');
            this.company_id = this.company.name
        }
        this.setDefaultDaterange();
        this.loadContacts('json');
    },
    methods: {
        loadContacts(exportType) {
            let responseType = 'json';
            if ( exportType == 'excel' ) {
                this.btnExcelIsLoading = true;
                responseType = 'blob';
            } else if(exportType == 'pdf') {
                this.btnPDFIsLoading = true;
                responseType = 'blob';
            } else {
                this.isSearching = true;
            }

            this.axios({
                url: `api/company/${this.company.id}/contact`,
                method: 'GET',
                responseType: responseType,
                params: {
                        page: this.currentPage,
                        q: this.filters.q,
                        export_type: exportType,
                        start_date: this.filters.daterange !== null ? this.$options.filters.toSqlDate(this.filters.daterange[0]) : '',
                        end_date: this.filters.daterange !== null ? this.$options.filters.toSqlDate(this.filters.daterange[1]) : '',
                    }
                }).then(response => {
                    if ( exportType == 'excel' || exportType == 'pdf' ) {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', response.headers.filename);
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    } else {
                        this.contacts = response.data.data;
                        this.totalRecords = response.data.pagination.total;
                    }

                    if ( exportType == 'excel' ) {

                        this.btnExcelIsLoading = false

                    } else if(exportType == 'pdf') {

                        this.btnPDFIsLoading = false

                    } else {

                        this.isSearching = false

                    }
                });
        },
        loadCompanies(query) {
            this.axios
            .get("/api/company-search", {
                params: {
                    page: 1,
                    q: query
                }
            })
            .then(response => {
                if (response.data.success == true) {
                    this.companies = response.data.data;
                }
            });
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.loadContacts('json');
        },
        handleSearch(event) {
            console.log(event);
        },
        handleEdit(index, row) {
            this.selectedContact = row
            this.importFormVisible = true
        },
        handleOnCloseImportForm() {
            this.currentPage = 1;
            this.isSearching = true;
            this.importFormVisible = false;
            this.loadContacts('json');
        },
        handleImportContacts() {
            this.importFormVisible = true
        },
        handleCompanyFilterChange(data) {
            this.company = data
            store.commit(
                "UpdateCurrentCompany",
                this.company
            );
            this.loadContacts('json')
        },
        handleFilterChange(data) {
            this.company = data
            store.commit(
                "UpdateCurrentCompany",
                this.company
            );
            this.getLatestCompanyData()
        },
        setDefaultDaterange() {
            // set default range: last 3 months
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);

            this.filters.daterange = [start, end];
        },
        handleDaterangeChange() {
            this.currentPage = 1;
            this.isSearching = true;
            this.loadContacts('json');
        },
        handleExport(exportType) {
            this.loadContacts(exportType)
        },
    },
    watch: {
        search: function (val, oldVal) {
            this.currentPage = 1;
            this.isSearching = true;
            this.loadContacts('json');
        }
    }
};
</script>
