<template>
    <el-button class="p-x-md" plain>Messenger <span size="tiny" class="badge badge-danger text-sm"><strong id="unread-message-counter">{{ message_count.unread_count }}</strong></span></el-button>
</template>

<script>
import store from "../store";

export default {
    components: {},
    data() {
        return {
            aloware_company_id: this.$route.params.aloware_company_id,
            message_count: {
                unread_count: 0
            },
            ajaxMessageCount: null,
            is_init: true
        }
    },
    beforeMount() {
        this.getUnreadMessageCount()
    },
    mounted() {
        let scope = this
        setInterval(scope.getUnreadMessageCount, 5000)
    },
    methods: {
        getUnreadMessageCount: function () {
            let scope = this

            if(scope.ajaxMessageCount) return false

            scope.ajaxMessageCount = this.axios
                .get(this.companyApiBaseUrl + "/messenger/count", {
                    params: {
                        aloware_company_id: scope.aloware_company_id
                    }
                })
                .then(response => {
                    if(response.data.success) {
                        // if its the first time it initialize the value of unread count
                        // If new unread count is greater than the old unread count
                        if(!scope.is_init && response.data.data.unread_count > scope.message_count.unread_count) scope.playNotifSound()
                        else scope.is_init = false

                        setTimeout(function(){
                            scope.message_count.unread_count = parseInt(response.data.data.unread_count)
                        }, 10000)
                    }
                    scope.ajaxMessageCount = null
                })
                .catch(err => {
                    scope.ajaxMessageCount = null
                    this.getUnreadMessageCount()
                });
        },
        playNotifSound() {
            var audioCtx = new (window.AudioContext || window.webkitAudioContext)();
            var source = audioCtx.createBufferSource();
            var xhr = new XMLHttpRequest();
            xhr.open('GET', '/mp3/facebook_chat_sound.mp3');
            xhr.responseType = 'arraybuffer';
            xhr.addEventListener('load', function (r) {
                audioCtx.decodeAudioData(
                    xhr.response,
                    function (buffer) {
                        source.buffer = buffer;
                        source.connect(audioCtx.destination);
                        source.loop = false;
                    });
                source.start(0);
            });
            xhr.send();
        }
    }
}
</script>

<style scoped>
b, strong {
    font-weight: 700;
}
.el-button {
    font-weight: 400!important;
    font-size: 1rem!important;
    max-width: 145px!important;
}
.el-button--default:not(.el-button--primary):hover {
    background-color: rgba(0,191,80,.1)!important;
    color: #00bf50!important;
    border-color: rgba(0,191,80,.4)!important;
}
.el-button--default:not(.el-button--primary):focus {
    background-color: #fff!important;
    color: #00bf50!important;
    border-color: rgba(0,191,80,.5)!important;
}
.text-sm {
    font-size: .9rem!important;
}
.badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
}
.badge-danger {
    color: #fff;
    background-color: #dc3545;
}
</style>
