<template>
    <div class="search-contact">
        <el-row :gutter="20">
            <el-col :span="24">
                <el-select v-model="leadSource" placeholder="Select Lead Source" @change="handleChangeDisposition">
                    <el-option
                        v-for="item in leadSources"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="24">
                <el-card :body-style="{ padding: '0px', textAlign: 'center' }" style="display: inline-block;margin-top: 10px">
                    <div style="padding: 14px;">
                        <h3 v-if="loadingContacts">...</h3>
                        <h3 v-else>{{contactsCount}}</h3>
                        <div class="bottom clearfix" style="border-top: 1px solid #d7caca;padding-top: 10px;">
                            {{parseInt(contactsCount) | pluralize('Contact', 'Contacts')}}
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import store from "../../../store";

export default {
    data() {
        return {
            leadSources: [],
            leadSource: '',
            selectedContacts: [],
            loadingContacts: false,
            contactsCount: 0,
        };
    },
    computed: {
    },
    mounted() {
        this.company = JSON.parse(store.state.currentCompany)
        this.user = JSON.parse(store.state.currentUser)
        this.getCompanyleadSources()
    },
    methods: {
        getCompanyleadSources() {
            this.loadingContacts = true
            this.axios
            .get('/api/company/' + this.company.aloware_id + '/aloware-lead-sources')
            .then(response => {
                if (response.data.success == true) {
                    this.leadSources = response.data.data

                    this.loadingContacts = false
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        handleChangeDisposition() {
            this.loadingContacts = true
            let params = {
                lead_source: [this.leadSource]
            }
            this.axios
            .get('/api/company/' + this.company.aloware_id + '/aloware-contacts-count', {params: params})
            .then(response => {
                if (response.data.success == true) {
                    this.contactsCount = response.data.data
                    this.$emit('onSelectContacts', [`{lead_source:${this.leadSource}}`])
                    this.loadingContacts = false
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        handleSubmit() {
            this.$emit('onSelectContacts', this.selectedContacts)
        }
    }
};
</script>
