<template>
    <el-main style="overflow: hidden;">
        <el-row gutter="10">
            <el-col :span="10">
                <el-input
                    v-model="search"
                    size="small"
                    placeholder="Search for company"
                    clearable
                /> <p>Showing {{totalRecords}} Records</p>
            </el-col>
            <el-col :span="2">
                <el-switch
                    v-model="active_only"
                    :active-text="active_only ? 'Active Only' : 'All'"
                    :inactive-text="active_only ? '' : ''"
                    active-color="#67C23A"
                    inactive-color="#ff4949"
                >
                </el-switch>
            </el-col>
            <el-col :span="12" style="text-align: right">
                <el-button type="primary" @click="handleNewRequest">
                    <i class="fa fa-plus"></i> Submit Request
                </el-button>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24" :offset="0" :sm="{offset: 0}" :md="{offset: 0}" :xl="{offset: 3}">
                <keep-alive>
                    <el-table :data="records" v-loading="loading">
                        <el-table-column label="Status" width="100">
                            <template
                                v-slot="props"
                            >
                                <el-switch
                                    :value="formatStatus(props.row.status)"
                                    active-color="#67C23A"
                                    inactive-color="#ff4949"
                                    @change="confirmUpdateStatusRequest(props.row)"
                                >
                                </el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column label="Name" prop="company.name" width="300%"></el-table-column>
                        <el-table-column
                            label="Dealervault ID"
                            prop="dealer_id"
                            width="120"
                        ></el-table-column>
                        <el-table-column
                            label="File Type"
                            prop="file_type"
                            width="100"
                        ></el-table-column>
                        <el-table-column
                            label="Type"
                            prop="type"
                            width="100%"
                        ></el-table-column>
                        <el-table-column label="Last Import" width="200%">
                            <template slot-scope="scope">
                                <span v-if="scope.row.file_type == 'SL'">{{scope.row.company.dealervault_last_import_date}}</span>
                                <span v-else>{{scope.row.company.dealervault_last_service_import_date}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Requests" width="300%">
                            <template v-slot="scope">
                                <el-row
                                    type="flex"
                                    align="middle"
                                    style="align-content: space-between;"
                                >
                                        <el-button
                                            size="mini"
                                            type="info"
                                            @click="handleRequestImport(scope.$index, scope.row)"
                                            style="align-self: flex-start;"
                                        >
                                            Request Import
                                        </el-button>
                                        <el-button
                                            size="mini"
                                            type="success"
                                            @click="handleViewCImportDetails(scope.$index, scope.row)"
                                            style="align-self: flex-start;"
                                        >
                                            View Import Details
                                        </el-button>
                                </el-row>
                            </template>
                        </el-table-column>
                    </el-table>
                </keep-alive>
            </el-col>
        </el-row>
        <el-pagination
            background
            @current-change="handlePageChange"
            :current-page.sync="currentPage"
            :page-size="10"
            layout="prev, pager, next"
            :total="totalRecords"
        ></el-pagination>

        <DealervaultRequestDialog
            :visible.sync="dealervaultRequestDialogVisible"
            v-on:onSubmit="handleOnRequestSubmission" />

        <ImportDetails
            v-if="companyDetailsVisible"
            :selected-company="selectedCompany"
            @close-import-details="closeViewImportDetails"
        ></ImportDetails>
    </el-main>
</template>
<style>

</style>
<script>
import DealervaultRequestDialog from './DealervaulRequestDialog'
import ImportDetails from "./ImportDetails";

export default {
    mame: 'DealerVaultApiRequests',
    data() {
        return {
            uploading: false,
            errors: {},
            selectedCompany: {},
            isProgress: false,
            records: [],
            search: "",
            totalRecords: 0,
            currentPage: 1,
            isSearching: false,
            dealervaultRequestDialogVisible: false,
            companyDetailsVisible: false,
            loading: false,
            active_only: true,
        };
    },
    components: {
        DealervaultRequestDialog,
        ImportDetails,
    },
    mounted() {
        this.loadRecords();
    },
    methods: {
        loadRecords() {
            this.loading = true
            this.axios
                .get("/api/dealervault/request", {
                    params: {
                        page: this.currentPage,
                        q: this.search,
                        active_only: this.active_only,
                    }
                })
                .then(response => {
                    if (response.data.success == true) {
                        this.records = response.data.data;
                        this.totalRecords = response.data.pagination.total;
                    } else {
                        this.$notify({
                            title: "Error",
                            message: "There has been an error getting companies list",
                            type: "error"
                        });
                    }
                    this.loading = false
                });
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.loadRecords();
        },
        currencyFormat(row, column) {
            return this.$options.filters.currency(row.price);
        },
        handleNewRequest() {
            this.dealervaultRequestDialogVisible = true
        },
        handleViewCImportDetails(index, row) {
            this.selectedCompany = row
            this.companyDetailsVisible = true
        },
        async handleRequestImport(index, row) {
            await axios.post('/api/dealervault/manual-request', {
                the_manual: true,
                request_id: row.id,
                file_type: row.file_type
            })
                .then(res => {
                    return this.$notify({
                        type: 'success',
                        title: 'Queued',
                        message: res.data.message
                    })
                })
                .catch(err => {
                    console.log('err?', err)
                    return this.$root.handleErrors(err.response);
                })
        },
        handleCompanySuccessfullyCreated() {
            this.newCompanyDealershipVisible = false
            this.currentPage = 1
            this.isSearching = true
            this.loadRecords()
        },
        handleOnRequestSubmission() {
            this.loadRecords()
        },
        closeViewImportDetails() {
            this.selectedCompany = {}
            this.companyDetailsVisible = false
        },
        formatStatus(status) {
            return status === 1
        },
        async handleUpdateStatusRequest(data) {
            this.loading = true
            await this.axios.post('/api/dealervault/update-status-request', {
                request_id: data.id,
                status: data.status === 1 ? 0 : 1
            })
                .then(res => {
                    const updatedReq = res.data.data.request
                    const recIndex = this.records.findIndex(record => record.id === updatedReq.id)
                    if(recIndex !== -1) {
                        this.records[recIndex] = updatedReq
                        const updatedRecords = JSON.stringify(this.records)
                        this.records = []
                        this.records = JSON.parse(updatedRecords)
                    }
                    this.loading = false
                })
                .catch(err => {
                    console.log('err?', err)
                    this.loading = false
                    return this.$root.handleErrors(err.response)
                })
        },
        confirmUpdateStatusRequest(data) {
            const statusText = data.status ? 'deactivate' : 'activate'
            this.$confirm(
                `Are you sure to ${statusText} ${data.company.name} DealerVault request?`,
                statusText === 'activate' ? 'Information' : 'Warning',
                {
                    confirmButtonText: statusText.toUpperCase(),
                    cancelButtonText: 'CANCEL',
                    type: statusText === 'activate' ? 'information' : 'warning',
                }
            )
                .then(res => {
                    return this.handleUpdateStatusRequest(data)
                })
                .catch(err => {
                    return this.$message({
                        type: 'info',
                        message: 'Request canceled'
                    })
                })
        },
    },
    watch: {
        search: function(val, oldVal) {
            this.currentPage = 1
            this.isSearching = true
            this.loadRecords()
        },
        active_only: function(val) {
            this.currentPage = 1
            this.isSearching = true
            this.loadRecords()
        }
    }
};
</script>
