<template>
    <div>
        <CompanyReportsLeadSource
                v-bind:lead_source.sync="selectedLeadSource"
                v-on:close="showLeadSourceBlock=false"
                v-if="showLeadSourceBlock"
                :dateRange="filters.daterange"
            />
        <div v-else>
            <el-row :gutter="20" style="margin-top: 20px;">
                <el-col :span="9">&nbsp;</el-col>
                <el-col :span="6" class="text-center">
                    <el-date-picker
                        v-model="filters.daterange"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="-"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                        format="MM-dd-yyyy"
                        @change="handleDaterangeChange"
                        :clear="handleDaterangeChange"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                </el-col>
                <el-col :span="9" style="text-align: right;">
                    <span style="color:#909399;font-size:14px;">Table Filters: </span>
                    <el-select
                        v-model="headerToShow"
                        multiple
                        filterable
                        default-first-option
                        collapse-tags
                    >
                    <!-- <el-option label="All" value="all"></el-option> -->
                        <el-option
                            v-for="item in filterOptions"
                            :key="item.value"
                            :label="item"
                            :value="item">
                        </el-option>
                    </el-select>
                    <el-button-group>
                        <el-button
                            type="primary"
                            size="small"
                            title="Export to Excel"
                            @click="handleExport('excel')"
                            :loading="btnExcelIsLoading">Excel</el-button>
                        <el-button
                            type="primary"
                            size="small"
                            title="Export to PDF"
                            @click="handleExport('pdf')"
                            :loading="btnPDFIsLoading">PDF</el-button>
                    </el-button-group>
                </el-col>
            </el-row>
            <el-row :gutter="20" style="margin-top: 2px;" v-if="parseInt(adsReport.impressions) > 0 || parseInt(adsReport.spend) > 0">
                <el-col :span="24">
                    <span style="color: #909399;">Facebook Ads Report from <span v-if="filters.daterange.length > 0">{{ this.$options.filters.toSqlDate(filters.daterange[0]) | formatDate }} to {{ this.$options.filters.toSqlDate(filters.daterange[1]) | formatDate }}</span></span>
                </el-col>
            </el-row>
            <el-row :gutter="20" style="margin-top: 2px;" v-if="parseInt(adsReport.impressions) > 0 || parseInt(adsReport.spend) > 0">
                <el-col :span="24">
                    <div class="summary-block">
                        <div class="summary-header">Impressions</div>
                        <div class="summary-content">
                            <span v-if="adsLoading">...</span>
                            <span v-else>{{ adsReport.impressions | commaSeparated }}</span>
                        </div>
                    </div>
                    <div class="summary-block">
                        <div class="summary-header">Total Spent</div>
                        <div class="summary-content">
                            <span v-if="adsLoading">...</span>
                            <span v-else>{{ adsReport.spend | currency }}</span>
                        </div>
                    </div>
                    <div class="summary-block">
                        <div class="summary-header">Total Clicks</div>
                        <div class="summary-content">
                            <span v-if="adsLoading">...</span>
                            <span v-else>{{ adsReport.clicks | commaSeparated }}</span>
                        </div>
                    </div>
                    <div class="summary-block">
                        <div class="summary-header">Total Cost Per Click</div>
                        <div class="summary-content">
                            <span v-if="adsLoading">...</span>
                            <span v-else>{{ adsReport.total_cost_per_unique_click | currency }}</span>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-table v-loading="isSearching" :data="reports" class="reports-table">
                <el-table-column v-if="showHeader('Lead Source')" label="Lead Source" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <span v-if="scope.row.id">
                            <el-link
                                type="primary"
                                @click="handleSelectedLeadSource(scope.$index, scope.row)"
                            >
                                {{scope.row.name}}
                            </el-link>
                            <br>
                            <el-tag
                                size="mini"
                                type="info"
                                title="Monthly Ads Cost"
                                class="btn-ads-cost"
                                @click="handleSelectedLeadSourceForMonthlyBudget(scope.$index, scope.row)">
                                    Ads: {{scope.row.monthly_ads_cost}}
                            </el-tag>
                        </span>
                        <strong v-else>{{scope.row.name}}</strong>
                    </template>
                </el-table-column>
                <!--<el-table-column v-if="showHeader('Cost Per Sold')" label="Cost Per Sold">
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.id" type="primary" @click="handleSelectedLeadSource(scope.$index, scope.row)" :formatter="currencyFormat">
                            {{ scope.row.cost_per_sold }}
                        </el-link>
                        <strong v-else>{{scope.row.cost_per_sold}}</strong>
                    </template>
                </el-table-column>-->
                <el-table-column v-if="showHeader('Age')" label="Avg age" prop="age">
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.id" type="primary" @click="handleSelectedLeadSource(scope.$index, scope.row)" >
                            {{ scope.row.age }}
                        </el-link>
                        <strong v-else>{{scope.row.age}}</strong>
                    </template>
                </el-table-column>
                <el-table-column v-if="showHeader('Sold')" label="Sold" prop="number_of_sold">
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.id" type="primary" @click="handleSelectedLeadSource(scope.$index, scope.row)" >
                            {{ scope.row.number_of_sold }}
                        </el-link>
                        <strong v-else>{{scope.row.number_of_sold}}</strong>
                    </template>
                </el-table-column>
                <el-table-column v-if="showHeader('Sales')" label="Sales" prop="sales" :formatter="currencyFormat">
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.id" type="primary" @click="handleSelectedLeadSource(scope.$index, scope.row)" >
                            {{ scope.row.sales }}
                        </el-link>
                        <strong v-else>{{scope.row.sales}}</strong>
                    </template>
                </el-table-column>
                <el-table-column v-if="showHeader('Front Gross')" label="Front Gross" prop="front_gross" :formatter="currencyFormat">
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.id" type="primary" @click="handleSelectedLeadSource(scope.$index, scope.row)" >
                            {{ scope.row.front_gross }}
                        </el-link>
                        <strong v-else>{{scope.row.front_gross}}</strong>
                    </template>
                </el-table-column>
                <el-table-column v-if="showHeader('Back Gross')" label="Back Gross" prop="back_gross" :formatter="currencyFormat">
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.id" type="primary" @click="handleSelectedLeadSource(scope.$index, scope.row)" >
                            {{ scope.row.back_gross }}
                        </el-link>
                        <strong v-else>{{scope.row.back_gross}}</strong>
                    </template>
                </el-table-column>
                <el-table-column v-if="showHeader('Total Gross')" label="Total Gross" prop="total_gross" :formatter="currencyFormat">
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.id" type="primary" @click="handleSelectedLeadSource(scope.$index, scope.row)" >
                            {{ scope.row.total_gross }}
                        </el-link>
                        <strong v-else>{{scope.row.total_gross}}</strong>
                    </template>
                </el-table-column>
                <el-table-column v-if="showHeader('Avg Front')" label="Avg Front" prop="avg_front_gross" :formatter="currencyFormat">
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.id" type="primary" @click="handleSelectedLeadSource(scope.$index, scope.row)" >
                            {{ scope.row.avg_front_gross }}
                        </el-link>
                        <strong v-else>{{scope.row.avg_front_gross}}</strong>
                    </template>
                </el-table-column>
                <el-table-column v-if="showHeader('Avg Back')" label="Avg Back" prop="avg_back_gross" :formatter="currencyFormat">
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.id" type="primary" @click="handleSelectedLeadSource(scope.$index, scope.row)" >
                            {{ scope.row.avg_back_gross }}
                        </el-link>
                        <strong v-else>{{scope.row.avg_back_gross}}</strong>
                    </template>
                </el-table-column>
                <el-table-column v-if="showHeader('Avg Gross')" label="Avg Gross" prop="avg_total_gross" :formatter="currencyFormat">
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.id" type="primary" @click="handleSelectedLeadSource(scope.$index, scope.row)" >
                            {{ scope.row.avg_total_gross }}
                        </el-link>
                        <strong v-else>{{scope.row.avg_total_gross}}</strong>
                    </template>
                </el-table-column>
                <el-table-column v-if="showHeader('Outbound SMS')" label="OUTB SMS" prop="outbound_texts">
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.id" type="primary" @click="handleSelectedLeadSource(scope.$index, scope.row)" >
                            {{ scope.row.outbound_texts }}
                        </el-link>
                        <strong v-else>{{scope.row.outbound_texts}}</strong>
                    </template>
                </el-table-column>
                <el-table-column v-if="showHeader('Outbound Calls')" label="OUTB Calls" prop="outbound_calls">
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.id" type="primary" @click="handleSelectedLeadSource(scope.$index, scope.row)" >
                            {{ scope.row.outbound_calls }}
                        </el-link>
                        <strong v-else>{{scope.row.outbound_calls}}</strong>
                    </template>
                </el-table-column>
                <el-table-column v-if="showHeader('Avg Outbound SMS')" label="Avg Sms/sold" prop="avg_outbound_texts">
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.id" type="primary" @click="handleSelectedLeadSource(scope.$index, scope.row)" >
                            {{ scope.row.avg_outbound_texts }}
                        </el-link>
                        <strong v-else>{{scope.row.avg_outbound_texts}}</strong>
                    </template>
                </el-table-column>
                <el-table-column v-if="showHeader('Avg Outbound Calls')" label="Avg Calls/sold" prop="avg_outbound_calls">
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.id" type="primary" @click="handleSelectedLeadSource(scope.$index, scope.row)" >
                            {{ scope.row.avg_outbound_calls }}
                        </el-link>
                        <strong v-else>{{scope.row.avg_outbound_calls}}</strong>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                background
                @current-change="handlePageChange"
                :current-page.sync="currentPage"
                :page-size="10"
                layout="prev, pager, next"
                :total="totalRecords"
                style="margin-bottom: 20px"
            ></el-pagination>
        </div>

        <el-dialog width="30%" :title="selectedLeadSource.name" :visible.sync="showMonthlyAdsBudgetDialog">
            <el-form>
                <el-form-item label="Monthly Ads Cost">
                    <el-input v-model="selectedLeadSource.monthly_ads_cost"></el-input>
                </el-form-item>
                <el-row style="text-align: right">
                    <el-button @click="showMonthlyAdsBudgetDialog = false">Close</el-button> <el-button type="primary" @click="handleMonthlyBudgetSubmit">Submit</el-button>
                </el-row>
            </el-form>
        </el-dialog>
    </div>
</template>
<style>
.el-table.reports-table {
    font-size: 10.5px;
}
.el-table.reports-table th>.cell {
    padding: 0 5px;
}
.text-center {
    text-align: center;
}
.el-pagination {
    text-align: center;
    margin-top: 20px;
}
.btn-ads-cost {
    cursor: pointer;
}
</style>
<script>
import CompanyReportsLeadSource from './CompanyReportsLeadSource';

export default {
    props: [
        'selectedCompany'
    ],
    components: {
        CompanyReportsLeadSource
    },
    data() {
        return {
            headerToShow: [
                'Lead Source',
                'Cost Per Sold',
                'Age',
                'Leads',
                'Sold',
                'Sales',
                'Front Gross',
                'Back Gross',
                'Total Gross',
                'Avg Front',
                'Avg Back',
                'Avg Gross',
                'Outbound SMS',
                'Outbound Calls',
                'Avg Outbound SMS',
                'Avg Outbound Calls'
            ],
            filterOptions: [
                'Lead Source',
                'Cost Per Sold',
                'Age',
                'Leads',
                'Sold',
                'Sales',
                'Front Gross',
                'Back Gross',
                'Total Gross',
                'Avg Front',
                'Avg Back',
                'Avg Gross',
                'Outbound SMS',
                'Outbound Calls',
                'Avg Outbound SMS',
                'Avg Outbound Calls'
            ],
            errors: {},
            selectedLeadSource: {
                id: 0
            },
            showMonthlyAdsBudgetDialog: false,
            leadSourceForMonthlyBudget: {
                id: 0
            },
            showLeadSourceBlock: false,
            isProgress: false,
            reports: [],
            filters: {
                start_date: '',
                end_date: '',
                daterange: '',
                q: '',
                export_type: 'json'
            },
            overall: {
                sales: '',
                gross: '',
                total_count: '',
            },
            selection: {
                sales: '',
                gross: '',
                total_count: '',
            },
            totalRecords: 0,
            currentPage: 1,
            isSearching: false,
            btnExcelIsLoading: false,
            btnPDFIsLoading: false,
            company: this.selectedCompany,
            pickerOptions: {
                shortcuts: [{
                    text: 'Last week',
                    onClick(picker) {
                        const now = new Date();
                        //previous sat
                        const end = new Date(new Date().setDate(now.getDate() - (now.getDay() == 0 ? 7 : now.getDay() + 1)));
                        //last sunday before sat
                        const start = new Date(end.getTime() - (6 * 24 * 60 * 60 * 1000));

                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last Month',
                    onClick(picker) {
                        const now = new Date();
                        const start = new Date(now.getFullYear(),now.getMonth() - 1,1);
                        const end = new Date(now.getFullYear(),now.getMonth(),0);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'This Month',
                    onClick(picker) {
                        const date = new Date();
                        const start = new Date(date.getFullYear(), date.getMonth(), 1);
                        const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last 3 months',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            adsReport: {
                clicks: 0,
                impressions: 0,
                spend: 0,
                total_cost_per_unique_click: 0,
            },
            adsLoading: false
        };
    },
    beforeMount() {
        if(this.$cookies.isKey('prev_page')) {
            let prev_page = this.$cookies.get('prev_page')

            if(prev_page.page === 'lead_source') {
                this.handleSelectedLeadSource(prev_page.index, prev_page.data)
            }
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.setDefaultDaterange()
            this.loadReport()
            this.loadAdsReport()
        },
        getLastWeekday( date , weekday ) {
            var d = new Date(date)
            d.setDate( d.getDate() + weekday - d.getDay() )
            return d
        },
        handleTotal(key) {
            const data = this.reports
        },
        showHeader(header) {
            if(this.headerToShow.includes(header)) {
                return true;
            }
            return false;
        },
        loadReport(exportType = 'json') {
            if ( exportType == 'excel' ) {
                this.btnExcelIsLoading = true;
            } else if(exportType == 'pdf') {
                this.btnPDFIsLoading = true;
            } else {
                this.isSearching = true;
            }

            this.axios
                .get("/api/company/" + this.company.id + "/report", {
                    params: {
                        page: this.currentPage,
                        q: this.filters.q,
                        export_type: exportType,
                        start_date: this.filters.daterange !== null ? this.$options.filters.toSqlDate(this.filters.daterange[0]) : '',
                        end_date: this.filters.daterange !== null ? this.$options.filters.toSqlDate(this.filters.daterange[1]) : '',
                    }
                })
                .then(response => {
                    if (response.data.success == true) {
                        //this.overall = response.data.overall;
                        //this.selection = response.data.selection;

                        if ( exportType !== 'json' ) {

                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = response.data.url;
                            a.target = "_blank";
                            //a.setAttribute('download', 'asdasd.pdf');
                            document.body.appendChild(a);
                            a.click();
                           //window.URL.revokeObjectURL(response.data.url);

                           if ( exportType == 'excel' ) {

                                this.btnExcelIsLoading = false

                            } else if(exportType == 'pdf') {

                                this.btnPDFIsLoading = false

                            } else {

                                this.isSearching = false

                            }

                        } else {

                            this.reports = response.data.data;
                            this.totalRecords = response.data.pagination.total;
                            this.isSearching = false
                        }

                    } else {
                        this.isSearching = false
                        this.$notify({
                            title: "Error",
                            message:
                                "There has been an error getting reports list",
                            type: "error"
                        });
                    }
                });
        },
        handlePageChange(page) {
            this.currentPage = page
            this.loadReport()
        },
        currencyFormat(row, column) {
            return this.$options.filters.currency(row[''+ column.property +''])
        },
        setDefaultDaterange() {
            // set default range: last 3 months
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)

            this.filters.daterange = [start, end]
        },
        handleDaterangeChange() {
            this.currentPage = 1
            this.isSearching = true
            this.loadReport()
            this.loadAdsReport()
        },
        handleExport(exportType) {
            this.loadReport(exportType)
        },
        handleSelectedLeadSource(index, row) {
            if(!this.$cookies.isKey('prev_page')) {
                this.$cookies.set('prev_page', { page: 'lead_source', index: index, data: row, page_num: 1 })
            }

            this.selectedLeadSource = row
            this.showLeadSourceBlock = true
        },
        handleSelectedLeadSourceForMonthlyBudget(index, row) {
            this.selectedLeadSource = row
            this.showMonthlyAdsBudgetDialog = true
        },
        handleMonthlyBudgetSubmit() {
            this.axios
                .put(this.companyApiBaseUrl + '/lead_source/' + this.selectedLeadSource.id, this.selectedLeadSource)
                .then(response => {
                    if (response.data.success == true) {
                        this.loadReport();
                        this.$notify({
                            title: "Success",
                            message: "Lead Source has been successfully Updated",
                            type: "success"
                        });
                    } else {
                        let errors = response.data.errors.message;
                        let message = "";
                        for (let error in errors) {
                            message = errors[error] + "\n";
                        }
                        this.$notify({
                            title: "Error",
                            message: message,
                            type: "error"
                        });
                    }
                })
                .catch(err => {
                    this.$root.handleErrors(err.response);
                });
        },
        loadAdsReport() {
            this.adsLoading = true
            this.axios
                .get("/api/company/" + this.company.id + "/ads-report", {
                    params: {
                        start_date: this.filters.daterange !== null ? this.$options.filters.toSqlDate(this.filters.daterange[0]) : '',
                        end_date: this.filters.daterange !== null ? this.$options.filters.toSqlDate(this.filters.daterange[1]) : '',
                    }
                })
                .then(response => {
                    this.adsLoading = false
                    if (response.data.success == true) {
                        this.adsReport = response.data.data
                    } else { }
                });
        }
    },
    watch: {
        'filters.q': function(val, oldVal) {
            this.currentPage = 1;
            this.isSearching = true;
            this.loadReport();
        },
        /*'filters.daterange': function(val, oldVal) {
            this.currentPage = 1;
            this.isSearching = true;

            this.loadReport();
        },*/
    }
};
</script>
