<template>
    <el-container
        style="overflow: hidden;"
    >
        <el-header
            height="2rem;"
            style="line-height: 2rem;"
        >
            <headers
                v-if="contact"
                :contact="contact"
            ></headers>
        </el-header>

        <el-main style="min-height: inherit;">
            <div
                style="display: block; text-align: center"
            >
                <el-link
                    v-if="hasMorePages"
                    @click="loadMore"
                >
                    load more...
                </el-link>
            </div>

            <preview
                :contact="contact"
            ></preview>
        </el-main>
        <el-row
            type="flex"
            justify="center"
            align="center"
            style=""
        >
            <el-col :span="20" style="border: 1px solid #343639; min-height: 6rem;">
                <el-input
                    v-model="convo.message"
                    :key="convoInputKey"
                    type="textarea"
                    :rows="8"
                    autosize
                    autofocus
                    style="min-width: 100%; height: 3.5rem; overflow: hidden;"
                ></el-input>
                <engage-buttons
                    :key="btnsKey"
                    @show-inventory="handleShowInventory"
                    @send-credit-application-link="handleSendCreditApplicationLink"
                    @show-appointment="handleShowAppointment"
                    style="min-width: 100%; height: 2.5rem; padding: .75rem;"
                ></engage-buttons>
            </el-col>
            <el-col
                :span="4"
                style="text-align: center; margin: auto;"
            >
                <el-link
                    style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%;"
                    :disabled="!convo.message"
                    @click="submitConvo"
                >
                    SEND
                </el-link>
            </el-col>
        </el-row>
        <engage-inventory
            v-if="showEngageInventory"
            v-loading="loading"
            @show-inventory="handleShowInventory"
            @submit="handleSubmit"
            @send-link="handleSendLink"
        ></engage-inventory>
        <engage-appointment
            v-if="showEngageAppointment"
            v-loading="loading"
            @show-appointment="handleShowAppointment"
            @appointment-close="handleShowAppointment"
            @submit-appointment="handleSubmitAppointment"
        ></engage-appointment>
    </el-container>
</template>
<script>
    import Headers from "./components/Headers";
    import Preview from "./components/Preview";
    import EngageButtons from "./components/EngageButtons";
    import EngageInventory from "./components/EngageInventory";
    import EngageAppointment from "./components/EngageAppointment";

    export default {
        name: 'Chatbox',
        components: {
            Preview,
            Headers,
            EngageButtons,
            EngageInventory,
            EngageAppointment,
        },
        props: {
            contact: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                company: {},
                convo: {
                    message: ''
                },
                convoInputKey: 0,
                showEngageInventory: false,
                btnsKey: 10,
                showEngageAppointment: false,
            }
        },
        mounted() {
            this.company = JSON.parse(this.$store.state.currentCompany)
        },
        methods: {
            loadMore() {
                return this.$emit('convo-load-more')
            },
            submitConvo(e) {
                e.preventDefault()
                return this.$emit('submit-convo', this.convo)
            },
            handleShowInventory(data) {
                if(data) {
                    this.handleFetchInventory()
                } else {
                    this.showEngageInventory = data
                }
            },
            async handleFetchInventory(filters = null) {
                let queryFilter = {
                    aloware_user_id: this.contact.user_id,
                    contact_id: this.contact.id,
                }
                if(filters) {
                    queryFilter = {
                        ...queryFilter,
                        q: filters.q,
                        price_min: filters.prices[0],
                        price_max: filters.prices[1],
                    }
                }
                const qs = this.$qs.stringify(queryFilter, {allowDots: true})
                await this.axios.get(`/api/company/${this.company.aloware_id}/cars?${qs}`)
                    .then(res => {
                        if(res?.data) {
                            this.$store.commit('UpdateInventoryData', [])
                            this.$store.commit('UpdateInventoryData', res.data)
                            this.showEngageInventory = true
                        }
                        this.loading = false
                    })
                    .catch(err => {
                        this.$root.handleErrors(null)
                        this.showEngageInventory = false
                        this.loading = false
                    })
            },
            handleSubmit(data) {
                this.loading = true
                this.handleFetchInventory(data)
            },
            async handleSendLink(data) {
                this.loading = true
                await this.axios.post(
                    `/api/company/${this.company.aloware_id}/engage/actions/send_link`,
                    {
                        car_data: data,
                        aloware_contact: this.contact,
                    }
                )
                    .then(res => {
                        this.showEngageInventory = false
                        return this.$notify({
                            type: "success",
                            title: "Success",
                            message: 'Message has been queued.',
                        })
                    })
                    .catch(err => {
                        this.showEngageInventory = false
                        return this.$root.handleErrors(err.response)
                    })
            },
            async handleSendCreditApplicationLink() {
                await this.axios.get(
                    `/api/company/${this.company.aloware_id}/user/${this.contact.user_id}/contact/${this.contact.id}/get-credit-application-link?to_send=sms`
                )
                    .then(res => {
                        this.btnsKey++
                        return this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: 'Credit application link has been queued.'
                        })
                    })
                    .catch(err => {
                        this.btnsKey++
                        return this.$root.handleErrors(err.response)
                    })
            },
            handleShowAppointment(data) {
                this.showEngageAppointment = data
            },
            async handleSubmitAppointment(data) {
                await this.axios.post(
                    `/api/user/${this.contact.user_id}/event`,
                    data
                )
                    .then(res => {
                        console.log(res, 'res???')
                        this.showEngageAppointment = false
                        return this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: 'The request has been queued.'
                        })
                    })
                    .catch(err => {
                        return this.$root.handleErrors(err.response)
                    })
            }
        },
        computed: {
            hasMorePages() {
                return this.$store.getters.getEngageConvos().has_more_pages ?? false
            },
            messageInputStatus() {
                return this.$store.getters.getEngageInputStatus
            },
        },
        watch: {
            'messageInputStatus': function (n, o) {
                if(n) {
                    this.convo.message = ''
                    this.$store.commit('UpdateEngageInput', !n)
                }
            }
        }
    }
</script>
