<template>
    <el-container style="height: 100vh">
        <div v-if="isAuthenticated===false" style="display: inline-flex;width: 100%;text-align: center;">
            <el-link
                class="btn-authorize"
                type="primary"
                :href="'/messenger/authorize_app?company_id=' + user.company_id + '&redirect=messenger&type=user'"
                target="_parent"><i class="el-icon-top-right"></i> Authorize Simpsocial to manage your page
            </el-link>
        </div>
    </el-container>
</template>

<script>
import store from "../../store";
export default {
    name: 'personal-messenger',
    data() {
        return {
            vh: window.innerHeight * 0.01,
            uploading: false,
            errors: {},
            user: {},
            company: {},
            conversations: {},
            isProgress: false,
            messages: null,
            message: {
                recipient_id: 0,
                conversation_id: 0,
                message: '',
                message_type: 'text',
            },
            companyInventoryVisible: false,
            cars: [],
            search: "",
            pricerange: [0, 50000],
            price_min: 0,
            price_max: 50000,
            totalRecords: 0,
            currentPage: 1,
            isSearching: false,
            isPageReady: false,
            mediaFileVisible: false,
            giphySettings: {
                data: [],
                page: 1,
                offset: 0,
                limit: 10,
                total: 0,
                visible: false,
                loading: ''
            },
            templatesVisible: false,
            addAsContactVisible: false,
            selectedContact: {
                first_name: '',
                last_name: '',
                phone_number: '',
                messenger_id: '',
                aloware_contact_id: '',
            },
            loadConversationsRetry: 0,
            selectedConvo: {},
            moreActionOpened: false,
            isAuthenticated: false,
            auth: {
                auth_url: '',
            },
            variableOptions: [{
                'label': 'to include contact’s first name',
                'value': '[FirstName]',
            },{
                'label': 'to include contact’s last name',
                'value': '[LastName]',
            },{
                'label': 'to include contact’s full name',
                'value': '[FullName]'
            },{
                'label': 'to include contact’s company name',
                'value': '[AccountName]'
            },{
                'label': 'to include contact’s agent name',
                'value': '[AgentName]'
            }],
            selectedVariable: '',
            loadingMoreConvo: '',
            loadingMoreMessage: '',
            loadingSenderAction: '',
            senderAction: '',
            translationLanguages: {},
            selectedTransLang: 'es'
        };
    },
    computed: {},
    mounted() {
        document.documentElement.style.setProperty('--vh', `${this.vh}px`);
        this.user = JSON.parse(store.state.currentUser);
        this.company = JSON.parse(store.state.currentCompany);
        // this.authenticate();
        // console.log(Echo.connector.socket.connected)
        // console.log(this.company);
    },
    methods: {
        authenticate() {
            console.log(this.companyApiBaseUrl)
            // this.axios
            //     .get(`${this.companyApiBaseUrl}/messenger/auth`)
            //     .then(response => {
            //         if (response.data.success == true) {
            //             this.isAuthenticated = true;
            //
            //             // this.loadTranslationLanguages();
            //             // this.loadConversations();
            //             // this.loadGiphys();
            //             // this.loadCars();
            //         }
            //     })
            //     .catch(err => {
            //         this.$root.handleErrors(err.response);
            //     });
        },
    },
};
</script>
