<template>
    <el-container>
        <el-drawer
            :visible.sync="drawer"
            :direction="direction"
            :before-close="handleClose"
            :size="'98%'"
        >
            <el-header>{{`${logStatus} - ${selectedLog.email_id}`}}</el-header>
            <el-main>
                <div>
                    <p>Date: {{ selectedLog.created_at }}</p>
                </div>
                <el-tabs v-model="activeTab">
                    <el-tab-pane
                        v-if="selectedLog.email_content"
                        label="Email Content"
                        name="email-content"

                    >
                        <div style="padding-bottom: 2rem;">
                            <pre style="height: 100vh; width: 100vw; font-size: 12px;">
                                {{ formatEmailContent }}
                            </pre>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane
                        v-if="selectedLog.sns_content"
                        label="Delivery Report"
                        name="delivery-content"
                    >
                        <div>
                            <p><strong class="font-16">Message-ID:</strong> <span class="font-12">{{ messageId }}</span></p>
                            <p v-if="snsContent?.Message?.eventType === 'Delivery'">
                                <strong class="font-16">Recipient:</strong> <span class="font-12">{{ snsContent?.Message?.delivery?.recipients.toString() }}</span>
                            </p>
                            <p v-else ><strong class="font-16">Recipients:</strong> <span class="font-12">{{ formatCommonHeadersLog('to') }}</span></p>
                            <p><strong class="font-16">Subject:</strong> <span class="font-12">{{ formatCommonHeadersLog('subject') }}</span></p>
                            <p><strong class="font-16">Date:</strong> <span class="font-12">{{ formatCommonHeadersLog('date') }}</span></p>
                            <div
                                v-if="deliveryDelay && deliveryDelay.hasOwnProperty('delayedRecipients')"
                            >
                                <p class="font-16"><strong>Delayed Delivery Recipients:</strong> </p>
                                <ol>
                                    <li
                                        v-for="(content, i) in deliveryDelay.delayedRecipients"
                                        :key="i"
                                    >
                                        <ul>
                                            <li
                                                v-for="(recipient, j) in content"
                                                :key="j"
                                            >
                                                {{ formatKey(j) }} : {{ recipient }}
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                            <div
                                v-if="bounceDetails && bounceDetails.hasOwnProperty('bouncedRecipients')"
                            >
                                <p class="font-16"><strong>Bounced Delivery Recipients:</strong> </p>
                                <ol>
                                    <li
                                        v-for="(content, i) in bounceDetails.bouncedRecipients"
                                        :key="i"
                                    >
                                        <ul>
                                            <li
                                                v-for="(recipient, j) in content"
                                                :key="j"
                                            >
                                                {{ formatKey(j) }} : {{ recipient }}
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </el-main>
        </el-drawer>
    </el-container>
</template>

<script>
export default {
    name: "EmailLogDetails",

    props: {
        selectedLog: {
            type: Object,
            required: true
        },
        logStatus: {
            type: String,
            required: true,
            default: 'Queued'
        }
    },

    data() {
        return {
            direction: 'rtl',
            drawer: true,
            logDetails: {},
            logMessage: {},
            snsContent: {},
            activeTab: 'email-content',
            deliveryDelay: null,
            bounceDetails: null,
        }
    },

    mounted() {
        this.formatSelectedLog()
    },

    computed: {
        messageId() {
            return this.snsContent && this.snsContent.hasOwnProperty('MessageId') ? this.snsContent.MessageId : '-'
        },
        formatEmailContent() {
            const xJson = this.$xmljs.xml2json(this.selectedLog.email_content, {compact: true, spaces: 2})
            return this.$xmljs.json2xml(xJson, {compact: true, ignoreComment: false, spaces: 2})
        },
    },

    methods: {
        handleClose() {
            return this.$emit('close-log-details')
        },
        formatSelectedLog() {
            this.snsContent = this.selectedLog && this.selectedLog.hasOwnProperty('sns_content') ? JSON.parse(this.selectedLog.sns_content) : {}
            this.logMessage = this.snsContent && this.snsContent.hasOwnProperty('Message') ? JSON.parse(this.snsContent.Message) : {}
            this.activeTab = this.selectedLog.email_content ? 'email-content' : 'delivery-content'
            this.deliveryDelay = this.logMessage.hasOwnProperty('deliveryDelay') ? this.logMessage.deliveryDelay : null
            this.bounceDetails = this.logMessage.hasOwnProperty('bounce') ? this.logMessage.bounce : null

            if(this.snsContent) {
                Object.entries(this.snsContent).forEach(([key, value]) => {
                    if (value) {
                        try {
                            this.snsContent[key] = JSON.parse(value)
                        } catch (e) {
                            this.snsContent[key] = value
                        }
                    }
                })
            }
        },
        formatCommonHeadersLog(data, toStr = false) {
            if (this.activeTab === 'delivery-content') {
                let res = this.logMessage.mail.commonHeaders[data]

                if (toStr) {
                    res = res.toString()
                }
                return res
            }
        },
        formatKey(str) {
            const result = str.replace(/([A-Z])/g, " $1")
            return result.charAt(0).toUpperCase() + result.slice(1)
        }
    },
}
</script>

<style scoped>
.el-header, .el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
    line-height: 60px;
}
.el-drawer__body, .el-tab-pane {
    height: 100vh;
    /*max-height: 100%;*/
    /*height: 100%;*/
    overflow: auto;
}
.font-16 {
    font-size: 16px;
}
.font-12 {
    font-size: 12px;
}
</style>
