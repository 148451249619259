<template>
    <el-table :data="companies" class="admin-companies-table">
        <el-table-column label="Id" prop="id"></el-table-column>
        <el-table-column label="Alo Id" prop="aloware_id"></el-table-column>
        <el-table-column label="Name" prop="name"></el-table-column>
        <el-table-column label="Last Import">
            <template slot-scope="scope">
                <span>{{scope.row.importing_end | formatDate}}</span>
            </template>
        </el-table-column>
        <el-table-column label="Start Import">
            <template slot-scope="scope">
                <span>{{scope.row.importing_start | formatDate}}</span>
            </template>
        </el-table-column>
        <el-table-column label="Is Importing" prop="is_importing"></el-table-column>
        <el-table-column label="Is Mapped" prop="is_mapped"></el-table-column>
        <el-table-column label="Cars Count">
            <template slot-scope="scope">
                <span>N: {{scope.row.new_cars_with_img_count}}, U: {{scope.row.used_cars_with_img_count}}</span><br>
                <span>Total: {{scope.row.cars_count}}</span>
            </template>
        </el-table-column>
        <el-table-column label="Status">
            <template slot-scope="scope">
                <span>{{scope.row.is_correct_dealership}}</span>
            </template>
        </el-table-column>
        <el-table-column label="CSV Filename" prop="csv_filename"></el-table-column>
        <el-table-column align="right">
            <template slot-scope="scope">
                <el-button
                    size="mini"
                    type="deafult"
                    @click="handleViewCompanyDetails(scope.$index, scope.row)"
                >
                    Details
                </el-button>
                <el-button
                    size="mini"
                    type="deafult"
                    @click="handleViewUrlGenerator(scope.$index, scope.row)"
                >
                    Url Gen
                </el-button>
                <el-button
                    size="mini"
                    type="deafult"
                    :disabled="scope.row.dealervault_ftp_folder == null"
                    @click="handleDealervaultSalesImport(scope.$index, scope.row)"
                >
                    Import Sales
                </el-button>
                <el-button
                    size="mini"
                    type="success"
                    :disabled="scope.row.pending_for_import == false"
                    @click="handleMap(scope.$index, scope.row)"
                >
                    Map
                </el-button>

                <el-button
                    size="mini"
                    type="primary"
                    :disabled="scope.row.pending_for_import == false"
                    :loading="importingCompanyId == scope.row.id"
                    @click="handleImport(scope.$index, scope.row)"
                >
                    Import
                </el-button>
                <el-button
                    size="mini"
                    type="warning"
                    :loading="hidingCompanyId == scope.row.id"
                    @click="handleHideCompany(scope.row.id)"
                >
                    Hide
                </el-button>
                <el-button
                    size="mini"
                    type="deafult"
                    title="Download the latest processed CSV file"
                    @click="handleLatestProcessedFileDownload(scope.row.id)"
                >
                    Download File
                </el-button>
            </template>
        </el-table-column>
    </el-table>
</template>
<style>
.el-pagination {
    text-align: center;
    margin-top: 20px;
}
.el-dialog .el-select,
.el-dialog td > .el-input {
    width: 100%;
    margin-top: 6px;
}
.table-company-details td{
    border-bottom: 1px solid #ebeef5;
    padding: 5px;
}
</style>
<script>

export default {
    props: {
        companies: {
            type: Array
        },
        importingCompanyId: {
            type: Number
        },
        hidingCompanyId: {
            type: Number
        },
        handleViewCompanyDetails: {
            type: Function
        },
        handleViewUrlGenerator: {
            type: Function
        },
        handleMap: {
            type: Function
        },
        handleImport: {
            type: Function
        },
        handleHideCompany: {
            type: Function
        },
        handleDealervaultSalesImport: {
            type: Function
        },
        handleLatestProcessedFileDownload: {
            type: Function
        },
    },
    data() {
        return {};
    },
    components: {},
    computed: {},
    mounted() {},
    methods: {},
};
</script>
