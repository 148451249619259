<template>
    <div style="margin: .5rem 0;">
        <el-row type="flex" justify="end">
            <el-col
                :span="12"
                style="padding-right: 1rem;"
            >
                <el-badge
                    :hidden="true"
                    :value="comments.length"
                    :max="99"
                    :type="comments.length ? 'primary' : 'danger'"
                    style="float: right;"
                >
                    <el-button
                        @click="toggleComments(true)"
                        type="text"
                        style="padding: 0 !important;"
                        :disabled="!!!comments.length"
                    >
                        <i
                            class="far fa-comment-dots"
                            style="font-size: 2rem; color: #67C23A;"
                        ></i>
                    </el-button>
                    <small>{{ comments.length }}</small>
                </el-badge>
            </el-col>
        </el-row>
        <comments
            v-if="showComment"
            :comments="comments"
            :ad="ad"
            @close-comments="toggleComments"
        ></comments>
    </div>
</template>

<script>
import Comments from "./Comments";

export default {
    name: "CommentButton",
    components: {
        Comments,
    },
    props: {
        ad: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showComment: false
        }
    },
    mounted() {},
    computed: {
        comments() {
            return this.$store.getters.getTiktokAdGroupComments(this.ad.ad_id)
        },
        company() {
            return JSON.parse(this.$store.state.currentCompany)
        }
    },
    methods: {
        async toggleComments(show) {
            this.showComment = show
        },
    }
}
</script>
