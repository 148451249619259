<template>
    <el-row>
        <el-col
            :span="24"
        >
            <editor-content
                :editor="editor"
                ref="comment-editor"
                class="comment-editor"
            ></editor-content>
        </el-col>
    </el-row>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";

export default {
    name: "CommentEditor",
    components: {
        Editor,
        EditorContent,
    },
    data() {
        return {
            editor: null,
            extensions: [
                StarterKit,
                Placeholder.configure({
                    placeholder: 'Add comment...',
                })
            ],
            value: "",
            company: {},
            user: {}
        }
    },
    mounted() {
        this.setupComponent()
    },
    methods: {

        setupComponent() {
            this.company = JSON.parse(this.$store.state.currentCompany)
            this.user = JSON.parse(this.$store.state.currentUser)

            this.editor = new Editor({
                element: document.querySelector('.comment-editor'),
                content: this.value,
                extensions: this.extensions,
                autofocus: 'start',
                onUpdate: (e) => {
                    this.handleUpdate(e)
                },
            })
        },
        handleUpdate(e) {
            return this.$emit('update', this.editor.getText())
        },
    },
    beforeDestroy() {
        if(this.editor) {
            this.editor.destroy()
        }
    }
}
</script>

<style>
.comment-editor .ProseMirror{
    background-color: #fff;
    color: #000;
    margin: .1rem .1rem;
    padding: .1rem .1rem;
    /*line-height: .5rem !important;*/
}
.ProseMirror > * {
    margin: .1rem .1rem;
    padding: .1rem .3rem;
}
.ProseMirror:focus {
    outline: none !important;
}
.ProseMirror {
    border: 1px solid #000 !important;
    border-radius: 8px;
}
.ProseMirror p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}
</style>
