<template>
    <el-main>
        <el-tabs
            v-model="activeTab"
            type="border-card"
        >
            <el-tab-pane label="GENERAL" name="general">
                <CompanyProfile
                    v-if="activeTab === 'general'"
                />
            </el-tab-pane>
<!--            <el-tab-pane label="CRM/DMS INTEGRATIONS" name="crm-dms-integrations">-->
<!--                <Integraitions-->
<!--                    v-if="activeTab === 'crm-dms-integrations'"-->
<!--                />-->
<!--            </el-tab-pane>-->
            <el-tab-pane
                label="SEQUENCE EMAIL TEMPLATES"
                name="sequence_email_templates"
            >
                <SequenceEmailTemplate
                    v-if="activeTab === 'sequence_email_templates'"
                    :company="company"
                />
            </el-tab-pane>
            <el-tab-pane
                label="SALES DATA"
                name="sales_data"
            >
                <SalesData
                    v-if="activeTab === 'sales_data'"
                ></SalesData>
            </el-tab-pane>
        </el-tabs>

    </el-main>
</template>
<style>

</style>
<script>
import store from "../store";
import CompanyProfile from './CompanyProfile';
import SequenceEmailTemplate from './sequences/emails/EmailTemplates'
// import Integraitions from "./crm-dms-integrations/Integrations.vue";
import SalesData from "./sales-report/SalesReport";

export default {
    components: {
        SalesData,
        // Integraitions,
        CompanyProfile,
        SequenceEmailTemplate,
    },
    data() {
        return {
            activeTab: 'general',
            company:{
                dealervault_ftp_folder:''
            },
        };
    },
    computed: {},
    mounted() {
        this.company = JSON.parse(store.state.currentCompany);
    },
    methods: {

    }
};
</script>
