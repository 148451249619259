<template>
    <el-card
        style="margin-top: 1rem;"
    >
        <div slot="header">
            <span>Lead Synchronization</span>
        </div>
        <div
            v-loading="loading"
        >
            <el-form>
<!--                <el-row-->
<!--                    type="flex"-->
<!--                    class="mt-2"-->
<!--                >-->
<!--                    <el-select-->
<!--                        v-model="fbPage.page"-->
<!--                        placeholder="Select FB Page"-->
<!--                        value-key="id"-->
<!--                        filterable-->
<!--                        clearable-->
<!--                        style="float: right;"-->
<!--                        @change="handleFbPageChange"-->
<!--                    >-->
<!--                        <el-option-->
<!--                            v-for="item in registeredPages"-->
<!--                            :key="item.id"-->
<!--                            :label="`${item.name} - ${item.page_id}`"-->
<!--                            :value="item"-->
<!--                            :title="item.name"-->
<!--                        >-->
<!--                        </el-option>-->
<!--                    </el-select>-->
<!--                </el-row>-->
                <el-row
                    type="flex"
                    justify="space-between"
                    class="mt-2"
                >
                    <el-select
                        v-model="fbPageForm.forms"
                        placeholder="Select FB Page Form"
                        value-key="id"
                        filterable
                        clearable
                        style="float: right;"
                        :disabled="pageForms.length === 0"
                        multiple
                        collapse-tags
                    >
                        <el-option
                            v-for="item in pageForms"
                            :key="item.id"
                            :label="formatFormLabel(item)"
                            :value="item"
                            :title="item.name"
                        >
                        </el-option>
                    </el-select>
                </el-row>
                <el-row
                    type="flex"
                    class="mt-2"
                >
                    <el-date-picker
                        v-model="fbPageForm.dateRange"
                        type="daterange"
                        range-separator="To"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                        format="dd MMM yyyy"
                        value-format="yyyy-MM-dd"
                        :disabled="Array.isArray(fbPageForm.forms) && fbPageForm.forms.length < 1"
                    >
                    </el-date-picker>
                </el-row>
                <el-row
                    class="mt-2 txt-smaller"
                    v-if="Array.isArray(crmLeadIntakeEmails) && crmLeadIntakeEmails.length > 0"
                >
                    <el-col :span="24">
                        <small>Upon submission, the leads will be sent to Dealership CRM Intake Email: </small>
                    </el-col>
                    <el-col
                        :span="24"
                    >
                        <ul>
                            <li v-for="email in crmLeadIntakeEmails">
                                <small>{{ email }}</small>
                            </li>
                        </ul>
                    </el-col>
                </el-row>
                <el-row
                    class="mt-2 txt-smaller"
                    v-if="Array.isArray(crmLeadIntakeEmails) && crmLeadIntakeEmails.length < 1"
                >
                    <el-col :span="24">
                        <small style="color: red">Dealership CRM Intake Email must be set.</small>
                    </el-col>
                </el-row>
                <el-row
                    type="flex"
                    justify="end"
                    class="mt-2"
                >
                    <el-button
                        type="success"
                        @click="handleGetFormLeads"
                        :disabled="(Array.isArray(fbPageForm.dateRange) && fbPageForm.dateRange.length < 1) || (Array.isArray(crmLeadIntakeEmails) && crmLeadIntakeEmails.length < 1)"
                    >Submit</el-button>
                </el-row>
            </el-form>
        </div>
    </el-card>
</template>

<script>
export default {
    name: "FbLeadgenSynchronization",
    props: {
        registeredPages: {
            type: Array,
            required: true
        },
        apiEndpoint: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: true,
            fbPage: {},
            fbPageForm: {
                forms: [],
                dateRange: []
            },
            pageForms: [],
            crmLeadIntakeEmails: [],
            company: {},
        }
    },

    mounted() {
        this.toggleLoading(false)
        this.setLeadIntakeEmails()
    },

    methods: {
        setLeadIntakeEmails() {
            const company = JSON.parse(localStorage.getItem('currentCompany'))
            this.company = company
            this.crmLeadIntakeEmails = company.adf_email ? company.adf_email.split(',') : []
            if(this.company.plain_text_lead_intake_email) {
                const plainTxtIntakes = this.company.plain_text_lead_intake_email.split(',')
                plainTxtIntakes.forEach(intake => {
                    this.crmLeadIntakeEmails.push(intake)
                })
            }
            if(localStorage.getItem('selectedFbPage') !== null) {
                this.fbPage.page = this.registeredPages.find(page => page.page_id === JSON.parse(localStorage.getItem('selectedFbPage')).id)
            }
            return this.handleFbPageChange(this.fbPage.page)
        },

        async handleFbPageChange(data) {
            if(data.hasOwnProperty('id')) {
                this.toggleLoading(true)
                return await this.axios.get(`${this.apiEndpoint}/leadgen-forms?page_id=${this.fbPage.page.page_id}`)
                    .then(res => {
                        if(typeof res.data === 'string' && res.data.includes('error')) {
                            this.$notify({
                                type: "error",
                                message: res.data
                            })
                            return this.toggleLoading(false)
                        }
                        this.pageForms = this.sortItems(res.data.data.forms.data)
                        // this.fbPageForm.forms = res.data.data.forms.data
                        return this.toggleLoading(false)
                    })
                    .catch(err => {
                        this.$notify({
                            type: "error",
                            title: 'Something went wrong, please try again later.'
                        })
                        console.log('err?', err)
                        return this.toggleLoading(false)
                    })
            }
            return this.resetPageForm()
        },

        formatFormLabel(obj) {
            let name = 'Unspecified'
            if(typeof obj === 'object' && obj.hasOwnProperty('name') && obj.hasOwnProperty('id')) {
                name = obj.name.slice(0, 20) + '...' + obj.name.slice(-10) + ' - ' + obj.id
            }
            return name
        },

        toggleLoading(val) {
            this.loading = val
        },

        resetPageForm() {
            this.fbPage = {}
            this.pageForms = []
        },

        async handleGetFormLeads() {
            if(Array.isArray(this.fbPageForm.forms) && this.fbPageForm.forms.length > 0 && Array.isArray(this.fbPageForm.dateRange) && this.fbPageForm.dateRange.length > 0) {
                this.toggleLoading(true)
                const pageForms = this.fbPageForm.forms.map(form => form.id)
                const user = JSON.parse(localStorage.getItem('currentUser'))

                const qs = `${this.apiEndpoint}/leadgen-forms-leads?fb_forms=${pageForms}&date_range=${this.fbPageForm.dateRange}&fb_page_id=${this.fbPage.page.page_id}&user_id=${user.id}`
                await this.axios.get(`${qs}`)
                    .then(res => {
                        this.$notify({
                            type: 'success',
                            title: res.data.message,
                            duration: 15000
                        })
                        this.toggleLoading(false)
                    })
                    .catch(err => {
                        let response = {message: ''}
                        if(err.hasOwnProperty('request')) {
                            response = JSON.parse(err.request.response)
                        }
                        this.toggleLoading(false)
                        return this.$notify({
                            type: "error",
                            title: err.message,
                            message: response.message
                        })
                    })
            } else {
                this.$notify({
                    type: "error",
                    title: "Invalid Form Data"
                })
            }

        },

        sortItems(items) {
            return items.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            })
        },
    },

    destroyed() {
        this.resetPageForm()
    }
}
</script>

<style scoped>
    .mt-2 {
        margin-top: 0.5rem;
    }
    .txt-smaller {
        font-size: 14px;
    }
</style>
