<template>
    <el-dialog title="Add Lead" @close="$emit('update:visible', false)" :visible.sync="isVisible" width="30%">
        <div>
            <el-row>
                <el-col :span="24" style="padding-top: 5px">
                    First Name<br>
                    <el-input style="margin-top: 5px" v-model="selectedContact.first_name"></el-input>
                </el-col>
                <el-col :span="24" style="padding-top: 5px">
                    Last Name<br>
                    <el-input style="margin-top: 5px" v-model="selectedContact.last_name"></el-input>
                </el-col>
                <el-col :span="24" style="padding-top: 5px">
                    Contact Number<br>
                    <el-input style="margin-top: 5px" v-model="selectedContact.phone_number"></el-input>
                </el-col>
            </el-row>
        </div>
        <div slot="footer">
            <el-row>
                <el-col :span="12" style="text-align: left">
                    <el-button @click="isVisible=false">Cancel</el-button>
                </el-col>
                <el-col :span="12" style="text-align: right">
                    <el-button @click="handleSubmit" type="primary"><i class="el-icon-check"></i> Submit</el-button>
                </el-col>
            </el-row>
        </div>
    </el-dialog>
</template>
<style>
    .pull-right {
        float: right;
    }
</style>
<script>
export default {
    props: ['contact', 'visible'],
    data() {
        return {
            isVisible: false,
            selectedContact: {
                first_name: '',
                last_name: '',
                phone_number: '',
                messenger_id: '',
                aloware_contact_id: '',
            }
        };
    },
    computed: {
    },
    mounted() {
        this.selectedContact = this.contact;
    },
    methods: {
        handleSubmit() {
            this.axios
                .post(`${this.companyApiBaseUrl}/messenger/contact`, this.selectedContact)
                .then(response => {
                    if (response.data.success == true) {
                        this.$notify({
                            title: "Success",
                            message: "Contact has been successfully added",
                            type: "success"
                        });

                        this.selectedContact = response.data.data;
                        this.isVisible = false;

                    } else {
                        let errors = response.data.errors.message;
                        let message = "";
                        for (let error in errors) {
                            message = errors[error] + "\n";
                        }
                        this.$notify({
                            title: "Error",
                            message: message,
                            type: "error"
                        });
                    }
                })
                .catch(err => {
                    this.$root.handleErrors(err.response);
                });
        },
    },
    watch: {
        visible: function(val, oldVal) {
            this.isVisible = this.visible;
        },
        contact: function(val, oldVal) {
            this.selectedContact = this.contact;
        }
    }
};
</script>

<style>
    .form-company .el-form-item__label {
        width: 100%;
        text-align: left;
    }
</style>
