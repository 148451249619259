<template>
    <el-main>
        <div v-loading="loading" v-if="loading"></div>
        <el-tabs v-model="active_tab">
            <el-tab-pane
                name="Dashboard"
                label="Dashboard"
            >
                <div v-if="!loading">
                    <el-form>
                        <el-form-item label="Enable">
                            <el-switch v-model="formData.enable_cdk_integration" @change="handleSubmit"></el-switch>
                        </el-form-item>
                        <div v-if="formData.enable_cdk_integration == true">
                            <el-form-item label="Subscription ID">
                                <el-input
                                    type="text"
                                    placeholder="Subscription ID"
                                    v-model="formData.properties.subscription_id">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="Department ID">
                                <el-input
                                    type="text"
                                    placeholder="Department ID"
                                    v-model="formData.properties.department_id">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="Sales">
                                <el-checkbox
                                    v-model="formData.properties.include_sales"
                                >Yes / No</el-checkbox>
                            </el-form-item>
                            <el-form-item label="Service">
                                <el-checkbox
                                    v-model="formData.properties.include_service"
                                >Yes / No</el-checkbox>
                            </el-form-item>
                            <el-form-item label="Repair Order">
                                <el-checkbox
                                    v-model="formData.properties.include_repair_order"
                                >Yes / No</el-checkbox>
                            </el-form-item>
                            <el-form-item class="bottom text-right">
                                <el-button class="button" type="primary" @click="handleSubmit">save</el-button>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>
            </el-tab-pane>
            <el-tab-pane
                name="API Logs"
                label="API Logs"
                v-if="company.cdk_integration"
            >
                <HttpLog
                    v-if="active_tab === 'API Logs'"
                    model_type="cdk_sales"
                    :model_id="company.cdk_integration?.id"
                />
            </el-tab-pane>
        </el-tabs>
    </el-main>
</template>
<script>
import HttpLog from "../components/HttpLog/HttpLog"

export default {
    name: 'CDK-DMS-Integration',
    components: {
        HttpLog,
    },
    data() {
        return {
            company: {},
            formData: {
                enable_cdk_integration: false,
                properties: {
                    subscription_id: '',
                    department_id: '',
                    include_sales: '',
                    include_service: '',
                    include_repair_order: '',
                }
            },
            loading: false,
            active_tab: 'Dashboard',
        }
    },
    mounted() {
        this.company = JSON.parse(this.$store.state.currentCompany)
        
        this.getLatestCompanyIntegration()
    },
    methods: {
        getLatestCompanyIntegration() {
            this.axios.get(`/api/company/simpid-${this.company.id}/integration/cdk`).then(response => {
                if( typeof response.data.status !== undefined ) {
                    this.formData.enable_cdk_integration = response.data.status !== 1 ? false : true
                    if( response.data.properties !== null && response.data.properties !== undefined ) {
                        this.formData.properties.subscription_id = response.data.properties.subscription_id
                        this.formData.properties.department_id = response.data.properties.department_id
                        this.formData.properties.include_sales = response.data.properties.include_sales
                        this.formData.properties.include_service = response.data.properties.include_service
                        this.formData.properties.include_repair_order = response.data.properties.include_repair_order
                    }
                }
            })
        },
        handleSubmit() {
            this.axios.post(`api/company/${this.company.id}/integration/cdk`, {
                status: this.formData.enable_cdk_integration,
                properties: {
                    subscription_id: this.formData.properties.subscription_id,
                    department_id: this.formData.properties.department_id,
                    include_sales: this.formData.properties.include_sales,
                    include_service: this.formData.properties.include_service,
                    include_repair_order: this.formData.properties.include_repair_order,
                },
            }).then(response => {
                if (response.data.success == true) {
                    this.$notify({
                        title: "Success",
                        message: "Company has been successfully Updated",
                        type: "success"
                    });
                } else {
                    this.$notify({
                        title: "Error",
                        message: response.data.message,
                        type: "error"
                    });
                }
            })
        }
    }
}
</script>
