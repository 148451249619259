<template>
    <el-main
        v-loading="loading"
    >
        <el-header style="background-color: transparent; color: #706d6d;">
            <h2 v-if="!loading" >{{ drawerTitle }}</h2>
        </el-header>
        <el-row :gutter="20" style="margin-top: 1rem;">
            <el-col
                v-if="carsCount > 0"
                :xs="24"
                :md="8"
                :lg="8"
                :xl="6"
                v-for="(car) in cars"
                :key=" carToUpdate && carToUpdate === car.id ? Date.now()+car.id : car.id"
                style="margin-bottom: 20px;"
            >
                <feed-card
                    :car="car"
                    :feed-status="feedStatus"
                    @confirmSetCurrentImg="confirmSetCurrentImg"
                ></feed-card>
            </el-col>
            <el-empty
                v-if="carsCount < 1 && !loading"
            />
        </el-row>
    </el-main>
</template>

<script>
import FeedCard from "./FeedCard";

export default {
    name: "FbFeedValidator",
    components: {
        FeedCard
    },
    props: {
        feedStatus: {
            type: String,
            Required: true,
        },
    },
    data() {
        return {
            loading: false,
            company: {},
            cars: [],
            carsCount: 0,
            carToUpdate: 0,
        }
    },
    mounted() {
        this.setupComponent()
    },
    computed: {
        drawerTitle() {
            let status = this.feedStatus.replace('-', ' ')
            status = status.replace('_', ' ')
            return `${this.company.name} : ${this.carsCount} ${status.toUpperCase()}`
        }
    },
    methods: {
        setupComponent() {
            this.company = JSON.parse(this.$store.state.currentCompany)
            this.handleSubmit()
        },
        async handleSubmit() {
            this.loading = true
            await this.axios.get(`/api/company/${this.company.slug}/fb-feed-validator/${this.feedStatus}`)
                .then(res => {
                    this.cars = res.data.data.cars
                    this.carsCount = res.data.data.total_cars
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    this.$root.handleErrors(err.response)
                })
        },
        async confirmSetCurrentImg(data) {
            await this.$alert(`<img width="100%" src="${data.img.url}">`, `Set this image of ${data.car.vin} as default?`, {
                dangerouslyUseHTMLString: true
            })
                .then(res => {
                    if(res === 'confirm') {
                        return this.setCarImageAsDefault(data)
                    }
                })
                .catch(err => {
                    return this.$notify({
                        type: 'error',
                        title: 'Cancelled',
                        message: 'Action has been cancelled.'
                    })
                })
        },
        async setCarImageAsDefault(data) {
            this.loading = true
            await this.axios.post(
                `api/company/${this.company.id}/car-gallery-position`,
                data
            )
                .then(res => {
                    const updatedCar = res.data.data.car
                    this.carToUpdate = updatedCar.id
                    this.cars = this.cars.map((car) => car.id === updatedCar.id ? updatedCar : car)

                    this.loading = false
                    return this.$notify({
                        type: "success",
                        title: "Success",
                        message: "Feed image has been updated."
                    })
                })
                .catch(err => {
                    console.log('err?', err)
                    return this.$root.handleErrors(err.response)
                })
        }
    },
    beforeDestroy() {
        this.cars = []
    }
}
</script>
