<template>
    <el-card
        v-loading="loading"
        style="margin-top: 1rem;"
    >
        <div slot="header">
            <span>Lead Intake - FB Form/s</span>
            <el-button
                :type="showForm ? 'danger' : 'primary'"
                round
                size="mini"
                @click="handleForm(showForm ? 'close' : 'add')"
                style="float: right;"
            >
                <i :class="showForm ? 'el-icon-circle-close' : 'el-icon-circle-plus-outline'"></i> {{ showForm ? 'Close' : 'Add' }}
            </el-button>
        </div>
        <el-row
            type="flex"
            justify="end"
        >
        </el-row>

        <el-form
            v-model="leadIntakeForm"
            v-if="showForm"
            @submit="handleSubmit"
        >
            <el-row
                type="flex"
                justify="start"
            >
                <el-form-item>
                    <div class="sub-title">Status</div>
                    <el-switch
                        v-model="leadIntakeForm.status"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                    />
                </el-form-item>
            </el-row>
            <el-row
                type="flex"
                justify="start"
            >
                <el-form-item>
                    <div class="sub-title">Name</div>
                    <el-input v-model="leadIntakeForm.name"/>
                </el-form-item>
            </el-row>
            <el-row
                type="flex"
                justify="space-between"
            >
                <el-col
                    :span="24"
                >
                    <el-form-item>
                        <div class="sub-title">FB Lead Intake Recipients</div>
                        <el-select
                            v-model="leadIntakeForm.emails"
                            multiple
                            placeholder="Intake Emails"
                            :filterable="true"
                            :allow-create="true"
                        >
                            <el-option
                                v-for="(item, i) in intakeEmails"
                                :key="i"
                                :label="item"
                                :value="item"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row
                type="flex"
                justify="space-between"
            >
                <el-col
                    :span="24"
                >
                    <el-form-item>
                        <div class="sub-title">FB Lead Forms</div>
                        <el-select
                            v-model="leadIntakeForm.page_forms"
                            multiple
                            placeholder="FB Lead Forms"
                            :filterable="true"
                            value-key="id"
                        >
                            <el-option
                                v-for="item in pageForms"
                                :key="item.id"
                                :label="formatFormLabel(item)"
                                :value="item"
                                :title="item.name"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row
                type="flex"
                justify="end"
            >
                <el-button
                    type="success"
                    @click="handleSubmit"
                    :disabled="!leadIntakeForm.name"
                >
                    Save
                </el-button>
            </el-row>

        </el-form>
        <div
            v-if="typeof leadIntakeForm.page_id !== 'object' && !leadIntakeForm.page_id.hasOwnProperty('id')"
        >
            <h5 style="text-align: center;">Select FB Page</h5>
        </div>

        <hr v-if="showForm">
        <el-table
            :data="pageLeadIntakes"
            stripe
            style="margin-top: 1rem;"
        >
            <el-table-column type="expand">
                <template v-slot="props">
                    <div>
                        <strong>Status: </strong> {{props.row.status ? 'Active' : 'Deactivated'}}
                    </div>
                    <div>
                        <strong>Emails:</strong>
                        <ul>
                            <li
                                v-for="(email, i) in JSON.parse(props.row.intake_emails)"
                                :key="i"
                            >{{ email }}</li>
                        </ul>
                    </div>
                    <div>
                        <strong>Forms:</strong>
                        <ul>
                            <li
                                v-for="(form, i) in JSON.parse(props.row.page_forms)"
                                :key="i"
                            >
                                <el-tooltip class="item" effect="dark" :content="pageForms.find(pForm => pForm.id === form).name" placement="top">
                                    <div v-html="formatFormLabel(pageForms.find(pForm => pForm.id === form))"></div>
                                </el-tooltip>
                            </li>
                        </ul>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="Status" width="75px;">
                <template v-slot="props">
                    <el-button
                        size="mini"
                        :type="props.row.status ? 'success' : 'danger'"
                        circle
                        disabled
                    ></el-button>
                </template>
            </el-table-column>
            <el-table-column
                label="Name"
                prop="name"
            ></el-table-column>
            <el-table-column label="Action"  width="100px;">
                <template v-slot="props">
                    <el-row
                        type="flex"
                        justify="start"
                    >
                        <el-button
                            v-for="(action, i) in buttonActions"
                            :key="i"
                            size="mini"
                            :type="action.type"
                            round
                            @click="handleForm(action.action, props.row)"
                        >
                            {{ action.label }}
                        </el-button>
                    </el-row>
                </template>
            </el-table-column>
        </el-table>
    </el-card>
</template>

<script>
export default {
    name: "FbLeadIntakeByFbForms",
    props: {
        registeredPages: {
            type: Array,
            required: true
        },
        endpoint: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            // selectedPage: {},
            leadIntakeForm: {
                name: '',
                status: true,
                page_id: {},
                emails: [],
                page_forms: []
            },
            intakeEmails: [],
            pageForms: [],
            pageLeadIntakes: [],
            showForm: false,
            buttonActions: [
                {
                    action: 'edit',
                    label: 'Edit',
                    type: 'info',
                }
            ],
            formTried: 0,
        }
    },
    mounted() {
        if(localStorage.getItem('selectedFbPage') !== null) {
            this.leadIntakeForm.page_id = this.registeredPages.find(page => page.page_id === JSON.parse(localStorage.getItem('selectedFbPage')).id)
        }
        if(this.leadIntakeForm.page_id) {
            delete this.leadIntakeForm.page_id.webhooks
            this.handleFbPageSelect()
        }
    },
    methods: {
        startLoading() {
            this.loading = true
        },
        endLoading() {
            this.loading = false
        },
        async handleFbPageSelect() {
            if(!this.leadIntakeForm.page_id){
                return
            }
            this.startLoading()
            await this.axios.get(`${this.endpoint}/leadgen-forms?page_id=${this.leadIntakeForm.page_id.page_id}`)
                .then(res => {
                    this.formTried = ++this.formTried
                    if(typeof res.data === 'string' && res.data.includes('error')) {
                        console.log('tried?', this.formTried)
                        if(this.formTried < 4) return this.handleFbPageSelect()
                        this.$notify({
                            type: "error",
                            message: res.data
                        })
                        return
                    }
                    this.formTried = 0

                    this.pageForms = this.sortItems(res.data.data.forms.data)
                    this.pageLeadIntakes = res.data.data.page_lead_intakes
                    /**
                    if(res.data.data.page_lead_intake) {

                        if (res.data.data.page_lead_intake.hasOwnProperty('intake_emails')) {
                            this.leadIntakeForm.emails = JSON.parse(res.data.data.page_lead_intake.intake_emails)
                        }
                        if (res.data.data.page_lead_intake.hasOwnProperty('page_forms')) {
                            const forms = JSON.parse(res.data.data.page_lead_intake.page_forms)
                            forms.forEach(id => {
                                const data = {id}
                                this.leadIntakeForm.page_forms.push(data)
                            })
                        }

                        if(res.data.data.page_lead_intake.hasOwnProperty('status')) {
                            this.leadIntakeForm.status = res.data.data.page_lead_intake.status
                        }
                    }
                    **/
                    return this.endLoading()
                })
                .catch(err => {
                    this.endLoading()
                    let response = {message: ''}
                    if(err.hasOwnProperty('request') && err.request.hasOwnProperty('response')) {
                        response = JSON.parse(err.request.response)
                    }

                    return this.$notify({
                        type: "error",
                        title: err.message,
                        message: response.message
                    })
                })
        },
        async handleSubmit() {
            this.startLoading()
            await this.axios.post(
                `${this.endpoint}/lead-intake-by-fb-page-forms`,
                this.leadIntakeForm
            )
                .then(res => {
                    this.leadIntakeForm.status = res.data.data.page_lead_intake.status
                    this.leadIntakeForm.emails = JSON.parse(res.data.data.page_lead_intake.intake_emails)
                    const forms = JSON.parse(res.data.data.page_lead_intake.page_forms)
                    this.leadIntakeForm.page_forms = []
                    forms.forEach(id => {
                        const data = {id}
                        this.leadIntakeForm.page_forms.push(data)
                    })
                    this.$notify({
                        type: 'success',
                        title: "Success"
                    })
                    this.handleFbPageSelect()
                    return this.resetForm()
                })
                .catch(err => {
                    this.endLoading()
                    let response = {message: ''}
                    if(err.hasOwnProperty('request') && err.request.hasOwnProperty('response')) {
                        response = JSON.parse(err.request.response)
                    }

                    return this.$notify({
                        type: "error",
                        title: err.message,
                        message: response.message
                    })
                })
        },
        sortItems(items) {
            return items.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            })
        },
        formatFormLabel(obj) {
            return `${obj.name.slice(0, 20)}... ${obj.name.slice(-10)} - ${obj.id}`
        },

        handleForm(action, data) {
            if(typeof action === 'string') {
                if(action === 'close') {
                    return this.resetForm()
                }

                if(action === 'add') {
                    this.leadIntakeForm.name = `${this.leadIntakeForm.page_id.name} - 0${++this.pageLeadIntakes.length}`
                    this.showForm = true
                    return
                }

                if(action === 'edit') {
                    this.resetForm()
                    JSON.parse(data.page_forms).forEach(id => {
                        const data = {id}
                        this.leadIntakeForm.page_forms.push(data)
                    })

                    this.leadIntakeForm.emails = data.intake_emails ? JSON.parse(data.intake_emails) : []
                    this.leadIntakeForm.id = data.id
                    this.leadIntakeForm.name = data.name ?? `${this.leadIntakeForm.page_id.name} - 0${++data.id}`
                    this.leadIntakeForm.status = data.status
                    this.showForm = true
                    return
                }
            }
        },

        resetForm() {
            this.leadIntakeForm = {
                name: '',
                status: true,
                page_id: this.leadIntakeForm.page_id,
                emails: [],
                page_forms: []
            }
            this.showForm = false
        }
    },
    beforeDestroy() {
        this.leadIntakeForm = {}
        this.intakeEmails = []
    }
}
</script>
