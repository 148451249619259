<template>
    <el-main>
        <div class="page-title">
            <h1 style="display: inline-block">Account</h1>
        </div>
        <el-form>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="Name">
                        <el-input v-model="user.name"></el-input>
                    </el-form-item>
                    <el-form-item label="Address">
                        <el-input v-model="user.address"></el-input>
                    </el-form-item>
                    <el-form-item label="Phone">
                        <el-input v-model="user.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="Email">
                        <el-input v-model="user.email"></el-input>
                    </el-form-item>
                    <el-row style="text-align: left">
                        <el-button type="primary" @click="handleSubmit">Submit</el-button>
                    </el-row>
                </el-col>
            </el-row>
        </el-form>
    </el-main>
</template>
<style>
.el-image {
    max-height: 300px;
}
</style>
<script>
import store from "../store";

export default {
    data() {
        return {
            uploading: false,
            errors: {},
            user: {},
            isProgress: false
        };
    },
    computed: {},
    mounted() {
        this.user = JSON.parse(store.state.currentUser);
        //console.log(this.company);
    },
    methods: {
        handleSubmit() {
            this.axios
                .put(
                    this.companyApiBaseUrl + "/user/" + this.user.aloware_id,
                    this.user
                )
                .then(response => {
                    if (response.data.success == true) {
                        store.commit("UpdateCurrentUser", response.data.data);
                        this.$notify({
                            title: "Success",
                            message:
                                "You Account has been successfully Updated",
                            type: "success"
                        });
                    } else {
                        let errors = response.data.errors.message;
                        let message = "";
                        for (let error in errors) {
                            message = errors[error] + "\n";
                        }
                        this.$notify({
                            title: "Error",
                            message: message,
                            type: "error"
                        });
                    }
                })
                .catch(err => {
                    this.$root.handleErrors(err.response);
                });
        }
    }
};
</script>
