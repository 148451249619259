<template>
    <div>
        <h2 style="margin-top: 0">{{currentCompany.name}}</h2>
        <p style="color: #C0C4CC; margin-top: -15px;">
            Filename: {{currentCompany.csv_filename ? currentCompany.csv_filename : 'No file assigned.'}}
        </p>
        <table style="width: 100%">
            <tr>
                <td>Year <span class="required">*</span><br>
                    <el-select v-model="mapField.year" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Make <span class="required">*</span><br>
                    <el-select v-model="mapField.make" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Model <span class="required">*</span><br>
                    <el-select v-model="mapField.model" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>VIN <span class="required">*</span><br>
                    <el-select v-model="mapField.vin" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>New_used / state_of_vehicle / is_new / is_used <span class="required">*</span><br>
                    <el-select v-model="mapField.new_used" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Value for new_used: Used<br><el-input v-model="mapField.value_for_used"></el-input></td>
            </tr>
            <tr>
                <td>Value for new_used: New<br><el-input v-model="mapField.value_for_new"></el-input></td>
            </tr>
            <tr>
                <td>Price <span class="required">*</span><br>
                    <el-select v-model="mapField.price" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Price column for used cars<br>
                    <el-select v-model="mapField.price_index_for_used" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Price column for new cars<br>
                    <el-select v-model="mapField.price_index_for_new" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>MSRP<br>
                    <el-select v-model="mapField.msrp" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Description / comments <span class="required">*</span><br>
                    <el-select v-model="mapField.description" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Features / options / standardequipment<br>
                    <el-select v-model="mapField.features" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Photo_url_list / imageurls / images <span class="required">*</span><br>
                    <el-select v-model="mapField.photo_url_list" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Photos_last_modified_date<br>
                    <el-select v-model="mapField.photos_last_modified_date" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Dealer ID<br>
                    <el-select v-model="mapField.dealerid" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Dealer name<br>
                    <el-select v-model="mapField.dealer_name" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Model number<br>
                    <el-select v-model="mapField.model_number" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Body / body_type / bodystyle / style (4D Sedan)<br>
                    <el-select v-model="mapField.body" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Transmission / transmission_type / transmission_description / trans (6-Speed Automatic)<br>
                    <el-select v-model="mapField.transmission" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Series (RST, 1.6 SV)<br>
                    <el-select v-model="mapField.series" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Body_door_ct / doorscount<br>
                    <el-select v-model="mapField.body_door_ct" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Odometer / mileage / miles<br>
                    <el-select v-model="mapField.odometer" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Engine_cylinder_ct / cylinders / engcyls<br>
                    <el-select v-model="mapField.engine_cylinder_ct" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Engine_displacement / enginesize / displacement (3.60)<br>
                    <el-select v-model="mapField.engine_displacement" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Engine / enginedescription / engine_name (Gas I4 2.0L/122; Turbocharged I4 1.4/83)<br>
                    <el-select v-model="mapField.engine" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Drivetrain_desc / drivetrain (4WD)<br>
                    <el-select v-model="mapField.drivetrain_desc" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Exterior color / Colour / exteriorcolor<br>
                    <el-select v-model="mapField.colour" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Interior color<br>
                    <el-select v-model="mapField.interior_color" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Stock / stocknumber<br>
                    <el-select v-model="mapField.stock" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Trim<br>
                    <el-select v-model="mapField.trim" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>VDP Url<br>
                    <el-select v-model="mapField.vdp_url" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Inventory date<br>
                    <el-select v-model="mapField.inventory_date" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Certified (false)<br>
                    <el-select v-model="mapField.certified" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>City mpg (19)<br>
                    <el-select v-model="mapField.city_mpg" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Highway mpg (22)<br>
                    <el-select v-model="mapField.highway_mpg" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Series detail (4MATIC®; Turbo)<br>
                    <el-select v-model="mapField.series_detail" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Fuel / fueltype (gasoline)<br>
                    <el-select v-model="mapField.fuel" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Age<br>
                    <el-select v-model="mapField.age" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Address<br>
                    <el-select v-model="mapField.address" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>City<br>
                    <el-select v-model="mapField.city" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>State<br>
                    <el-select v-model="mapField.state" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Zip<br>
                    <el-select v-model="mapField.zip" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>FB Page ID<br>
                    <el-select v-model="mapField.fb_page_id" filterable placeholder="Select">
                        <el-option
                        v-for="item in filteredFields"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>Image separator <span class="required">*</span><br><el-input v-model="mapField.image_separator"></el-input></td>
            </tr>
            <tr>
                <td>Features separator <span class="required">*</span><br><el-input v-model="mapField.features_separator"></el-input></td>
            </tr>
            <tr>
                <td>
                    <el-collapse>
                        <el-collapse-item title="Data Dump" name="4">
                            <div>{{dataDump}}</div>
                        </el-collapse-item>
                    </el-collapse>
                </td>
            </tr>
        </table>
        <el-row>
            <el-col :span="12">
                <el-button @click="$emit('closeDialog')">Cancel</el-button>
                <el-button type="danger" @click="setToEmpty">Empty fields</el-button>
                <el-button type="warning" @click="setSelectedFields">Auto Assign fields</el-button>
            </el-col>
            <el-col :span="12" style="text-align: right">
                <el-button type="primary" @click="handleSaveMappedFields">Confirm</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<style>
    span.required {
        color: red;
    }
</style>

<script>
export default {
    props: ['company'],
    data() {
        return {
            currentCompany: {},
            availableMapFields: [],
            selectedMapFields: [],
            dataDump: {},
            mapField: {
                image_separator:'|',
                features_separator:'|',
                company_id:'',
                year:'',
                make:'',
                model:'',
                vin:'',
                dealerid:'',
                dealer_name:'',
                new_used:'',
                model_number:'',
                body:'',
                transmission:'',
                series:'',
                body_door_ct:'',
                odometer:'',
                engine_cylinder_ct:'',
                engine_displacement:'',
                drivetrain_desc:'',
                colour:'',
                stock:'',
                trim:'',
                vdp_url:'',
                interior_color:'',
                msrp:'',
                price:'',
                inventory_date:'',
                certified:'',
                description:'',
                features:'',
                city_mpg:'',
                highway_mpg:'',
                photo_url_list:'',
                photos_last_modified_date:'',
                series_detail:'',
                engine:'',
                fuel:'',
                age:'',
                value_for_used:'',
                value_for_new:'',
                price_index_for_used:'',
                price_index_for_new:'',
                address: null,
                city: null,
                state: null,
                zip: null,
                fb_page_id: null,
            },
        };
    },
    computed: {
        filteredFields: function() {
            return this.availableMapFields.filter(field => {
                return !this.selectedMapFields.includes(field.value);
            });
        }
    },
    mounted() {
        this.currentCompany = this.company;
        this.mapField.company_id = this.company.id;
        this.loadCompany();
    },
    methods: {
        loadCompany() {
            this.availableMapFields = []
            this.selectedMapFields = []

            var companyId = this.currentCompany.aloware_id
            if(this.currentCompany.aloware_id == '0') {
                companyId = 'simpid-' + this.currentCompany.id
            }

            this.axios
                .get("api/company/" + companyId + "/get_columns_to_map")
                .then(response => {
                    if (response.data.success == true) {

                        const setOptions = async () => {
                            return Promise.all(
                                response.data.data[0].map((rowHeading, key) => {

                                    var find = [' #', ' ', '/', ' '];
                                    var replace = ['', '_', '_', '_'];
                                    var mvariable = rowHeading.toLowerCase().replaceArray(find, replace);
                                    var fval = response.data.data[1][key] !== undefined ? (response.data.data[1][key]).substring(0,30) : '';
                                    var sval = response.data.data[1][key] !== undefined ? (response.data.data[2][key]).substring(0,30) : '';

                                    this.availableMapFields.push({
                                        value: key,
                                        heading: mvariable,
                                        label: rowHeading + ' (' +
                                        fval + '; ' +
                                        sval+
                                        ')'
                                    });
                                })
                            )
                        }

                        setOptions().then(() => {
                            // add empty selector
                            this.availableMapFields.push({
                                        value: '',
                                        heading: '[blank]',
                                        label:  '[Empty]'
                                    });

                            this.setSelectedFields();
                        })

                        this.dataDump = response.data.data;
                    } else {
                        this.$notify({
                            title: "Error",
                            message:
                                "There has been an error getting mapping list",
                            type: "error"
                        });
                    }
                });
        },
        handleSaveMappedFields() {
            var companyId = this.currentCompany.aloware_id
            if(this.currentCompany.aloware_id == '0') {
                companyId = 'simpid-' + this.currentCompany.id
            }
            this.axios
                .post("api/company/" + companyId + "/save_mapped_columns", this.mapField)
                .then(response => {
                    if (response.data.success == true) {
                        this.currentCompany.is_mapped = 1;

                        this.$notify({
                            title: "Success",
                            message:
                                "Company fields has been successfully mapped",
                            type: "success"
                        });

                        this.$emit('closeDialog');

                    } else {
                        this.$notify({
                            title: "Error",
                            message:
                                "There has been an error getting mapping list",
                            type: "error"
                        });
                    }
                });
        },
        setSelectedFields() {
            if (!this.currentCompany.is_mapped) {
                this.availableMapFields.forEach(field => {
                    //console.log(field.heading);
                    switch(field.heading) {
                        case 'stock':
                        case 'stocknumber':
                        case 'stock_number': {
                            this.mapField.stock = field.value
                            break;
                        }
                        case 'trim': {
                            this.mapField.trim = field.value
                            break;
                        }
                        case 'vdp_url': {
                            this.mapField.vdp_url = field.value
                            break;
                        }
                        case 'dealer_name':
                        case 'dealername': {
                            this.mapField.dealer_name = field.value
                            break;
                        }
                        case 'new_used':
                        case 'state_of_vehicle':
                        case 'is_new':
                        case 'is_used':
                        case 'condition': {
                            this.mapField.new_used = field.value
                            break;
                        }
                        case 'engine_cylinder_ct':
                        case 'cylinders':
                        case 'enginecylinders':
                        case 'engcyls': {
                            this.mapField.engine_cylinder_ct = field.value
                            break;
                        }
                        case 'drivetrain_desc':
                        case 'drivetrain': {
                            this.mapField.drivetrain_desc = field.value
                            break;
                        }
                        case 'colour':
                        case 'exteriorcolor':
                        case 'ext_color_generic':
                        case 'extcolor':
                        case 'exterior_color_name':
                        case 'ext_color':
                        case 'ext_color':
                        case 'extcolor': {
                            this.mapField.colour = field.value
                            break;
                        }
                        case 'interior_color':
                        case 'interiorcolor':
                        case 'int_color_generic':
                        case 'intcolor':
                        case 'interior_color_name': {
                            this.mapField.interior_color = field.value
                            break;
                        }
                        case 'body_door_ct':
                        case 'doorscount':
                        case 'doors': {
                            this.mapField.body_door_ct = field.value
                            break;
                        }
                        case 'price':
                        case 'instoreprice':
                        case 'sellingprice':
                        case 'online_price':
                        case 'retail_price': {
                            this.mapField.price = field.value
                            break;
                        }
                        case 'inventory_date':
                        case 'lastmodifieddate':
                        case 'dateinstock':
                        case 'instockdate':
                        case 'indate': {
                            this.mapField.inventory_date = field.value
                            break;
                        }
                        case 'features':
                        case 'options':
                        case 'optiontext':
                        case 'standardequipment': {
                            this.mapField.features = field.value

                            //determine features separator
                            if(this.dataDump[1][field.value].indexOf(',') > 0) {
                                this.mapField.features_separator = ','
                            } else {
                                this.mapField.features_separator = '|'
                            }
                            break;
                        }
                        case 'photo_url_list':
                        case 'photourls':
                        case 'imagelist':
                        case 'images':
                        case 'picture_urls':
                        case 'imageurls': {
                            this.mapField.photo_url_list = field.value

                            //determine image separator
                            if(this.dataDump[1][field.value].indexOf(',') > 0) {
                                this.mapField.image_separator = ','
                            } else {
                                this.mapField.image_separator = '|'
                            }
                            break;
                        }
                        case 'city_mpg':
                        case 'citympg':
                        case 'mpgcity': {
                            this.mapField.city_mpg = field.value
                            break;
                        }
                        case 'highway_mpg':
                        case 'highwaympg':
                        case 'mpghighway': {
                            this.mapField.highway_mpg = field.value
                            break;
                        }
                        case 'photos_last_modified_date':
                        case 'photoslastmodifiedon':
                        case 'date_photos_updated':
                        case 'phototimestamp':
                        case 'latestphotomodifieddate': {
                            this.mapField.photos_last_modified_date = field.value
                            break;
                        }
                        case 'engine':
                        case 'enginedescription':
                        case 'engine_description':
                        case 'engdescription':
                        case 'engine_name': {
                            this.mapField.engine = field.value
                            break;
                        }
                        case 'fuel':
                        case 'fueltype':
                        case 'fuel_type': {
                            this.mapField.fuel = field.value
                            break;
                        }
                        case 'engine_displacement':
                        case 'enginesize':
                        case 'enginedisplacement':
                        case 'displacement': {
                            this.mapField.engine_displacement = field.value
                            break;
                        }
                        case 'model_number':
                        case 'modelnumber': {
                            this.mapField.model_number = field.value
                            break;
                        }
                        case 'odometer':
                        case 'miles':
                        case 'mileage':
                        case 'mileage.value': {
                            this.mapField.odometer = field.value
                            break;
                        }
                        case 'transmission':
                        case 'transmission_description':
                        case 'trans':
                        case 'transmission_name':
                        case 'transmission_type': {
                            this.mapField.transmission = field.value
                            break;
                        }
                        case 'year':
                        case 'modelyear':
                        case 'vehicle_year': {
                            this.mapField.year = field.value
                            break;
                        }
                        case 'body':
                        case 'bodystyle':
                        case 'body_type':
                        case 'style':
                        case 'body_style': {
                            this.mapField.body = field.value
                            break;
                        }
                        case 'description':
                        case 'comments': {
                            this.mapField.description = field.value
                            break;
                        }
                        case 'make': {
                            this.mapField.make = field.value
                            break;
                        }
                        case 'model': {
                            this.mapField.model = field.value
                            break;
                        }
                        case 'vin': {
                            this.mapField.vin = field.value
                            break;
                        }
                        case 'msrp': {
                            this.mapField.msrp = field.value
                            break;
                        }
                        case 'series': {
                            this.mapField.series = field.value
                            break;
                        }
                        case 'certified': {
                            this.mapField.certified = field.value
                            break;
                        }
                        case 'age': {
                            this.mapField.age = field.value
                            break;
                        }
                        case 'dealer_id':
                        case 'dealerid': {
                            this.mapField.dealerid = field.value
                            break;
                        }
                    }
                });
            } else {
                for (var mfield in this.mapField) {
                    this.mapField[mfield] = this.currentCompany.field_map[mfield]
                }
            }
        },
        onselectField: function(val, oldVal) {
            let canBeselectedMultipleTimes = false;
            let canBeselectedMultipleTimesArr = ['price', 'msrp'];

            if(typeof this.availableMapFields[val] !== 'undefined') {
                let headingVal = this.availableMapFields[val].heading;
                canBeselectedMultipleTimesArr.forEach((v)=>{
                    if(headingVal.indexOf(v) > -1) {
                        canBeselectedMultipleTimes = true;
                        return;
                    }
                });
            }

            if(canBeselectedMultipleTimes === false) {
                if ( !this.selectedMapFields.includes(val) ) {
                    if(val !== '') {
                        this.selectedMapFields.push(val);
                    }
                }
            }

            const index = this.selectedMapFields.indexOf(oldVal);
            if (index > -1) {
                this.selectedMapFields.splice(index, 1);
            }
        },

        setToEmpty() {
            Object.entries(this.mapField).forEach(([key]) => {
                this.mapField[key] = ''
            })
            this.$notify.info({
                title: "Info",
                message:
                    "Fields has been emptied."
            });
        }
    },
     watch: {
        company: function(val, oldVal) {
            this.currentCompany = this.company
        },
        'mapField.year': 'onselectField',
        'mapField.make': 'onselectField',
        'mapField.model': 'onselectField',
        'mapField.vin': 'onselectField',
        'mapField.dealerid': 'onselectField',
        'mapField.dealer_name': 'onselectField',
        'mapField.new_used': 'onselectField',
        'mapField.model_number': 'onselectField',
        'mapField.body': 'onselectField',
        'mapField.transmission': 'onselectField',
        'mapField.series': 'onselectField',
        'mapField.body_door_ct': 'onselectField',
        'mapField.odometer': 'onselectField',
        'mapField.engine_cylinder_ct': 'onselectField',
        'mapField.engine_displacement': 'onselectField',
        'mapField.drivetrain_desc': 'onselectField',
        'mapField.colour': 'onselectField',
        'mapField.stock': 'onselectField',
        'mapField.trim': 'onselectField',
        'mapField.vdp_url': 'onselectField',
        'mapField.interior_color': 'onselectField',
        'mapField.msrp': 'onselectField',
        'mapField.price': 'onselectField',
        'mapField.inventory_date': 'onselectField',
        'mapField.certified': 'onselectField',
        'mapField.description': 'onselectField',
        'mapField.features': 'onselectField',
        'mapField.city_mpg': 'onselectField',
        'mapField.highway_mpg': 'onselectField',
        'mapField.photo_url_list': 'onselectField',
        'mapField.photos_last_modified_date': 'onselectField',
        'mapField.series_detail': 'onselectField',
        'mapField.engine': 'onselectField',
        'mapField.fuel': 'onselectField',
        'mapField.age': 'onselectField',
        'mapField.value_for_used': 'onselectField',
        'mapField.value_for_new': 'onselectField',
        'mapField.price_index_for_used': 'onselectField',
        'mapField.price_index_for_new': 'onselectField'
     }
};
</script>
