<template>
    <el-tabs
        v-model="activeTab"
        type="border-card"
    >
        <el-tab-pane
            label="Partner Dealers"
            name="partner-dealers"
        >
            <el-tag
                v-if="showEnv"
                :type="isProduction ? 'success' : 'warning'"
            >{{isProduction ? 'Production' : 'Sandbox'}}</el-tag>
            <PartnerDealers
                :loading="loading"
                :partners="partners"
                :with-actions="true"
                :actions="tableActions"
                @btn-action="handleTableBtnAction"
            ></PartnerDealers>
        </el-tab-pane>
    </el-tabs>
</template>
<script>
import PartnerDealers from "../components/PartnerDealers"

export default {
    components: {
        PartnerDealers,
    },
    data() {
        return {
            company: {},
            loading: false,
            activeTab: 'partner-dealers',
            showEnv: false,
            isProduction: false,
            partners: [],
            tableActions: [
                {
                    name: 'Disconnect',
                    type: 'danger',
                    description: 'Disconnect VinSolutions Digital Showroom integration.'
                }
            ],
        }
    },
    mounted() {
        this.setupComponent()
    },
    methods: {
        setupComponent() {
            this.company = JSON.parse(this.$store.state.currentCompany)
            this.handleGetPartnerDealers()
        },
        async handleGetPartnerDealers() {
            this.loading = true
            const url = `api/company/${this.company.id}/crm-dms-integrations/vs-dealer/integrations/partner-dealers?product=ds`
            await this.axios.get(url)
                .then(res => {
                    this.partners = res.data.data.partners.Items
                    this.isProduction = res.data.data.production
                    this.showEnv = true

                    this.partners = this.partners.sort((a, b) => {
                        const partnerA = a.Name.toLowerCase()
                        const partnerB = b.Name.toLowerCase()

                        if (partnerA < partnerB) {
                            return -1
                        }
                        if (partnerA > partnerB) {
                            return 1
                        }
                        return 0
                    })
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    return this.$root.handleErrors(err.response)
                })
        },
        async handleTableBtnAction({action, data}) {
            let msg = ''
            if(action.name === 'Disconnect') {
                msg = `This will permanently disconnect the VinSolutions Digital Showroom integration for ${data.Name}. Are you sure you want to proceed?`
            }
            await this.$confirm(
                msg,
                'Warning',
                {
                    confirmButtonText: 'Proceed',
                    cancelButtonText: 'Abort',
                    type: 'warning'
                }
            )
                .then(() => {
                    if(action.name === 'Disconnect') {
                        return this.handleDisconnectAction(data)
                    }
                })
                .catch(() => {
                    this.$message(
                        {
                            type: 'info',
                            message: 'Action has been aborted.'
                        }
                    )
                })
        },
        async handleDisconnectAction(data) {
            const url = `api/company/${this.company.id}/crm-dms-integrations/vs-dealer/integrations/remove?product=ds`
            this.loading = true
            await this.axios.post(
                url,
                data
            )
                .then(res => {
                    const disconnected = res.data.data.disconnected
                    const msg = `${data?.Name} ${disconnected ? 'has been disconnected' : 'failed to disconnect'}.`
                    this.$notify({
                        type: disconnected ? 'success' : 'error',
                        message: msg,
                    })
                    return this.handleGetPartnerDealers()
                })
                .catch(err => {
                    this.loading = false
                    return this.$root.handleErrors(err.response, 0)
                })
        }
    }
}
</script>
