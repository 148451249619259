import { Node } from '@tiptap/vue-2'

const ImageLink = Node.create({
    name: "imageLink",
    group: "inline",
    draggable: false,
    inline: true,

    addAttributes: () => ({
        src: { default: null },
        alt: { default: null },
        link: { default: null },
    }),
    
    renderHTML: ({ HTMLAttributes, }) => {
        const imgLinkNode = [
            "img",
            {
                src: HTMLAttributes.src,
                alt: HTMLAttributes.alt,
                title: HTMLAttributes.alt,
            },
        ];
        
        
        return [
            "a",
            {
                href: HTMLAttributes.link || '#',
                target: "_blank",
            },
            imgLinkNode
        ]
    },

    addCommands() {
        return {
            setImageLink: (attributes) => ({ commands }) => {
                return commands
                .insertContent({
                    type: this.name,
                    attrs: attributes,
                })
            },
        };
    },    
});

export default ImageLink
