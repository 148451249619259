<template>
    <el-aside width="200px" style="background-color: rgb(238, 241, 246)" v-if="userIsAdmin">
        <el-menu class="el-menu-vertical-demo">
            <el-menu-item index="1" @click="visitLink('/dashboard')">
                <i class="el-icon-s-data"></i>
                <span>Dashboard</span>
            </el-menu-item>
            <el-menu-item index="2" @click="visitLink('/companies')">
                <i class="el-icon-s-cooperation"></i>
                <span>Companies</span>
            </el-menu-item>
            <el-menu-item index="3" @click="visitLink('/cars')">
                <i class="el-icon-position"></i>
                <span>Cars</span>
            </el-menu-item>
            <el-menu-item index="4" @click="logout()">
                <i class="el-icon-switch-button"></i>
                <span>Logout</span>
            </el-menu-item>
        </el-menu>
    </el-aside>
</template>
<style>

</style>
<script>
    import store from "../../store";

    export default {
        data() {
            return {
                user: {
                    id: 0
                },
            };
        },
        computed: {
            userIsAdmin() {
                if (store.state.currentUser != null) {
                    this.user = JSON.parse(store.state.currentUser);
                    return this.user.id == 2;
                }
            }
        },
        mounted() {
        },
        methods: {
            logout() {
            this.axios
                .get("api/auth/logout?token=" + this.$store.state.token)
                .then(response => {
                    if (response.data.success == true) {
                        // login user, store the token and redirect to dashboard
                        this.$store.commit("LogoutUser");
                        this.$router.push({ name: "login" });
                    }
                })
                .catch(error => {});
            },
            visitLink(path) {
                if (this.$router.currentRoute.path !== "/" + path) {
                    this.$router.replace({
                        path: path
                        /*query: {
                            //Ensure that each click, query is not the same
                            //to ensure that refresh the view
                            t: +new Date()
                        }*/
                    });
                }
            }
        },
        watch: {

        }
    }
</script>
