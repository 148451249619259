<template>
    <el-tabs
        v-model="activeTab"
    >
        <el-tab-pane
            name="dealervault"
            label="DealerVault Requests"
        >
            <dealervault
                v-if="activeTab === 'dealervault'"
            ></dealervault>
        </el-tab-pane>
        <el-tab-pane
            name="dealervault-audit-report"
            label="DealerVault Entries"
        >
            <audit-report
                v-if="activeTab === 'dealervault-audit-report'"
            ></audit-report>
        </el-tab-pane>
    </el-tabs>
</template>
<style>

</style>
<script>
import Dealervault from "./dealervault/Dealervault";
import AuditReport from "./dealervault/AuditReport";
export default {
    components: {
        Dealervault,
        AuditReport,
    },
     data() {
        return {
            activeTab: 'dealervault',
        }
     },
};
</script>
