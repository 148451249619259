<template>
    <el-drawer
        :visible.sync="drawer"
        :direction="direction"
        :before-close="handleClose"
        :size="'98%'"
    >
        <div style="padding: 0 1rem;">
            <h3>
                {{selectedCompany.company.name}}
            </h3>
            <el-container style="margin-top: 1rem;">
                <el-table
                    :data="importedDetails.data"
                    stripe
                    style="width: 80%"
                >
                    <el-table-column label="Status">
                        <template v-slot="scope">
                            <span>{{ scope.row.status ? 'Success' : 'Failed'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Records Processed" prop="total_records_processed"/>
                    <el-table-column label="Total Records" prop="total_records"/>
                    <el-table-column label="Processed On" prop="created_at" />
                </el-table>
            </el-container>
            <el-pagination
                background
                @current-change="handlePageChange"
                :current-page.sync="currentPage"
                :page-size="importedDetails.per_page"
                layout="prev, pager, next"
                :total="importedDetails.total"
            ></el-pagination>
        </div>
    </el-drawer>
</template>

<script>
import TemplateSelector from "../../webmail/dialogs/TemplateSelectorDialog.vue";

export default {
    name: "ImportDetails",
    components: {TemplateSelector},

    props: {
        selectedCompany: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            drawer: true,
            direction: 'rtl',
            importedDetails: {},
            loading: false,
            currentPage: 1,
        }
    },

    mounted() {
        this.getImportedDetails()
    },

    methods: {
        async getImportedDetails() {
            await this.axios.get(`/api/dealervault/request/${this.selectedCompany.id}?page=${this.currentPage}`)
                .then(res => {
                    this.importedDetails = res.data
                })
                .catch(err => {
                    return this.$root.handleErrors(err.response)
                })
        },

        handlePageChange(n) {
            this.currentPage = n
            this.getImportedDetails()
        },

        handleClose() {
            return this.$emit('close-import-details')
        },
    },
}
</script>

<style scoped>

</style>
