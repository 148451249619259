<template>
    <div style="/*width: 99%;padding-left: 5px;*/" class="dms-campaign-container">
        <company-filter
            v-if="showDealershipSelector"
            style="margin-top: 1rem;"
            @change="handleFilterChange"
        ></company-filter>

        <div v-if="company.dms_campaign_enabled == '1' || isCDKIntegrated">
            <el-row>
                <el-col :span="24">
                    <el-tabs v-model="activeTab">
                        <el-tab-pane label="Sales" name="sold">
                            <DmsSalesCampaign :selectedCompany="company" :activeTab="activeTab" @doBulkMail="handleBulkMail"/>
                        </el-tab-pane>
                        <el-tab-pane label="Service" name="service">
                            <DmsServicesCampaign :selectedCompany="company" :activeTab="activeTab"/>
                        </el-tab-pane>
<!--                        <el-tab-pane label="Social Listening" name="social-listening">-->
<!--                            <SocialListening-->
<!--                                v-if="activeTab === 'social-listening'"-->
<!--                            >-->
<!--                            </SocialListening>-->
<!--                        </el-tab-pane>-->
                    </el-tabs>
                </el-col>
            </el-row>
            <PageHeader :company="company" label="DMS DATA MINING" :darkMode="false"/>
        </div>
        <div v-else>
            <RestrictedScreen />
        </div>
    </div>
</template>
<style>
    .dms-campaign-container {
        background-image: url('/images/bg-dms-equity.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        color: #000000;
        width: 100%;
        overflow-x: hidden;
        padding-bottom: 20px;
    }
    .dms-campaign-container > div:first-child {
        height: 100%;
    }
    .dms-campaign-container > div:first-child {
        display: flex;
        flex-direction: column;
    }
    .dms-campaign-container > div:first-child > div:last-child {
        align-self: flex-end;
        width: 100%;
        margin-top: auto;
    }
    .dms-campaign-container .el-tabs__item {
        color: #000000;
    }
    .dms-campaign-container .el-date-editor .el-range-input {
        background: none;
    }
    .dms-campaign-container .el-input__inner,
    .dms-campaign-container .el-table {
        background-color: rgb(0 0 0 / 67%);
    }
    .dms-campaign-container .el-table th,
    .dms-campaign-container .el-table tr {
        background: none;
    }

</style>
<script>
import store from "../store";
import DmsSalesCampaign from './dms/DmsSalesCampaign';
import DmsServicesCampaign from './dms/DmsServicesCampaign';
import PageHeader from './widgets/PageHeader';
import RestrictedScreen from './widgets/RestrictedScreen';
import CompanyFilter from "./admin/components/CompanyFilter";
// import SocialListening from "./SocialListening";

export default {
    components: {
        DmsSalesCampaign,
        DmsServicesCampaign,
        PageHeader,
        RestrictedScreen,
        CompanyFilter,
        // SocialListening,
    },
    props: {
        showDealershipSelector: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            S3_URL: process.env.MIX_AWS_S3_URI,
            logoUrl: '',
            errors: {},
            isProgress: false,
            activeTab: 'sold',
            company: {},
            dmsExportId: 0,
            isCDKIntegrated: false,
        };
    },
    beforeMount() {

    },
    mounted() {
        this.company = JSON.parse(store.state.currentCompany);
        this.getLatestCompanyData();
    },
    methods: {
        getLatestCompanyData() {
            this.axios
            .get('/api/company/' + this.company.aloware_id)
            .then(response => {
                if (response.data.success == true) {
                    this.company = response.data.data;
                    this.isCDKIntegrated = response.data.data.cdk_integration !== null ? response.data.data.cdk_integration.status == 1 : false;
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        handleBulkMail(dmsExportId) {
            this.dmsExportId = dmsExportId
            let redirectBaseUrl = `https://app.simpsocial.com/email-blast`
            if( process.env.MIX_APP_ENV == 'local' ) {
                redirectBaseUrl= `http://simpsocial-cms.test/webmail`
            }
            let redirectUrl = redirectBaseUrl + `?action=email-campaign&export_id=${dmsExportId}`

            window.top.location = redirectUrl

            //5 seconds expiry

            const now = new Date()
            localStorage.setItem('action', JSON.stringify({
                value: 'email-campaign',
                expiry: now.getTime() + 5000,
            }))
            localStorage.setItem('export_id', JSON.stringify({
                value: dmsExportId,
                expiry: now.getTime() + 5000,
            }))
            //console.log(dmsExportId)
        },
        handleFilterChange(data) {
            this.company = data
            store.commit(
                "UpdateCurrentCompany",
                this.company
            );
            this.getLatestCompanyData()
        },
    },
    watch: {
    }
};
</script>
