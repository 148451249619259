<template>
    <div>
        <company-filter
            v-if="showDealershipSelector"
            style="margin-top: 1rem;"
            @change="handleFilterChange"
        ></company-filter>
        <el-row v-if="showDealershipSelector && false">
            <el-col :span="12">
                &nbsp;
            </el-col>
            <el-col :span="12" style="text-align: right">
                <el-select v-model="company_id"
                    remote
                    filterable
                    clearable
                    :remote-method="loadCompanies"
                    @change="handleCompanyChange"
                    placeholder="All Companies">
                    <el-option
                        v-for="item in companies"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-col>
        </el-row>
        <!--<el-row>
            <el-col :span="24">
                <el-tabs v-model="activeTab">
                    <el-tab-pane label="General" name="general">-->
                        <el-form>
                            <label class="el-form-item__label" style="width: 100%;text-align: left">Logo</label>
                            <el-row>
                                <el-col :span="4">
                                    <el-image v-if="company.logo" :src="company.logo" fit="cover"></el-image>
                                </el-col>
                                <el-col :span="10" style="padding-left: 20px">
                                    <el-upload
                                        class="upload-demo"
                                        drag
                                        :action="companyApiBaseUrl + '/aws_fileupload?token=' + this.$store.state.token"
                                        accept="image/*"
                                        :show-file-list="false"
                                        :on-success="handleSuccess"
                                        :before-upload="handleBeforeUpload"
                                    >
                                        <i class="el-icon-upload"></i>
                                        <div class="el-upload__text">
                                            Drop file here or
                                            <em>click to upload</em>
                                        </div>
                                    </el-upload>
                                </el-col>
                                <el-col :span="10" style="padding-left: 20px">
                                    <el-alert title="FTP Credentials" type="info" show-icon closable>
                                        <span @click="copyFTPCreds" title="Click to copy" class="ftp-creds">
                                            Host: dealer.simpsocial.com
                                            <br />
                                            Username: {{company.ftp_username}}
                                            <br />
                                            Password: {{company.ftp_password}}
                                            <br />Port: 21
                                        </span>
                                        <br><br>
                                        Last successful update: {{company.importing_end | formatDate}}
                                        <br />
                                        Total Cars Imported: {{company.cars_count}}
                                        <br />
                                        <br />
                                        FB Inventory Feed Url:
                                        <table class="feed-url-table" cellspacing="0" v-if="company.slug == 'drive-oz-motors'">
                                            <tr>
                                                <td>Used Cars Below 100k</td>
                                                <td><span class="fb-feed-url">{{company.url}}/used-cars/fb-feed.xml?max_price=99000</span></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Used Cars Above 100k</td>
                                                <td><span class="fb-feed-url">{{company.url}}/used-cars/fb-feed.xml?min_price=100000</span></td>
                                                <td></td>
                                            </tr>
                                        </table>
                                        <table class="feed-url-table" cellspacing="0" v-else>
                                            <tr v-if="companyStats.newCarsWithImg > 0 && (company.slug !== 'carmazone' && company.slug !== '77-auto')">
                                                <td>New Cars<br/><strong @click="handleFbFeedPreview('new-cars')" class="fb-feed-url">Preview FB Feed</strong></td>
                                                <td><span @click="copyFbFeedNew" title="Click to copy" class="fb-feed-url">{{company.url}}/new-cars/fb-feed.xml</span></td>
                                                <td>{{companyStats.newCarsWithImg}}</td>
                                            </tr>
                                            <tr v-if="companyStats.usedCarsWithImg > 0 && (company.slug !== 'carmazone' && company.slug !== '77-auto')">
                                                <td>Used Cars<br/><strong @click="handleFbFeedPreview('used-cars')" class="fb-feed-url">Preview FB Feed</strong></td>
                                                <td><span @click="copyFbFeedUsed" title="Click to copy" class="fb-feed-url">{{company.url}}/used-cars/fb-feed.xml</span></td>
                                                <td>{{companyStats.usedCarsWithImg}}</td>
                                            </tr>
                                            <tr v-if="companyStats.usedCarsExceptTrucksAndSuvsWithImg > 0 && (company.slug == 'carmazone' || company.slug == '77-auto')">
                                                <td>Used Cars (except trucks &amp; SUVs)<br/><strong @click="handleFbFeedPreview('used-except-trucks-and-suvs')" class="fb-feed-url">Preview FB Feed</strong></td>
                                                <td><span @click="copyUsedCarsExceptTrucksAndSuvs" title="Click to copy" class="fb-feed-url">{{company.url}}/used-except-trucks-and-suvs/fb-feed.xml</span></td>
                                                <td>{{companyStats.usedCarsExceptTrucksAndSuvsWithImg}}</td>
                                            </tr>
                                            <tr v-if="companyStats.trucksAndSuvsWithImg > 0 && (company.slug == 'carmazone' || company.slug == '77-auto')">
                                                <td>Trucks &amp; SUVs<br/><strong @click="handleFbFeedPreview('trucks-and-suvs')" class="fb-feed-url">Preview FB Feed</strong></td>
                                                <td><span @click="copyTrucksAndSuvs" title="Click to copy" class="fb-feed-url">{{company.url}}/trucks-and-suvs/fb-feed.xml</span></td>
                                                <td>{{companyStats.trucksAndSuvsWithImg}}</td>
                                            </tr>
                                            <tr v-if="(['nemer-chrysler-jeep-dodge-ram', 'nemer-chrysler-jeep-dodge-ram-of-saratoga'].includes(company.slug))">
                                                <td>Ram 1500<br/><strong @click="handleFbFeedPreview('dodge-ram_1500-cars')" class="fb-feed-url">Preview FB Feed</strong></td>
                                                <td><span @click="copyNemerRam1500" title="Click to copy" class="fb-feed-url">{{company.url}}/dodge-ram_1500-cars/fb-feed.xml</span></td>
                                                <td>{{companyStats.nemer_ram_1500}}</td>
                                            </tr>
                                            <tr v-if="(['keystone-auto-sales'].includes(company.slug))">
                                                <td>Certified Cars<br/><strong @click="handleFbFeedPreview('certified-cars')" class="fb-feed-url">Preview FB Feed</strong></td>
                                                <td><span @click="copyCertifiedCars" title="Click to copy" class="fb-feed-url">{{company.url}}/certified-cars/fb-feed.xml</span></td>
                                                <td>{{companyStats.certified_cars_count}}</td>
                                            </tr>
                                            <tr v-if="(['mt-orab-chrysler-dodge-jeep-and-ram'].includes(company.slug))">
                                                <td>RAM 1500 - 2023<br/><strong @click="handleFbFeedPreview('ram_1500_2023-trucks')" class="fb-feed-url">Preview FB Feed</strong></td>
                                                <td><span @click="copyMtOrabRAM15002023" title="Click to copy" class="fb-feed-url">{{company.url}}/ram_1500_2023-trucks/fb-feed.xml</span></td>
                                                <td><span @click="handleFbFeedPreview('ram_1500_2023-trucks')">Click here</span></td>
                                            </tr>
                                            <tr>
                                                <td>Products Url</td>
                                                <td><span @click="copyFbFeedProducts" title="Click to copy" class="fb-feed-url">{{company.url}}/products/fb-feed.xml</span></td>
                                                <td>{{companyStats.carsWithImg}}</td>
                                            </tr>
                                            <!--<tr>
                                                <td>Payments Url</td>
                                                <td><span @click="copyFbFeedPayments" title="Click to copy" class="fb-feed-url">{{company.url}}/payments/fb-feed.xml</span></td>
                                                <td></td>
                                            </tr>-->
                                        </table>
                                        <br>
                                        <input type="hidden" id="fbFeedUrlPayments" :value="company.url+'/payments/fb-feed.xml'">
                                        <input type="hidden" id="fbFeedUrlNew" :value="company.url+'/new-cars/fb-feed.xml'">
                                        <input type="hidden" id="fbFeedUrlUsed" :value="company.url+'/used-cars/fb-feed.xml'">
                                        <input type="hidden" id="usedCarsExceptTrucksAndSuvs" :value="company.url+'/used-except-trucks-and-suvs/fb-feed.xml'">
                                        <input type="hidden" id="trucksAndSuvs" :value="company.url+'/trucks-and-suvs/fb-feed.xml'">
                                        <input type="hidden" id="fbFeedUrlProducts" :value="company.url+'/products/fb-feed.xml'">
                                        <input type="hidden" id="FTPCreds" :value="'Host: dealer.simpsocial.com | Username: '+company.ftp_username+' | Password: '+company.ftp_password+' | Port: 21'">
                                        <input type="hidden" id="fbNemerRam1500" :value="company.url+'/dodge-ram_1500-cars/fb-feed.xml'">
                                        <input type="hidden" id="fbMtOrabRAM15002023" :value="company.url+'/ram_1500_2023-trucks/fb-feed.xml'">
                                        <input type="hidden" id="certifiedCars" :value="company.url+'/certified-cars/fb-feed.xml'">

                                        TikTok Inventory Feed Url:
                                        <table class="feed-url-table" cellspacing="0" v-if="company.slug == 'drive-oz-motors'">
                                            <tr>
                                                <td>Used Cars Below 100k</td>
                                                <td><span class="tiktok-feed-url">{{company.url}}/used-cars/tiktok-feed.xml?max_price=99000</span></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Used Cars Above 100k</td>
                                                <td><span class="tiktok-feed-url">{{company.url}}/used-cars/tiktok-feed.xml?min_price=100000</span></td>
                                                <td></td>
                                            </tr>
                                        </table>
                                        <table class="feed-url-table" cellspacing="0" v-else>
                                            <tr v-if="companyStats.newCarsWithImg > 0 && (company.slug !== 'carmazone' && company.slug !== '77-auto')">
                                                <td>New Cars</td>
                                                <td><span @click="copyTikTokFeedNew" title="Click to copy" class="tiktok-feed-url">{{company.url}}/new-cars/tiktok-feed.xml</span></td>
                                                <td>{{companyStats.newCarsWithImg}}</td>
                                            </tr>
                                            <tr v-if="companyStats.usedCarsWithImg > 0 && (company.slug !== 'carmazone' && company.slug !== '77-auto')">
                                                <td>Used Cars</td>
                                                <td><span @click="copyTikTokFeedUsed" title="Click to copy" class="tiktok-feed-url">{{company.url}}/used-cars/tiktok-feed.xml</span></td>
                                                <td>{{companyStats.usedCarsWithImg}}</td>
                                            </tr>
                                            <tr v-if="companyStats.usedCarsExceptTrucksAndSuvsWithImg > 0 && (company.slug == 'carmazone' || company.slug == '77-auto')">
                                                <td>Used Cars (except trucks &amp; SUVs)</td>
                                                <td><span @click="copyTikTokUsedCarsExceptTrucksAndSuvs" title="Click to copy" class="tiktok-feed-url">{{company.url}}/used-except-trucks-and-suvs/tiktok-feed.xml</span></td>
                                                <td>{{companyStats.usedCarsExceptTrucksAndSuvsWithImg}}</td>
                                            </tr>
                                            <tr v-if="companyStats.trucksAndSuvsWithImg > 0 && (company.slug == 'carmazone' || company.slug == '77-auto')">
                                                <td>Trucks &amp; SUVs</td>
                                                <td><span @click="copyTikTokTrucksAndSuvs" title="Click to copy" class="tiktok-feed-url">{{company.url}}/trucks-and-suvs/tiktok-feed.xml</span></td>
                                                <td>{{companyStats.trucksAndSuvsWithImg}}</td>
                                            </tr>
                                            <tr>
                                                <td>Products Url</td>
                                                <td><span @click="copyTikTokFeedProducts" title="Click to copy" class="tiktok-feed-url">{{company.url}}/products/tiktok-feed.xml</span></td>
                                                <td>{{companyStats.carsWithImg}}</td>
                                            </tr>
                                        </table>
                                        <br>
                                        <input type="hidden" id="tiktokFeedUrlPayments" :value="company.url+'/payments/tiktok-feed.xml'">
                                        <input type="hidden" id="tiktokFeedUrlNew" :value="company.url+'/new-cars/tiktok-feed.xml'">
                                        <input type="hidden" id="tiktokFeedUrlUsed" :value="company.url+'/used-cars/tiktok-feed.xml'">
                                        <input type="hidden" id="usedCarsExceptTrucksAndSuvs" :value="company.url+'/used-except-trucks-and-suvs/tiktok-feed.xml'">
                                        <input type="hidden" id="tiktokTrucksAndSuvs" :value="company.url+'/trucks-and-suvs/tiktok-feed.xml'">
                                        <input type="hidden" id="tiktokFeedUrlProducts" :value="company.url+'/products/tiktok-feed.xml'">
                                    </el-alert>
                                    <el-alert type="info" show-icon closable>
                                        <span>
                                            <strong><a style="color: inherit;text-decoration: none;" target="_parent" href='https://app.simpsocial.com/integrations?tab=integrations&name=SMTP'>SMTP Credentials</a></strong><br>
                                            Server Address: email-smtp.us-east-1.amazonaws.com<br />
                                            Port: 25, 587 or 2587<br />
                                            Encryption type: TLS<br />
                                            Sender Address: {{company.slug}}@dealer.simpsocial.com<br />
                                            Sender Name: {{company.name}} <small>(can use any values)</small><br />
                                            Username: AKIAUQKN7WHG2YH2LC7F<br />
                                            Password:  BPl5hAvQ1y26X6pol4j5u8JaAjcDKnzAqiQvGgH3VZbf<br />
                                        </span>
                                    </el-alert>
                                </el-col>
                            </el-row>

                            <el-row :gutter="20">
                                <el-col :span="14">
                                    <el-row
                                        type="flex"
                                        justify="start"
                                        v-if="company && showDealershipSelector"
                                    >
                                        <el-form-item :label="`${!company.non_alo ? 'Full' : 'Ads Only'} Account`">
                                            <el-switch v-model="company.non_alo" @change="handleSubmit"></el-switch>
                                        </el-form-item>
                                        <el-form-item :label="`${company.non_alo_status ? 'Active' : 'Deactivated'} Ads Only Account`">
                                            <el-switch v-model="company.non_alo_status" @change="handleSubmit"></el-switch>
                                        </el-form-item>
                                    </el-row>
                                    <el-row
                                        type="flex"
                                        justify="start"
                                    >
                                        <el-form-item label="Lead push notifications">
                                            <el-switch v-model="company_meta.lead_push_notifications" @change="handleSubmit"></el-switch>
                                        </el-form-item>
                                        <el-form-item label="Tag push notifications">
                                            <el-switch v-model="company_meta.tag_push_notifications" @change="handleSubmit"></el-switch>
                                        </el-form-item>
                                    </el-row>
                                    <el-form-item label="Company Name">
                                        <el-input v-model="company.name"></el-input>
                                    </el-form-item>
                                    <el-form-item label="Address">
                                        <el-input v-model="company.address"></el-input>
                                    </el-form-item>
                                    <el-form-item label="Website">
                                        <el-input v-model="company.website"></el-input>
                                    </el-form-item>
                                    <el-form-item label="Representative Name">
                                        <el-input v-model="company.representative_name"></el-input>
                                    </el-form-item>
                                    <el-form-item label="Representative Phone">
                                        <phone-input
                                            :phone-value="company.representative_phone"
                                            :key="phoneInputKey"
                                            @success="phoneInputSuccess"
                                        ></phone-input>
                                    </el-form-item>
                                    <el-form-item label="Dealership CRM Intake Email">
                                        <el-select
                                            v-model="adfEmails"
                                            multiple
                                            placeholder="Email"
                                            :filterable="true"
                                            :allow-create="true"
                                            :collapse-tags="true"
                                            class="w-100"
                                        >
                                            <el-option
                                                v-for="(item, i) in adfEmails"
                                                :key="i"
                                                :label="item"
                                                :value="item"
                                            >
                                            </el-option>
                                        </el-select>
                                        <small>To add an email: Type the email address and click from the dropdown.</small>
                                    </el-form-item>
                                    <el-form-item label="Dealership Plain Text Lead Intake Email">
                                        <el-select
                                            v-model="plainTextIntakeEmails"
                                            multiple
                                            placeholder="Email"
                                            :filterable="true"
                                            :allow-create="true"
                                            :collapse-tags="true"
                                            class="w-100"
                                        >
                                            <el-option
                                                v-for="(item, i) in plainTextIntakeEmails"
                                                :key="i"
                                                :label="item"
                                                :value="item"
                                            >
                                            </el-option>
                                        </el-select>
                                        <small>To add an email: Type the email address and click from the dropdown.</small>
                                    </el-form-item>
                                    <el-form-item label="Group Email Address" v-if="company.email_setup !== null">
                                        <el-input v-model="company.email_setup.master_email_address"></el-input>
                                    </el-form-item>
                                    <el-form-item label="Facebook Page ID">
                                        <el-input v-model="company.fb_page_id"></el-input>
                                    </el-form-item>
                                    <el-form-item label="TikTok Advertiser ID">
                                        <el-input v-model="company.tiktok_advertiser_id"></el-input>
                                    </el-form-item>
                                    <el-form-item label="Ad Espresso Account ID">
                                        <el-input v-model="company_meta.ad_espresso_account_id"></el-input>
                                    </el-form-item>
                                    <label class="el-form-item__label" style="width: 100%;text-align: left">QR Code</label>
                                    <el-row>
                                        <el-col :span="5">
                                            <el-image v-if="company.qr_code" :src="company.qr_code" fit="cover"></el-image>
                                            <el-image v-else src="https://simpsocial.s3.us-east-2.amazonaws.com/assets/img/qr-code-placeholder.png" fit="cover"></el-image>
                                        </el-col>
                                        <el-col :span="19" style="padding-left: 20px">
                                            <el-upload
                                                class="upload-demo"
                                                drag
                                                :action="companyApiBaseUrl + '/aws_fileupload?token=' + this.$store.state.token"
                                                accept="image/*"
                                                :show-file-list="false"
                                                :on-success="handleSuccessQrCode"
                                                :before-upload="handleBeforeUpload"
                                            >
                                                <i class="el-icon-upload"></i>
                                                <div class="el-upload__text">
                                                    Drop file here or
                                                    <em>click to upload</em>
                                                </div>
                                            </el-upload>
                                        </el-col>
                                    </el-row>
                                    <el-form-item label="Inventory Provider">
                                        <el-select v-model="company.company_car_data_source_id"
                                            remote
                                            filterable
                                            clearable
                                            :remote-method="loadCompanyCarDataSources"
                                            @change="handleCompanyCarDataSourcesChange"
                                            placeholder="Select Provider">
                                            <el-option
                                            v-for="item in company_car_data_sources"
                                            :key="item.id"
                                            :label="item.name + ' (Type: ' + item.data_type + ')'"
                                            :value="item.id">
                                                <span style="float: left">{{ item.name }} (Type: {{ item.data_type }})</span>
                                                <span style="float: right">
                                                    <i class="el-icon-edit" title="Edit Provider" @click="handleUpdateProvider(item)"></i>
                                                </span>
                                            </el-option>
                                        </el-select>

                                        <el-button type="secondary" @click="handleAddProvider" icon="el-icon-plus">Add Provider</el-button>
                                    </el-form-item>
                                    <el-form-item label="CSV Column Value" v-if="currentDataSource.data_type=='3'">
                                        <el-input v-model="company.csv_dealership_value"></el-input>
                                    </el-form-item>
                                    <el-row style="text-align: left; margin-top: 10px">
                                        <el-button type="primary" @click="handleSubmit">Submit</el-button>
                                        <el-button
                                            @click="loginToFacebook"
                                            v-if="!fbBusinessMessengerUser && company.facebook_messenger_integration == null"
                                        >
                                            Authorize Facebook Messenger
                                        </el-button>
                                        <el-button
                                            @click="loginToFacebook"
                                            v-else
                                        >
                                            Reconnect Facebook Messenger
                                        </el-button>
<!--                                        <el-link-->
<!--                                            v-if="company.facebook_messenger_integration == null"-->
<!--                                            class="el-button el-button&#45;&#45;secondary"-->
<!--                                            type="primary"-->
<!--                                            target="_parent"-->
<!--                                            :href="'/messenger/authorize_app?redirect=dealer-profile'">-->
<!--                                            <i class="el-icon-top-right"></i> Authorize facebook messenger-->
<!--                                        </el-link>-->
<!--                                        <el-link-->
<!--                                            v-else-->
<!--                                            class="el-button el-button&#45;&#45;secondary"-->
<!--                                            type="primary"-->
<!--                                            target="_parent"-->
<!--                                            :href="'/messenger/authorize_app?redirect=dealer-profile'">-->
<!--                                            <i class="el-icon-top-right"></i> Reconnect facebook messenger-->
<!--                                        </el-link>-->
                                        <el-link
                                            v-if="company.tiktok_integration == null"
                                            class="el-button el-button--secondary"
                                            type="primary"
                                            target="_parent"
                                            :href="tiktokAuthorizationUrl">
                                            <i class="el-icon-top-right"></i> Authorize TikTok
                                        </el-link>
                                        <el-link
                                            v-else
                                            class="el-button el-button--secondary"
                                            type="primary"
                                            target="_parent"
                                            :href="tiktokAuthorizationUrl">
                                            <i class="el-icon-top-right"></i> Reconnect TikTok
                                        </el-link>
                                    </el-row>
                                </el-col>
                                <el-col :span="10" style="padding-top: 20px">
                                    Settings & Integrations

                                    <el-tabs
                                        v-model="activeIntegrationTab"
                                        @tab-click="handleTabClick"
                                    >
                                        <el-tab-pane label="Settings" name="settings">
                                            <company-settings></company-settings>
                                        </el-tab-pane>
                                        <el-tab-pane label="Tiktok" name="tiktok">
                                            <CompanyTikTokIntegration :selectedCompany="company"/>
                                        </el-tab-pane>
                                        <el-tab-pane
                                            v-if="company && showDealershipSelector"
                                            label="FB LeadGen"
                                            name="fb-leadgen"
                                        >
                                            <f-b-lead-gen
                                                v-if="activeTab === 'fb-leadgen'"
                                                :company="company"
                                                :user="user"
                                            ></f-b-lead-gen>
                                        </el-tab-pane>
                                        <el-tab-pane
                                            v-if="company && showDealershipSelector"
                                            label="Chat GPT"
                                            name="chatgpt"
                                        >
                                            <CompanyChatGPTIntegration  v-if="activeTab === 'chatgpt'" :selectedCompany="company"/>
                                        </el-tab-pane>
                                    </el-tabs>
                                </el-col>
                            </el-row>
                        </el-form>
                    <!--</el-tab-pane>
                    <el-tab-pane label="Ad Espresso" name="facebook-ads">
                        <FacebookAds :selectedCompany="company" :activeTab="activeTab"/>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>-->
        <el-dialog title="Inventory Provider Form" :visible.sync="companyCarDatasourceFormVisible">
            <CompanyCarDatasourceForm
                :company_car_data_source="selectedDataSource"
                v-on:onSuccessfulSubmit="handleCompanyCarDatasourceFormSuccess"
                v-on:closeDialog="companyCarDatasourceFormVisible = false"/>
        </el-dialog>
        <el-drawer
            v-if="showFbFeedValidator"
            :visible.sync="showFbFeedValidator"
            size="90%"
        >
            <FbFeedValidator
                :feed-status="fbFeedStatus"
            ></FbFeedValidator>
        </el-drawer>
    </div>
</template>
<style>
.el-image {
    max-height: 300px;
}
.fb-feed-url:hover,
.ftp-creds:hover,
.tiktok-feed-url:hover {
    text-decoration: underline;
    cursor: pointer;
}
.feed-url-table td {
    border: 1px solid #d1d3d7;
    padding: 5px;
}
</style>
<script>
import store from "../store"
import CompanyCarDatasourceForm from './CompanyCarDatasourceForm'
import CompanyTikTokIntegration from './company-profile/integrations/TikTok'
import CompanyChatGPTIntegration from './company-profile/integrations/ChatGPT'
import FBLeadGen from "./company-integrations/FBLeadGen"
import FacebookAds from "./company-profile/FacebookAds"
import CompanyFilter from "./admin/components/CompanyFilter"
import PhoneInput from "./inputs/PhoneInput.vue"
import FbFeedValidator from "./FbFeed/FbFeedValidator"
import CompanySettings from "./company-profile/settings/CompanySettings"

export default {
    components: {
        CompanyCarDatasourceForm,
        CompanyTikTokIntegration,
        CompanyChatGPTIntegration,
        FBLeadGen,
        FacebookAds,
        CompanyFilter,
        PhoneInput,
        FbFeedValidator,
        CompanySettings,
    },
    props: {
        showDealershipSelector: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            uploading: false,
            errors: {},
            company: {
                facebook_messenger_integration: {},
                email_setup: {
                    master_email_address: ''
                },
                webmail_enabled: 0
            },
            company_meta: {
                ad_espresso_account_id: '',
                lead_push_notifications: true,
                tag_push_notifications: true,
            },
            companyStats: {
                newCarsWithImg: 0,
                usedCarsWithImg: 0,
                usedCarsExceptTrucksAndSuvsWithImg: 0,
                trucksAndSuvsWithImg: 0,
                carsWithImg: 0,
            },
            isProgress: false,
            currentDataSource: {
                data_type: 1
            },
            selectedDataSource: {},
            companyCarDatasourceFormVisible: false,
            company_car_data_sources: [],
            activeIntegrationTab: 'settings',
            tiktokAuthorizationUrl: 'https://ads.tiktok.com/marketing_api/auth?app_id=7078607387472445442&state=your_custom_params&redirect_uri=https%3A%2F%2Fdealer.simpsocial.com%2Fwebhook%2Ftiktok-callback&rid=thxiqhsgg7',
            confirmWebmailSetupVisible: false,
            activeTab: 'settings',
            user: {},
            company_id: 0,
            companies: [],
            adfEmails : [],
            plainTextIntakeEmails : [],
            phoneInputKey: 0,
            showFbFeedValidator: false,
            fbFeedStatus: '',
        };
    },
    computed: {
        fbBusinessMessengerUser: function() {
            return this.$store.getters.getFbBusinessMessengerUser
        },
        fbBusinessMessengerMe: function() {
            return this.$store.getters.getFbBusinessMessengerMe
        },

    },
    created() {
        if(!document.getElementById('fb-business-sdk')) {
            const script = document.createElement('script');
            script.src = 'https://connect.facebook.net/es_LA/sdk.js';
            script.async = true;
            script.defer = true;
            script.crossOrigin = 'anonymous';
            script.id = 'fb-business-sdk';
            document.head.appendChild(script);
        }
    },
    mounted() {
        this.loadFacebookSDK()
        this.company = Object.assign(this.company, JSON.parse(store.state.currentCompany));
        this.user = Object.assign(this.user, JSON.parse(store.state.currentUser))
        this.currentDataSource = this.company.company_car_data_source != null ? this.company.company_car_data_source : this.currentDataSource;
        this.resetValues();
        this.getCompanyCarsCount();
        this.loadCompanyCarDataSources('');
        this.checkFBAuth();

        if( this.showDealershipSelector == true ) {
            this.loadCompanies('');
            this.company_id = this.company.name
        }
    },
    methods: {
        resetValues() {
            this.company_meta.ad_espresso_account_id = ''
            this.company_meta.lead_push_notifications = true
            this.company_meta.tag_push_notifications = true
        },
        getCompanyCarsCount() {
            this.axios
                .get(`/api/company/simpid-${this.company.id}`, this.company)
                .then(response => {
                    this.companyStats.newCarsWithImg = response.data.data.new_cars_with_img_count;
                    this.companyStats.usedCarsWithImg = response.data.data.used_cars_with_img_count;
                    this.companyStats.usedCarsExceptTrucksAndSuvsWithImg = response.data.data.used_cars_except_trucks_and_suvs_with_img_count;
                    this.companyStats.trucksAndSuvsWithImg = response.data.data.trucks_and_suvs_with_img_count;
                    this.companyStats.carsWithImg = response.data.data.cars_with_img_count;

                    if(['nemer-chrysler-jeep-dodge-ram', 'nemer-chrysler-jeep-dodge-ram-of-saratoga'].includes(this.company.slug)) {
                        this.companyStats.nemer_ram_1500 = response.data.data.nemer_ram_1500;
                    }

                    if(['keystone-auto-sales'].includes(this.company.slug)) {
                        this.companyStats.certified_cars_count = response.data.data.certified_cars_count
                    }

                    this.company = response.data.data;
                    this.adfEmails = typeof this.company.adf_email === 'string' ? this.company.adf_email.split(',') : []
                    this.plainTextIntakeEmails = typeof this.company.plain_text_lead_intake_email === 'string' ? this.company.plain_text_lead_intake_email.split(',') : []

                    this.company.dms_campaign_enabled = (response.data.data.dms_campaign_enabled != '0') ? true : false;

                    this.company.webmail_enabled = this.company.webmail_enabled !== 1 ? false : true

                    if(this.company.email_setup == 'null') {
                        this.company.email_setup.master_email_address = '';
                    }
                    if( this.company.meta.length > 0 ) {
                        this.company.meta.forEach((v) => {
                            if( v.value == '1' ) {
                                this.company_meta[v.key] = true
                            } else if( v.value == '0' ) {
                                this.company_meta[v.key] = false
                            } else {
                                this.company_meta[v.key] = v.value
                            }
                        })
                    } else {
                        this.company_meta.lead_push_notifications = true
                        this.company_meta.tag_push_notifications = true
                    }
                })
                .catch(err => {
                    this.$root.handleErrors(err.response);
                });
        },
        handleSuccess(res, file) {
            this.company.logo = res.url;
        },
        handleSuccessQrCode(res, file) {
            this.company.qr_code = res.url;
        },
        handleBeforeUpload() {},
        handleSubmit() {
            this.company = {
                ...this.company,
                adf_email: this.adfEmails.toString(),
                meta_arr: this.company_meta,
                plain_text_lead_intake_email: this.plainTextIntakeEmails.toString(),
            }
            this.axios
                .put(`/api/company/simpid-${this.company.id}`, this.company)
                .then(response => {
                    if (response.data.success == true) {
                        store.commit(
                            "UpdateCurrentCompany",
                            response.data.data
                        );
                        this.$notify({
                            title: "Success",
                            message: "Company has been successfully Updated",
                            type: "success"
                        });
                    } else {
                        let errors = response.data.errors.message;
                        let message = "";
                        for (let error in errors) {
                            message = errors[error] + "\n";
                        }
                        this.$notify({
                            title: "Error",
                            message: message,
                            type: "error"
                        });
                    }
                })
                .catch(err => {
                    this.$root.handleErrors(err.response);
                });
        },
        visitCompanyProfile() {
            window.open(this.company.url);
        },
        copyFbFeedNew() {
            this.copyText( document.querySelector('#fbFeedUrlNew') );
        },
        copyFbFeedUsed() {
            this.copyText( document.querySelector('#fbFeedUrlUsed') );
        },
        copyFbFeedPayments() {
            this.copyText( document.querySelector('#fbFeedUrlPayments') );
        },
        copyUsedCarsExceptTrucksAndSuvs() {
            this.copyText( document.querySelector('#usedCarsExceptTrucksAndSuvs') );
        },
        copyTrucksAndSuvs() {
            this.copyText( document.querySelector('#trucksAndSuvs') );
        },
        copyFbFeedProducts() {
            this.copyText( document.querySelector('#fbFeedUrlProducts') );
        },
        copyTikTokFeedNew() {
            this.copyText( document.querySelector('#tiktokFeedUrlNew') );
        },
        copyTikTokFeedUsed() {
            this.copyText( document.querySelector('#tiktokFeedUrlUsed') );
        },
        copyTikTokFeedPayments() {
            this.copyText( document.querySelector('#tiktokFeedUrlPayments') );
        },
        copyTikTokUsedCarsExceptTrucksAndSuvs() {
            this.copyText( document.querySelector('#tiktokUsedCarsExceptTrucksAndSuvs') );
        },
        copyTikTokTrucksAndSuvs() {
            this.copyText( document.querySelector('#tiktokTrucksAndSuvs') );
        },
        copyTikTokFeedProducts() {
            this.copyText( document.querySelector('#tiktokFeedUrlProducts') );
        },
        copyNemerRam1500() {
            this.copyText( document.querySelector('#fbNemerRam1500') );
        },
        copyMtOrabRAM15002023() {
            this.copyText( document.querySelector('#fbMtOrabRAM15002023') );
        },
        copyCertifiedCars() {
            this.copyText( document.querySelector('#certifiedCars') );
        },
        copyText(element) {
            element.setAttribute('type', 'text');
            element.select();

            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                this.$notify({
                    title: "Success",
                    message: "Feed Url has been copied to clipboard",
                    type: "success"
                });
            } catch (err) { }

            /* unselect the range */
            element.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()
        },
        copyFTPCreds() {
            let testingCodeToCopy = document.querySelector('#FTPCreds');
            testingCodeToCopy.setAttribute('type', 'text');
            testingCodeToCopy.select();

            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                this.$notify({
                    title: "Success",
                    message: "FTP Credentials has been copied to clipboard",
                    type: "success"
                });
            } catch (err) {
            }

            /* unselect the range */
            testingCodeToCopy.setAttribute('type', 'hidden');
            window.getSelection().removeAllRanges();
        },
        loadCompanyCarDataSources(query) {
            this.axios
                .get("/api/company_car_data_source", {
                    params: {
                        page: 1,
                        page_size: 9999999999,
                        q: query
                    }
                })
                .then(response => {
                    if (response.data.success == true) {
                        this.company_car_data_sources = response.data.data;
                    }
                });
        },
        handleCompanyCarDataSourcesChange(selectedDataSourceID) {
            this.company_car_data_sources.forEach((e) => {
                if(selectedDataSourceID == e.id) {
                    this.currentDataSource = e;
                }
            });
        },
        handleCompanyCarDatasourceFormSuccess() {
            this.loadCompanyCarDataSources('');
            this.companyCarDatasourceFormVisible = false;
        },
        handleAddProvider() {
            this.selectedDataSource = {
                id: '',
                name: '',
                email_address: '',
                phone_number: '',
                directory: '',
                data_type: 1,
                dealership_column_header: '',
            };

            this.companyCarDatasourceFormVisible = true;
        },
        handleUpdateProvider(dataSource) {
            this.selectedDataSource = dataSource;
            this.companyCarDatasourceFormVisible = true;
        },
        handleDMSCampaignChange(value) {
            this.handleSubmit();
        },
        handleTabClick(tab) {
            this.activeTab = tab.name
        },
        checkFBAuth() {
            if(this.$route.query.fb_ss_success && this.$route.query.fb_access_token) {
                this.activeTab = 'fb-leadgen'
                this.activeIntegrationTab = this.activeTab
            }
        },
        loadCompanies(query) {
            this.axios
            .get("/api/company-search", {
                params: {
                    page: 1,
                    q: query
                }
            })
            .then(response => {
                if (response.data.success == true) {
                    this.companies = response.data.data;
                }
            });
        },
        handleCompanyChange(selectedCompany) {
            this.companies.forEach((e,i) => {
                if( selectedCompany == e.id ) {
                    this.resetPage()
                    this.company = Object.assign(this.company, e);
                    this.getCompanyCarsCount();
                    localStorage.removeItem('selectedFbPage')
                    return true;
                }
            });
        },
        resetPage() {
            this.company = {}
            this.activeTab = ''
            this.activeIntegrationTab = "settings"
            ++this.phoneInputKey
        },
        handleFilterChange(data) {
            this.resetPage()
            this.company = data
            return this.getCompanyCarsCount()
        },
        phoneInputSuccess(data) {
            if(data) {
                this.company.representative_phone = data
            }
        },
        handleFbFeedPreview(status) {
            this.fbFeedStatus = status
            this.showFbFeedValidator = true
        },

        loadFacebookSDK() {
            window.fbAsyncInit = () => {
                const fbAppId = this.$fbAppId
                FB.init({
                    appId: fbAppId,
                    autoLogAppEvents: true,
                    xfbml: true,
                    version: process.env.MIX_FACEBOOK_APP_VERSION || 'v20.0',
                })

                FB.getLoginStatus(function(response) {

                    if(response.status === 'connected'){
                        FB.api('/me', function(me_response) {
                            store.commit('UpdateFbBusinessMessengerMe', me_response)
                        });
                        store.commit('UpdateFbBusinessMessengerUser', {
                            response: response,
                            source: 'get-logged-in',
                        })
                    } else {
                        store.commit('UpdateFbBusinessMessengerUser', null)
                    }
                });
            };
            ((d, s, id) => {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = 'https://connect.facebook.net/en_US/sdk.js';
                fjs.parentNode.insertBefore(js, fjs);
            })(document, 'script', 'facebook-jssdk');
        },

        loginToFacebook() {
            FB.login((response) => {
                if (response.authResponse && response.status === "connected") {
                    const meSelfThis = this
                    FB.api('/me', function(me_response) {
                        store.commit('UpdateFbBusinessMessengerMe', me_response)
                        meSelfThis.$notify({
                            title: "SimpSocial FB App Connected",
                            message: `Thank you, ${me_response.name}!`,
                            type: "success"
                        })
                    });
                    store.commit('UpdateFbBusinessMessengerUser', {
                        response: response,
                        source: 'login',
                    })
                } else if(response.status === "unknown") {
                    this.$notify({
                        title: "SimpSocial FB App Connection Failed",
                        message: "You opted not to connect the SimpSocial Facebook app to your Facebook account.",
                        type: "error"
                    })
                    store.commit('UpdateFbBusinessMessengerUser', {})
                }
                else {
                    console.log('User cancelled login or did not fully authorize.');
                }
            }, {scope: 'pages_messaging,pages_show_list,pages_read_engagement,pages_manage_metadata'});
        },

        async handleMessengerTokenUpdate(auth) {
            await this.axios.put(
                `api/company/${this.company.id}/messenger-save-business-login-access-token`,
                {
                    access_token: auth.accessToken || null,
                    page_access_token: auth.accessToken || null,
                    user_access_token: auth.accessToken || null,
                    user_id: auth.userID || null,
                    auth_response: auth || null,
                    fb_page_id: this.company.fb_page_id || null,
                }
            )
                .then(res => {
                    console.log('handleMessengerTokenUpdate => res?', res)
                })
                .catch(err => {
                    console.log('handleMessengerTokenUpdate => err?', err)
                })
        }
    },

    watch: {
        'company.non_alo': {
            immediate: true,
            handler: function(n) {
                if(n !== undefined || typeof n === 'number') {
                    this.company.non_alo = n == 1 ? true : false
                }
            }
        },
        'company.non_alo_status': {
            immediate: true,
            handler: function(n) {
                if(n !== undefined || typeof n === 'number') {
                    this.company.non_alo_status = n == 1 ? true : false
                }
            }
        },
        'fbBusinessMessengerUser': function (val) {
            if(val && val?.response?.authResponse &&
                val?.response?.authResponse?.accessToken && val?.source === 'login'
            ) {
                this.handleMessengerTokenUpdate(val.response.authResponse)
            }
        }
    },

    beforeDestroy() {
        this.resetPage()
    }
};
</script>
