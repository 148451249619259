<template>
    <el-main>
        <el-tabs
            v-model="activeTab"
            type="border-card"
        >
            <el-tab-pane name="global">
                <div slot="label" style="display: flex; align-items: center; justify-content: center;">
                    Lead source -> Sequence (global)
                </div>
                <GlobalSettings v-if="activeTab === 'global'" />
            </el-tab-pane>
            <el-tab-pane name="per-company">
                <div slot="label" style="display: flex; align-items: center; justify-content: center;">
                    Lead source -> Sequence (Per Company)
                </div>
                <CompanySettings v-if="activeTab === 'per-company'" />
            </el-tab-pane>
            <el-tab-pane name="per-company-dispo-to-seq">
                <div slot="label" style="display: flex; align-items: center; justify-content: center;">
                    Disposition Status -> Sequence (Per Company)
                </div>
                <CompanySettingsDispoToSequence v-if="activeTab === 'per-company-dispo-to-seq'" />
            </el-tab-pane>
        </el-tabs>
    </el-main>
</template>
<script>

    import GlobalSettings from "./Settings/Global.vue";
    import CompanySettings from "./Settings/PerCompany.vue";
    import CompanySettingsDispoToSequence from "./Settings/PerCompanyDispositionToSequence.vue";

    export default {
        components: {
            GlobalSettings,
            CompanySettings,
            CompanySettingsDispoToSequence,
        },
        data() {
            return {
                activeTab: 'global',
            }
        },
    }
</script>
