<template>
    <div style="display: flex; flex-direction: column;">
        <el-card
            v-if="Array.isArray(chats) && chats.length > 0"
            v-for="(chat, i) in chats"
            :key="`${chat.id}-${i}`"
            :ref="i === chats.length-1 ? 'last' : null"
            :class="alignByDirection(chat.direction)"
            style="margin: 15px 0; max-width: 65%;"
        >
            <div
                style="font-size: x-small"
                :class="floatByDirection(chat.direction)"
            >
                <small>
                    <i>{{ getSender(chat) }}</i>
                </small>
            </div>

            <el-image
                v-if="Array.isArray(chat?.attachments) && chat?.attachments.length > 0 && chat?.attachments[0].hasOwnProperty('url')"
                :src="chat.attachments[0].url"
                lazy
                fit="scale-down"
                style="width: 80%; height: 80%;"
            >
                <div slot="error">
                </div>
            </el-image>
            <p
                style="font-size: small;"
                v-html="formatBody(chat)"
            ></p>

            <div
                style="font-size: xx-small"
                :class="floatByDirection(chat.direction)"
            >
                <small>
                    {{ chat.created_at|formatDate(true) }}
                </small>
            </div>
        </el-card>
    </div>

</template>

<script>
import linkifyHtml from 'linkify-html';
    export default {
        name: 'Preview',
        props: {
            contact: {
                type: Object,
                required: true
            },
        },
        data() {
            return {}
        },
        mounted() {
            this.scrollToBottom()
        },
        methods: {
            alignByDirection(direction) {
                switch (direction) {
                    case 0:
                        return 'align-to-center'
                    case 1:
                        return 'align-to-left'
                    case 2:
                        return 'align-to-right'
                }
            },
            floatByDirection(direction) {
                switch (direction) {
                    case 0:
                        return 'float-to-center'
                    case 1:
                        return 'float-to-left'
                    case 2:
                        return 'float-to-right'
                }
            },
            getSender(chat) {
                if(chat.direction === 1) {
                    return this.contact.name
                }
                return 'SimpSocial Engage AI'
            },
            scrollToBottom() {
                const el = this.$refs.last
                if(el) {
                    el[0].$el.scrollIntoView({});
                }

            },
            formatBody(content) {
                let body = content.body

                body = linkifyHtml(
                    body,
                    {
                        target: '_blank',
                        nl2br: true,
                    }
                )
                return body
            },
        },
        computed: {
            chats() {
                const convos = this.$store.getters.getEngageConvos().data
                return convos.filter(chat => {
                    const is_appointment_set = typeof chat === 'object' && chat.hasOwnProperty('body') ? chat?.body.toLowerCase().includes('an appointment has been set for') && chat?.direction === 2 : false
                    if(is_appointment_set) return chat

                    if(
                        chat && chat.hasOwnProperty('body') && chat.body &&
                        (typeof chat === 'object' && chat.hasOwnProperty('campaign_id') && !(chat?.direction === 2 && chat.campaign_id === null))
                    ) return chat
                })
            }
        },
    }
</script>

<style scoped>
    .align-to-center {
        align-self: center;
    }
    .align-to-left {
        align-self: start;
    }
    .align-to-right {
        align-self: end;
    }
    .float-to-center {
        text-align: center;
    }
    .float-to-left {
        float: left;
    }
    .float-to-right {
        float: right;
    }
</style>
