<template>
    <div
        v-loading="loading"
    >
        <el-row type="flex" class="row-bg" justify="center">
            <el-link
                v-if="!validOrWithUserToken"
                class="el-button el-button--secondary"
                type="primary"
                target="_parent"
                :href="`/auth/facebook/user/${currentUser.id}`"
            >
                <i class="el-icon-top-right"></i> Connect to Facebook
            </el-link>
        </el-row>

        <el-row>
            <el-col v-if="validOrWithUserToken && !showAddSubscription">
                <el-row type="flex" class="row-bg" justify="center">
                    <el-button
                        type="success"
                        @click="toggleAddSubscription"
                    >Select FB Page</el-button>
                </el-row>
            </el-col>
            <el-col
                v-if="showAddSubscription"
                :span="24"
            >
                <select-fb-page
                    :fb-data="fbMeData"
                    :associated="associated"
                    :fb-api-endpoint="fbApiEndpoint"
                    :fb-user-info="fbUserInfo"
                    @loading-fb-add-subs="handleLoading"
                    @close-add-subscription="toggleAddSubscription"
                    @error-permission="validOrWithUserToken = false"
                    @reauthenticate="handleReauthenticate"
                ></select-fb-page>
            </el-col>
        </el-row>
    </div>

</template>

<script>
import SelectFbPage from "./SelectFbPage"

export default {
    name: "FBLeadGen",
    components: {
        SelectFbPage,
    },
    props: {
        company: {
            type: Object,
            required: true
        },
        user: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: true,
            selectedCompany: {},
            currentUser: {},
            validOrWithUserToken: false,
            storedFBUserToken: false,
            fbMeData: {},
            showAddSubscription: false,
            fbApiEndpoint: '',
            associated: [],
            fbUserInfo: {},
        }
    },
    mounted() {
        this.setFB()
    },
    methods: {
        setFB() {
            this.selectedCompany = this.company
            this.currentUser = this.user
            this.fbApiEndpoint = `api/company/${this.selectedCompany.id}/facebook/${this.currentUser.id}`

            if(this.$route.query.fb_ss_success && this.$route.query.fb_access_token) {
                return this.updateUsersFBUserToken()
            } else {
                return this.checkUserToken()
            }
        },
        async checkUserToken() {
            await this.axios.get(
                `api/company/${this.selectedCompany.id}/facebook/${this.currentUser.id}/me`
            )
                .then(res => {
                    this.fbMeData = res.data.data.fb_user
                    this.associated = res.data.data.associated
                    this.fbUserInfo = res.data.data.fb_info
                    this.validOrWithUserToken = true
                    this.toggleAddSubscription()
                    return this.handleLoading()
                })
                .catch(err => {
                    if(err.response.data.message && err.response.data.message === 'missing user') {
                        return console.log('need to show log in button')
                    }
                    console.log('err?', err)
                    return this.handleLoading()
                })
        },

        async updateUsersFBUserToken() {
            await this.axios.post(
                `/api/company-user/${this.currentUser.id}/update`,
                {
                    'fb_user_access_token': this.$route.query.fb_access_token,
                    'fb_token_update': true
                }
            )
                .then(res => {

                    const storedUser = JSON.parse(localStorage.currentUser)
                    localStorage.removeItem('currentUser')

                    const newStoredUser = {
                        ...storedUser,
                        fb_user_access_token: res.data.data.user.fb_user_access_token
                    }
                    localStorage.setItem('currentUser', JSON.stringify(newStoredUser))

                    this.$notify({
                        title: 'Success:',
                        type: "success",
                        message: "Log in successful"
                    })
                    this.validOrWithUserToken = true
                    this.checkUserToken()
                    return this.$router.replace('/company')
                })
                .catch(err => {
                    console.log('errUser', err)
                    return this.$notify({
                        title: 'Error:',
                        type: "error",
                        message: err.response.data.message
                    })
                })
        },
        toggleAddSubscription() {
            this.showAddSubscription = !this.showAddSubscription
        },
        handleLoading(state = undefined) {
            if(state !== undefined) {
                this.loading = state
                return
            }
            this.loading = !this.loading
        },
        handleReauthenticate() {
            this.toggleAddSubscription()
            this.validOrWithUserToken = false
        }
    }
}
</script>
