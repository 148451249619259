<template>
    <el-tabs v-model="activeTab" style='width: 100%' class="messenger-tabs">
        <el-tab-pane label="Messenger" name="messenger">
            <company-messenger
                v-if="activeTab === 'messenger'"
                @messenger-authorized="handleMessengerAuth"
            ></company-messenger>
        </el-tab-pane>
        <el-tab-pane label="Webchat ai" name="webchatai" v-if="isWebchatAiEnabled">
            <webchat-ai
                v-if="activeTab === 'webchatai'"
            ></webchat-ai>
        </el-tab-pane>
        <el-tab-pane label="TikTok" name="tiktok">
            <RestrictedScreen
                v-if="tiktokLoginFailed"
                :enable_click="false"
            />
            <tiktok
                v-if="activeTab === 'tiktok' && !tiktokLoginFailed"
                @login-failed="handleLoginFailed"
            ></tiktok>
        </el-tab-pane>
    </el-tabs>
</template>
<script>
import store from "../store";
import CompanyMessenger from './messenger/CompanyMessenger';
import PersonalMessenger from './messenger/PersonalMessenger';
import Tiktok from "./social-listening/Tiktok";
import RestrictedScreen from "./widgets/RestrictedScreen";
import WebchatAi from "./webchat/WechatAi";

export default {
    components: {
        RestrictedScreen,
        CompanyMessenger,
        PersonalMessenger,
        Tiktok,
        WebchatAi,
    },
    data() {
        return {
            activeTab: 'messenger',
            doneInitialRedirect: false,
            tiktokLoginFailed: false,
            isWebchatAiEnabled: false,
            user: {},
            company: {},
        };
    },
    mounted() {
        this.user = JSON.parse(store.state.currentUser)
        this.company = JSON.parse(store.state.currentCompany)
        this.getLatestCompanyData()
    },
    methods: {
        handleMessengerAuth(data) {
            if(!data && !this.doneInitialRedirect) {
                this.activeTab = 'tiktok'
                this.doneInitialRedirect = true
            }
        },
        handleLoginFailed() {
            this.tiktokLoginFailed = true
            this.activeTab = 'messenger'
        },
        getLatestCompanyData() {
            this.axios
                .get('/api/company/' + this.company.aloware_id)
                .then(response => {
                    if (response.data.success == true) {
                        this.company = response.data.data;
                        // this.isWebchatAiEnabled = response.data.data.webchat_sarahai_integration !== null ? response.data.data.webchat_sarahai_integration.status == 1 : false
                        this.isWebchatAiEnabled = true
                        
                        // if( this.isWebchatAiEnabled ) {
                        //     this.activeTab = 'webchatai'
                        // }
                    }
                })
                .catch(err => {
                    this.$root.handleErrors(err.response)
            });
        },
    }
};
</script>
<style scope>
.btn-authorize {
    margin: 0 auto;
}
.btn-authorize span {
    padding: 50px;
    background-color: #51e3a4;
    border-radius: 10px;
    color: white;
    font-size: 20px;
}
.messenger-tabs .el-tabs__content {
    height: 90vh;
}
.ellipsis-1 {
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.message-history {
    height: 70vh;
    background-color: #ffffff;
    padding: 10px;
    overflow-y: auto;
}
.contact-list {
    background-color: #fff;
    width: 360px;
    padding: 8px;
}
.contact-list .contact-list-header {
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    direction: ltr;
}
.contact-list .contact-list-header img {
    max-width: 40px;
    margin-right: 12px;
    display: flex;
    direction: ltr;
    flex-direction: column;
    align-self: flex-start;
    position: relative;
}
.contact-list .contact-list-header .heading {
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-shrink: 1;
}
.contact-list .contact-list-body {
    height: calc(100vh - 52px - 16px);
    overflow: auto;
}
.contact-list .el-card {
    border: 0px;
    border-radius: 8px;
}
.contact-list .el-card:hover {
    background-color: rgb(240, 242, 245);
}
.contact-list .el-card.active {
    background-color: #e9f3ff;
}
.contact-list .el-card__body {
    padding: 0px;
    margin: 0 8px;
    display: flex;
    flex-direction: row;
    direction: ltr;
    flex-grow: 1;
    flex-shrink: 1;
}
.contact-list .el-card__body img {
    padding: 0px;
    margin: 8px 12px 8px 0px;
    max-height: 56px;
    display: flex;
    direction: ltr;
    flex-direction: column;
    align-self: flex-start;
    position: relative;
}
.contact-list .msg-info {
    align-items: center;
    font-size: 15px;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-shrink: 1;
}
.contact-list .msg-info .msg-user-details {
    padding: 12px 0;
    direction: ltr;
    display: flex;
    flex-basis: 0px;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
}
.contact-list .msg-info .msg-user-details .msg-name {
    display: block;
}
.contact-list .msg-info .msg-user-details .msg-details {
    display: block;
    font-size: 13px;
    color: rgb(101, 103, 107);
}
.contact-list .msg-info .msg-user-details .msg-details .short-message {
    max-width: calc(100% - 40px);
    float: left;
}
.contact-list .msg-info .msg-user-details .msg-details .convo-time {
    max-width: 40px;
    float: left;
}
.contact-list .msg-info .msg-status {
    width: 12px;
    height: 12px;
    background-color: rgb(24, 118, 242);
    border-radius: 50%;
    margin: 8px 0 8px 12px;
}
.contact-list .msg-info .msg-status.invisible {
    display: none;
}
.message-list {
    padding: 0;
    height: 100vh;
}
.msg-item {
    padding:10px;
    display: inline-block;
    border-radius: 5px;
    max-width: 100%;
}
.msg-row {
    margin-bottom: 10px;
}
.msg-row.right {
    text-align: right;
}
.msg-row.left {
    text-align: left;
}
.msg-row.right .msg-item {
    text-align: right;
    background-color: #0084ff;
    border-radius: 18px;
}
.msg-row.right .msg-item * {
    color: #fff;
}
.msg-row.left .msg-item {
    text-align: right;
    background-color: #e4e6eb;
    border-radius: 18px;
}
.msg-row.left .msg-item * {
    color: rgb(5, 5, 5);
}
.cars-listing {
    border: 1px solid #ccc;
    margin-bottom: 15px;
}
.car-heading,
.car-image,
.car-details {
    padding: 15px;
}
.car-heading,
.car-image {
    border-bottom: 1px solid #ccc;
}
.car-heading::after {
    display: block;
    content: "";
    clear: both;
}
p.ellipsis-2 {
    margin: 0;
}
.ellipsis-2 {
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.el-upload, .el-upload-dragger {
    width: 100%!important;
}
.message-form {
    padding: 8px 0;
    display: flex;
    flex-direction: row;
    direction: ltr;
    align-items: flex-end;
}
.textarea-container {
    background-color: #f0f2f5;
    border-radius: 20px;
    min-height: 38px;
    height: 38px;
    display: flex;
    flex-direction: row;
    direction: ltr;
    max-height: 110px;
    flex-shrink: 1;
    width: 100%;
    margin-left: 8px;
}
.el-textarea {
    display: flex;
    align-items: center;
    padding: 4px 0 4px 10px;
    height: calc(100% - 8px);
}
.el-textarea__inner {
    background-color: transparent;
    resize: none;
    border: none;
    color: #65676b;
    font-family: Segoe UI Historic, Segoe UI, Helvetica, Arial, sans-serif;
    font-size: 15px;
    height: 28px;
    padding: 4px 0;
    line-height: 20px;
    max-height: 108px;
    width: 100%;
}
.el-textarea__inner::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #65676b;
    opacity: 1; /* Firefox */
}
.el-textarea__inner:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #65676b;
}
.el-textarea__inner::-ms-input-placeholder { /* Microsoft Edge */
    color: #65676b;
}
.send-message-button,
.translate-message-button {
    padding: 8px 0;
    display: flex;
    direction: ltr;
    flex-direction: column;
    position: relative;
    align-content: center;
    background-color: #0084ff;
    border-radius: 6px;
    margin: 0 8px;
}
.send-message-button span,
.translate-message-button span {
    width: 100px;
    font-size: 16px;
    color: #fff;
    text-align: center;
}
.translate-message-button span {
    width: 120px;
}
.send-message-button.el-link:hover::after,
.translate-message-button.el-link:hover::after {
    display: none;
}
.message-icons {
    padding: 4px;
    height: 20px;
    width: 20px;
    display: flex;
    direction: ltr;
    flex-direction: column;
    position: relative;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 4px;
    align-self: center;
    transition-duration: 0.3s;
    transition-property: transform;
}
.message-icons:hover {
    background-color: #f0f2f5;
}
.open-more-action {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.add-attachments {
    display: flex;
    direction: ltr;
    flex-direction: column;
    align-self: flex-start;
    height: 20px;
    width: 20px;
    background-color: #0084ff;
    color: #fff;
    border-radius: 50%;
    font-size: 20px;
    line-height: 20px;
    align-items: center;
}
.action-buttons {
    margin-top: 8px;
    padding: 0 8px;
}
.action-buttons .el-button {
    border: unset;
    background-color: #e4e6eb;
    color: #050505;
    padding: 8px 0;
    height: 36px;
    width: calc(14% - 12px);
    border-radius: 6px;
}
.action-buttons .el-button:hover {
    border: unset;
    background-color: #d8dadf;
}
.action-buttons .el-link {
    background-color: #e4e6eb;
    color: #050505;
    width: calc((100% / 8) - 7px);
    border-radius: 6px;
}
.action-buttons .el-link:not(:first-child) {
    margin-left: 3px;
}
.action-buttons .el-link span {
    color: #fff;
    padding: 8px 0;
    font-size: 13px;
}
.action-buttons .el-link:hover::after {
    display: none;
}
.action-buttons .el-link .el-link--inner {
    display: flex;
    flex-direction: row;
    direction: ltr;
}
.action-buttons .el-link .el-link--inner .btn-icon {
    display: flex;
    direction: ltr;
    flex-direction: column;
    align-self: flex-start;
    margin-right: 5px;
    height: 16px;
}
.action-buttons .el-link .el-link--inner .btn-name {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: unset;
    color: #fff;
}
.giphy-listing {
    display: flex;
    flex-wrap: wrap;
}
.gif-container {
    max-width: 100%;
    flex: calc(100% - 20px);
    margin: 4px;
    display: grid;
    align-content: center;
    border: 1px solid #efefef;
    background-color: #fafafa;
    border-radius: 4px;
}
.gif-container img {
    width: 100%;
}
.search-bar,
.search-bar:focus {
    width:100%;
    height:25px;
    line-height:25px;
    padding:0px 5px;
    border:1px solid #ccc;
    border-radius:4px;
    color:#333;
    outline: none;
}

.btn-grad-variable {background-image: linear-gradient(to right, #7474BF 0%, #348AC7  51%, #7474BF  100%)}
.btn-grad-template {background-image: linear-gradient(to right, #834d9b 0%, #d04ed6  51%, #834d9b  100%)}
.btn-grad-inventory {background-image: linear-gradient(to right, #DC2424 0%, #4A569D  51%, #DC2424  100%)}
.btn-grad-credit-app {background-image: linear-gradient(to right, #ec6212 0%, #394068  51%, #a27de7  100%)}
.btn-grad-media {background-image: linear-gradient(to right, #159957 0%, #155799  51%, #159957  100%)}
.btn-grad-giphy {background-image: linear-gradient(to right, #000000 0%, #434343  51%, #000000  100%)}
.btn-grad-lead {background-image: linear-gradient(to right, #FF512F 0%, #DD2476  51%, #FF512F  100%)}
.btn-grad-support {background-image: linear-gradient(to right, #6A9113 0%, #141517  51%, #6A9113  100%)}
.btn-grad {
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
}
.btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}
.giphy-dialog .giphy-header {
    display: flex;
    flex-direction: row;
    direction: ltr;
    font-weight: bold;
}
.giphy-dialog .giphy-header .giphy-text {
    font-size: 20px;
    display: flex;
    flex-direction: row;
    padding: unset;
    margin-right: 5px;
}
.giphy-dialog .giphy-header .giphy-logo {
    display: flex;
    direction: ltr;
    flex-direction: column;
    align-self: center;
    height: 16px;
}
.bg-transparent {
    background-color: transparent!important;
    padding: unset;
}
.mt-8 {
    margin-top: 8px;
}
.ml-0 {
    margin-left: 0!important;
}
#tab-messenger {
    padding-left: 20px;
}
@media screen and (max-width: 1220px) {
    .action-buttons .btn-grad-giphy .el-link--inner .btn-icon {
        height: 13px;
    }
    .action-buttons .el-link .el-link--inner .btn-name {
        display: none;
    }
}
</style>
