<template>
    <el-container class="mail-inbox">
        <div v-if="selectedMessage">
            <el-button type="light"
                            size="small"
                            icon="el-icon-back"
                            style=""
                            @click="selectedMessage = null">Back</el-button>

            <MessageContent :message="selectedMessage" @messageIsRead="handleMessageIsRead" @messageIsUnRead="handleMessageIsUnRead" />
        </div>
        <div v-else>
            <el-table
                :data="incomingEmails"
                stripe
                style="width: 100%"
                @row-click="handleSelectedMail"
                >
                <el-table-column label="" prop="from" width="25">
                    <template slot-scope="scope">
                        <span v-if="scope.row.is_read == 0">
                            <i class="fa fa-envelope"></i>
                        </span>
                        <span v-else>
                            <i class="far fa-envelope-open"></i>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="From" prop="from">
                    <template slot-scope="scope">
                        <span :class="scope.row.is_read == 0 ? 'unread' : ''">{{ scope.row.from_name }} {{ scope.row.from_email }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="To" prop="to">
                    <template slot-scope="scope">
                        <span :class="scope.row.is_read == 0 ? 'unread' : ''">{{ scope.row.recipient }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Message" prop="subject">
                    <template slot-scope="scope">
                        <span :class="scope.row.is_read == 0 ? 'unread' : ''">{{ scope.row.subject }} - {{ scope.row.message }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Date" prop="date_received">
                    <template slot-scope="scope">
                        <span :class="scope.row.is_read == 0 ? 'unread' : ''">{{ scope.row.date_received | formatDate }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                background
                @current-change="handlePageChange"
                :current-page.sync="currentPage"
                :page-size="10"
                layout="prev, pager, next"
                :total="totalRecords"
            ></el-pagination>
        </div>
    </el-container>
</template>
<style>

</style>
<script>
import store from "../../store";
import MessageContent from "./inbox/MessageContent";

export default {
    components: {
        MessageContent
    },
    data() {
        return {
            incomingEmails: [],
            company: {},
            user: {},
            selectedMessage: null,
            totalRecords: 0,
            currentPage: 1,
            perPage: 20,
            exportType: 'json'
        };
    },
    computed: {
    },
    mounted() {
        this.company = JSON.parse(store.state.currentCompany)
        this.user = JSON.parse(store.state.currentUser)
        console.log('getting incoming emails')
        this.getIncomingEmails()
        this.listenToBroadcast()
    },
    created() {
        //this.listenToBroadcast()
    },
    methods: {
        getIncomingEmails() {
            let params = {
                page: this.currentPage,
                page_size: this.perPage,
                //q: this.filters.q,
                export_type: this.exportType,
            }
            this.axios
            .get('/api/company/' + this.company.aloware_id + '/user/' + this.user.id + '/emails', params)
            .then(response => {
                if (response.data.success == true) {
                    this.incomingEmails = response.data.data
                    this.totalRecords = response.data.pagination.total

                    console.log('total recs', this.totalRecords)
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        listenToBroadcast: function() {
            window.Echo.private(`email-received-${this.user.company_id}-${this.user.id}`).listen('EmailReceived', (e) => {
                this.incomingEmails.unshift(e.data)
            })
        },
        handleSelectedMail(row) {
            this.selectedMessage = row
        },
        handleMessageIsRead(messageId) {
            if(this.selectedMessage.id == messageId) {
                this.selectedMessage.is_read = 1
            }
        },
        handleMessageIsUnRead(messageId) {
            if(this.selectedMessage.id == messageId) {
                this.selectedMessage.is_read = 0
            }
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.getIncomingEmails();
        },
    },
    watch: {
        isConnected: function(val, oldVal) {

        }
    },
};
</script>
