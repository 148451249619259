import { Node, mergeAttributes } from '@tiptap/vue-2'


const MessageTree = Node.create({
    name: 'MessageTree',
    group: 'block',
    draggable: true,
    inline: false,

    addAttributes: () => ({
        content: { default: null },
        message: { default: null }
    }),
    
    
    addNodeView() {
        
        return ({ HTMLAttributes }) => {
            if(HTMLAttributes.content && HTMLAttributes.message) {
                const dom = document.createElement('div')
                dom.style.cssText = 'margin: 1rem;'
                dom.append(document.createElement('p').innerHTML = '---')
                dom.append(document.createElement('p'))

                const msg = HTMLAttributes.message
                const options = { year: "numeric", month: "long", day: "numeric", hour: 'numeric', minute: 'numeric' }
                const wroteInd = `On ${new Date(msg.created_at).toLocaleDateString(undefined, options)} ${msg.from_name} <${msg.from_email}> wrote:`
                dom.append(document.createElement('p').innerHTML = wroteInd)
                const clone = HTMLAttributes.content.cloneNode(true)
                dom.append(clone)

                return {dom}
            }
        }
    },

    parseHTML() {
        return [
            {
                tag: 'div'
            }
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['div', mergeAttributes(HTMLAttributes)]
        
    },    
    
    addCommands() {
        return {
            setMessageTree: (attributes) => ({ commands }) => {
                return commands
                    .insertContent({
                        type: this.name,
                        attrs: attributes
                    })
            },
        };
    },
})

export default MessageTree
