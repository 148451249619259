<template>
    <el-main>
        <div class="page-title">
            <h1>Inventory</h1>
        </div>
        <el-row>
            <el-col :span="6">
                <el-tag type="info" effect="plain">
                    Found:
                    <strong>{{ totalRecords }}</strong> cars
                </el-tag>
            </el-col>
            <el-col :span="6">
                <el-select v-model="company_id"
                    remote
                    filterable
                    clearable
                    :remote-method="loadCompanies"
                    @change="handleCompanyChange"
                    placeholder="All Companies">
                    <el-option
                    v-for="item in companies"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="6" style="font-size: 14px;">
                Price range:
                <el-tag size="mini">$ {{pricerange[0]}}</el-tag>&nbsp;-&nbsp;
                <el-tag size="mini">$ {{pricerange[1]}}</el-tag>
                <el-slider
                    v-model="pricerange"
                    range
                    :max="price_max"
                    :step="1000"
                    label
                    @change="handlePricerangeChange"
                ></el-slider>
            </el-col>
            <el-col :span="6">
                <el-input
                    v-model="search"
                    size="mini"
                    clearable
                    placeholder="Search for make, model, stock #, vin"
                />
            </el-col>
        </el-row>
        <el-pagination
            background
            @current-change="handlePageChange"
            :current-page.sync="currentPage"
            :page-size="10"
            layout="prev, pager, next"
            :total="totalRecords"
        ></el-pagination>
        <el-table :data="cars" v-loading="loading">
            <el-table-column label="Image">
                <template slot-scope="scope">
                    <img
                        v-if="(typeof scope.row.galleries[0] !== 'undefined')"
                        :src="scope.row.galleries[0].url"
                        alt="Car Image"
                        style="max-height: 50px;max-width: 100px;"
                    />
                </template>
            </el-table-column>
            <el-table-column label="Company" prop="company.name"></el-table-column>
            <el-table-column label="VIN" prop="vin"></el-table-column>
            <el-table-column label="Image Count" prop="galleries.length"></el-table-column>
            <el-table-column label="Price" prop="price"></el-table-column>
            <el-table-column label="Type">
                <template v-slot="props">
                    {{ formatCarType(props.row.new_used) }}
                </template>
            </el-table-column>
            <el-table-column align="right">
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="success"
                        @click="handleView(scope.$index, scope.row)"
                    >
                        View
                        <i class="el-icon-top-right"></i>
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            background
            @current-change="handlePageChange"
            :current-page.sync="currentPage"
            :page-size="10"
            layout="prev, pager, next"
            :total="totalRecords"
        ></el-pagination>
    </el-main>
</template>
<style>
.el-pagination {
    text-align: center;
    margin-top: 20px;
}
</style>
<script>
import store from "../../store";

export default {
    props: {
        company_id: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            uploading: false,
            errors: {},
            isProgress: false,
            cars: [],
            companies: [],
            search: "",
            pricerange: [0, 50000],
            price_min: 0,
            price_max: 50000,
            totalRecords: 0,
            currentPage: 1,
            isSearching: false,
            loading: false,
        };
    },
    computed: {},
    mounted() {
        this.loadCars();
        this.loadCompanies('');
    },
    methods: {
        loadCars() {
            this.cars = []
            this.loading = true
            this.axios
                .get("/api/car", {
                    params: {
                        page: this.currentPage,
                        q: this.search,
                        price_min: this.pricerange[0],
                        price_max: this.pricerange[1],
                        company_id: this.company_id
                    }
                })
                .then(response => {
                    if (response.data.success == true) {
                        this.cars = response.data.data;
                        this.totalRecords = response.data.pagination.total;

                        if (!this.isSearching) {
                            this.pricerange[1] = response.data.max_price_db;
                        }
                    } else {
                        this.$notify({
                            title: "Error",
                            message:
                                "There has been an error getting cars list",
                            type: "error"
                        });
                    }
                    this.loading = false
                });
        },
        loadCompanies(query) {
            this.axios
                .get("/api/company-search", {
                    params: {
                        page: 1,
                        q: query
                    }
                })
                .then(response => {
                    if (response.data.success == true) {
                        this.companies = response.data.data;
                    }
                });
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.loadCars();
        },
        handleSearch(event) {
            console.log(event);
        },
        currencyFormat(row, column) {
            return this.$options.filters.currency(row.price);
        },
        displayCarImage(row, column) {
            return '<img src="...">';
        },
        handleView(index, row) {
            window.open(row.link);
        },
        handlePricerangeChange() {
            this.currentPage = 1;
            this.isSearching = true;
            this.loadCars();
        },
        handleCompanyChange() {
            this.currentPage = 1;
            this.isSearching = true;
            this.loadCars();
        },
        formatCarType(type) {
            return type === 'N' ? 'New' : 'Used'
        }
    },
    watch: {
        search: function(val, oldVal) {
            this.currentPage = 1;
            this.isSearching = true;
            this.loadCars();
        }
    }
};
</script>
