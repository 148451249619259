<template>
    <el-container>
        <el-container
            v-loading="loading"
            :element-loading-text="loadingText"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
        >
            <el-main>
                <el-row>
                    <el-col :lg="10" :sm="24" class="mt-sm-1">
                        <SequencesEmailsTemplatesList
                            :templates="templates"
                            :key="listLKey"
                            @selected-row="handleSelectedRow"
                        />
                    </el-col>
                    <hr class="show-hr">
                    <el-col :lg="14" :sm="24">
                        <SequencesEmailTemplatesUpsert
                            :selected-template="selectedTemplate"
                            :key="upsertKey"
                            @reset-form="listLKey++"
                            @submit-form="handleSubmitForm"
                            @delete-template="handleDeleteTemplate"
                        />
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import SequencesEmailsTemplatesList from "./EmailsTemplatesList"
import SequencesEmailTemplatesUpsert from "./EmailTemplatesUpsert.vue";
export default {
    name: "SequencesEmailTemplates",
    components: {
        SequencesEmailsTemplatesList,
        SequencesEmailTemplatesUpsert
    },
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            loadingText: 'Loading...',
            templates: [],
            selectedTemplate: {},
            listLKey: 0,
            upsertKey: 0,
            apiURL: '',
        }
    },
    mounted() {
        this.apiURL = `api/company/${this.company.id}/sequence-email-templates`
        this.getSequenceEmailTemplates()
    },
    methods: {
        handleSelectedRow(row) {
            this.selectedTemplate = row
            this.upsertKey++
        },
        async getSequenceEmailTemplates() {
            this.startLoading()
            await this.axios.get(`${this.apiURL}?all_status=1`)
                .then(res => {
                    this.templates = res.data.data || []
                    this.listLKey++
                    this.endLoading()
                })
                .catch(err => {
                    this.$root.handleErrors(err.response)
                    this.endLoading()
                })
        },
        async handleSubmitForm(template) {
            this.startLoading(template)
            await this.axios.post(
                this.apiURL,
                template
            )
                .then(res => {
                    this.selectedTemplate = {}
                    this.upsertKey++
                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: template.hasOwnProperty('id') ? `${res.data.data.template.title} has been updated.` : `${res.data.data.template.title} has been saved.`
                    })
                    return this.getSequenceEmailTemplates()
                })
                .catch(err => {
                    this.$root.handleErrors(err.response)
                    return this.endLoading()
                })
        },
        startLoading(data = null) {
            if(data && data.hasOwnProperty('content')) {
                this.loadingText = data.hasOwnProperty('id') ? 'Updating...' : 'Saving...'
            }
            this.loading = true
        },
        endLoading() {
            this.loadingText = 'Loading...'
            this.loading = false
        },
        async handleDeleteTemplate(template) {
            this.startLoading()
            await this.axios.delete(`${this.apiURL}/${template.id}`)
                .then(res => {
                    this.selectedTemplate = {}
                    this.upsertKey++
                    this.$message({
                        type: 'success',
                        message: 'Delete completed'
                    });
                    return this.getSequenceEmailTemplates()
                })
                .catch(err => {
                    this.$root.handleErrors(err.response)
                    return this.endLoading()
                })
        }
    },
}
</script>

<style scoped>
    .show-hr {
        display: none !important;
    }
    @media screen and (max-width: 1210px) {
        .mt-sm-1 {
            margin-bottom: 1rem;
        }
        .show-hr {
            display: block !important;
            margin: 2rem 0;
        }
    }
</style>
