<template>
    <el-container style="height: 100vh">
        <template v-if="isPageReady">
            <el-aside width="360px" class="contact-list">
                <div class="contact-list-header">
                    <!--<img src="images/fb-messenger-logo.png" alt='messenger logo' />-->
                    <div class="heading">Webchat Sarah.ai Chats</div>
                </div>
                <div v-if="conversations.length <= 0">
                    No Contact found. Please ensure that the page is properly connected
                </div>
                <div v-else class="contact-list-body" v-on:scroll.passive="handleContactListScroll" ref="conversationHistory">
                    <div v-for="convo in conversations" :key="convo.id" v-on:click="handleRecipientChange(convo)">
                        <el-card :class="['convo-' + convo.id, { active : selectedConvo === convo }]" :key="convo.id" shadow="none">
                            <i style="font-size: 50px; border-radius: 50%;margin-right: 10px" class="el-icon-user-solid"></i>
                            <div class="msg-info">
                                <div class="msg-user-details">
                                    <div class="msg-name">{{ convo.name }}</div>
                                    <div class="msg-details">
                                        <div class="convo-time">{{ convo.created_at | timestampFormatDateTime }}</div></div>
                                </div>
                            </div>
                        </el-card>
                    </div>
                </div>
            </el-aside>
            <el-main class="message-list">
                <div v-if="isEntriesLoading">
                    Loading ...
                </div>
                <div v-else-if="entries.length <= 0 && !isEntriesLoading">
                    No Conversation found
                </div>
                <div v-else>
                    <div class="message-history" v-on:scroll.passive="handleMessageListScroll" ref="messageHistory">
                        <div
                            v-for="msg in entries"
                            :key="msg.id">
                            <div class="msg-row" :class="msg.sender=='assistant' ? 'right' : 'left'">
                                <div class="msg-item" :class="msg.sender=='user2' ? 'bg-transparent' : ''">
                                    <div v-html="msg.content"></div>
                                </div><br>
                                <small>{{ msg.created_at | timestampFormatDateTime }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </el-main>
        </template>
        <template v-else>
            <div style="display: flex;width: 100%;height: 100vh;align-items: center;">
                <div class="text-center" style='margin: 0 auto;'>
                    <div class="cogs-container">
                        <i class="fas fa-cog fa-spin custom-cog"></i>
                        <i class="fas fa-cog fa-spin spin-reverse custom-cog-sm"></i>
                    </div>
                    <p class="loading-text">Loading Webchat Sarah.ai Conversations...</p>
                </div>
            </div>
        </template>
    </el-container>
</template>

<script>
import store from "../../store";

export default {
    name: 'webchat-ai',
    components: {
    },
    data() {
        return {
            vh: window.innerHeight * 0.01,
            uploading: false,
            errors: {},
            user: {},
            company: {},
            conversations: [],
            entries: [],
            isProgress: false,
            currentConversationPage: 1,
            totalConversationPages: 1,
            currentEntryPage: 1,
            totalEntryPages: 1,
            isEntriesLoading: false,
            isPageReady: false,
            selectedContact: {
                name: '',
                phone_number: '',
                ip_address: '',
            },
            selectedConvo: {},
            loadingMoreConvo: '',
            loadingMoreMessage: '',
            loadingSenderAction: '',
            nextpageLink: '',
            pageFreshLoaded: true,
            convoCounter: 0,
            convoToLoad: 20
        };
    },
    computed: {},
    mounted() {
        document.documentElement.style.setProperty('--vh', `${this.vh}px`)
        this.user = JSON.parse(store.state.currentUser)
        this.company = JSON.parse(store.state.currentCompany)
        this.loadConvos()
    },
    methods: {
        loadConvos() {
            this.axios.get(`/api/company/${this.company.id}/webchat-ai-convos`, {
                params: {
                    page: this.currentConversationPage,
                    order_by: 'created_at',
                    order_dir: 'desc',
                    page_size: 20,
                }
            }).then(response => {
                this.isPageReady = true
                this.totalConversationPages = response.data.pagination.total_pages

                var data = response.data.data
                
                this.conversations = this.conversations.concat(data)
            })
            .catch((error) => {})
        },
        loadEntries() {
            this.axios.get(`/api/company/${this.company.id}/webchat-ai-convo/${this.selectedConvo.id}/entries`, {
                params: {
                    page: this.currentEntryPage,
                    page_size: 20,
                }
            }).then(response => {
                this.isEntriesLoading = false
                this.totalEntryPages = response.data.pagination.total_pages

                var data = response.data.data
                
                this.entries = this.entries.concat(data)
            })
            .catch((error) => {})
        },
        handleRecipientChange(convo) {
            this.isEntriesLoading = true
            this.entries = []
            this.totalEntryPages = 1
            this.selectedConvo = convo
            this.loadEntries()
        },
        handleContactListScroll: function(e) {
            if(e.target.scrollTop >= (e.target.scrollHeight - e.target.clientHeight)-100) {
                let newPage = this.currentConversationPage + 1
                if( newPage <= this.totalConversationPages ) {
                    this.currentConversationPage++
                    this.loadConvos()
                }
            }
        },
        handleMessageListScroll: function(e) {
            if(e.target.scrollTop >= (e.target.scrollHeight - e.target.clientHeight)-100) {
                let newPage = this.currentEntryPage + 1
                if( newPage <= this.totalEntryPages ) {
                    this.currentEntryPage++
                    this.loadEntries()
                }
            }
        },
    },
    watch: { }
};
</script>
<style scoped>
.item {
    margin-top: 10px;
    margin-right: 40px;
}
.el-card {
    border-bottom: 1px solid #cacaca !important;
    border-radius: 8px 8px 0 0 !important;
    padding-top: 10px !important;
}
.message-list {
    padding: 20px 30px;
}
</style>
