<template>
    <div style="margin-top: 2rem;">
        <div class="message-details">
            <el-row>
                <el-col :span="12">
                    From: {{currentMessage.from_name}} <{{currentMessage.from_email}}><br>
                    Subject: <strong>{{currentMessage.subject}}</strong>
                </el-col>
                <el-col :span="12" style="text-align: right">
                    <el-button type="default"
                               size="small"
                               style=""
                               @click="markAs('unread')"><i class="fas fa-envelope"></i> Mark as unread</el-button>
                    <el-button type="primary"
                               size="small"
                               style=""
                               @click="handleReply"><i class="fas fa-reply"></i> Reply</el-button>
                </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row style="margin-top: 10px">
                <el-col :span="24">
                    <div v-html="currentMessage.message" ref="msg-dom"></div>
                </el-col>
            </el-row>
        </div>
        <div v-if="showComposer" style="margin-top: 2rem;">
            <el-container
                class="mail-compose"
                v-loading="sendingEmail"
                element-loading-text="Queueing Email..."
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :key="containerKey"
                style="display: block; padding: 10px"
            >
                <el-row>
                    <el-col :span="24">
                        <el-form
                            ref="formCompose"
                            :model="mail"
                            :rules="rules"
                            @submit.prevent="submitForm"
                        >
                            <el-form-item
                                label="Recipient(s)"
                                prop="recipients"
                            >
                                <el-row>
                                    <el-col :span="20">
                                        <div style="max-height: 100px;overflow: auto;">
                                            <el-tag
                                                :key="recipient"
                                                v-for="recipient in mail.recipients"
                                                closable
                                                size="mini"
                                                :disable-transitions="false"
                                                type="info"
                                                effect="plain"
                                                @close="handleRemoveRecipient(recipient)">
                                                {{recipient}}
                                            </el-tag>

                                            <el-input
                                                class="input-new-tag"
                                                v-if="inputVisible"
                                                v-model="recipientValue"
                                                ref="saveTagInput"
                                                @keyup.enter.native="handleRecipientConfirm"
                                                @blur="handleRecipientConfirm"
                                                autocomplete="email"
                                                name="email"
                                            >
                                            </el-input>
                                            <el-link v-else class="button-new-tag"
                                                     @click="showRecipientInput"
                                                     :underline="false"
                                                     icon="el-icon-circle-plus-outline"></el-link>
                                        </div>
                                    </el-col>
                                </el-row>
                                <span v-if="invalidInputs && invalidInputs.recipients" class="el-form-item__error">{{ invalidInputs.recipients[0] }}</span>
                                <span v-if="invalidInputs.emails && invalidInputs.emails.length > 0" class="el-form-item__error">Invalid emails: {{ invalidInputs.emails.toString() }}</span>
                            </el-form-item>

                            <el-form-item
                                label="CC(s)"
                                prop="cc"
                            >
                                <el-row>
                                    <el-col :span="20">
                                        <div style="max-height: 100px;overflow: auto;">
                                            <el-tag
                                                :key="cc"
                                                v-for="cc in mail.cc"
                                                closable
                                                size="mini"
                                                :disable-transitions="false"
                                                type="info"
                                                effect="plain"
                                                @close="handleRemoveRecipient(cc, 'cc')">
                                                {{cc}}
                                            </el-tag>
                                            <el-input
                                                class="input-new-tag"
                                                v-if="ccVisible"
                                                v-model="ccValue"
                                                ref="saveTagCCInput"
                                                @keyup.enter.native="handleRecipientConfirm('cc')"
                                                @blur="handleRecipientConfirm('cc')"
                                                autocomplete="email"
                                                name="cc"
                                            >
                                            </el-input>
                                            <el-link v-else class="button-new-tag"
                                                     @click="showRecipientInput('cc')"
                                                     :underline="false"
                                                     icon="el-icon-circle-plus-outline"></el-link>
                                        </div>
                                    </el-col>
                                </el-row>
                                <span v-if="invalidInputs.cc && invalidInputs.cc.length > 0" class="el-form-item__error">Invalid emails: {{ invalidInputs.cc.toString() }}</span>
                            </el-form-item>

                            <el-form-item
                                label="BCC(s)"
                                prop="bcc"
                            >
                                <el-row>
                                    <el-col :span="20">
                                        <div style="max-height: 100px;overflow: auto;">
                                            <el-tag
                                                :key="bcc"
                                                v-for="bcc in mail.bcc"
                                                closable
                                                size="mini"
                                                :disable-transitions="false"
                                                type="info"
                                                effect="plain"
                                                @close="handleRemoveRecipient(bcc, 'bcc')">
                                                {{bcc}}
                                            </el-tag>
                                            <el-input
                                                class="input-new-tag"
                                                v-if="bccVisible"
                                                v-model="bccValue"
                                                ref="saveTagBCCInput"
                                                @keyup.enter.native="handleRecipientConfirm('bcc')"
                                                @blur="handleRecipientConfirm('bcc')"
                                                autocomplete="email"
                                                name="bcc"
                                            >
                                            </el-input>
                                            <el-link v-else class="button-new-tag"
                                                     @click="showRecipientInput('bcc')"
                                                     :underline="false"
                                                     icon="el-icon-circle-plus-outline"></el-link>
                                        </div>
                                    </el-col>
                                </el-row>
                                <span v-if="invalidInputs.bcc && invalidInputs.bcc.length > 0" class="el-form-item__error">Invalid emails: {{ invalidInputs.bcc.toString() }}</span>
                            </el-form-item>

                            
                            <el-form-item label="Subject" prop="subject" style="display: block">
                                <el-input style="width: 80%" v-model="mail.subject" @focus="activeInput = 'subject'"></el-input>
                                <span v-if="invalidInputs.subject" class="el-form-item__error">{{ invalidInputs.subject[0] }}</span>
                            </el-form-item>
                        <el-form-item label="Message" prop="message">
                            <!--<composer-editor
                                v-model="mail.message"
                                :value="mail.message"
                                :replyContent="messageDOM"
                                :message="currentMessage"
                                :key="editorKey"
                                @update="handleEditorUpdate"
                                style="margin-top: 2rem; width: 100%"
                                v-loading="uploadingAttachment"
                                :element-loading-text="editorLoadTxt"
                                element-loading-spinner="el-icon-loading"
                                element-loading-background="rgba(0, 0, 0, 0.8)"
                                ref="reply-msg"
                            ></composer-editor>-->

                            <composer-editor
                                ref="composerEditor"
                                :value="mail.message"
                                :message="currentMessage"
                                :key="editorKey"
                                :replyContent="messageDOM"
                                @update="handleEditorUpdate"
                                element-loading-spinner="el-icon-loading"
                                element-loading-background="rgba(0, 0, 0, 0.8)"
                                @focus="activeInput = 'message'"
                                hide-actions
                            ></composer-editor>
                        </el-form-item>
                        </el-form>
                    </el-col>
                    
                    <el-col :span="24" style="text-align: right">
                        <el-button
                            type="danger"
                            @click="handleResetForm"
                            size="small"
                        >
                            Reset <i class="el-icon-refresh-right"></i>
                        </el-button>
                        <el-button type="primary" size="small" @click="submitForm" :loading="isSending" id="send-btn">Send <i class="el-icon-position"></i></el-button>
                    </el-col>
                </el-row>
            </el-container>
        </div>
    </div>
</template>
<style>

</style>
<script>
import store from "../../../store";
import ComposerEditor from "../composer/ComposerEditor";

export default {
    components: {
        ComposerEditor
    },
    props: ['message'],
    data() {
        return {
            currentMessage: {},
            showComposer: false,
            messageDOM: null,
            isSending: false,
            sendingEmail: false,
            editorKey: 0,
            containerKey: 0,
            inputVisible: false,
            ccVisible: false,
            bccVisible: false,
            recipientValue: '',
            ccValue: '',
            bccValue: '',
            invalidInputs: {
                emails: []
            },
            activeInput: null,
            uploadingAttachment: false,
            editorLoadTxt: 'Attaching image...',
            mail: {
                recipients: [],
                cc: [],
                bcc: [],
                subject: '',
                message: '',
            },
            rules: {
                recipients: [
                    { type: 'array', required: true, message: 'The recipients is required.', trigger: 'blur' }
                ],
                subject: [
                    { required: true, message: 'The subject field is required.', trigger: 'blur' }
                ],
                message: [
                    { required: true, message: 'The message field is required.', trigger: 'blur' }
                ],
            },
        };
    },
    computed: {},
    mounted() {
        this.company = JSON.parse(store.state.currentCompany)
        this.user = JSON.parse(store.state.currentUser)
        this.currentMessage = Object.assign({}, this.message)

        this.getAwsEmailMessage(this.currentMessage.id)
        this.markAs('read')
    },
    methods: {
        getAwsEmailMessage(incomingMessageId) {
            this.axios
            .get('/api/company/' + this.company.aloware_id + '/user/' + this.user.id + '/aws-email/' + incomingMessageId)
            .then(response => {
                if (response.data.success == true) {
                    this.currentMessage.message = response.data.data.message_html
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response)
            });
        },
        markAs(state) {
            let isREad = true
            if(state == 'unread') {
                isREad = false
            }
            this.axios
            .put('/api/company/' + this.company.aloware_id + '/user/' + this.user.id + '/email/' + this.currentMessage.id, {
                is_read: isREad
            })
            .then(response => {
                if (response.data.success == true) {
                    this.currentMessage = response.data.data
                    if(state == 'unread') {
                        this.$emit('messageIsUnRead', response.data.data.id)
                    } else {
                        this.$emit('messageIsRead', response.data.data.id)
                    }
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response)
            });
        },
        handleReply() {
            if(this.$refs["msg-dom"]){
                this.messageDOM = this.$refs["msg-dom"]
                this.mail.recipients.push(this.currentMessage.from_email)
                this.mail.subject = `Re: ${this.currentMessage.subject}`
                this.showComposer = !this.showComposer
            }
        },
        submitForm(e) {
            e.preventDefault()
            this.$refs.formCompose.validate(valid => {
                if(valid) {
                    return this.smtpSend()
                }
                this.invalidInputs.emails = []
            })
        },
        async smtpSend() {
            this.sendingEmail = true
            let formData = new FormData
            // this.attachedFileList.map(file => {
            //     formData.append(`files[]`, file.file, file.name)
            // })

            for (const [key, value] of Object.entries(this.mail)) {
                if(key === 'recipients') {
                    value.forEach(val => {
                        formData.append('recipients[]', val)
                    })
                }
                else if(key === 'cc') {
                    value.forEach(val => {
                        formData.append('cc[]', val)
                    })
                }
                else if(key === 'bcc') {
                    value.forEach(val => {
                        formData.append('bcc[]', val)
                    })
                } else if(key === 'message') {
                    let composed = this.$refs.composerEditor
                    composed.$el.removeChild(composed.$el.parentNode.querySelector('.composer-btns'))
                    const compEditor = composed.$el.parentNode.querySelector('.composer-editor')

                    compEditor.querySelectorAll('grammarly-extension').forEach(gram => {
                        compEditor.removeChild(gram)
                    })

                    formData.append('message', composed.$el.innerHTML)

                    //formData.append(key, this.$refs.composerEditor.$el.innerHTML)
                } else {
                    formData.append(key, value)
                }
            }

            await this.axios.post(
                `api/company/${this.company.id}/webmail/send-smtp-email/${this.user.id}`,
                formData
            )
                .then(() => {
                    this.sendingEmail = false
                    this.handleResetForm()
                    this.mail = {
                        recipients: [],
                        cc: [],
                        bcc: [],
                        subject: '',
                        message: '',
                    }
                    this.invalidInputs = {}
                    this.attachedFileList = []
                    this.containerKey++
                    this.editorKey++
                    return this.$notify({
                        title: 'Success',
                        message: 'Email has been queued.',
                        type: 'success'
                    })
                })
                .catch((err) => {
                    this.sendingEmail = false
                    this.$notify({
                        title: 'Error',
                        message: 'Something went wrong while sending email.',
                        type: 'error'
                    })

                    this.invalidInputs = err.response.data.errors
                    const invalidRecipients = Object.keys(this.invalidInputs)

                    this.invalidInputs = {
                        ...this.invalidInputs,
                        emails: [],
                        cc: [],
                        bcc: [],
                    }

                    invalidRecipients.find(str => {
                        if(str.includes('recipients.')) {
                            this.invalidInputs.emails.push(this.invalidInputs[str][0])
                        }
                        if(str.includes('bcc.')) {
                            this.invalidInputs.bcc.push(this.invalidInputs[str][0])
                        }
                        if(str.includes('cc.') && !str.includes('bcc.')) {
                            this.invalidInputs.cc.push(this.invalidInputs[str][0])
                        }
                    })
                })
        },
        handleResetForm() {
            this.showComposer = false
            this.containerKey++
        },
        handleRemoveRecipient(recipient, type = 'recipients') {
            this.mail[type].splice(this.mail[type].indexOf(recipient), 1)
        },
        handleRecipientConfirm(type = '') {
            if(type === 'cc') {
                let cc = this.ccValue
                if (cc) {
                    this.mail.cc.push(cc)
                }
                this.ccVisible = false
                this.ccValue = ''
                return
            }

            if(type === 'bcc') {
                let cc = this.bccValue
                if (cc) {
                    this.mail.bcc.push(cc)
                }
                this.bccVisible = false
                this.bccValue = ''
                return
            }

            let recipientValue = this.recipientValue
            if (recipientValue) {
                this.mail.recipients.push(recipientValue)
            }
            this.inputVisible = false;
            this.recipientValue = '';
        },
        showRecipientInput(type = '') {
            if(type === 'cc') {
                this.ccVisible = true;
                this.$nextTick(_ => {
                    this.$refs.saveTagCCInput.$refs.input.focus()
                });
                return
            }

            if(type === 'bcc') {
                this.bccVisible = true;
                this.$nextTick(_ => {
                    this.$refs.saveTagBCCInput.$refs.input.focus()
                });
                return
            }

            this.inputVisible = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInput.$refs.input.focus()
            });
        },
        handleEditorUpdate(value) {
            this.mail.message = value
        },
    },
    beforeDestroy() {
        this.messageDOM = {}
    }
};
</script>
