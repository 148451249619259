<template>
    <div style="/*width: 99%;padding-left: 5px;*/" class="digital-lead-campaign-container">
        <div>
            <el-row>
                <el-col :span="24">
                    <DigitalLeadCampaign />
                </el-col>
            </el-row>
            <PageHeader :company="company" label="Digital Lead War" :darkMode="false"/>
        </div>
    </div>
</template>
<style>
    .digital-lead-campaign-container {
        background-image: url('/images/bg-digital-leads.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        color: #000000;
        width: 100%;
        overflow-x: hidden;
        padding-bottom: 20px;
    }
    .digital-lead-campaign-container > div:first-child {
        height: 100%;
    }
    .digital-lead-campaign-container > div:first-child {
        display: flex;
        flex-direction: column;
    }
    .digital-lead-campaign-container > div:first-child > div:last-child {
        align-self: flex-end;
        width: 100%;
        margin-top: auto;
    }
    .digital-lead-campaign-container .el-date-editor .el-range-input {
        background: none;
    }
    .digital-lead-campaign-container .el-input__inner,
    .digital-lead-campaign-container .el-table {
        background-color: rgb(0 0 0 / 67%);
    }
    .digital-lead-campaign-container .el-table th,
    .digital-lead-campaign-container .el-table tr {
        background: none;
    }
</style>
<script>
import store from "../store";
import DigitalLeadCampaign from './dms/DigitalLeadCampaign';
import PageHeader from './widgets/PageHeader';
import RestrictedScreen from './widgets/RestrictedScreen';

export default {
    components: {
        DigitalLeadCampaign,
        PageHeader,
        RestrictedScreen,
    },
    data() {
        return {
            S3_URL: process.env.MIX_AWS_S3_URI,
            logoUrl: '',
            errors: {},
            isProgress: false,
            activeTab: 'sold',
            company: {}
        };
    },
    beforeMount() {

    },
    mounted() {
        this.company = JSON.parse(store.state.currentCompany);
        
        this.getLatestCompanyData();
    },
    methods: {
        getLatestCompanyData() {
            this.axios
            .get('/api/company/' + this.company.aloware_id)
            .then(response => {
                if (response.data.success == true) {
                    this.company = response.data.data;
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        }
    },
    watch: {
    }
};
</script>
