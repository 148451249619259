<template>
    <el-container
        class="mail-compose"
        v-loading="sendingEmail"
        element-loading-text="Queueing Email..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :key="containerKey"
    >
        <el-row :gutter="10" style="width: 100%">
            <el-col :span="24">
                <el-form
                    ref="formCompose"
                    :model="mail"
                    :rules="rules"
                    label-width="120px"
                    @submit.prevent="submitForm"
                >
                    <el-form-item
                        label="Recipient(s)"
                        prop="recipients"
                    >
                        <el-row>
                            <el-col :span="20">
                                <div style="max-height: 100px;overflow: auto;">
                                    <el-tag
                                        :key="recipient"
                                        v-for="recipient in mail.recipients"
                                        closable
                                        size="mini"
                                        :disable-transitions="false"
                                        type="info"
                                        effect="plain"
                                        @close="handleRemoveRecipient(recipient)">
                                        {{recipient}}
                                    </el-tag>

                                    <el-input
                                        class="input-new-tag"
                                        v-if="inputVisible"
                                        v-model="recipientValue"
                                        ref="saveTagInput"
                                        @keyup.enter.native="handleRecipientConfirm"
                                        @blur="handleRecipientConfirm"
                                        autocomplete="email"
                                        name="email"
                                    >
                                    </el-input>
                                    <el-link v-else class="button-new-tag"
                                        @click="showRecipientInput"
                                        :underline="false"
                                        icon="el-icon-circle-plus-outline"></el-link>
                                </div>
                            </el-col>
                            <el-col :span="4" style="text-align: right">
                                <el-button icon="el-icon-user" @click="handleSelectFromContacts"></el-button>
                            </el-col>
                        </el-row>
                        <span v-if="invalidInputs && invalidInputs.recipients" class="el-form-item__error">{{ invalidInputs.recipients[0] }}</span>
                        <span v-if="invalidInputs.emails && invalidInputs.emails.length > 0" class="el-form-item__error">Invalid emails: {{ invalidInputs.emails.toString() }}</span>
                    </el-form-item>

                    <el-form-item
                        label="CC(s)"
                        prop="cc"
                    >
                        <el-row>
                            <el-col :span="20">
                                <div style="max-height: 100px;overflow: auto;">
                                    <el-tag
                                        :key="cc"
                                        v-for="cc in mail.cc"
                                        closable
                                        size="mini"
                                        :disable-transitions="false"
                                        type="info"
                                        effect="plain"
                                        @close="handleRemoveRecipient(cc, 'cc')">
                                        {{cc}}
                                    </el-tag>
                                    <el-input
                                        class="input-new-tag"
                                        v-if="ccVisible"
                                        v-model="ccValue"
                                        ref="saveTagCCInput"
                                        @keyup.enter.native="handleRecipientConfirm('cc')"
                                        @blur="handleRecipientConfirm('cc')"
                                        autocomplete="email"
                                        name="cc"
                                    >
                                    </el-input>
                                    <el-link v-else class="button-new-tag"
                                        @click="showRecipientInput('cc')"
                                        :underline="false"
                                        icon="el-icon-circle-plus-outline"></el-link>
                                </div>
                            </el-col>
                        </el-row>
                        <span v-if="invalidInputs.cc && invalidInputs.cc.length > 0" class="el-form-item__error">Invalid emails: {{ invalidInputs.cc.toString() }}</span>
                    </el-form-item>

                    <el-form-item
                        label="BCC(s)"
                        prop="bcc"
                    >
                        <el-row>
                            <el-col :span="20">
                                <div style="max-height: 100px;overflow: auto;">
                                    <el-tag
                                        :key="bcc"
                                        v-for="bcc in mail.bcc"
                                        closable
                                        size="mini"
                                        :disable-transitions="false"
                                        type="info"
                                        effect="plain"
                                        @close="handleRemoveRecipient(bcc, 'bcc')">
                                        {{bcc}}
                                    </el-tag>
                                    <el-input
                                        class="input-new-tag"
                                        v-if="bccVisible"
                                        v-model="bccValue"
                                        ref="saveTagBCCInput"
                                        @keyup.enter.native="handleRecipientConfirm('bcc')"
                                        @blur="handleRecipientConfirm('bcc')"
                                        autocomplete="email"
                                        name="bcc"
                                    >
                                    </el-input>
                                    <el-link v-else class="button-new-tag"
                                        @click="showRecipientInput('bcc')"
                                        :underline="false"
                                        icon="el-icon-circle-plus-outline"></el-link>
                                </div>
                            </el-col>
                        </el-row>
                        <span v-if="invalidInputs.bcc && invalidInputs.bcc.length > 0" class="el-form-item__error">Invalid emails: {{ invalidInputs.bcc.toString() }}</span>
                    </el-form-item>

                    <el-form-item label="Subject" prop="subject" style="display: block">
                        <el-input v-model="mail.subject" @focus="activeInput = 'subject'"></el-input>
                        <span v-if="invalidInputs.subject" class="el-form-item__error">{{ invalidInputs.subject[0] }}</span>
                    </el-form-item>
                    <el-form-item label="Message" prop="message">
                        <composer-editor
                            ref="composerEditor"
                            :value="mail.message"
                            :key="editorKey"
                            @update="handleEditorUpdate"
                            v-on:onSelectTemplate="handleTemplateSelect"
                            element-loading-spinner="el-icon-loading"
                            element-loading-background="rgba(0, 0, 0, 0.8)"
                            @focus="activeInput = 'message'"
                        ></composer-editor>
                        <span v-if="invalidInputs.message" class="el-form-item__error">{{ invalidInputs.message[0] }}</span>
                    </el-form-item>
                    <el-form-item>
                        <el-col :span="24" style="text-align: right">
                            <el-button
                                type="danger"
                                @click="handleResetForm"
                                size="small"
                            >
                                Reset <i class="el-icon-refresh-right"></i>
                            </el-button>
                            <el-button type="primary" size="small" @click="submitForm" :loading="isSending">Send <i class="el-icon-position"></i></el-button>
                        </el-col>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <SearchContactsDialog
            :visible.sync="contactSearchDialogVisible"
            v-on:onSelectContacts="handleOnContactsSelect" />
    </el-container>
</template>
<style>

</style>
<script>
import store from "../../store";
import { VueEditor, Quill } from "vue2-editor";
import SearchContactsDialog from "./dialogs/SearchContactsDialog";
import ComposerEditor from "./composer/ComposerEditor";

export default {
    components: {
        VueEditor,
        SearchContactsDialog,
        ComposerEditor,
    },
    props: ['recipient'],
    data() {
        return {
            company: {},
            user: {},
            mail: {
                recipients: [],
                cc: [],
                bcc: [],
                subject: '',
                message: '',
            },
            inputVisible: false,
            ccVisible: false,
            bccVisible: false,
            isSending: false,
            contactSearchDialogVisible: false,
            recipientValue: '',
            ccValue: '',
            bccValue: '',
            activeInput: null,
            rules: {
                recipients: [
                    { type: 'array', required: true, message: 'The recipients is required.', trigger: 'blur' }
                ],
                subject: [
                    { required: true, message: 'The subject field is required.', trigger: 'blur' }
                ],
                message: [
                    { required: true, message: 'The message field is required.', trigger: 'blur' }
                ],
            },
            invalidInputs: {},
            sendingEmail: false,
            containerKey: 0,
            editorKey: 0,
        };
    },
    computed: {},
    mounted() {
        this.company = JSON.parse(store.state.currentCompany)
        this.user = JSON.parse(store.state.currentUser)
    },
    methods: {
        handleSubmit() {
            //console.log(this.mail)
            this.isSending = true
            this.axios
            .post('/api/company/' + this.company.aloware_id + '/user/' + this.user.id + '/email/send', this.mail)
            .then(response => {
                if (response.data.success == true) {
                    this.$notify({
                            title: "Success",
                            message: "Message successfully sent!",
                            type: "success"
                        })
                    this.handleResetForm()
                }
                this.isSending = false
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
                this.isSending = false
            });
        },
        async smtpSend() {
            this.sendingEmail = true
            let formData = new FormData

            /*if( this.attachedFileList.length > 0 ) {
                this.attachedFileList.map(file => {
                    formData.append(`files[]`, file.file, file.name)
                })
            }*/

            for (const [key, value] of Object.entries(this.mail)) {
                if(key === 'recipients') {
                    value.forEach(val => {
                        formData.append('recipients[]', val)
                    })
                }
                else if(key === 'cc') {
                    value.forEach(val => {
                        formData.append('cc[]', val)
                    })
                }
                else if(key === 'bcc') {
                    value.forEach(val => {
                        formData.append('bcc[]', val)
                    })
                } else {
                    formData.append(key, value)
                }
            }

            await this.axios.post(
                `api/company/${this.company.id}/webmail/send-smtp-email/${this.user.id}`,
                formData
            )
                .then(() => {
                    this.sendingEmail = false
                    this.mail = {
                        recipients: [],
                        cc: [],
                        bcc: [],
                        subject: '',
                        message: '',
                    }
                    this.invalidInputs = {}
                    this.attachedFileList = []
                    this.containerKey++
                    this.editorKey++
                    return this.$notify({
                        title: 'Success',
                        message: 'Email has been queued.',
                        type: 'success'
                    })
                })
                .catch((err) => {
                    this.sendingEmail = false
                    this.$notify({
                        title: 'Error',
                        message: 'Something went wrong while sending email.',
                        type: 'error'
                    })

                    this.invalidInputs = err.response.data.errors
                    const invalidRecipients = Object.keys(this.invalidInputs)

                    this.invalidInputs = {
                        ...this.invalidInputs,
                        emails: [],
                        cc: [],
                        bcc: [],
                    }

                    invalidRecipients.find(str => {
                        if(str.includes('recipients.')) {
                            this.invalidInputs.emails.push(this.invalidInputs[str][0])
                        }
                        if(str.includes('bcc.')) {
                            this.invalidInputs.bcc.push(this.invalidInputs[str][0])
                        }
                        if(str.includes('cc.') && !str.includes('bcc.')) {
                            this.invalidInputs.cc.push(this.invalidInputs[str][0])
                        }
                    })
                })
        },
        handleSelectFromContacts() {
            this.contactSearchDialogVisible = true
        },
        handleOnContactsSelect(contactsArr) {
            contactsArr.forEach((e) => {
                if( typeof e.email == 'undefined' ) {
                    this.mail.recipients.push(e)
                } else {
                    this.mail.recipients.push(e.email)
                }
            })
        },
        handleRemoveRecipient(recipient, type = 'recipients') {
            this.mail[type].splice(this.mail[type].indexOf(recipient), 1)
        },
        handleRecipientConfirm(type = '') {
            if(type === 'cc') {
                let cc = this.ccValue
                if (cc) {
                    this.mail.cc.push(cc)
                }
                this.ccVisible = false
                this.ccValue = ''
                return
            }

            if(type === 'bcc') {
                let cc = this.bccValue
                if (cc) {
                    this.mail.bcc.push(cc)
                }
                this.bccVisible = false
                this.bccValue = ''
                return
            }

            let recipientValue = this.recipientValue
            if (recipientValue) {
                this.mail.recipients.push(recipientValue)
            }
            this.inputVisible = false;
            this.recipientValue = '';
        },
        showRecipientInput(type = '') {
            if(type === 'cc') {
                this.ccVisible = true;
                this.$nextTick(_ => {
                    this.$refs.saveTagCCInput.$refs.input.focus()
                });
                return
            }

            if(type === 'bcc') {
                this.bccVisible = true;
                this.$nextTick(_ => {
                    this.$refs.saveTagBCCInput.$refs.input.focus()
                });
                return
            }

            this.inputVisible = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInput.$refs.input.focus()
            });
        },
        handleResetForm() {
            this.mail.recipients = []
            this.mail.subject = ''
            this.mail.message = ''
            this.attachedFileList = []
        },
        submitForm(e) {
            e.preventDefault()
            this.$refs.formCompose.validate(valid => {
                if(valid) {
                    return this.smtpSend()
                }
                this.invalidInputs.emails = []
            })
        },
        handleEditorUpdate(value) {
            this.mail.message = value
            this.activeInput = 'message'
        },
        handleTemplateSelect(template) {
            this.mail.subject = template.subject
        },
    },
    watch: {
        recipient: function(val, oldVal) {
            //this.recipient = this.val
            this.mail.recipients.push(val)
        },
    }
};
</script>
