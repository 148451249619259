<template>
    <el-row class="create-email">
        <el-col :span="24" >
            <span class="create-email-form">
                Email address is not yet setup. Enter email:
                <el-form>
                    <div style="margin-top: 15px;">
                        <el-input placeholder="mymail" v-model="email">
                            <template slot="append">.{{companyDomain}}</template>
                        </el-input>
                    </div>
                    <div style="margin-top: 15px;">
                        <el-button type="primary" @click="handleSubmit" :disabled="loading">Submit</el-button>
                    </div>
                </el-form>
            </span>
        </el-col>
    </el-row>
</template>
<style>
    .create-email-form {
        text-align: center;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 30px;
        border-radius: 10px;
        border: 1px solid #ffffff;
    }
</style>
<script>
import store from "../../store";

export default {
    components: {
    },
    data() {
        return {
            user: {},
            company: {},
            companyDomain: {},
            email: '',
            loading: false
        };
    },
    computed: {},
    mounted() {
        this.company = JSON.parse(store.state.currentCompany)
        this.user = JSON.parse(store.state.currentUser)

        if(this.company.email_setup !== null) {
            this.companyDomain = this.company.email_setup.domain
        }
        this.email = this.user.name.replace(/[^a-z0-9_]+/gi, '').replace(/^-|-$/g, '').toLowerCase()
    },
    methods: {
        handleSubmit() {
            this.loading = true

            this.axios.post(`api/company/${this.company.aloware_id}/user/${this.user.id}/email/setup`, {
                email: this.email
            })
            .then(response => {
                if (response.data.success == true) {
                    this.$notify({
                        title: "Success",
                        message: response.data.message,
                        type: "success"
                    });
                    this.loading = false

                    store.commit(
                            "UpdateCurrentUser",
                            response.data.user
                        );

                    window.location.reload()
                } else {
                    this.$notify({
                        title: "Error",
                        message: response.data.message,
                        type: "error"
                    });
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        }
    }
};
</script>
