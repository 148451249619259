<template>
    <el-main>
        <el-row>
            <el-col :span="24">
                <company-filter
                    style="margin-bottom: 1rem;"
                    @change="handleCompanyChange"
                ></company-filter>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <el-tag type="info" effect="plain">
                    Found:
                    <strong>{{ totalRecords }}</strong> settings
                </el-tag>
            </el-col>
            </el-col>
            <el-col :span="12" style="text-align: right">
                <el-button
                        type="primary"
                        @click="handleCreate"
                    >
                        <i class="fa fa-plus"></i> Create
                    </el-button>
            </el-col>
        </el-row>
        <el-table :data="settings" v-if="!leadSourceListLoading && !sequenceListLoading">
            <el-table-column label="Lead Source">
                <template slot-scope="scope">
                    {{ JSON.parse(scope.row.val)['lead_source']['name'] }}
                </template>
            </el-table-column>
            <el-table-column label="Sequence">
                <template slot-scope="scope">
                    {{ JSON.parse(scope.row.val)['sequence']['name'] }}
                </template>
            </el-table-column>
            <el-table-column align="right">
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="success"
                        @click="handleEdit(scope.$index, scope.row)"
                    >
                        Edit
                        <i class="fa fa-edit"></i>
                    </el-button>

                    <el-popconfirm
                        title="Are you sure you want to delete this?"
                        @confirm="handleDelete(scope.row)">
                        <el-button
                            size="mini"
                            type="danger"
                            slot="reference"
                        >
                            Delete
                            <i class="fa fa-trash"></i>
                        </el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <div v-else>Loading...</div>
        <el-pagination
            background
            @current-change="handlePageChange"
            :current-page.sync="currentPage"
            :page-size="10"
            layout="prev, pager, next"
            :total="totalRecords"
        ></el-pagination>

        <el-dialog title="Setting" :visible.sync="settingFormVisible" :close-on-click-modal="false">
            <SettingsFormPerCompany 
                :setting="selectedSetting" 
                :leadsources="leadSourceList" 
                :sequences="sequenceList" 
                :company="company"
                v-on:closeDialog="handleOnCloseSettingForm"/>
        </el-dialog>
    </el-main>
</template>
<style>
.el-pagination {
    text-align: center;
    margin-top: 20px;
}
</style>
<script>
import SettingsFormPerCompany from "./SettingsFormPerCompany.vue"
import store from "../../../store"
import CompanyFilter from "../../admin/components/CompanyFilter"

export default {
    components: {
        SettingsFormPerCompany,
        CompanyFilter
    },
    props: {},
    data() {
        return {
            uploading: false,
            errors: {},
            isProgress: false,
            settings: [],
            search: "",
            totalRecords: 0,
            currentPage: 1,
            isSearching: false,
            settingFormVisible: false,
            selectedSetting: {},
            company: {},
            leadSourceList: [],
            sequenceList: [],
            leadSourceListLoading: false,
            sequenceListLoading: false,
        };
    },
    computed: {
    },
    mounted() {
        this.company = JSON.parse(store.state.currentCompany)
        this.getAlowareLeadSources()
        this.getAlowareSequences()
        this.loadSettings()
    },
    methods: {
        loadSettings() {
            this.axios
                .get("api/settings", {
                    params: {
                        page: this.currentPage,
                        name: 'leadsource_to_sequence',
                        company_id: this.company.id,
                    }
                })
                .then(response => {
                if (response.data.success == true) {
                    this.settings = response.data.data;
                    this.totalRecords = response.data.pagination.total;
                }
                else {
                    this.$notify({
                        title: "Error",
                        message: "There has been an error getting settings list",
                        type: "error"
                    });
                }
            });
        },
        getAlowareSequences() {
            this.sequenceListLoading = true
            this.axios.get(`/api/company/simpid-${this.company.id}/aloware-sequences`).then(response => {
                this.sequenceListLoading = false
                if( response.data.success !== true ) {
                    this.$notify({
                        title: "Error",
                        message: response.data.message,
                        type: "error"
                    });
                } else {
                    this.sequenceList = response.data.data
                }
            })
        },
        getAlowareLeadSources() {
            this.leadSourceListLoading = true
            this.axios.get(`/api/company/simpid-${this.company.id}/aloware-leadsources`).then(response => {
                this.leadSourceListLoading = false
                if( response.data.success !== true ) {
                    this.$notify({
                        title: "Error",
                        message: response.data.message,
                        type: "error"
                    });
                } else {
                    this.leadSourceList = response.data.data
                }
            })
        },
        handlePageChange(page) {
            this.currentPage = page
            this.loadSettings()
        },
        handleSearch(event) {
            console.log(event)
        },
        handleEdit(index, row) {
            row.valArr = {}
            row.valArr['lead_source'] = parseInt(JSON.parse(row.val)['lead_source']['id'])
            row.valArr['sequence'] = parseInt(JSON.parse(row.val)['sequence']['id'])

            this.selectedSetting = row
            this.settingFormVisible = true
        },
        handleDelete(row) {
            this.axios
            .delete(`/api/settings/${row.id}`)
            .then(response => {
                if (response.data.success == true) {
                    this.$notify({
                        title: "Success",
                        message: "Record has been successfully deleted",
                        type: "success"
                    });
                    this.loadSettings()
                } else {
                    let errors = response.data.errors.message;
                    let message = "";
                    for (let error in errors) {
                        message = errors[error] + "\n";
                    }
                    this.$notify({
                        title: "Error",
                        message: message,
                        type: "error"
                    });
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        handleCreate() {
            this.selectedSetting = {
                id: 0,
                name: 'leadsource_to_sequence',
                val: {},
                valArr: {
                    leadsource: '',
                    sequence: '',
                },
            }
            this.settingFormVisible = true
        },
        handleChangeSettingType() {
            this.currentPage = 1
            this.isSearching = true
            this.loadSettings()
        },
        handleOnCloseSettingForm() {
            this.settingFormVisible=false
            this.isSearching = true
            this.loadSettings()
        },
        handleCompanyChange(selectedCompany) {
            this.company = selectedCompany
            this.loadSettings()
            this.getAlowareLeadSources()
            this.getAlowareSequences()

            store.commit(
                "UpdateCurrentCompany",
                this.company
            );
        }
    },
    watch: {
        search: function (val, oldVal) {
            this.currentPage = 1
            this.isSearching = true
            this.loadSettings()
        }
    }
};
</script>