<template>
    <el-dialog title="Select Variable" @close="$emit('update:visible', false)" :visible.sync="isVisible" :close-on-click-modal="false" width="30%">
        <el-select
            v-model="variable"
            filterable
            @change="useVariable"
        >
            <el-option-group
                v-for="(group, i) in variables"
                :key="i"
                :label="formatGroupName(group[0].group)">
                <el-option
                    v-for="item in group"
                    :key="item.value"
                    :label="`${item.label} - ${item.description}`"
                    :value="item.value"
                >
                </el-option>
            </el-option-group>
        </el-select>
    </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: "Variables",
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            variable: '',
            variables: {},
            isVisible: false,
        }
    },
    mounted() {
        this.getVariables()
    },
    methods: {
        formatGroupName(str) {
            if(str) {
                let title = str.replace('_', ' ')
                return title.trim().toLowerCase().replace(
                    /\w\S*/g,
                    (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))
                )
            }
        },
        
        useVariable() {
            this.$emit('onVariableSelect', this.variable)
            this.variable = ''
        },

        getVariables() {
            this.axios.get('/api/variables').then(res => {
                this.variables = res.data.data
            })
        },

    },
    watch: {
        visible: function(val, oldVal) {
            this.isVisible = this.visible;
        },
        /*emailVariables: {
            immediate: true,
            handler: function(n) {
                if(Object.keys(n).length > 0) {
                    this.variables = n
                }
            }
        }*/
    }
}
</script>
