<template>
    <el-dialog
        title="Add Appointment"
        :visible.sync="dialogVisible"
        width="36rem"
        :fullscreen="currentScreenWidth <= 765"
        top="2rem"
        center
        @close="handleDialog"
    >
        <el-row
            :gutter="20"
            justify="space-between"
            style="margin-top: 2rem;"
        >
            <el-col
                :span="8"
            >
                <strong>Date</strong>
                <el-date-picker
                    v-model="appointment.date"
                    format="MM/dd/yyyy"
                    value-format="MM/dd/yyyy"
                    :clearable="false"
                    :picker-options="datePickerOptions"
                    @focus="dateToJSDate"
                    @change="dateChange"
                    :disabled="loading"
                ></el-date-picker>
            </el-col>
            <el-col
                :span="8"
            >
                <strong>Time</strong>
                <el-time-select
                    v-model="appointment.time"
                    :picker-options="timePickerOptions"
                    :clearable="false"
                    :disabled="loading"
                ></el-time-select>
            </el-col>
            <el-col
                :span="8"
            >
                <strong>Duration (minutes)</strong>
                <el-select
                    v-model="appointment.duration"
                    :disabled="loading"
                >
                    <el-option
                        v-for="(item, i) in durations"
                        :key="i"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-col>
        </el-row>
        <el-row
            style="margin-top: 2rem;"
        >
            <el-col
                :span="24"
            >
                <strong>Timezone</strong>
                <el-select
                    v-model="appointment.timezone"
                    placeholder="Timezone"
                    :disabled="loading"
                >
                    <el-option
                        v-for="(item, i) in timezones"
                        :key="i"
                        :label="item.name"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-col>
        </el-row>
        <el-row
            style="margin-top: 2rem;"
        >
            <el-col
                :span="24"
            >
                <strong>Note</strong>
                <el-input
                    v-model="appointment.note"
                    placeholder="Write a note for this event"
                    type="text"
                    :autosize="true"
                    :disabled="loading"
                ></el-input>
            </el-col>
        </el-row>
        <span slot="footer">
            <el-button
                @click="handleDialog"
                :loading="loading"
            >Cancel</el-button>
            <el-button
                type="success"
                @click="handleSubmit"
                :loading="loading"
            >Save Event</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    name: "EngageAppointment",
    data() {
        return {
            dialogVisible: true,
            loading: false,
            company: {},
            contact: {},
            appointment: {
                contact_id: 0,
                date: '',
                time: '06:00',
                duration: 15,
                timezone: '',
                note: '',
                datetime: '',
            },
            timePickerOptions: {
                start: '06:00',
                step: '00:15',
                end: '23:45',
            },
            durations: [
                {
                    label: '15 minutes',
                    value: 15,
                },
                {
                    label: '30 minutes',
                    value: 30,
                },
                {
                    label: '1 hour',
                    value: 60
                },
                {
                    label: '1 hour and 30 minutes',
                    value: 90
                },
                {
                    label: '2 hours',
                    value: 120
                },
            ],
            timezones: [
                {"value":"America/New_York","name":"New York (Eastern)"},
                {"value":"America/Chicago","name":"Chicago (Central Standard Time)"},
                {"value":"America/Denver","name":"Denver (Mountain Daylight Time)"},
                {"value":"America/Phoenix","name":"Phoenix (Mountain Standard Time)"},
                {"value":"America/Los_Angeles","name":"Los Angeles (Pacific Standard Time)"},
                {"value":"Pacific/Honolulu","name":"Honolulu (Hawaii Standard Time)"},
                {"value":"America/Halifax","name":"Atlantic (Atlantic Standard Time)"}
            ],
            datePickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7
                },
            },
        }
    },
    methods: {
        handleDialog() {
            return this.$emit('appointment-close', false)
        },
        handleSubmit() {
            this.loading = true
            this.appointment.datetime = `${this.appointment.date} ${this.appointment.time}`
            return this.$emit('submit-appointment', this.appointment)
        },
        dateToJSDate() {
            this.appointment.date = new Date(this.appointment.date)
        },
        dateChange(date) {
            this.appointment.date = date
        }
    },
    mounted() {
        this.company = JSON.parse(this.$store.state.currentCompany)
        this.contact = this.$store.state.contact
        this.appointment.contact_id = this.contact.id
        this.appointment.date = this.currentDate
        const tz_exists = !!this.timezones.find(tz => tz?.value === this.contact?.timezone)

        if(tz_exists && this.contact && this.contact?.timezone) {
            this.appointment.timezone = this.contact.timezone
        } else {
            this.appointment.timezone = 'America/New_York'
        }
    },
    computed: {
        currentScreenWidth() {
            return this.$store.getters.getScreenWidth
        },
        currentDate() {
            return this.moment(new Date()).format('MM/DD/YYYY')
        }
    },
}
</script>
<style scoped>
    .el-select {
        margin-top: 0;
    }
</style>
