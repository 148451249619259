<template>
    <div class="form-company">
        <el-form>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="Company Name" required>
                        <el-input v-model="company.name"></el-input>
                    </el-form-item>
                    <el-form-item label="Address" required>
                        <el-input v-model="company.address"></el-input>
                    </el-form-item>
                    <el-form-item label="Website">
                        <el-input v-model="company.website"></el-input>
                    </el-form-item>
                    <el-form-item label="Representative Name">
                        <el-input v-model="company.representative_name"></el-input>
                    </el-form-item>
                    <el-form-item label="Representative Phone">
                        <el-input v-model="company.representative_phone"></el-input>
                    </el-form-item>
                    <el-form-item label="Dealership CRM Intake Email">
                        <el-input v-model="company.adf_email"></el-input>
                    </el-form-item>
                    <el-form-item label="Facebook Page ID">
                        <el-input v-model="company.fb_page_id"></el-input>
                    </el-form-item>
                    <el-form-item label="TikTok Advertiser ID">
                        <el-input v-model="company.tiktok_advertiser_id"></el-input>
                    </el-form-item>
                    <label class="el-form-item__label" style="width: 100%;text-align: left">Logo</label>
                    <el-row>
                        <el-col :span="5">
                            <el-image v-if="company.logo" :src="company.logo" fit="cover"></el-image>
                        </el-col>
                        <el-col :span="19" style="padding-left: 20px">
                            <el-upload
                                class="upload-demo"
                                drag
                                :action="companyApiBaseUrl + '/aws_fileupload?token=' + this.$store.state.token"
                                accept="image/*"
                                :show-file-list="false"
                                :on-success="handleSuccess"
                                :before-upload="handleBeforeUpload"
                            >
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">
                                    Drop file here or
                                    <em>click to upload</em>
                                </div>
                            </el-upload>
                        </el-col>
                    </el-row>
                    <label class="el-form-item__label" style="width: 100%;text-align: left">QR Code</label>
                    <el-row>
                        <el-col :span="5">
                            <el-image v-if="company.qr_code" :src="company.qr_code" fit="cover"></el-image>
                            <el-image v-else src="https://simpsocial.s3.us-east-2.amazonaws.com/assets/img/qr-code-placeholder.png" fit="cover"></el-image>
                        </el-col>
                        <el-col :span="19" style="padding-left: 20px">
                            <el-upload
                                class="upload-demo"
                                drag
                                :action="companyApiBaseUrl + '/aws_fileupload?token=' + this.$store.state.token"
                                accept="image/*"
                                :show-file-list="false"
                                :on-success="handleSuccessQrCode"
                                :before-upload="handleBeforeUpload"
                            >
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">
                                    Drop file here or
                                    <em>click to upload</em>
                                </div>
                            </el-upload>
                        </el-col>
                    </el-row>
                    <el-form-item label="Inventory Provider">
                        <el-select v-model="company.company_car_data_source_id"
                            remote
                            filterable
                            clearable
                            :remote-method="loadCompanyCarDataSources"
                            @change="handleCompanyCarDataSourcesChange"
                            placeholder="Select Provider">
                            <el-option
                            v-for="item in company_car_data_sources"
                            :key="item.id"
                            :label="item.name + ' (Type: ' + item.data_type + ')'"
                            :value="item.id">
                                <span style="float: left">{{ item.name }} (Type: {{ item.data_type }})</span>
                                <span style="float: right">
                                    <i class="el-icon-edit" title="Edit Provider" @click="handleUpdateProvider(item)"></i>
                                </span>
                            </el-option>
                        </el-select>

                        <el-button type="secondary" @click="handleAddProvider" icon="el-icon-plus">Add Provider</el-button>
                    </el-form-item>
                    <el-form-item label="CSV Column Value" v-if="currentDataSource.data_type=='3'">
                        <el-input v-model="company.csv_dealership_value"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-row style="margin-top: 15px">
            <el-col :span="12">
                <el-button @click="$emit('closeDialog')">Cancel</el-button>
            </el-col>
            <el-col :span="12" style="text-align: right">
                <el-button type="primary" @click="handleSubmit">Submit</el-button>
            </el-col>
        </el-row>
        <el-dialog title="Inventory Provider Form" :visible.sync="companyCarDatasourceFormVisible" append-to-body>
            <CompanyCarDatasourceForm
                :company_car_data_source="selectedDataSource"
                v-on:onSuccessfulSubmit="handleCompanyCarDatasourceFormSuccess"
                v-on:closeDialog="companyCarDatasourceFormVisible = false"/>
        </el-dialog>
    </div>
</template>

<script>
import CompanyCarDatasourceForm from '../CompanyCarDatasourceForm';

export default {
    props: [],
    components: {
        CompanyCarDatasourceForm,
    },
    data() {
        return {
            company: {
                logo: '',
                qr_code: '',
                name: '',
                address: '',
            },
            currentDataSource: {
                data_type: 1
            },
            company_car_data_sources: [],
            selectedDataSource: {
                id: '',
                name: '',
                email_address: '',
                phone_number: '',
                directory: '',
                dealership_column_header: '',
            },
            companyCarDatasourceFormVisible: false
        };
    },
    computed: {},
    mounted() {
        this.loadCompanyCarDataSources('')
    },
    methods: {
        handleSubmit() {
            this.axios
                .post('/api/company/register', this.company)
                .then(response => {
                    if (response.data.success == true) {
                        this.$notify({
                            title: "Success",
                            message: "Company has been successfully Added",
                            type: "success"
                        });

                        this.$emit('companyCreated');
                    } else {
                        let errors = response.data.errors.message;
                        let message = "";
                        for (let error in errors) {
                            message = errors[error] + "\n";
                        }
                        this.$notify({
                            title: "Error",
                            message: message,
                            type: "error"
                        });
                    }
                })
                .catch(err => {
                    this.$root.handleErrors(err.response);
                });
        },
        handleBeforeUpload() {},
        handleSuccess(res, file) {
            this.company.logo = res.url;
        },
        handleSuccessQrCode(res, file) {
            this.company.qr_code = res.url;
        },
        loadCompanyCarDataSources(query) {
            this.axios
                .get("/api/company_car_data_source", {
                    params: {
                        page: 1,
                        page_size: 9999999999,
                        q: query
                    }
                })
                .then(response => {
                    if (response.data.success == true) {
                        this.company_car_data_sources = response.data.data;
                    }
                });
        },
        handleCompanyCarDataSourcesChange(selectedDataSourceID) {
            this.company_car_data_sources.forEach((e) => {
                if(selectedDataSourceID == e.id) {
                    this.currentDataSource = e;
                }
            });
        },
        handleCompanyCarDatasourceFormSuccess() {

        },
        handleCompanyMetaFormSuccess() {
            this.getLatestCompanyData();
        },
        handleAddProvider() {
            this.selectedDataSource = {
                id: '',
                name: '',
                email_address: '',
                phone_number: '',
                directory: '',
                data_type: 1,
                dealership_column_header: '',
            };

            this.companyCarDatasourceFormVisible = true;
        },
        handleUpdateProvider(dataSource) {
            this.selectedDataSource = dataSource;
            this.companyCarDatasourceFormVisible = true;
        },
    },
    watch: { }
};
</script>

<style>
    .form-company .el-form-item__label {
        width: 100%;
        text-align: left;
    }
</style>
