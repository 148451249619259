<template>
    <div>
        <el-row :gutter="20" style="margin-top: 1rem; margin-bottom: 10px;">
            <el-col :span="20" class="text-left">
                <el-date-picker
                    v-model="filters.daterange"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="-"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    size="small"
                    @change="handleDaterangeChange"
                    :clear="handleDaterangeChange"
                    :picker-options="pickerOptions"
                    format="MM-dd-yyyy"
                    popper-class="datepicker-custom"
                    ref="startEndDate"
                    name="se">
                </el-date-picker>
                <el-select v-model="filters.year"
                    multiple
                    collapse-tags
                    filterable
                    placeholder="Select Year"
                    size="small"
                    clearable
                    @change="handleYearChange"
                    :clear="handleYearChange">
                    <el-option
                        v-for="item in yearOptions"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                </el-select>
                <el-select v-model="filters.make"
                    multiple
                    collapse-tags
                    filterable
                    placeholder="Select Makes"
                    size="small"
                    clearable
                    @change="handleMakeChange"
                    :clear="handleMakeChange">
                    <el-option
                        v-for="item in makeOptions"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                </el-select>
                <el-select v-model="filters.model"
                    multiple
                    collapse-tags
                    filterable
                    placeholder="Select Models"
                    size="small"
                    clearable
                    @change="handleModelChange"
                    :clear="handleModelChange">
                    <el-option
                        v-for="item in modelOptions"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                </el-select>
                <el-button-group>
                    <el-button
                        type="primary"
                        title="Start DMS Campaign Now"
                        @click="handleDMSCampaignNow"
                        style="font-weight: bold;"
                        size="small"
                        :disabled="!Array.isArray(this.filters.daterange)"
                        :loading="btnDMSIsLoading">
                        <span v-if="btnDMSIsLoading">Processing... ({{percentageProcessing}})</span>
                        <span v-else>Text Campaign now</span>
                        </el-button>
                </el-button-group>
                <el-button-group>
                    <el-button
                        type="primary"
                        style="font-weight: bold;"
                        size="small"
                        :disabled="!Array.isArray(this.filters.daterange)"
                        :loading="btnEmailIsLoading"
                        @click="handleEMailCampaignNow"
                    >
                        <span v-if="btnEmailIsLoading">Processing... ({{percentageProcessing}})</span>
                        <span v-else>Email Campaign Now</span>
                    </el-button>
                </el-button-group>
            </el-col>
            <el-col :span="4" style="text-align: right;">
                <el-button-group style="margin-right: 5px;">
                        <el-button
                            type="primary"
                            size="small"
                            title="Export to Excel"
                            @click="handleExport('excel')"
                            :loading="btnExcelIsLoading">Excel</el-button>
<!--                        <el-button-->
<!--                            type="primary"-->
<!--                            size="small"-->
<!--                            title="Export to PDF"-->
<!--                            @click="handleExport('pdf')"-->
<!--                            :loading="btnPDFIsLoading">PDF</el-button>-->
                    </el-button-group>
            </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-bottom: 10px;">
            <el-col :span="20" class="text-left">&nbsp;
            </el-col>
<!--            <el-col :span="4" class="text-right">-->
<!--                <small>Found <strong>{{totalRecords}}</strong> {{parseInt(totalRecords) | pluralize('record', 'records')}}</small>-->
<!--            </el-col>-->
        </el-row>
        <el-table
            :data="reports"
            class="dms-table"
            v-loading="isSearching"
            element-loading-text="Loading..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
        >
            <el-table-column label="Full name" prop="full_name" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.name}}
                </template>
            </el-table-column>
            <el-table-column label="Phone Number" prop="home_phone" width="120px" header-align="center" align="center">
                <template slot-scope="scope">
                    <strong>{{scope.row.phone_number}}</strong>
                </template>
            </el-table-column>
            <el-table-column label="Year" prop="vin" header-align="center" align="center">
                <template slot-scope="scope">
                    {{ formatField(scope.row.attributes, 'year') }}
                </template>
            </el-table-column>
            <el-table-column label="Make" prop="make" width="100px" header-align="center" align="center">
                <template slot-scope="scope">
                    {{ formatField(scope.row.attributes, 'make') }}
                </template>
            </el-table-column>
            <el-table-column label="Model" prop="model" header-align="center" align="center">
                <template slot-scope="scope">
                    {{ formatField(scope.row.attributes, 'model') }}
                </template>
            </el-table-column>
            <el-table-column label="Created At" prop="created_at" header-align="center" align="center">
                <template slot-scope="scope">
                    {{ formatCreatedAt(scope.row)}}
                </template>
            </el-table-column>
            <el-table-column label="APR" prop="apr" v-if="filters.sale_type.toLowerCase()=='financed'" width="60px" header-align="center" align="center">
                <template slot-scope="scope">
                    {{scope.row.apr}}%
                </template>
            </el-table-column>
            <el-table-column label="Estimated Current Payoff" prop="current_payoff" v-if="filters.sale_type.toLowerCase()=='financed'" header-align="center" align="center">
                <template slot-scope="scope">
                    {{scope.row.current_payoff | currency}}
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            background
            @current-change="handlePageChange"
            :current-page.sync="currentPage"
            :page-size="10"
            layout="prev, pager, next"
            :total="totalRecords"
        ></el-pagination>
    </div>
</template>
<style>
.el-table.dms-table {
    font-size: 10.5px;
}
.el-table.dms-table thead {
    color: #ffffff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 11.5px;
}
.el-table.dms-table th>.cell {
    padding: 0 5px;
}
.dms-campaign-container .el-checkbox {
    margin-right: 0;
}
.dms-campaign-container .el-checkbox.is-bordered.is-checked {
    border-color: #FFFFFF;
}
.dms-campaign-container .el-slider__runway {
    margin: 6px 0;
}
.dms-campaign-container .el-slider__button {
    width: 10px;
    height: 10px;
}
.dms-table th {
    font-family: monospace;
}
</style>
<script>
import moment from 'moment';
import store from "../../store";

export default {
    props: [],
    data() {
        return {
            errors: {},
            isProgress: false,
            reports: [],
            filters: {
                start_date: '',
                end_date: '',
                daterange: '',
                q: '',
                year: '',
                make: '',
                model: '',
                sale_type: 'All Sale Types',
                tag_id: '',
                term_range: [0, 300],
                apr_range: [0, 30],
                export_type: 'json'
            },
            max_term: 300,
            min_apr: 0,
            max_apr: 50,
            totalRecords: 0,
            currentPage: 1,
            perPage: 10,
            isSearching: false,
            btnDMSIsLoading: false,
            btnExcelIsLoading: false,
            btnPDFIsLoading: false,
            saletypeleased: true,
            makeOptions: [],
            modelOptions: [],
            yearOptions: [],
            pickerOptions: {
                shortcuts: [{
                    text: 'This month',
                    onClick(picker) {
                        const date = new Date();
                        const start = new Date(date.getFullYear(), date.getMonth(), 1);
                        const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last 3 mos',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '18-28 mos',
                    onClick(picker) {
                        const start = moment().subtract(28, 'months');
                        const end = moment().subtract(18, 'months');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '29-39 mos',
                    onClick(picker) {
                        const now = new Date();
                        const start = moment().subtract(36, 'months');
                        const end = moment().subtract(29, 'months');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: this.getDateByFormat('year', 1),
                    onClick(picker) {
                        const now = new Date();
                        const start = new Date(now.getFullYear() - 1, 0, 1);
                        const end = new Date(now.getFullYear() - 1, 11, 31);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: this.getDateByFormat('year', 2),
                    onClick(picker) {
                        const now = new Date();
                        const start = new Date(now.getFullYear() - 2, 0, 1);
                        const end = new Date(now.getFullYear() - 2, 11, 31);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: this.getDateByFormat('year', 3),
                    onClick(picker) {
                        const now = new Date();
                        const start = new Date(now.getFullYear() - 3, 0, 1);
                        const end = new Date(now.getFullYear() - 3, 11, 31);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: this.getDateByFormat('year', 4),
                    onClick(picker) {
                        const now = new Date();
                        const start = new Date(now.getFullYear() - 4, 0, 1);
                        const end = new Date(now.getFullYear() - 4, 11, 31);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            percentageProcessing: '0%',
            attributes: {},
            toDMSCampaign: false,
            btnEmailIsLoading: false,
            tag: {},
        };
    },
    mounted() {
        this.company = JSON.parse(store.state.currentCompany);

        this.init();

        //listen to manually enterred dates
        window.document.getElementsByName('s')[0].addEventListener('keyup', (event) => {
            let fromDate = moment(event.target.value, 'MM-DD-YYYY');
            if(fromDate.isValid()) {
                this.handleDaterangeChange();
            }
        });
        window.document.getElementsByName('e')[0].addEventListener('keyup', (event) => {
            let toDate = moment(event.target.value, 'MM-DD-YYYY');
            if(toDate.isValid()) {
                this.handleDaterangeChange();

            }
        });
    },
    computed: {
        tagName() {
            const startDate = `${Array.isArray(this.filters.daterange) ? moment(this.filters.daterange[0]).format('MM-DD-YYYY') : 'no-start-date'}`
            const endDate = `${Array.isArray(this.filters.daterange) ? moment(this.filters.daterange[1]).format('MM-DD-YYYY') : 'no-end-date'}`

            let tagName = `Digital Lead Wars - ${startDate} to `
            tagName += `${endDate} | `
            tagName += `${this.filters.year.toString() || 'all-years'} | `
            tagName += `${this.filters.make.toString() || 'all-makes'} | `
            tagName += `${this.filters.model.toString() || 'all-models'}`

            return tagName
        }
    },
    methods: {
        init() {
            this.setDefaultDaterange();
            this.loadReport();
            this.getMakes();
            this.getYears();
            this.getModels();
            this.getMaxTerm();
            this.getMinAndMaxApr();
            this.getDealerAttributes()
        },
        listAllProperties(o) {
            var objectToInspect;
            var result = [];

            for(objectToInspect = o; objectToInspect !== null;
                objectToInspect = Object.getPrototypeOf(objectToInspect)) {
                result = result.concat(
                    Object.getOwnPropertyNames(objectToInspect)
                );
            }

            return result;
        },
        async loadReport(exportType = 'json') {
            if(!this.toDMSCampaign) {
                this.reports = []
            }

            this.isSearching = true
            let responseType = 'json';
            if (exportType == 'alowareimport') {
                this.btnDMSIsLoading = true;
            } else if (exportType == 'excel') {
                this.btnExcelIsLoading = true;
                // responseType = 'blob';
            } else if(exportType == 'pdf') {
                this.btnPDFIsLoading = true;
                responseType = 'blob';
            }

            let params = {
                page: this.currentPage,
                page_size: this.perPage,
                q: this.filters.q,
                export_type: exportType,
                year: this.filters.year,
                model: this.filters.model,
                make: this.filters.make,
                tag_id: this.filters.tag_id,
                start_date: this.filters.daterange !== null ? this.$options.filters.toSqlDate(this.filters.daterange[0]) : '',
                end_date: this.filters.daterange !== null ? this.$options.filters.toSqlDate(this.filters.daterange[1]) : '',
            }

            if(this.filters.sale_type.toLowerCase() == 'leased') {
                params.sale_type = 'L';
            } else if(this.filters.sale_type.toLowerCase() == 'financed') {
                params.sale_type = 'R';
            }

            if(params.sale_type == 'L' || params.sale_type == 'R') {
                params.term_min = this.filters.term_range[0];
                params.term_max = this.filters.term_range[1];
            }
            if(params.sale_type == 'R') {
                params.apr_min = this.filters.apr_range[0];
                params.apr_max = this.filters.apr_range[1];
            }

            const allExport = [
                'alowareimport',
                'excel',
                'pdf',
                'bulkEmailImport',
            ]

            params = {
                ...params,
                attribute_query: this.processQueryStr(allExport.includes(exportType))
            }

            await this.axios({
                    url: `/api/company/${this.company.id}/aloware-by-attr-contact`,
                    method: 'GET',
                    params: params,
                    responseType: responseType
                })
                .then(response => {
                    if(exportType == 'pdf' || exportType == 'excel' && (response?.data?.success === true)) {
                        this.btnPDFIsLoading = false;
                        this.btnExcelIsLoading = false;

                        const data = [
                            {
                                sheet: '',
                                columns: [
                                    { label: "Full Name", value: "name" },
                                    { label: "Phone Number", value: "phone_number" },
                                    { label: "Year", value: (row) => (this.formatField(row.attributes, 'year')), format: 'mmm dd, yyyy'},
                                    { label: "Make", value: (row) => (this.formatField(row.attributes, 'make'))},
                                    { label: "Model", value: (row) => (this.formatField(row.attributes, 'model'))},
                                    { label: "Created At", value: (row) => (this.formatCreatedAt(row))},
                                ],
                                content: response.data.data
                            }
                        ]

                        this.$jsonXlsx(data, { fileName: this.tagName})
                        this.reports = response.data.data.slice(0, 20)
                    } else if (exportType === 'bulkEmailImport') {
                        this.reports = response.data.data.slice(0, 20)
                        return this.handleBulkMail(response.data.dms_export_id)
                    } else if (response?.data?.success === true) {

                        if ( exportType == 'alowareimport' ) {
                            if(response.data.tag_id !== '') {
                                this.filters.tag_id = response.data.tag_id;
                            }

                            let currentPage = parseInt(response.data.pagination.current_page);
                            let totalPages = parseInt(response.data.pagination.total_pages);
                            if( currentPage <= totalPages) {

                                this.percentageProcessing = Math.round((100 * (currentPage / totalPages))) + '%';
                                this.currentPage = currentPage + 1;
                                this.loadReport('alowareimport');

                            } else {
                                return this.createCompanyTags(response.data.data)
                            }

                        } else {
                            this.btnDMSIsLoading = false;
                            this.reports = response.data.data;
                            this.totalRecords = response.data.pagination.total;
                        }

                    } else {
                        this.$notify({
                            title: "Error",
                            message : response.data?.message || 'Unhandled error, please contact support.',
                            type: "error"
                        });
                    }
                    this.isSearching = false
                })
                .catch(err => {
                    this.$notify({
                        title: "Error",
                        message : err?.response?.data?.message || err || 'Unhandled error, please contact support.',
                        type: "error"
                    });
                    this.isSearching = false
                });
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.loadReport();
        },
        setDefaultDaterange() {
            // set default range: last 3 months
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);

            this.filters.daterange = [start, end];
        },
        handleDaterangeChange() {
            this.currentPage = 1;
            this.isSearching = true;
            this.filters.tag_id = '';
            this.perPage = 10;
            this.loadReport();
        },
        handleSaleTypeChange() {
            this.currentPage = 1;
            this.isSearching = true;
            this.filters.tag_id = '';
            this.perPage = 10;
            this.loadReport();
        },
        handleTermChange() {
            this.currentPage = 1;
            this.isSearching = true;
            this.filters.tag_id = '';
            this.perPage = 10;
            this.loadReport();
        },
        handleAprChange() {
            this.currentPage = 1;
            this.isSearching = true;
            this.filters.tag_id = '';
            this.perPage = 10;
            this.loadReport();
        },
        handleExport(exportType) {
            this.loadReport(exportType);
        },
        getMakes() {
            this.axios.get("/api/company/" + this.company.aloware_id + "/car-makes", {})
            .then(response => {
                if (response.data.success == true) {
                    this.makeOptions = response.data.data;
                }
            });
        },
        getYears() {
            var curretData = new Date();
            for( var i=curretData.getFullYear(); i >= 2000; i-- ) {
                this.yearOptions.push(i);
            }
        },
        getModels() {
            this.axios.get("/api/company/" + this.company.aloware_id + "/car-models?" +  decodeURIComponent( $.param( {
                make: this.filters.make,
                start_date: this.filters.daterange !== null ? this.$options.filters.toSqlDate(this.filters.daterange[0]) : '',
                end_date: this.filters.daterange !== null ? this.$options.filters.toSqlDate(this.filters.daterange[1]) : '',
            })))
            .then(response => {
                if (response.data.success == true) {
                    this.modelOptions = response.data.data;
                }
            });
        },
        handleYearChange() {
            this.currentPage = 1;
            this.filters.tag_id = '';
            this.perPage = 10;
            this.loadReport();
        },
        handleModelChange() {
            this.currentPage = 1;
            this.filters.tag_id = '';
            this.perPage = 10;
            this.loadReport();
        },
        handleMakeChange() {
            this.currentPage = 1;
            this.filters.tag_id = '';
            this.perPage = 10;
            this.getModels();
            this.loadReport();
        },
        handleDMSCampaignNow() {
            this.toDMSCampaign = true
            this.isSearching = true;
            this.filters.tag_id = '';
            this.perPage = 1000;

            this.loadReport('alowareimport');

        },
        getMaxTerm() {
            this.axios.get("/api/company/" + this.company.id + "/dms-maxterm", {})
            .then(response => {
                if (response.data.success == true) {
                    this.max_term = response.data.data;
                }
            });
        },
        getMinAndMaxApr() {
            this.axios.get("/api/company/" + this.company.id + "/dms-minmaxapr", {})
            .then(response => {
                if (response.data.success == true) {
                    this.min_apr = Math.floor(response.data.data.min);
                    this.max_apr = Math.ceil(response.data.data.max);
                    this.filters.apr_range[this.min_apr, this.max_apr];
                }
            });
        },
        processQueryStr(getAll) {
            const startDate = `${moment(this.filters?.daterange?.[0]).format('YYYY-MM-DD')+'+00:00:00' || ''}`
            const endDate = `${moment(this.filters?.daterange?.[1]).format('YYYY-MM-DD')+'+23:59:59' || ''}`

            let qStr = `page=${getAll ? 1 : this.currentPage}&date_field=created_at&`
                qStr += `from_date=${startDate}&to_date=${endDate}&include_custom_contact_fields=true`
                qStr += `${getAll ? '&per_page=2500' : ''}`
            let qList = {'custom_attributes': []}


            if(Array.isArray(this.filters.year) && this.filters.year.length > 0 ) {
                this.filters.year.forEach((year) => {
                    const detail = []
                    detail['field'] = 'year'
                    detail['value'] = year
                    qList.custom_attributes.push(detail)
                })
            }

            if(Array.isArray(this.filters.make) && this.filters.make.length > 0 ) {
                this.filters.make.forEach((make) => {
                    const detail = []
                    detail['field'] = 'make'
                    detail['value'] = make
                    qList.custom_attributes.push(detail)
                })
            }

            if(Array.isArray(this.filters.model) && this.filters.model.length > 0 ) {
                this.filters.model.forEach((model) => {
                    const detail = []
                    detail['field'] = 'model'
                    detail['value'] = model
                    qList.custom_attributes.push(detail)
                })
            }

            let currentFields = []

            qList.custom_attributes.filter((query) => {
                if(!currentFields.includes(query['field'])){
                    currentFields.push(query['field'])
                }
            })

            if(currentFields.length > 1) {
                qStr += `&custom_contact_field_conjunction=true`
            }

            const customQ = decodeURI(this.$qs.stringify(qList, { allowDots: true }))
            qStr += `${customQ ? '&'+customQ : ''}`

            return qStr
        },
        async getDealerAttributes() {
            await this.axios.get(
                `/api/company/${this.company.id}/contact-attributes`
            )
                .then(res => {
                    this.attributes = res.data.data
                })
                .catch(err => {
                    this.$notify({
                        title: "Error: Attributes",
                        message: err.data?.message || 'Unable to retrieve attributes, please contact support.',
                        type: "error"
                    })
                })
        },
        formatField(arr, type) {
            if(Array.isArray(arr)) {
                const row = arr.find((obj) => {
                    return obj.name.toLowerCase() === type
                })
                if(row && row.hasOwnProperty('value')) return row.value
            }
            return '-'
        },
        formatCreatedAt(data) {
            return moment(data.created_at).format('MM-DD-YYYY')
        },
        async createCompanyTags(result) {

            await this.axios({
                method: "POST",
                url: `/api/company/${this.company.id}/aloware-tags`,
                data: {
                    tag_name: this.tagName
                }
            })
                .then(res => {
                    this.tag = res.data.data.tag
                    return this.assignTagToContact(result, this.tag)
                })
                .catch(err => {
                    this.$notify({
                        title: 'Error',
                        message: err.data.message,
                        type: 'error'
                    })
                })
        },
        async assignTagToContact(result, tag) {
            const contacts = result
            const total = contacts.length


            await Promise.all(
                contacts.map(async function (contact, i) {
                    await this.axios({
                        method: 'POST',
                        url: `/api/company/${this.company.id}/aloware-contact-assign-tag/${contact.id}`,
                        data: { tag_id: tag.id }
                    })
                        .then(() => {
                            this.percentageProcessing = Math.round((100 * (i / total))) + '%'
                        })
                        .catch(err => {
                            return this.$notify({
                                title: 'Error',
                                message: err.data.message,
                                type: 'error'
                            })
                        })
                }, this)
            )

            this.percentageProcessing = '100%'
            if(this.toDMSCampaign) {
                return this.notifyCreatedTag()
            }

            this.toDMSCampaign = false

            this.$notify({
                title: "Success",
                message: 'Import success!',
                type: "success"
            });

            return setTimeout(() => {
                window.parent.location.href = 'https://app.simpsocial.com/broadcast/bulk-message';
            }, 3000);
        },
        handleEMailCampaignNow() {
            this.btnEmailIsLoading = true
            this.isSearching = true
            this.filters.tag_id = ''

            this.loadReport('bulkEmailImport');
        },
        handleBulkMail(dmsExportId) {
            this.dmsExportId = dmsExportId
            let redirectBaseUrl = `https://app.simpsocial.com/email-blast`
            if(this.$appENV === 'local') {
                redirectBaseUrl= `${this.$appUrl}/webmail`
            }
            window.top.location = redirectBaseUrl + `?action=email-campaign&export_id=${dmsExportId}`

            //5 seconds expiry
            const now = new Date()
            localStorage.setItem('action', JSON.stringify({
                value: 'digital-lead-wars-bulkmail',
                expiry: now.getTime() + 5000,
            }))
            localStorage.setItem('export_id', JSON.stringify({
                value: dmsExportId,
                expiry: now.getTime() + 5000,
            }))
        },
        async notifyCreatedTag() {
            await this.$confirm(`Contacts Tag Name: ${this.tag.hasOwnProperty('name') ? this.tag.name : '-'}`, 'Contact Import: Success!', {
                center: true,
                showCancelButton: true,
                showClose: true,
                cancelButtonText: 'View Contacts',
                confirmButtonText: 'Copy Tag Name to Clipboard',
                distinguishCancelAndClose: true,
            })
                .then(() => {
                    return this.copyTagNameAndRedirect('https://app.simpsocial.com/broadcast/bulk-message', 'Broadcast')
                })
                .catch((action) => {
                    if(action === 'cancel') {
                        return this.copyTagNameAndRedirect(
                            `https://app.simpsocial.com/contacts?tag_id=${this.tag.hasOwnProperty('id') ? this.tag.id : ''}`,
                            'Contacts'
                        )
                    }
                    return this.copyTagNameAndRedirect('https://app.simpsocial.com/broadcast/bulk-message', 'Broadcast')
                })
        },
        async copyTagNameAndRedirect(url, page_name) {
            const tag_name = this.tag.hasOwnProperty('name') ? this.tag.name : '-'
            await this.$copyText(tag_name)

            this.$notify({
                type: 'success',
                title: 'Success: Copied to clipboard',
                message: `You will be redirected to ${page_name} page.`
            })

            return setTimeout(() => {
                window.parent.location.href = url
            }, 2000)
        }
    },
    watch: {
        'filters.q': function(val, oldVal) {
            this.currentPage = 1;
            this.isSearching = true;
            this.filters.tag_id = '';
            this.perPage = 10;
            this.loadReport();
        },
    }
};
</script>
