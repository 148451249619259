<template>
    <div>
        <el-row
            justify="flex"
            :gutter="20"
        >
            <el-col
                :xs="24"
                :md="8"
                :lg="8"
                :xl="6"
                v-for="(ad) in ads"
                :key="ad.ad_id"
                style="margin-bottom: 20px;"
            >
                <ad
                    :ad="ad"
                >
                </ad>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Ad from './Ad'
export default {
    name: "Ads",
    props: {
        adsData: {
            type: Object,
            required: true
        }
    },
    components: {
        Ad
    },
    data() {
        return {
            ads: [],
            pagination: {},
        }
    },
    mounted() {
        this.setUpComponent()
    },
    methods: {
        setUpComponent() {
            this.ads = this.adsData.ads.data.list
            this.pagination = this.adsData.ads.data.page_info
        }
    }
}
</script>
