<template>
    <el-main>
        <el-row>
            <el-col :span="12">
                Subscriptions
            </el-col>
            <el-col :span="12" style="text-align: right">
                <el-button type="secondary" @click="handleNewSubscription" size="small">New Subscription</el-button>
            </el-col>
        </el-row>
        <el-row v-if="subscriptionsList.length > 0" v-loading="loading">
            <el-col :span="24">
                <el-card class="box-card" v-for="subscription in subscriptionsList" v-bind:key="subscription.subscription_id"
                    style="margin-top: 10px"
                    >
                    <el-row>
                        <el-col :span="12">
                            Subscription Type:
                        </el-col>
                        <el-col :span="12">
                        {{subscription.object}}
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            Page ID:
                        </el-col>
                        <el-col :span="12">
                        {{subscription.subscription_detail.page_id}}
                        </el-col>
                    </el-row>
                    <div class="bottom text-right">
                        <el-button class="button" type="info" size="mini" @click="handleImportLeads(subscription)">Import Leads</el-button>
                        <el-button class="button" type="danger" size="mini" @click="handleUnsunscribe(subscription)">Unsubscribe</el-button>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-row v-else>
            <el-col :span="24">
                There are currenly no subscriptions
            </el-col>
        </el-row>

        <TikTokSubscriptionDialog
            :company="company"
            :visible.sync="subscriptionVisible"
            v-on:onCloseModal="handleOnCloseSubscription" />
    </el-main>
</template>
<style>

</style>
<script>
import store from "../../../store"
import { mapState } from 'vuex'
import TikTokSubscriptionDialog from "../../dialogs/TikTokSubscriptionDialog"

export default {
    components: {TikTokSubscriptionDialog},
    data() {
        return {
            company:{},
            subscriptionVisible: false,
            subscriptionsList: [],
            loading: false
        };
    },
    computed: mapState(['currentCompany']),
    mounted() {
        this.company = JSON.parse(store.state.currentCompany);
        this.getSubscriptions()
    },
    methods: {
        handleNewSubscription() {
            this.subscriptionVisible = true
        },
        handleOnCloseSubscription(subscription) {
            this.subscriptionVisible = false
            this.getSubscriptions()
        },
        getSubscriptions() {
            this.loading = true
            this.axios
            .get(this.companyApiBaseUrl + '/tiktok/subscription')
            .then(response => {
                this.loading = false
                if (response.data.success == true) {
                    this.subscriptionsList = response.data.data.list
                } else {
                }
            })
            .catch(err => {
                this.loading = false
                this.$root.handleErrors(err.response);
            });
        },
        handleUnsunscribe(subscription) {
            this.$confirm('This will permanently unsubscribe the page. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.loading = true
                this.axios
                .delete(this.companyApiBaseUrl + '/tiktok/subscription/' + subscription.subscription_id)
                .then(response => {
                    this.loading = false
                    this.getSubscriptions()
                })
                .catch(err => {
                    this.loading = false
                    this.$root.handleErrors(err.response)
                });
            }).catch(() => {});
        },
        handleImportLeads(subscription) {
            this.$confirm('This will import leads for the past 90 days to aloware. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.loading = true
                this.axios
                .delete(this.companyApiBaseUrl + '/tiktok/import-leads/' + subscription.subscription_detail.page_id)
                .then(response => {
                    this.loading = false
                    this.$notify({
                        title: "Success",
                        message: "Leads are now queued for import",
                        type: "success"
                    });
                })
                .catch(err => {
                    this.loading = false
                    this.$root.handleErrors(err.response)
                });
            }).catch(() => {});
        }
    },
    watch: {
        currentCompany(newValue, oldValue) {
            this.company = JSON.parse(newValue)
        },
    },
};
</script>
