<template>
    <div>
        <el-container style="height: 100%;">
            <el-container>
                <router-view></router-view>
            </el-container>
        </el-container>
    </div>
</template>

<style>
@font-face {
    font-family: "Cronisse";
    src: local("Cronisse"),
    url('../fonts/Cronisse.ttf') format("truetype");
}
body {
    margin: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.page-title {
    border-bottom: 1px solid #efe3e369;
    margin-bottom: 20px;
}
.el-footer,
.el-header {
    background-color: rgb(94, 156, 221);
    color: #333;
    line-height: 60px;
}
.el-aside {
    color: #333;
    border-right: solid 1px #e6e6e6;
    background-color: #fff;
    /*width: 200px;*/
}
.el-menu {
    border: none;
}
.el-main {
    min-height: calc(100vh - 121px);
}
.el-menu-item.active {
    background-color: #ecf5ff;
}
.el-dropdown {
    cursor: pointer;
}
.el-notification__content {
    text-align: left;
}
</style>

<script>
import adminMenu from './components/admin/menu';

export default {
    components: {
        adminMenu
    },
    data() {
        return {
            isDarkMode: false,
            darkModePages: ['dms-campaigns', 'digital-leads', 'webmail']
        };
    },
    created() {
        window.addEventListener('resize', this.updateScreenWidth);
    },
    mounted() {
        if(typeof(this.$route.meta.darkMode) !== 'undefined' && this.$route.meta.darkMode ||
            typeof(this.$route.query.redirect) !== 'undefined' && this.darkModePages.includes(this.$route.query.redirect)) {
            document.body.classList.toggle("dark-mode");
        }
        this.updateScreenWidth()
    },
    methods: {
        updateScreenWidth() {
            this.$store.commit('UpdateScreenWidth', window.innerWidth)
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateScreenWidth);
    },
};
</script>
