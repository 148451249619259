<template>
    <el-main
        v-loading="loading"
    >
        <h2
            v-if="company"
        >
            {{ this.company.name }}
        </h2>
        <el-container
            style="display: flex; justify-content: center; overflow: hidden;"
        >
            <chatbox
                v-if="contact"
                :contact="contact"
                class="mobile-box"
                @convo-load-more="loadMore"
                @submit-convo="submitConvo"
            ></chatbox>
        </el-container>
    </el-main>
</template>
<script>
import Chatbox from "./engage/Chatbox.vue";

export default {
    name: 'Engage',
    components: {
        Chatbox
    },
    props: {
        communicationsSummary: {
            type: String,
        }
    },
    data() {
        return {
            loading: false,
            url: '',
            shortcode: '',
            company: null,
            user: null,
            contact: null,
            convo: null,
            page_option: {
                page: 1
            },
        }
    },
    mounted() {
        this.company = JSON.parse(this.$store.state.currentCompany)
        this.user = JSON.parse(localStorage.getItem('user'))

        if(this.company && this.user) {
            this.handleSetupComponent()
        }

    },
    methods: {
        handleSetupComponent() {
            const loginData = {
                user: this.user,
                access_token: this.user.access_token,
                token_exp: this.user.token_exp,
                isSingleSignedIn: this.user.isSingleSignedIn
            }
            this.$store.commit('LoginUser', loginData)
            this.url = `/api/company/${this.company.id}/engage/contacts`
            this.shortcode = this.$router?.currentRoute?.params?.path
            if(this.url && this.shortcode) {
                this.handleEventUpdates()
                this.getConversations()
            }
        },
        handleEventUpdates() {
            Echo.private(`engage-communication-disposed.${this.shortcode}`)
                .listen('.updates', event => {
                    this.getConversations(true)
                })
        },
        async getConversations(recent = false) {
            let pageOptions = this.page_option
            if(recent) {
                pageOptions = {
                    ...this.page_option,
                    page: 1
                }
            } else {
                this.loading = true
            }

            const qs = this.$qs.stringify(pageOptions)
            await this.axios.get(
                `${this.url}/${this.shortcode}?${qs}`
            )
                .then(res => {
                    this.contact = res.data.data.contact
                    this.$store.commit('UpdateContactData', this.contact)
                    const storePayload = {
                        convo: res.data.data.convo,
                        recent: recent
                    }
                    this.$store.commit('UpdateEngageConvos', storePayload)
                    this.loading = false
                })
                .catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading = false
                })
        },
        loadMore() {
            ++this.page_option.page
            this.getConversations()
        },
        async submitConvo(data) {
            await this.axios.post(
                `${this.url}/${this.shortcode}`,
                data
            )
                .then(async res => {
                    this.$store.commit('UpdateEngageInput', true)
                    return this.$notify({
                        type: "success",
                        title: "Queued",
                        message: res.data.message,
                    })
                })
                .catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading = false
                })
        }
    }
}
</script>
