<template>
    <el-form>
        <el-col :span="24">
            <el-form-item>
                <el-select v-model="urlType" placeholder="Select" @change="handleOptionChange">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                {{urlValue}}
            </el-form-item>
        </el-col>

    </el-form>
</template>
<style>

</style>
<script>
import store from "../../store";

export default {
    props: ['company'],
    data() {
        return {
            currentCompany: {},
            options: [{
                value: '',
                label: '-- Select Url Type --'
            },{
                value: 'company-profile',
                label: 'Company Profile'
            }, {
                value: 'credit-application',
                label: 'Credit Application'
            }, {
                value: 'credit-application-guest',
                label: 'Guest / Messenger Credit Application'
            }, {
                value: 'car-details',
                label: 'Car Details Page'
            }, {
                value: 'fb-url-used',
                label: 'FB Url Used'
            }, {
                value: 'fb-url-new',
                label: 'FB Url New'
            }, {
                value: 'fb-url-payments',
                label: 'FB Url Payments'
            }, {
                value: 'fb-car-details',
                label: 'FB Car Details Page'
            }],
            urlType: '',
            urlValue: ''
        };
    },
    computed: {},
    mounted() {
        this.currentCompany = this.company
    },
    methods: {
        handleOptionChange() {

            this.urlValue = '...'

            var companyId = this.currentCompany.aloware_id
            if(this.currentCompany.aloware_id == '0') {
                companyId = 'simpid-' + this.currentCompany.id
            }

            this.axios
                .get('/api/company/' + companyId + '/get_url', {
                    params: {
                        'url_type' : this.urlType
                    }
                })
                .then(response => {
                    if (response.data.success == true) {
                        this.urlValue = response.data.url
                    } else {
                        let errors = response.data.errors.message;
                        let message = "";
                        for (let error in errors) {
                            message = errors[error] + "\n";
                        }
                        this.$notify({
                            title: "Error",
                            message: message,
                            type: "error"
                        });
                    }
                })
                .catch(err => {
                    this.$root.handleErrors(err.response);
                });
        },
    },
     watch: {
        company: function(val, oldVal) {
            this.currentCompany = this.company
            this.handleOptionChange();
        },
     }
};
</script>
