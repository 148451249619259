<template>
    <el-row>
        <el-col :span="24" >
            <span class="error-message-container" @click="enable_click ? window.parent.location.href = 'https://go.dealervault.com' : ''">
                <span class="error-message blink_me">
                    If you would like this feature turned on, please contact Support <a href="mailto:support@simpsocial.com">support@simpsocial.com</a>
                </span>
            </span>
        </el-col>
    </el-row>
</template>
<style>
    .error-message-container {
        text-align: center;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #FF5050;
        padding: 30px;
        border-radius: 10px;
        cursor: pointer;
        z-index: 1000;
    }
    .blink_me {
    animation: blinker 1s linear infinite;
    }
    @keyframes blinker {
    50% { opacity: 0; }
    }
    .error-message {
        font-weight: bolder;
        font-size: 40px;
        color: #ffffff;
    }
</style>
<script>
export default {
    props: {
        enable_click: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            S3_URL: process.env.MIX_AWS_S3_URI,
            logoUrl: '',
        };
    }
};
</script>
