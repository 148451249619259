<template>
    <div class="row" style="margin:2% auto; width:420px;margin-bottom:30px;">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>Login</span>
            </div>
            <el-form ref="form" label-width="120px" v-if="!(aloware_id && auth_token)">
                <el-form-item label="Email">
                    <el-input v-model="email"></el-input>
                    <span
                        class="text text-danger"
                        v-if="loginError && errors.email"
                    >{{ errors.email[0] }}</span>
                </el-form-item>
                <el-form-item label="Password">
                    <el-input v-model="password" show-password></el-input>
                    <span
                        class="text text-danger"
                        v-if="loginError && errors.password"
                    >{{ errors.password[0] }}</span>
                </el-form-item>
                <el-row style="text-align: center">
                    <el-button type="primary" @click="login()" :loading="isProgress">Login</el-button>
                    <el-button type="secondary" @click="handleForgotPassword()">Forgot Password</el-button>
                </el-row>
            </el-form>
            <el-alert v-else title="Loggin in..." type="info" :closable="false"></el-alert>
        </el-card>
    </div>
</template>
<script>
import store from "../store";

export default {
    data() {
        return {
            email: "",
            password: "",
            aloware_id: "",
            auth_token: "",
            redirect: "",
            loginError: false,
            errors: {},
            isProgress: false
        };
    },
    mounted() {
        if ( typeof this.$route.query.aloware_user_id !== "undefined" && typeof this.$route.query.redirect !== "undefined"  ) {
            this.isProgress = true;
            this.aloware_id = this.$route.query.aloware_user_id;
            //this.auth_token = this.$route.query.token;
            this.redirect = this.$route.query.redirect;
            this.login();
        }
    },
    methods: {
        login() {
            this.loginError = false;
            this.axios
                .post("api/auth/login", {
                    email: this.email,
                    password: this.password,
                    aloware_id: this.aloware_id,
                    auth_token: this.auth_token
                })
                .then(response => {
                    this.isProgress = true;
                    if (response.data.success == true) {
                        //setTimeout(() => {
                        this.isProgress = false;
                        store.commit("LoginUser", response.data);
                        store.commit(
                            "UpdateCurrentCompany",
                            response.data.user.company
                        );
                        let supportedRedirects = ['account', 'cars', 'dashboard', 'company', 'company-reports', 'messenger', 'dms-campaigns', 'messenger-lookup', 'messenger-user-profile', 'digital-leads', 'webmail', 'social-listening'];

                        if( supportedRedirects.includes(this.redirect)) {
                            if(this.redirect === 'messenger-lookup' || this.redirect === 'messenger-user-profile') {
                                this.redirect = '/' + this.redirect + '?contact_id=' + this.$route.query.contact_id

                                this.$router.push({ path: this.redirect });
                            }
                            else {
                                let urlParams = ''
                                if(typeof this.$route.query.contact_id !== 'undefined') {
                                    urlParams += '?contact_id=' + this.$route.query.contact_id
                                }

                                if(urlParams !== '') {
                                    this.$router.push({ path: this.redirect + urlParams });
                                } else {
                                    this.$router.push({ name: this.redirect });
                                }
                            }

                        } else {
                            this.$router.push({ name: "user-dealerprofile" });
                        }
                        //}, 2000);
                    } else {
                        this.isProgress = false;
                        let errors = response.data.errors.message;
                        let message = "";

                        for (let error in errors) {
                            if(typeof errors[error] == 'string') {
                                message = errors[error] + "\n";
                            }
                        }
                        this.$notify({
                            title: "Error",
                            message: message,
                            type: "error"
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.$root.handleErrors(err.response);
                });
        },
        handleForgotPassword() {
            this.$router.push({ path: 'forgot-password' });
        }
    }
};
</script>
