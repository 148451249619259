<template>
    <div>
        <el-pagination
            layout="prev, pager, next"
            :total="meta.total"
            :page-size="meta.per_page"
            :current-page="meta.current_page"
            :page-count="meta.last_page"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
        <el-table
            :data="tableData"
            stripe
            style="width: 100%"
            @sort-change="handleSortChange"
        >
            <el-table-column
                type="index"
                width="50"
            >
            </el-table-column>
<!--            <el-table-column-->
<!--                prop="leadgen_id"-->
<!--                label="FB Lead ID"-->
<!--            >-->
<!--            </el-table-column>-->
            <el-table-column
                prop="contact.first_name"
                label="First Name"
                width="120"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="contact.last_name"
                label="Last Name"
                width="120"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="contact.email"
                label="Email"
                width="250"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="contact.phone_number"
                label="Phone Number"
                width="180"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="contact.vin"
                label="VIN"
                width="180"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="contact.year"
                label="Year"
                width="100"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="contact.make"
                label="Make"
                width="120"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="contact.model"
                label="Model"
                width="120"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="created_at"
                label="Received"
                width="200"
                align="center"
                :sortable="'custom'"
            >
            </el-table-column>
            <el-table-column
                prop="lead_sent_at"
                label="Email Sent"
                width="200"
                align="center"
                :sortable="'custom'"
            >
            </el-table-column>
        </el-table>
        <el-pagination
            layout="prev, pager, next"
            :total="meta.total"
            :page-size="meta.per_page"
            :current-page="meta.current_page"
            :page-count="meta.last_page"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
    </div>
</template>

<script>
export default {
    name: "FbLeadgenTable",
    props: {
        leads: {
            type: Array,
            required: true
        },
        links: {
            type: Object,
            required: true
        },
        meta: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            tableData: []
        }
    },
    mounted() {
        this.tableData = this.leads
    },
    methods: {
        handleCurrentChange(data) {
            return this.$emit('current-change', data)
        },
        handleSortChange(data) {
            return this.$emit('current-change', data)
        }
    }
}
</script>
