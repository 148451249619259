<template>
    <div v-if='is_page_ready'>
        <button class="md-btn md-fab md-sm m-b-sm text-white messenger" @click='handleClick'><img src="/images/messenger-line-white.png" alt='Messenger' /></button>
        <span class="d-block text-xs text-muted">Msgr Lookup</span>
    </div>
</template>

<script>
import store from "../store";

export default {
    components: {},
    data() {
        return {
            user: {},
            contact: {},
            aloware_contact_id: null,
            is_page_ready: false
        }
    },
    beforeMount() {
        var url_string = document.location.href
        var url = new URL(url_string)

        var contact_id = url.searchParams.get("contact_id")
        if(contact_id) {
            this.aloware_contact_id = contact_id
        }

        // let pathnames = url.pathname.trim().split('/')
        // pathnames = pathnames.filter(function(el) { return el; })
        //
        // let params = []
        // for(let i = 0; i < pathnames.length; i+=2) {
        //     params[`${pathnames[i]}`] = pathnames[i+1]
        // }
        //
        // if(params['contacts']) {
        //     this.aloware_contact_id = params['contacts']
        // }
    },
    mounted() {
        if(this.aloware_contact_id) {
            this.getAlowareContact().then(res => {
                if(res.success) {
                    this.contact = res.data
                    this.is_page_ready = true
                }
            })
        }
    },
    methods: {
        getAlowareContact() {
            return this.axios.get(`${this.companyApiBaseUrl}/aloware-contact/` + this.aloware_contact_id)
                .then(res => {
                    return Promise.resolve(res.data)
                })
                .catch((err) => {
                    console.log(err)
                    return Promise.reject(err)
                })
        },
        handleClick() {
            let base_url = '/messenger-lookup?firstname=' + this.contact.first_name + '&lastname=' + this.contact.last_name + '&email=' + this.contact.email + '&contact_id=' + this.contact.id

            window.open(base_url);
        }
    }
}
</script>

<style scoped>
.m-b-sm {
    margin-bottom: .5rem!important;
}
.md-btn {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    outline: none;
    border: 0;
    border-radius: 3px;
    padding: 6px;
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-style: inherit;
    font-variant: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
    transition: box-shadow .4s cubic-bezier(.25,.8,.25,1),background-color .4s cubic-bezier(.25,.8,.25,1),transform .4s cubic-bezier(.25,.8,.25,1);
}
.md-btn.md-fab, .md-btn.md-raised {
    transform: translateZ(0);
}
.md-btn.md-fab {
    z-index: 20;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    overflow: hidden;
    transition: .2s linear;
    transition-property: transform,box-shadow;
}
.md-btn.md-fab, .md-btn.md-raised:not([disabled]) {
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
}
.md-btn.md-fab.md-sm {
    width: 48px;
    height: 48px;
    line-height: 36px;
}
.hover-menu .col-4:hover, .hover-menu .col-4:hover span.block, .text-whitish {
    color: #fff!important;
}
.messenger {
    background-color: #0084ff;
    display: flex;
    align-items: center;
    flex-direction: row;
    direction: ltr;
    margin: 0 auto;
}
.text-white {
    color: #fff!important;
}
.text-muted {
    word-break: break-word;
    font-weight: 600!important;
    color: inherit!important;
    opacity: .7;
}
.d-block {
    display: block!important;
}
.text-xs {
    font-size: 13.6px!important;
    font-family: Circe,-apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
}
.messenger img {
    height: 28px;
    width: 28px;
    margin: 0 auto;
}
</style>
