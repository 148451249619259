<template>
    <el-row>
        <div class="page-header" style="text-align: center;padding: 20px">
            <img v-if="darkMode" :src="S3_URL + '/assets/img/simpsocial-logo-white.png'" style="width: 175px">
            <img v-else :src="S3_URL + '/assets/img/simpsocial-logo.png'" style="width: 175px"><br>
            <span class="page-title">{{ label }}</span><br>
            <span class="page-company-label">{{ company.name }}</span>
        </div>
    </el-row>
</template>
<style>
    .page-header .page-title {
        font-weight: bold;
        font-size: 25pt;
        font-family: 'Cronisse';
    }
    .page-header .page-company-label {
        font-weight: bold;
        font-size: 15pt;
    }
</style>
<script>
export default {
    props: [
        'company',
        'label',
        'darkMode'
    ],
    data() {
        return {
            S3_URL: process.env.MIX_AWS_S3_URI,
            logoUrl: '',
        };
    },
    mounted() {
        console.log(this.darkMode);
    }
};
</script>
