<template>
    <el-main>
        <el-container class="page-webmail" v-if="company.webmail_enabled == '1'">
            <el-header height="20px"></el-header>
            <el-container v-if="user.simp_email_address !== '' && user.simp_email_address !== null">
                <el-aside width="200px">
                    <!--<el-row>
                        <el-col :span="24" style="padding: 10px">
                            <el-button type="primary"
                            size="small"
                            icon="el-icon-edit-outline"
                            style="width: 100%"
                            @click="handleNavClick('compose')">Compose</el-button>
                        </el-col>
                    </el-row>-->
                    <el-menu :default-active="activeMenuIndex"
                        :collapse="isCollapse">
                        <el-menu-item index="5" @click="handleNavClick('bulk')">
                            <div class="compose-menu-item">
                                <i class="fa fa-mail-bulk" style="margin-right: 18px;width: 24px"></i>
                                <span slot="title">Compose - Blast</span>
                            </div>
                        </el-menu-item>
                        <el-menu-item index="6" @click="handleNavClick('compose')">
                            <div class="compose-menu-item" style="">
                                <i class="el-icon-edit-outline" style="margin-right: 18px;width: 24px"></i>
                                <span slot="title">Compose - Single</span>
                            </div>
                        </el-menu-item>
                        <el-menu-item index="2" @click="handleNavClick('inbox')">
                            <div class="menu-item">
                                <i class="el-icon-message"></i>
                                <span slot="title">Inbox</span>
                            </div>
                        </el-menu-item>
                        <el-menu-item index="3" @click="handleNavClick('sent')">
                            <div class="menu-item">
                                <i class="el-icon-position"></i>
                                <span slot="title">Sent</span>
                            </div>
                        </el-menu-item>
                        <el-menu-item index="4" @click="handleNavClick('templates')">
                            <div class="menu-item">
                                <i class="el-icon-document"></i>
                                <span slot="title">Templates</span>
                            </div>
                        </el-menu-item>

                    </el-menu>
                    <div>
                        <el-divider></el-divider>
                        <small>Logged in as : <strong>{{user.simp_email_address}}</strong></small>

                        <div v-if="pendingProcessess.length > 0" style="margin-top: 20px">
                            <small>Sending in progress:</small>
                            <ul class="list">
                                <li v-for="pendingProcess in pendingProcessess" v-bind:key="pendingProcess.id">
                                    <small>Total Recipients: <span>{{pendingProcess.total_procesed}}/{{pendingProcess.total_recipients}}</span></small>
                                    <el-progress :percentage="getPercentage(pendingProcess)" color="#f2225d"></el-progress>
                                </li>
                            </ul>
                        </div>
                    </div>
                </el-aside>
                <el-main style="padding-top: 0;">
                    <WebmailCompose v-if="activePage=='compose'" :recipient="defaultRecipient" />
                    <WebmailSent v-else-if="activePage=='sent'" style="margin-left: -20px; margin-right: -20px;" />
                    <WebmailInbox v-else-if="activePage=='inbox'" style="margin-left: -20px; margin-right: -20px;" />
                    <WebmailTemplates v-else-if="activePage=='templates'" />
                    <WebmailSendBulk v-else-if="activePage=='bulk'" @onProgress="getOutgoingEmailsProgress" :dmsExportId="dmsExportId" />
                    <div v-else>
                        Page not found: {{activePage}}
                    </div>
                </el-main>
            </el-container>
            <el-container v-else>
                <CreateEmail />
            </el-container>
        </el-container>
        <div v-else>
            <RestrictedScreen />
        </div>
    </el-main>
</template>

<script>
import store from "../store";
import WebmailInbox from './webmail/Inbox';
import WebmailSent from './webmail/Sent';
import WebmailCompose from './webmail/Compose';
import WebmailTemplates from './webmail/Templates';
import WebmailSendBulk from './webmail/Bulk';
import CreateEmail from './webmail/CreateEmail';
import RestrictedScreen from './widgets/RestrictedScreen';

export default {
    components: {
        WebmailInbox,
        WebmailCompose,
        WebmailSent,
        WebmailTemplates,
        WebmailSendBulk,
        RestrictedScreen,
        CreateEmail,
    },
    data() {
        return {
            user: {},
            company: {},
            isCollapse: false,
            activePage: 'bulk',
            defaultRecipient: '',
            pendingProcessess: [],
            dmsExportId: 0,
            activeMenuIndex: '5',
        };
    },
    computed: {},
    mounted() {
        this.company = JSON.parse(store.state.currentCompany)
        this.user = JSON.parse(store.state.currentUser)

        //this.getUser()
        if(typeof this.$route.query.contact_id !== 'undefined') {
            this.activePage = 'compose'

            this.getAlowareContact(this.$route.query.contact_id)
        } else if(localStorage.getItem('action') !== '') {
            let action = JSON.parse(localStorage.getItem('action'))
            if(action !== null) {
                this.processAction(action.value)
            }

            //localStorage.removeItem("action");
        }

        this.getOutgoingEmailsProgress()
    },
    methods: {
        handleNavClick(pageId) {
            this.activePage = pageId
        },
        getUser() {
            this.axios
            .get(
                this.companyApiBaseUrl + "/user/" + this.user.aloware_id
            )
            .then(response => {
                if (response.data.success == true) {
                    this.user = response.data.data
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        getAlowareContact(contactId) {
            this.axios
            .get(
                this.companyApiBaseUrl + "/aloware-contact/" + contactId
            )
            .then(response => {
                if (response.data.success == true) {
                    this.defaultRecipient = response.data.data.email
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        getOutgoingEmailsProgress() {
            this.axios
            .get(
                this.companyApiBaseUrl + "/webmail/pending-outgoing"
            )
            .then(response => {
                this.pendingProcessess = response.data

                if(response.data.length > 0) {
                    setTimeout(() => {
                        this.getOutgoingEmailsProgress()
                    }, 2000)
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        getPercentage(outgoingMail) {
            let percentage = (outgoingMail.total_procesed / outgoingMail.total_recipients) * 100
            return Math.ceil(percentage)
        },
        getQueryString() {
            if( process.env.MIX_APP_ENV == 'local' ) {
                var queryStringKeyValue = window.location.search.replace('?', '').split('&');
            } else {
                var queryStringKeyValue = window.parent.location.search.replace('?', '').split('&');
            }

            var qsJsonObject = {};
            if (queryStringKeyValue != '') {
                for (var i = 0; i < queryStringKeyValue.length; i++) {
                    qsJsonObject[queryStringKeyValue[i].split('=')[0]] = queryStringKeyValue[i].split('=')[1];
                }
            }
            return qsJsonObject;
        },
        processAction(action) {
            switch(action) {
                case 'email-campaign': {
                    let export_id = JSON.parse(localStorage.getItem('export_id'))
                    this.doEmailCampaign(export_id.value)
                    //localStorage.removeItem("export_id");
                    break;
                }
                default: {
                    break;
                }
            }
        },
        doEmailCampaign(exPortId) {
            this.activePage = 'bulk'
            this.activeMenuIndex = '5'
            this.dmsExportId = parseInt(exPortId)
        }
    }
};
</script>
