<template>
    <el-container>
        <el-header
            height="20"
            style="background-color: transparent;"
        >
            <filtering
                v-if="showFiltering"
                :company="company"
                @filter-apply="handleGetAds"
            ></filtering>
        </el-header>
        <el-main
            v-loading="loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            style="margin-top: 1rem;"
        >
            <el-pagination
                v-if="showAds && pagination.total_page > 1"
                small
                layout="prev, pager, next"
                :total="pagination.total_number"
                :page-count="pagination.total_page"
                :current-page="pagination.page"
                :page-size="pagination.page_size"
                @current-change="handlePaginationChange"
            >
            </el-pagination>

            <Ads
                v-if="showAds"
                :ads-data="adsData"
                :key="adsDataKey"
            ></Ads>

            <el-empty
                v-if="showAds && !loading && adsData.ads.data.list.length < 1"
            ></el-empty>

            <el-pagination
                v-if="showAds && pagination.total_page > 1"
                small
                layout="prev, pager, next"
                :total="pagination.total_number"
                :page-count="pagination.total_page"
                :current-page="pagination.page"
                :page-size="pagination.page_size"
                @current-change="handlePaginationChange"
            >
            </el-pagination>
        </el-main>
    </el-container>
</template>

<script>
import Ads from './tiktok/Ads';
import Filtering from "./Filtering";
export default {
    name: "Tiktok",
    components: {
        Filtering,
        Ads
    },
    props: {
        company: {
            type: Object,
            default() {
                return JSON.parse(this.$store.state.currentCompany)
            }
        },
        user: {
            type: Object,
            default() {
                return JSON.parse(this.$store.state.currentUser)
            }
        },
        apiUrl: {
            type: String,
            default() {
                return `/api/company/${this.company.id}/social-listening`
            }
        }
    },
    data() {
        return {
            loading: false,
            showFiltering: false,
            adsData: {},
            adsDataKey: 0,
            showAds: false,
            pagination: {},
            currentFilter: {},
            adGroups: [],
            comments: [],
        }
    },
    mounted() {
        this.handleAcctLogin()
    },
    methods: {
        async handleAcctLogin() {
            await this.axios.post(`${this.apiUrl}/login`, {
                user: this.user.id,
                platform: 'tiktok'
            })
                .then(res => {
                    this.adGroups = res.data.data.ad_groups.data.list
                    if(this.adGroups.length) {
                        this.adGroups.forEach(async ad => {
                            if(ad.hasOwnProperty('adgroup_id')) {
                                await this.handleGetCommentsByAdGroup(ad.adgroup_id)
                            }
                        })
                    }
                    this.showFiltering = true
                })
                .catch(err => {
                    console.log('err?', err)
                    this.$emit('login-failed')
                    return this.$root.handleErrors(err.response)
                })
        },
        async handleGetCommentsByAdGroup(adgroup_id) {
            await this.axios.get(
                `api/company/${this.company.id}/social-listening/get-tiktok-adgroup-comments?adgroup_id=${adgroup_id}&platform=tiktok`
            )
                .then(res => {
                    this.comments = [
                        ...this.comments,
                        ...res.data.data.comments.data.comments
                    ]
                    this.comments = [...new Set(this.comments)]
                    this.$store.commit('UpdateTiktokAdGroupComments', this.comments)
                })
                .catch(err => {
                    console.log('err?', err)
                    return this.$root.handleErrors(err.response)
                })
        },
        async handleGetAds(filter) {
            this.loading = true
            this.showAds = false
            this.currentFilter = filter

            const qs = filter ? this.$qs.stringify(filter) : ''
            await this.axios.get(`${this.apiUrl}/get-ads?platform=tiktok&${qs}`)
                .then(res => {
                    this.adsData = res.data.data
                    this.pagination = res.data.data.ads.data.page_info
                    this.showAds = true
                    this.loading = false
                })
                .catch(err => {
                    console.log('err?', err)
                    return this.$root.handleErrors(err.response)
                })
        },
        handlePaginationChange(page) {
            this.currentFilter = {
                ...this.currentFilter,
                page: page
            }
            return this.handleGetAds(this.currentFilter)
        }
    }

}
</script>

<style scoped>

</style>
