<template>
    <el-card

    >
        <ul style="list-style: none; padding: 0;">
            <li
                v-if="car.hasOwnProperty('id')"
            >
                <small><strong>Vehicle ID:</strong> {{ formatVehicleId(car.id) }}</small>
            </li>
            <li
                v-if="car.hasOwnProperty('title')"
            >
                <small><strong>Title:</strong> {{ car.title }}</small>
            </li>
            <li
                v-if="car.hasOwnProperty('description')"
                @mouseover="toggleDescription(car.description, true)"
                @mouseleave="toggleDescription(car.description, false)"
            >
                <small>
                    <strong>Description:</strong> {{ carDescription }}
                </small>
            </li>
            <li
                v-if="car.hasOwnProperty('vdp')"
            >
                <small><strong>VDP Link:</strong> <a :href="car.vdp" class="fb-feed-url" target="_blank">Click here</a></small>
            </li>
            <li
                v-if="car.hasOwnProperty('vin')"
            >
                <small><strong>VIN:</strong> {{ car.vin }}</small>
            </li>
            <li
                v-if="car.hasOwnProperty('year')"
            >
                <small><strong>Year:</strong> {{ car.year }}</small>
            </li>
            <li
                v-if="car.hasOwnProperty('price')"
            >
                <small><strong>Price:</strong> {{ car.price }}</small>
            </li>
            <li
                v-if="car.hasOwnProperty('make')"
            >
                <small><strong>Make:</strong> {{ car.make }}</small>
            </li>
            <li
                v-if="car.hasOwnProperty('model')"
            >
                <small><strong>Model:</strong> {{ car.model }}</small>
            </li>
            <li
                v-if="car.hasOwnProperty('odometer')"
            >
                <small><strong>Mileage:</strong> {{ car.odometer }} MI</small>
            </li>
            <li
                v-if="car.hasOwnProperty('transmission')"
            >
                <small><strong>Transmission:</strong> {{ ['AUTOMATIC', 'MANUAL'].includes(car.transmission.toUpperCase()) ? car.transmission.toUpperCase() : 'OTHER' }}</small>
            </li>
            <li
                v-if="car.hasOwnProperty('galleries')"
            >
                <small><strong>Image count:</strong> {{ car.galleries.length }}</small>
            </li>
            <li
                v-if="car.hasOwnProperty('galleries')"
            >
                <el-carousel
                    trigger="click"
                    indicator-position="none"
                    arrow="always"
                    :autoplay="false"
                    @change="handleCarouselChange"
                    ref="feedCarousel"
                >
                    <el-carousel-item
                        v-for="(img, index) in car.galleries"
                        :key="img.id"
                    >
                        <div style="text-align: center; font-size: xx-small; margin: 10px 0">
                            <el-button
                                size="small"
                                @click="confirmSetCurrentImg(img, car)"
                            >
                                Set this picture as default
                            </el-button>
                        </div>
                        <img
                            :src="img.url"
                            :alt="`${formatVehicleId(car.id)}_${index}`"
                            style="width: 100%"
                            loading="lazy"
                        >
                    </el-carousel-item>
                </el-carousel>
                <div class="centered-img-text">{{currentImgIndex}} of {{car.galleries.length}}</div>
            </li>
        </ul>
    </el-card>
</template>

<script>
export default {
    name: "FeedCard",
    props: {
        car: {
            type: Object,
            required: true,
        },
        feedStatus: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            company: {},
            carDescription: '',
            currentImgIndex: 1,
            showImageDefaultConfirmation: false,
        }
    },
    mounted() {
        this.setupComponent()
    },
    methods: {
        setupComponent() {
            this.company = JSON.parse(this.$store.state.currentCompany)
            this.toggleDescription(this.car.description)
            this.$refs.feedCarousel.$el.addEventListener('wheel', event => this.scrollCarousel(event))
        },
        formatVehicleId(car_id) {
            return `${this.company.id}${this.feedStatus.toUpperCase().replace('-', '')}${car_id}`
        },
        toggleDescription(text, long = false) {
            if(long){
                this.carDescription = text
                return
            }
            this.carDescription = `${text.slice(0, 16)}...`
        },
        handleCarouselChange(newIndex) {
            this.currentImgIndex = ++newIndex
        },
        confirmSetCurrentImg(img, car) {
            this.$emit('confirmSetCurrentImg', {img, car})
        },
        scrollCarousel(index) {
            index.preventDefault()
            Math.ceil(index.deltaY) > 0 ? this.$refs.feedCarousel.next() : this.$refs.feedCarousel.prev()
        }
    },
    beforeDestroy() {
        this.currentImgIndex = 1
    }
}
</script>

<style scoped>
a:visited, a {
    color: #909399;
    text-decoration: none;
}
.centered-img-text {
    text-align: center;
    display: block;
}
>>>.el-carousel__arrow {
    background-color: #FF000030 !important;
}
</style>
