<template>
    <el-main
        v-loading="loading"
    >
        <el-select
            v-model="subscriber"
            :placeholder="placeholder"
            filterable
            :loading="loading"
            value-key="id"
        >
            <el-option
                v-for="item in subscriptions"
                :key="item.id"
                :label="`${item.name} #${item.sub_id}`"
                :value="item"
            >
            </el-option>
        </el-select>
        <div
            v-if="subscriber && subscriber.hasOwnProperty('id')"
        >
            <p
                v-for="(item, key) in subscriber"
                :key="key"
                v-if="['number', 'string'].includes(typeof item)"
            >
                <strong>{{`${key.toUpperCase()}`}}:</strong> {{`${item}`}}
            </p>
            <p><strong>STATUS: </strong>
                <el-radio-group
                    v-model="subscriber.vs_subscription.status"
                    :fill="radioButtonColor"
                    @change="handleStatusUpdate"
                >
                    <el-radio-button
                        v-for="(status, key) in status_options"
                        :key="key"
                        :label="status"
                    ></el-radio-button>
                </el-radio-group>
            </p>
            <p>
                <strong>SUBSCRIPTIONS: </strong>
                <el-button
                    :type="enableEditSubscription ? 'danger' : 'primary'"
                    icon="el-icon-edit"
                    circle
                    size="mini"
                    @click="handleSubscriptionUpdate(enableEditSubscription)"
                ></el-button>
            </p>
            <el-checkbox
                v-model="checkAll"
                :indeterminate="isIndeterminate"
                @change="handleCheckAllChange"
                :disabled="!enableEditSubscription"
            >Check all</el-checkbox>
            <el-checkbox-group
                v-model="subscriber.vs_subscription.subscriptions"
                @change="handleCheckedSubscriptionChange"
                :disabled="!enableEditSubscription"
            >
                <el-checkbox v-for="option in subscription_options" :label="option" :key="option">{{option}}</el-checkbox>
            </el-checkbox-group>
        </div>
    </el-main>
</template>
<script>
export default {
    name: 'ManageSubscriptions',
    data() {
        return {
            loading: false,
            company: {},
            subscriptions: [],
            subscription_options: [],
            status_options: [],
            subscriber: {
                vs_subscription: {
                    subscriptions: [],
                    status: 'inactive'
                }
            },
            placeholder: 'Select Dealer',
            isIndeterminate: true,
            checkAll: false,
            enableEditSubscription: false,
            oldSubscriberData: {},
            radioButtonColor: '',
        }
    },
    mounted() {
        this.loading = true
        this.setupComponent()
    },
    methods: {
        setupComponent() {
            this.company = JSON.parse(this.$store.state.currentCompany)
            this.handleGetSubscription()
        },
        async handleGetSubscription() {
            this.loading = true
            const url = `api/company/${this.company.id}/crm-dms-integrations/vs-dealer/integrations/connect-event-service`
            await this.axios.get(url)
                .then(res => {
                    this.subscriptions = res.data.data.subscriptions
                    this.subscription_options = res.data.data.subscription_options
                    this.status_options = res.data.data.status_options
                    this.loading = false
                    this.placeholder = 'Select Dealer'
                    this.$emit('ces-production', res.data.data.production)
                })
                .catch(err => {
                    this.loading = false
                    return this.$root.handleErrors(err.response)
                })
        },
        handleCheckAllChange(val) {
            this.subscriber.vs_subscription.subscriptions = val ? this.subscription_options : [];
            this.isIndeterminate = false;
        },
        handleCheckedSubscriptionChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.subscription_options.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.subscription_options.length;
        },
        handleSubscriptionUpdate() {
            this.enableEditSubscription = !this.enableEditSubscription
            if(!this.enableEditSubscription && this.oldSubscriberData.hasOwnProperty('id') &&
                JSON.stringify(this.subscriber.vs_subscription.subscriptions) !== JSON.stringify(this.oldSubscriberData.vs_subscription.subscriptions)
            ) {
                return this.confirmSubscriptionUpdate('subscriptions')
            }
        },
        handleStatusUpdate(data) {
            if(this.oldSubscriberData.vs_subscription.status !== data) {
                return this.confirmSubscriptionUpdate('status')
            }
        },
        async confirmSubscriptionUpdate(type) {
            await this.$confirm('Changes has been made. Do you want to submit this?', {
                confirmButtonText: 'Submit',
                cancelButtonText: 'Cancel',
            })
                .then(() => {
                    return this.handleSubscriptionUpdateSubmit(type)
                })
                .catch(err => {
                    this.subscriber.vs_subscription[type] = this.oldSubscriberData.vs_subscription[type]

                    return this.$notify({
                        type: 'info',
                        title: 'Action',
                        message: 'Action has been cancelled.'
                    })
                })
        },
        async handleSubscriptionUpdateSubmit(type) {
            this.loading = true
            const url = `api/company/${this.company.id}/crm-dms-integrations/vs-dealer/integrations/connect-event-service`
            await this.axios.put(
                url,
                this.subscriber.vs_subscription
            )
                .then(res => {
                    this.handleGetSubscription()
                    this.resetSubscriber()
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    return this.$root.handleErrors(err.response)
                })
        },
        resetSubscriber() {
            this.subscriber = {
                vs_subscription: {
                    subscriptions: [],
                    status: 'inactive'
                }
            }
        }
    },
    watch: {
        'subscriber' : {
            immediate: true,
            handler: function(n) {
                if(n && n.hasOwnProperty('id')) {
                    this.oldSubscriberData = JSON.parse(JSON.stringify(n))

                    if(n.hasOwnProperty('vs_subscription') && n.vs_subscription.hasOwnProperty('status')) {
                        if(n.vs_subscription.status === 'active') {
                            this.radioButtonColor = '#67C23A'
                        } else {
                            this.radioButtonColor = '#ff4949'
                        }
                    }
                }
            }
        }
    }
}
</script>
