<template>
    <div>
        <el-form v-if="!loading">
            <el-form-item label="Status: ">
                <el-switch
                    v-model="integration.status"
                    :active-text="integration.status ? 'Active' : 'Inactive'"
                    :active-value="1"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                ></el-switch>
            </el-form-item>
            <el-form-item label="Site ID">
                <el-input
                    v-model="integration.properties.site_id"
                    placeholder="Site ID"
                    style="width: 15rem;"
                ></el-input>
            </el-form-item>
            <el-form-item
                v-if="integration.hasOwnProperty('id')"
                label="Send WorkNote: "
            >
                <el-switch
                    v-model="integration.properties.to_send_work_note"
                    :active-text="integration.to_send_work_note ? 'Yes' : 'No'"
                    :active-value="1"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                ></el-switch>
            </el-form-item>
            <el-form-item
                v-if="integration.hasOwnProperty('id')"
                label="Send Appointment: "
            >
                <el-switch
                    v-model="integration.properties.to_send_appointment"
                    :active-text="integration.to_send_appointment ? 'Yes' : 'No'"
                    :active-value="1"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                ></el-switch>
            </el-form-item>
            <el-form-item
                v-if="integration.hasOwnProperty('id')"
                label="New Contact to Sequence"
            >
                <el-select
                    v-if="integration.hasOwnProperty('id')"
                    v-model="integration.properties.new_contact_to_sequence_id"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(sequence, key) in integration.dealer_sequences"
                        :key="key"
                        :label="`${sequence.name} #${sequence.id}`"
                        :value="sequence.id"
                    ></el-option>
                </el-select>
            </el-form-item>
<!--            <el-form-item label="Sold Contact Disposition">-->
<!--                <el-select-->
<!--                    v-model="integration.properties.sold_contact_disposition_id"-->
<!--                    filterable-->
<!--                    clearable-->
<!--                >-->
<!--                    <el-option-->
<!--                        v-for="(disposition, key) in integration.contact_dispositions"-->
<!--                        :key="key"-->
<!--                        :label="`${disposition.name} #${disposition.id}`"-->
<!--                        :value="disposition.id"-->
<!--                    ></el-option>-->
<!--                </el-select>-->
<!--            </el-form-item>-->
            <el-form-item
                v-if="integration.hasOwnProperty('id')"
            >
                <div style="display: flex; justify-content: space-between; width: 30vw;">
                    <div>
                        <strong>Metadata</strong>
                    </div>
                    <div>
                        <el-button
                            type="success"
                            icon="el-icon-plus"
                            circle
                            size="mini"
                            @click="addMetadata"
                        ></el-button>
                    </div>
                </div>
                <ul>
                    <li
                        v-for="(datum, key) in integration.properties.metadata"
                        style="list-style: none;"
                    >
                        <div
                            v-if="typeof datum === 'object'"
                        >
                            <div
                                style="display: flex; justify-content: space-around; width: 30vw; padding: .25rem;"
                            >
                                <el-input
                                    v-model="integration.properties.metadata[key].data_key"
                                    v-if="integration.properties.metadata[key].hasOwnProperty('data_key')"
                                    :key="key+2"
                                    placeholder="Key"
                                ></el-input>
                                <el-input
                                    v-model="integration.properties.metadata[key].data_value"
                                    :key="key+3"
                                    placeholder="Value"
                                ></el-input>
                                <div style="margin-left: .25rem;">
                                    <el-button
                                        type="danger"
                                        icon="el-icon-minus"
                                        circle
                                        size="mini"
                                        @click="integration.properties.metadata.splice(key, 1)"
                                    ></el-button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </el-form-item>
            <el-row style="text-align: left; margin-top: 10px">
                <el-button type="primary" @click="handleSubmit">Submit</el-button>
            </el-row>
        </el-form>
    </div>
</template>
<script>
export default {
    name: 'Integrate',
    props: {
        integrationData: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            loading: false,
            integration: {
                status: false,
                properties: {
                    site_id: null,
                },
            }
        }
    },
    mounted() {
        if(this.integrationData.hasOwnProperty('id')) {
            this.integration = this.integrationData
        }
    },
    methods: {
        handleSubmit() {
            return this.$emit('integration-submit', this.integration)
        },
        addMetadata() {
            if(!this.integration.properties.hasOwnProperty('metadata')) {
                this.integration.properties = {
                    ...this.integration.properties,
                    metadata: [],
                }
            }

            const default_data_key = this.integration.properties.metadata.length > 0 ? '' : 'FranchiseName || DsDealerId'
            this.integration.properties.metadata.push({
                data_key: default_data_key,
                data_value: ''
            })
        }
    },
}
</script>
