<template>
    <div style="width: 99%;padding-left: 5px;">
        <div v-if="company.dms_campaign_enabled == '1' || isCDKIntegrated">
            <PageHeader :company="company" label="SIMPSOCIAL ROI REPORT FOR" :darkMode="false"/>
            <el-row>
                <el-col :span="24">
                    <DmsSales :selectedCompany="company"/>
                </el-col>
            </el-row>
        </div>
        <div v-else>
            <RestrictedScreen />
        </div>
    </div>
</template>
<style>

</style>
<script>
import store from "../store";
import DmsSales from './dms/DmsSales';
import PageHeader from './widgets/PageHeader';
import RestrictedScreen from './widgets/RestrictedScreen';

export default {
    components: {
        DmsSales,
        PageHeader,
        RestrictedScreen
    },
    data() {
        return {
            errors: {},
            isProgress: false,
            company: {},
            isCDKIntegrated: false,
        };
    },
    mounted() {
        this.company = JSON.parse(store.state.currentCompany)
        this.getLatestCompanyData()
    },
    methods: {
        getLatestCompanyData() {
            this.axios
            .get('/api/company/' + this.company.aloware_id)
            .then(response => {
                if (response.data.success == true) {
                    this.company = response.data.data;
                    this.isCDKIntegrated = response.data.data.cdk_integration !== null ? response.data.data.cdk_integration.status == 1 : false
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response)
            });
        },
    },
    watch: {
    }
};
</script>
