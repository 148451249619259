<template>
    <div>
        <div style="display: flex; justify-content: end;">
            <el-button
                icon="el-icon-download"
                size="mini"
                type="primary"
                @click.prevent="handleDownloadDefaultHeader"
            >
                DEFAULT HEADER FILE
            </el-button>
        </div>

        <hr>

        <el-upload
            ref="sales-data-upload"
            :before-upload="handleChange"
            :show-file-list="false"
            :file-list="fileList"
            :http-request="handleSubmit"
            :action="actionURL"
            :on-error="handleError"
            size="10"
        >
            <el-button type="info">Select the CSV file to upload</el-button>
            <ul style="" class="el-upload__tip" slot="tip">
                <li>
                    <div class="el-upload__tip" slot="tip">CSV file with a size less than 2000 kilobytes.</div>
                </li>
                <li>
                    <div class="el-upload__tip" slot="tip">CSV file headers should be using our <a href="#" @click.prevent="handleDownloadDefaultHeader">default headers.</a></div>
                </li>
            </ul>
        </el-upload>
    </div>

</template>
<script>

export default {
    name: 'Import',
    data() {
        return {
            company: {},
            fileList: [],
        }
    },
    mounted() {
        this.setupComponent()
    },
    methods: {
        setupComponent() {
            this.company = JSON.parse(this.$store.state.currentCompany)
        },
        handleChange(file) {
            try {
                const isNotCSV = file.type !== 'text/csv'
                const isBadSize = (file.size / 1000000) > 2

                if (isNotCSV || isBadSize) {
                    this.$notify({
                        type: 'error',
                        title: 'Error',
                        message: 'The selected file is invalid.'
                    })
                    this.$refs["sales-data-upload"].clearFiles()
                    this.$refs["sales-data-upload"].abort
                    return false
                }
            } catch (e) {
                console.log('e?', e, file)
                this.$refs["sales-data-upload"].abort
                this.$refs["sales-data-upload"].clearFiles()
                this.$notify({
                    type: 'error',
                    title: 'Error',
                    message: 'The selected file is invalid. '
                })
                return false
            }

        },
        async handleSubmit({file}) {
            let formData = new FormData
            formData.append('sales_data', file)

            await this.axios.post(
                this.actionURL,
                formData
            )
                .then(res => {
                    this.$emit('upload-success')
                    return this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: "Sales data has been uploaded and ready for import."
                    });
                })
                .catch(err => {
                    this.$refs["sales-data-upload"].abort
                    this.$refs["sales-data-upload"].clearFiles()
                    return this.$root.handleErrors(err.response)
                })
        },
        handleError(err, file, fileList) {
            console.log('handle err',err, file, fileList)
        },
        async handleDownloadDefaultHeader(){
            return window.open('https://simpsocial.s3.us-east-2.amazonaws.com/assets/sold-report/Sold_Data_Default_Headers.csv')
        },
    },
    computed: {
        actionURL() {
            return `api/company/${this.company.id}/sales-report/upload`
        }
    }
}
</script>
