<template>
    <div class="mail-template-selector">
        <el-row type="flex" justify="space-between">
            <el-col>
                <el-form @submit.prevent="false">
                    <el-input
                        v-model="search"
                        clearable
                        size="small"
                        placeholder="Search"
                        suffix-icon="el-icon-search"
                    ></el-input>
                </el-form>
            </el-col>
        </el-row>
        <el-row>
            <el-col>
                <div style="height: 400px;overflow-x: auto">
                    <el-table
                        :data="currentTemplates.filter(data => !search || data.subject.toLowerCase().includes(search.toLowerCase()))"
                        stripe
                        style="width: 100%"
                        >
                        <el-table-column label="Subject" prop="subject">
                            <template slot-scope="scope">
                                <span>{{ scope.row.subject }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Action" prop="id" width="90">
                            <template slot-scope="scope">
                                <el-button size="mini" @click="handleSelectTemplate(scope.row)">Use</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<style>

</style>
<script>
export default {
    name: 'EmailTemplatesTable',
    props: ['type', 'templates'],
    components: {

    },
    data() {
        return {
            search: '',
            currentTemplates: [],
        };
    },
    computed: {

    },
    mounted() {
    },
    methods: {
        handleSelectTemplate(template) {
            this.$emit('onTemplateSelect', template)
        }
    },
    watch: {
        templates: function(val, oldVal) {
            this.currentTemplates = val
        }
    },
};
</script>
