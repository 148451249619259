<template>
    <el-main>
        <el-form v-if="!loading">
            <el-form-item label="Status: ">
                <el-switch
                    v-model="integration.status"
                    :active-text="integration.status ? 'Active' : 'Inactive'"
                    :active-value="1"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                ></el-switch>
            </el-form-item>
            <el-form-item label="ProMax Dealer ID">
                <el-input
                    v-model="integration.properties.dealer_ids"
                    placeholder="ProMax Dealer ID"
                    style="width: 15rem;"
                ></el-input>
                <br>
                <small>For multiple ID's, please separate by comma [1,2,3]</small>
            </el-form-item>
            <el-row style="text-align: left; margin-top: 10px">
                <el-button type="primary" @click="handleSubmit">Submit</el-button>
            </el-row>
        </el-form>
    </el-main>
</template>
<script>
export default {
    name: 'ProMax',
    data() {
        return {
            loading: true,
            integration: {},
            platform: 'promax',
        }
    },
    mounted() {
        this.setupComponent()
    },
    methods: {
        setupComponent() {
            this.company = JSON.parse(this.$store.state.currentCompany)
            this.handleGet()
        },
        async handleGet() {
            this.loading = true
            await this.axios.get(`/api/company/${this.company.id}/integration/${this.platform}`)
                .then(res => {
                    this.integration = res.data
                    if(
                        !this.integration.properties ||
                        (this.integration.properties && !this.integration.properties.hasOwnProperty('dealer_ids'))
                    ) {
                        this.integration = {
                            ...this.integration,
                            properties: {
                                ...this.integration.properties,
                                dealer_ids: ''
                            }
                        }
                    }
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    return this.$root.handleErrors(err.response)
                })
        },
        async handleSubmit() {

            await this.axios.post(
                `/api/company/${this.company.id}/integration/${this.platform}`,
                this.integration
            )
                .then(res => {
                    this.$notify({
                        type: 'success',
                        message: 'Success'
                    })
                    return this.handleGet()
                })
                .catch(err => {
                    return this.$root.handleErrors(err.response)
                })
        },
    }
}
</script>
