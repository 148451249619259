<template>
    <el-main style="padding: 0">
        <el-tabs
            v-model="activeTab"
            @tab-click="handleTabClick">
            <el-tab-pane label="Homepage" name="homepage">
                <webchat-homepage-settings></webchat-homepage-settings>
            </el-tab-pane>
            <el-tab-pane label="SRP" name="srp">
                <webchat-srp-settings></webchat-srp-settings>
            </el-tab-pane>
            <el-tab-pane label="VDP" name="vdp">
                <webchat-vdp-settings></webchat-vdp-settings>
            </el-tab-pane>
        </el-tabs>
    </el-main>
</template>
<script>
import WebchatHomepageSettings from './WebchatHomepageSettings'
import WebchatSrpSettings from './WebchatSRPSettings'
import WebchatVdpSettings from './WebchatVDPSettings'

export default {
    name: 'Webchat-Integration',
    components: {
        WebchatHomepageSettings,
        WebchatSrpSettings,
        WebchatVdpSettings,
    },
    data() {
        return {
            activeTab: 'homepage',
            loading: false,
        }
    },
    mounted() {
    },
    methods: {
        handleTabClick(tab) {
            this.activeTab = tab.name
        },
    },
    watch: { },
}
</script>
