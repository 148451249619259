<template>
    <el-dialog
        title="Send a car"
        :visible.sync="dialogVisible"
        width="36rem"
        :fullscreen="currentScreenWidth <= 765"
        top="2rem"
        center
    >

        <div style="margin-top: 15px;">
            <el-input
                v-model="filter.q"
                placeholder="Search for model, make, vin, stock #"
                prefix-icon="el-icon-search"
                @change="handleSubmit"
            >
                <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="handleSubmit"
                ></el-button>
            </el-input>
        </div>

        <div style="display: block;">
            <el-slider
                v-model="filter.prices"
                range
                :max="100000"
                :step="1000"
                show-tooltip
                :format-tooltip="formatCurrency"
                :marks="{25000: '$25,000', 75000: '$75,000'}"
                @change="handleSubmit"
            >
            </el-slider>
        </div>

        <el-main
            style="margin-top: 2rem; max-height: 80vh;"
        >
            <el-card
                v-if="Array.isArray(cars) && cars.length > 0"
                v-for="car in cars"
                :key="car.id"
                style="margin-top: 1rem;"
            >
                <div slot="header">
                    <el-switch
                        v-model="selectedCar.hide_price"
                        :key="car.id"
                        active-text="Hide price?"
                    ></el-switch>
                    <el-button
                        type="primary"
                        style="float: right; padding: 3px 0; width: 30%;"
                        @click="handleSendLink(car)"
                    >SEND LINK</el-button>
                </div>
                <div>
                    <img
                        v-if="Array.isArray(car.galleries) && car.galleries.length > 0"
                        :src="car.galleries[0].url"
                        loading="lazy"
                        style="width: 100%; object-fit: scale-down;"
                    >
                </div>

                <h3>VIN#: {{car.vin}}</h3>
                <p><strong>Text to Customer:</strong> <small>{{car.description}}</small></p>

                <p><strong>Year:</strong> {{car.year}}</p>
                <p><strong>Make:</strong> {{car.make}}</p>
                <p><strong>Model:</strong> {{car.model}}</p>
                <p><strong>Color:</strong> {{car.colour}}</p>
                <p><strong>Mileage:</strong> {{formatNumbers(car.odometer)}}</p>
                <p><strong>Price:</strong> {{formatCurrency(car.price)}}</p>
            </el-card>
            <el-empty
                v-if="(Array.isArray(cars) && cars.length === 0) || !cars"
                :description="`No result ${filter.q ? `for ${filter.q}` : ''}`"
            ></el-empty>
        </el-main>
    </el-dialog>
</template>
<script>
export default {
    name: 'EngageInventory',
    data() {
        return {
            dialogVisible: true,
            filter: {
                prices: [0,100000],
                q: '',
            },
            selectedCar: {
                hide_price: false,
            },
        }
    },
    mounted() {
    },
    methods: {
        formatCurrency(value) {
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            });

            return formatter.format(value)
        },
        formatNumbers(value) {
            const formatter = new Intl.NumberFormat('en-US')
            return formatter.format(value)
        },
        handleSubmit() {
            return this.$emit('submit', this.filter)
        },
        handleSendLink(car) {
            const selectedCar = {
                hide_price: this.selectedCar.hide_price,
                ...car
            }
            return this.$emit('send-link', selectedCar)
        },
    },
    computed: {
        currentScreenWidth() {
            return this.$store.getters.getScreenWidth
        },
        cars() {
            let cars = this.$store.getters.getInventoryData.data ?? []
            this.selectedCar = {
                hide_price: false,
            }
            if(Array.isArray(cars) && cars.length > 0) {
                cars = cars.map(c => {
                    return c
                })
            }
            return cars
        }
    },
    watch: {
        'dialogVisible': {
            handler: function (n, o) {
                if(o && !n) {
                    this.$emit('show-inventory', false)
                }
            }
        },
    },

}
</script>
