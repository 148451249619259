<template>
    <el-form
        ref="inputPhone"
        v-bind:model="inputPhone"
        :rules="rules"
    >
        <el-form-item prop="phone">
            <el-input
                v-model="inputPhone.phone"
                id="phoneInput"
                type="tel"
            ></el-input>
        </el-form-item>
    </el-form>
</template>

<script>
import intTelInput from 'intl-tel-input'
import {iUtils} from 'intl-tel-input/build/js/utils'

export default {
    name: "PhoneInput",

    props: {
        phoneValue: {
            type: String,
            default: ''
        },
        errorMsg: {
            type: String,
            default: 'Invalid phone number'
        }
    },

    data() {

        const validatePhoneInput = (rule, value, callback) => {
            if ((value !== '' && !this.iti.isValidNumber()) && (this.iti.getNumber() === value)) {
                callback(new Error(this.errorMsg))
                return
            }
            if(this.iti && this.iti.isValidNumber()) {
                return this.$emit('success', this.iti.getNumber())
            }
        };

        return {
            telInputSettings: {
                dense: false,
                outlined: true,
                mode: "international",
                validCharactersOnly: true,
                telInputOption: {
                    showDialCode: true,
                },
                readonly: false,
                initialCountry: 'us',
                preferredCountries: ['us'],
                separateDialCode: false,
                value: ''
            },
            rules: {
                phone: [
                    {validator: validatePhoneInput, trigger: 'change'},
                ]
            },
            iti: null,
            inputPhone: {
                phone: ''
            },
        }
    },

    watch: {
        'inputPhone.phone': {
            immediate: false,
            handler: function(n) {
                this.telInputSettings.value = n
            }
        },
        'phoneValue': {
            immediate: true,
            handler: function(n) {
                this.inputPhone.phone = n
            }
        },

    },

    mounted() {
        this.setupPhoneInput()
    },

    methods: {
        setupPhoneInput() {
            this.iti = intTelInput(window.document.getElementById('phoneInput'), this.telInputSettings)
        },

    },

    beforeDestroy() {
        this.telInputSettings.value = ''
        this.inputPhone.phone = ''
    }
}
</script>

<style scoped>
@import "~intl-tel-input/build/css/intlTelInput.css";
</style>
