<template>
    <el-main>
        <div
            v-loading="loading"
            v-if="loading"
        >
        </div>
        <div
            v-if="!loading"
        >
            <table>
                <tr>
                    <td>VinSolutions Dealer Name</td>
                    <td><strong>{{ vin_solutions_dealer.Name }}</strong></td>
                </tr>
                <tr>
                    <td>VinSolutions Dealer ID</td>
                    <td><strong>{{ vin_solutions_dealer.DealerId }}</strong></td>
                </tr>
                <tr>
                    <td>VinSolutions User Name</td>
                    <td><strong>{{ vin_solutions_user.FullName }}</strong></td>
                </tr>
                <tr>
                    <td>VinSolutions User ID</td>
                    <td><strong>{{ vin_solutions_user.UserId }}</strong></td>
                </tr>
                <tr>
                    <td>VinSolutions User Role</td>
                    <td><strong>{{ vin_solutions_user.IlmAccess }}</strong></td>
                </tr>
            </table>
        </div>
        <div
            v-if="!loading"
            style="margin-top: 3rem;"
        >
            <features-table
                v-if="company.vin_solutions"
                :integration="company.vin_solutions"
                :sequences="dealer_sequences"
                @update-integration="updateIntegration"
            />
        </div>
    </el-main>
</template>
<script>
import FeaturesTable from "./FeaturesTable.vue";

export default {
    name: 'Information',
    components: {FeaturesTable},
    data() {
        return {
            company: {},
            vin_solutions_dealer: {},
            vin_solutions_user: {},
            dealer_sequences: [],
            loading: false,
        }
    },
    mounted() {
        this.company = JSON.parse(this.$store.state.currentCompany)
        this.getIntegrationInfo()
    },
    methods: {
        async getIntegrationInfo() {
            this.loading = true
            await this.axios.get(`api/company/${this.company.id}/crm-dms-integrations/vs-dealer/integration-info`)
                .then(res => {
                    this.vin_solutions_dealer = res.data.data.vs_dealer
                    this.vin_solutions_user = res.data.data.vs_user
                    this.dealer_sequences = res.data.data.sequences
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    return this.$root.handleErrors(err.response)
                })
        },
        async updateIntegration(data) {
            this.loading = true
            await this.axios.put(`api/company/${this.company.id}/crm-dms-integrations/vs-dealer/integration-info-update`, data)
                .then(res => {
                    this.company = res.data.data.company
                    this.$store.commit('UpdateCurrentCompany', this.company)
                    this.$notify({
                        type: 'success',
                        message: "Integration has been updated."
                    })
                    return this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    return this.$root.handleErrors(err.response)
                })
        }
    }
}

</script>
