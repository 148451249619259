import Image from '@tiptap/extension-image'
import { mergeAttributes } from '@tiptap/core'

export default Image.extend({
    name: 'custom-image',

    addAttributes() {
        return {
            ...Image.config.addAttributes(),
            size: {
                default: '50',
                rendered: false
            },
            align: {
                default: 'left',
                rendered: false
            }
        }
    },

    addCommands() {
        return {
            setImage: (options) => ({ commands }) => {
                return commands.insertContent({
                    type: this.name,
                    attrs: options
                })
            },
            setSize: (options) => ({ tr, commands }) => {
                return commands.insertContent({
                    type: this.name,
                    attrs: {
                        ...tr.selection.node.attrs,
                        ...options
                    }
                })
            },
            setAttributes: (attributes) => ({ tr, dispatch }) => {
                // Check it's a valid size option
                /*if (!this.options.sizes.includes(attributes.size)) {
                    return false
                }*/
                const { selection } = tr
                const options = {
                    ...selection.node.attrs,
                    ...attributes
                }
                const node = this.type.create(options)

                if (dispatch) {
                    tr.replaceRangeWith(selection.from, selection.to, node)
                }
            }
        }
    },

    renderHTML({ node }) {
        const size = node.attrs.size
        const align = node.attrs.align

        let alignmntStyle = 'display:block;'
        if(align == 'left') {
            alignmntStyle += 'margin-left:0;margin-right:auto'
        } else if(align == 'right') {
            alignmntStyle += 'margin-left:auto;margin-right:0'
        } else {
            alignmntStyle += 'margin-left:auto;margin-right:auto'
        }
        return [
            "img",
            {
                src: node.attrs.src,
                alt: 'img',
                style: 'width: ' + size + '%;' + alignmntStyle
            },
        ];
    }
})