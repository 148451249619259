<template>
    <div class="search-contact">
        <el-row :gutter="20">
            <el-col :span="24">
                <el-date-picker
                    v-model="daterange"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="-"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    format="MM-dd-yyyy"
                    @change="handleDaterangeChange"
                    :clear="handleDaterangeChange"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="24">
                <el-card :body-style="{ padding: '0px', textAlign: 'center' }" style="display: inline-block;margin-top: 10px">
                    <div style="padding: 14px;">
                        <h3 v-if="loadingContacts">...</h3>
                        <h3 v-else>{{contactsCount}}</h3>
                        <div class="bottom clearfix" style="border-top: 1px solid #d7caca;padding-top: 10px;">
                            {{parseInt(contactsCount) | pluralize('Contact', 'Contacts')}}
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import store from "../../../store";

export default {
    data() {
        return {
            tags: [],
            daterange: '',
            selectedContacts: [],
            loadingContacts: false,
            contactsCount: 0,
            pickerOptions: {
                shortcuts: [{
                    text: 'Last week',
                    onClick(picker) {
                        const now = new Date();
                        //previous sat
                        const end = new Date(new Date().setDate(now.getDate() - (now.getDay() == 0 ? 7 : now.getDay() + 1)));
                        //last sunday before sat
                        const start = new Date(end.getTime() - (6 * 24 * 60 * 60 * 1000));

                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last Month',
                    onClick(picker) {
                        const now = new Date();
                        const start = new Date(now.getFullYear(),now.getMonth() - 1,1);
                        const end = new Date(now.getFullYear(),now.getMonth(),0);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'This Month',
                    onClick(picker) {
                        const date = new Date();
                        const start = new Date(date.getFullYear(), date.getMonth(), 1);
                        const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last 3 months',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
        };
    },
    computed: {
    },
    mounted() {
        this.company = JSON.parse(store.state.currentCompany)
        this.user = JSON.parse(store.state.currentUser)
        this.setDefaultDaterange()
        this.getContactsCount()
    },
    methods: {
        getContactsCount() {
            this.loadingContacts = true
            let params = {
                start_date: this.daterange !== null ? this.$options.filters.toSqlDate(this.daterange[0]) : '',
                end_date: this.daterange !== null ? this.$options.filters.toSqlDate(this.daterange[1]) : '',
            }
            this.axios
            .get('/api/company/' + this.company.aloware_id + '/aloware-contacts-count', {params: params})
            .then(response => {
                if (response.data.success == true) {
                    this.contactsCount = response.data.data
                    this.$emit('onSelectContacts', [`{daterange:${params.start_date},${params.end_date}}`])
                    this.loadingContacts = false
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        setDefaultDaterange() {
            // set default range: last 3 months
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);

            this.daterange = [start, end];
        },
        handleDaterangeChange() {
            this.getContactsCount()
        },
        handleSubmit() {
            this.$emit('onSelectContacts', this.selectedContacts)
        }
    }
};
</script>
