class Dictionary {
    constructor() {
    }

    carMakes() {
        return {
            'Mercedes Benz' : [
                'Mercedes-Benz',
                'ME/BE',
                'MBENZ',
                'ME-BENZ',
                'MEBE/BE',
                'MER/BE',
                'MERCEDES',
                'M-ENZ',
                'M-BENZ',
                'MERCEDES-BENZ'
            ],
            'Land Rover' : [
                'LA/RO',
                'LAND R',
            ]
        }
    }
}
export default Dictionary
