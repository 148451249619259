import Vue from 'vue';
import Vuex from 'vuex';
import moment from "moment";
// import VueEcho from 'vue-echo-laravel';
//
// window.io = require('socket.io-client');
//
// Vue.use(VueEcho, {
//     broadcaster: 'socket.io',
//     host: window.location.hostname + ':6001',
// });

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
        screenWidth: 0,
		isLoggedIn: !!localStorage.getItem('token'),
		token: localStorage.getItem('token'),
		currentUser: localStorage.getItem('currentUser'),
		isSingleSignedIn: localStorage.getItem('isSingleSignedIn') == 'true',
		currentCompany: localStorage.getItem('currentCompany'),
		emailVariables: {},
        tiktokAdGroupComments: [],
        salesReportsProgress: [],
        engage: {
            convos: {
                data: [],
                has_more_pages: false
            },
            resetInput: false
        },
        inventoryData: [],
        contact: {},
        fbBusinessUser: {},
        fbBusinessMessengerUser: {},
        fbBusinessMessengerMe: {},
        fbBusinessMe: {},
	},
	mutations: {
        UpdateScreenWidth(state, width) {
            state.screenWidth = width;
        },
		LoginUser(state, data) {
			state.isLoggedIn = true;
			let token = data.access_token;
			state.token = token;
			state.currentUser = JSON.stringify(data.user);
			state.isSingleSignedIn = data.isSingleSignedIn == 'true';

			localStorage.setItem('token', token);
			localStorage.setItem('token_exp', data.token_exp);
			localStorage.setItem('currentUser', JSON.stringify(data.user));
			localStorage.setItem('isSingleSignedIn', data.isSingleSignedIn);
		},
		LogoutUser(state) {
			state.isLoggedIn = false;
			state.token = localStorage.removeItem('token');
			state.currentUser = localStorage.removeItem('currentUser');
			state.currentCompany = localStorage.removeItem('currentCompany');
		},
		tokenStored(state) {
			state.token = localStorage.getItem('token');
		},
		UpdateCurrentCompany(state, data) {
			state.currentCompany = JSON.stringify(data);
			localStorage.setItem('currentCompany', JSON.stringify(data));
		},
		UpdateCurrentUser(state, data) {
			state.currentUser = JSON.stringify(data);
			localStorage.setItem('currentUser', JSON.stringify(data));
		},
		UpdateEmailVariables(state, data) {
			state.emailVariables = data
		},
        UpdateTiktokAdGroupComments(state, comments) {
            state.tiktokAdGroupComments = comments
        },
        UpdateSalesReportsProgress(state, data)
        {
            const ind = state.salesReportsProgress.findIndex(sData => {
                return sData.id === data.id
            })
            ind > -1 ? state.salesReportsProgress[ind] = data : state.salesReportsProgress.push(data)

            state.salesReportsProgress = state.salesReportsProgress.map((progress) => {
                const start = moment(progress.at)
                const end = moment()
                const duration = moment.duration(end.diff(start))

                if (duration.asHours() < 2) return progress
            })

            state.salesReportsProgress = state.salesReportsProgress.filter(e => e !== undefined)
            localStorage.setItem('salesReportsProgress', JSON.stringify(state.salesReportsProgress))
        },
        UpdateEngageConvos(state, data) {
            if(Array.isArray(data.convo.data)) {
                const reversedData = data.convo.data.reverse()
                if(data.recent) {
                    state.engage.convos.data = []
                }

                reversedData.forEach(convo => {
                    state.engage.convos.data.unshift(convo)
                })
            }
            state.engage.convos.has_more_pages = data.convo.has_more_pages ?? false
        },
        UpdateEngageInput(state, data) {
            state.engage.resetInput = data
        },
        UpdateInventoryData(state, data) {
            state.inventoryData = data
        },
        UpdateContactData(state, data) {
            state.contact = data
        },
        UpdateFbBusinessUser(state, data) {
            state.fbBusinessUser = data
        },
        UpdateFbBusinessMessengerUser(state, data) {
            state.fbBusinessMessengerUser = data
        },
        UpdateFbBusinessMessengerMe(state, data) {
            state.fbBusinessMessengerMe = data
        },
        UpdateFbBusinessMe(state, data) {
            state.fbBusinessMe = data
        },
	},
	getters: {
		getEmailVariables(state) {
			return state.emailVariables
		},
        getTiktokAdGroupComments: (state) => (ad_id) => {
            return state.tiktokAdGroupComments.filter(comment => comment.ad_id === ad_id)
        },
        getSalesReportsProgress(state) {
            state.salesReportsProgress = localStorage.getItem('salesReportsProgress') ? JSON.parse(localStorage.getItem('salesReportsProgress')) : []
            return state.salesReportsProgress
        },
        getEngageConvos:(state) => () => {
            return state.engage.convos
        },
        getEngageInputStatus(state) {
            return state.engage.resetInput
        },
        getScreenWidth(state) {
            return state.screenWidth
        },
        getInventoryData(state) {
            return state.inventoryData
        },
        getFbBusinessUser(state) {
            return state.fbBusinessUser
        },
        getFbBusinessMessengerUser(state) {
            return state.fbBusinessMessengerUser
        },
        getFbBusinessMessengerMe(state) {
            return state.fbBusinessMessengerMe
        },
        getFbBusinessMe(state) {
            return state.fbBusinessMe
        },
	}
});
