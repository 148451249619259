<template>
    <el-main>
        <div style="text-align: right">
            <el-button
                type="primary"
                icon="el-icon-refresh"
                circle
                size="small"
                :disabled="loading"
                @click="getHttpLogs"
            ></el-button>
        </div>

        <el-table
            v-loading="loading"
            :data="http_logs"
        >
            <el-table-column
                type="index"
            >
            </el-table-column>
            <el-table-column
                label="Request ID"
                prop="id"
            >
            </el-table-column>
            <el-table-column
                label="HTTP Response Code"
                prop="response_status_code"
            >
            </el-table-column>
            <el-table-column
                label="Requested At"
                prop="created_at"
            >
            </el-table-column>
            <el-table-column type="expand">
                <template v-slot="props">
                    <pre
                        v-for="(data, i) in props.row?.response_body_content"
                        :key="i"
                        v-if="data"
                        style="text-align: start; background: #0f162a; color: #fff; border-radius: 0.25rem; padding: 0.75rem;"
                    >{{ data }}</pre>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-size="+page_meta.per_page"
            layout="sizes, prev, pager, next, total"
            :total="page_meta.total"
            :current-page.sync="page_meta.current_page"
        >
        </el-pagination>
    </el-main>
</template>
<script>
export default {
    name: 'HttpLog',
    props: {
        model_type: {
            required: true,
            type: String,
        },
        model_id: {
            required: true,
            type: Number,
        }
    },
    data() {
        return {
            loading: false,
            company: {},
            http_logs: [],
            page_meta: {},
            page_links: {},
            pagination_params: [],
        }
    },
    mounted() {
        this.company = JSON.parse(this.$store.state.currentCompany)
        this.getHttpLogs()
    },
    methods: {
        async getHttpLogs() {
            this.loading = true
            this.http_logs = []
            const qs = this.$qs.stringify(this.pagination_params)

            await this.axios.get(`api/company/${this.company.id}/crm-dms-integrations/http_logs/${this.model_type}/${this.model_id}?${qs}`)
                .then(res => {
                    console.log('res?', res)
                    this.http_logs = res.data.data
                    this.page_meta = res.data.meta
                    this.page_links = res.data.links
                    this.loading = false
                })
                .catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading = false
                })
        },
        handleSizeChange(size) {
            this.pagination_params['per_page'] = size
            this.getHttpLogs()
        },
        handleCurrentChange(page) {
            this.pagination_params['page'] = page
            this.getHttpLogs()
        }
    }
}
</script>
