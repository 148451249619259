<template>
    <div>
        <el-form>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="Key">
                        <el-input v-model="currentMeta.key"></el-input>
                    </el-form-item>
                    <el-form-item label="Value">
                        <el-input v-model="currentMeta.value"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-button @click="$emit('closeDialog')">Close</el-button>
                </el-col>
                <el-col :span="12" style="text-align: right">
                    <el-button type="primary" @click="handleSubmit">Submit</el-button>
                </el-col>
            </el-row>
        </el-form>

    </div>
</template>

<script>

export default {
    props: ['current_company', 'current_meta'],
    data() {
        return {
            currentCompany: {},
            currentMeta: {},
        };
    },
    computed: {},
    mounted() {
        this.currentCompany = this.current_company
        this.currentMeta = this.current_meta
    },
    methods: {
        handleSubmit() {
            if (typeof this.currentMeta.id !== 'undefined' && this.currentMeta.id !== '') {
                this.axios
                    .put(`api/company/${this.currentCompany.aloware_id}/meta/${this.currentMeta.id}`, this.currentMeta)
                    .then(response => {
                        if (response.data.success == true) {
                            this.$notify({
                                title: "Success",
                                message: "Company meta has been successfully Updated",
                                type: "success"
                            });

                            this.$emit('onSuccessfulSubmit');
                        } else {
                            let errors = response.data.errors.message;
                            let message = "";
                            for (let error in errors) {
                                message = errors[error] + "\n";
                            }
                            this.$notify({
                                title: "Error",
                                message: message,
                                type: "error"
                            });
                        }
                    })
                    .catch(err => {
                        this.$root.handleErrors(err.response);
                    });
            } else {
                this.axios
                    .post(`api/company/${this.currentCompany.aloware_id}/meta`, this.currentMeta)
                    .then(response => {
                        if (response.data.success == true) {
                            this.$notify({
                                title: "Success",
                                message: "Company meta has been successfully Added",
                                type: "success"
                            });

                            this.$emit('onSuccessfulSubmit');
                        } else {
                            let errors = response.data.errors.message;
                            let message = "";
                            for (let error in errors) {
                                message = errors[error] + "\n";
                            }
                            this.$notify({
                                title: "Error",
                                message: message,
                                type: "error"
                            });
                        }
                    })
                    .catch(err => {
                        this.$root.handleErrors(err.response);
                    });
            }
        }
    },
     watch: {
        /*company_car_data_source: function(val, oldVal) {
            this.currentDatasource = this.company_car_data_source
        },*/
     }
};
</script>
