<template>
    <div class="form-setting">
        <el-form>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="Lead source">
                        <el-input v-model="localSetting.valArr.leadsource"></el-input>
                        <small>Can be partial lead source value</small>
                    </el-form-item>
                    <el-form-item label="Sequence">
                        <el-input v-model="localSetting.valArr.sequence"></el-input>
                        <small>Should be identical to the sequence name</small>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-row style="margin-top: 15px">
            <el-col :span="12">
                <el-button @click="$emit('closeDialog')">Cancel</el-button>
            </el-col>
            <el-col :span="12" style="text-align: right">
                <el-button type="primary" @click="handleSubmit">Submit</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>

export default {
    props: {
        setting : {
            type: Object,
            default(rawProps) {
                return {
                    id: 0,
                    name: '',
                    valArr: {},
                }
            }
        }
    },
    components: {
    },
    data() {
        return {
            localSetting: {
                id: 0,
                name: 'leadsource_to_sequence',
                val: {},
                valArr: {
                    leadsource: '',
                    sequence: '',
                },
            }
        };
    },
    computed: {},
    mounted() {
        if(this.setting.id > 0) {
            this.localSetting = { ...this.setting }
        } else {
            this.localSetting = {
                id: 0,
                name: 'leadsource_to_sequence',
                val: {},
                valArr: {
                    leadsource: '',
                    sequence: '',
                },
            }
        }
    },
    methods: {
        handleSubmit() {
            let url = ''
            let action = ''
            let putOrPost = ''

            this.localSetting.val = {}
            this.localSetting.val[this.localSetting.valArr.leadsource] = this.localSetting.valArr.sequence

            if(this.setting.id > 0) {
                url += '/api/settings/' + this.localSetting.id
                action = 'updated'
                putOrPost = 'put'
            } else {
                url += '/api/settings'
                action = 'added'
                putOrPost = 'post'
            }

            this.axios({
                    method: putOrPost,
                    url: url,
                    data: this.localSetting
                })
                .then(response => {
                    if (response.data.success == true) {
                        this.$notify({
                            title: "Success",
                            message: "Setting has been successfully " + action,
                            type: "success"
                        });
                        this.$emit('closeDialog')
                    } else {
                        let errors = response.data.errors.message;
                        let message = "";
                        for (let error in errors) {
                            message = errors[error] + "\n";
                        }
                        this.$notify({
                            title: "Error",
                            message: message,
                            type: "error"
                        });
                    }
                })
                .catch(err => {
                    this.$root.handleErrors(err.response);
                });
        },
    },
    watch: {
        setting: function(val, oldVal) {
            this.localSetting = { ...val }
        }
    }
};
</script>

<style>
    .form-company .el-form-item__label {
        width: 100%;
        text-align: left;
    }
</style>
