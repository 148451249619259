<template>
    <div
        v-loading="loading"
        :element-loading-text="loadingMsg"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        style="width: 100%"
    >
        <el-row
            type="flex"
            justify="space-between"
        >
            <div>
                <div
                    v-if="dealer.page_id"
                >
                    <strong>{{ dealer.name }}</strong>
                     -
                    <a
                        :href="`https://www.facebook.com/${dealer.page_id}`"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {{ dealer.page_id }}
                    </a>
                </div>
            </div>

            <el-form>
                <el-form-item label="Dealers">
                    <el-select
                        v-model="dealer"
                        :value="dealer"
                        placeholder="Select a dealer"
                        filterable
                        clearable
                        remote
                        :remote-method="setPages"
                        value-key="id"
                        @change="handleSelectedPage"
                        @clear="clearAllData"
                        :loading="loadingInput"
                    >
                        <el-option
                            v-for="item in dealers"
                            :key="item.id"
                            :label="`${item.name} - ${item.page_id}`"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row
            type="flex"
            justify="space-between"
        >
            <div
                v-if="dealer.page_id"
            >
                <el-select
                    v-model="filters.batch"
                    placeholder="Select Batch"
                    @change="handleBatchChange"
                >
                    <el-option
                        v-for="(item, i) in batches"
                        :key="i"
                        :label="formatBatchName(item)"
                        :value="item.batch"
                    >
                    </el-option>
                </el-select>

                <el-button
                    v-if="Array.isArray(filters.date_range) && filters.date_range.length"
                    @click="getDataByDateRange"
                    type="primary"
                >Get Data</el-button>
                <strong
                    v-if="showLeadCount"
                    style="margin-left: 1rem;"
                >Total Unique Leads Pulled: {{ pulledLeadsTotal }}</strong>
            </div>
        </el-row>
        <div
            v-if="showLeadCount"
            class="mt-8"
        >
            <el-time-select
                placeholder="Minimum push delay"
                v-model="filters.push_leads_delay_range.start"
                :picker-options="{
                  start: '01:00',
                  step: '01:00',
                  end: '71:00',
                }"
                :disabled="!pulledLeadsTotal"
            >
            </el-time-select>
            <el-time-select
                placeholder="Maximum push delay"
                v-model="filters.push_leads_delay_range.end"
                :picker-options="{
                    start: '01:00',
                    step: '01:00',
                    end: '72:00',
                    minTime: filters.push_leads_delay_range.start
                }"
                :disabled="!pulledLeadsTotal"
            >
            </el-time-select>
<!--            <el-button-->
<!--                type="success"-->
<!--                :disabled="!pulledLeadsTotal"-->
<!--                @click="handlePushBatchToCrm"-->
<!--            >-->
<!--                PUSH BATCH TO CRM-->
<!--            </el-button>-->
        </div>
        <p
            v-if="showLeadCount"
            style="font-size: xx-small;"
        >
            Push To CRM Delay Range
        </p>
        <el-row
            v-if="fbPageFormLeads.length"
            style="margin-top: 2rem;"
        >
            <el-col :span="24">
                <FbLeadgenSyncedLeadsTable
                    :fbPageFormLeads="fbPageFormLeads"
                    @current-change="clickedPage"
                    @push-batch="handlePushBatchToCrm"
                />
            </el-col>
        </el-row>
    </div>
</template>

<script>
import FbLeadgenSyncedLeadsTable from "./FbLeadgenSyncedLeadsTable";

export default {
    name: "FbLeadgenSynedLead",
    components: {
        FbLeadgenSyncedLeadsTable
    },
    props: {
        user: {
            type: Object,
            required: true
        },
        company: {
            type: Object,
            required: true
        },
        apiEndpoint: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: true,
            loadingInput: false,
            loadingMsg: 'Fetching data...',
            dealers: [],
            dealer: {
                id: null,
                name: null
            },
            filters: {
                fb_page_id: null,
                company_id: null,
                date_range: [],
                type: '',
                batch: '',
                push_leads_delay_range: {
                    start: '01:00',
                    end: '12:00'
                }
            },
            pickerOptions: {
                disabledDate: this.handleDisabledDate,
                onPick: this.handleOnPick,
                shortcuts: [{
                    text: 'Last week',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                    }, {
                        text: 'Last month',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                ]
            },
            leadCount: 0,
            showLeadCount: false,
            leads: [],
            leadsKey: 0,
            links: {},
            meta: {},
            leadActionForm: {
                downloadAs: '',
                processFBLead: false
            },
            storedFbPage: {},
            batches: [],
            selectedRequestCount: 0,
            fbPageFormLeads: [],
            pulledLeadsTotal: 0,
        }
    },
    mounted() {
        this.storedFbPage = JSON.parse(localStorage.getItem('selectedFbPage'))
        this.setPages(this.storedFbPage?.name)
    },
    methods: {
        async setPages(q = '') {
            this.loadingInput = true
            await this.axios.get(`${this.apiEndpoint}/subscribed-pages?q=${q}`)
                .then(res => {
                    this.dealers = res.data.data.dealers

                    if(this.storedFbPage && this.dealers.some(d => d.page_id === this.storedFbPage.page_id)) {
                        this.dealer = this.storedFbPage
                        this.dealer.page_id = this.storedFbPage.page_id
                    }
                    this.finishLoading()
                })
                .catch(err => {
                    this.finishLoading()
                    console.log('setPages - err', err)
                })
        },
        async handleSelectedPage() {
            if(this.dealer.page_id) {
                this.selectedRequestCount = this.selectedRequestCount+1
                this.clearAllData()
                this.startLoading('Fetching dealer...')
                await this.axios.get(`${this.apiEndpoint}/subscribed-pages-show?fb_page_id=${this.dealer.page_id}`)
                    .then(res => {
                        this.batches = res.data.syncedLeadsBatches
                        this.filters.fb_page_id = res.data.fb_page.page_id
                        this.filters.company_id = res.data.fb_page.company_id
                        this.finishLoading()
                    })
                    .catch(err => {
                        this.finishLoading()
                        console.log('handleSelectedPage - err?', err)
                    })
            }
        },
        async getDataByDateRange(page = 1) {
            if(Array.isArray(this.filters.date_range) && this.filters.date_range.length) {
                this.startLoading('Fetching leads...')
                const qs = this.$qs.stringify(this.filters, { allowDots: true })
                await this.axios.get(`${this.apiEndpoint}/subscribed-pages-leads-by-date?${qs}&page=${page}`)
                    .then(res => {
                        this.leadCount = res.data.meta.total
                        this.leads = res.data.data
                        this.meta = res.data.meta
                        this.links = res.data.links
                        this.showLeadCount = true
                        this.leadsKey++
                        this.finishLoading()
                    })
                    .catch(err => {
                        console.log(err)
                        this.finishLoading()
                        return this.$notify({
                            type: 'error',
                            message: 'Something went wrong, please refresh the page.'
                        })
                    })
            }
        },
        resetLeads(data) {
            if(data === null) {
                this.clearAllData()
            }
            this.leadCount = 0
        },
        clickedPage(data) {
            this.getDataByDateRange(data)
        },
        clearAllData() {
            this.showLeadCount = false
            this.count = 0
            this.leads = []
            this.filters.date_range = []
            this.filters.type = ''
            this.fbPageFormLeads = []
        },
        async handleExport(type) {
            const vTypes = ['EXCEL', 'PDF', 'to-crm']

            if(Array.isArray(this.filters.date_range) && this.filters.date_range.length && vTypes.find(vt => vt === type)) {
                this.startLoading('Processing the request...')
                this.filters.type = type
                const qs = this.$qs.stringify(this.filters, { allowDots: true })
                let responseType = type === 'to-crm' ? 'json' : 'arraybuffer'

                await this.axios({
                    url: `${this.apiEndpoint}/subscribed-pages-leads-by-date-export?${qs}`,
                    method: 'GET',
                    responseType
                })
                    .then(res => {
                        this.finishLoading()
                        if(type === 'to-crm') {
                            return this.$notify({
                                type: 'success',
                                title: 'Job has been queued.',
                                message: 'Resending leads to dealerships CRM.'
                            })
                        }

                        const fileURL = window.URL.createObjectURL(new Blob([res.data]))
                        const fileLink = document.createElement('a')
                        fileLink.href = fileURL
                        fileLink.setAttribute('download', res.headers.filename)
                        fileLink.target = '_blank'
                        document.body.appendChild(fileLink)
                        return fileLink.click()
                    })
                    .catch(err => {
                        console.log(err)
                        this.finishLoading()
                        return this.$notify({
                            type: 'error',
                            message: 'Something went wrong, please refresh the page.'
                        })
                    })
            }
        },
        startLoading(msg = '') {
            this.loading = true
            if(msg) {
                this.loadingMsg = msg
            }
        },
        finishLoading() {
            this.loading = false
            this.loadingMsg = 'Fetching data...'
            this.loadingInput = false
        },
        handleDisabledDate(data) {
            return data < Date.parse('09 Aug 2022 00:00:00 GMT')
        },
        handleOnPick(maxDate, minDate) {
            return minDate < Date.parse('09 Aug 2022 00:00:00 GMT')
        },
        formatBatchName(obj) {
            const createdAt = obj.created_at.replace('T', ' ')
            const tDisplay = this.moment.parseZone(createdAt, this.moment.ISO_8601).local().format('DD/MMM/YYYY hh:mm a')

            return `${obj.batch} - ${tDisplay}`
        },
        async handleBatchChange() {
            if(this.filters.batch) {
                await this.axios.get(`${this.apiEndpoint}/leadgen-synced-leads?batch=${this.filters.batch}`)
                    .then(res => {
                        this.fbPageFormLeads = res.data.data.fbSyncedLeads
                        this.computePulledLeads()
                    })
                    .catch(err => {
                        console.log('err?', err)
                    })
            }
        },
        computePulledLeads() {
            let counts = []
            counts = this.fbPageFormLeads.map(form => {
                return form.fb_webhook_count
            })

            this.pulledLeadsTotal = counts.reduce((a, b) => a + b, 0)
            this.showLeadCount = true
        },
        async handlePushBatchToCrm(data) {
            await this.axios.post(
                `${this.apiEndpoint}/process-leadgen-synced-leads/${data.id}`,
                this.filters
            )
                .then(res => {
                    console.log('res?', res)
                    this.handleBatchChange()
                    return this.$notify({
                        type: 'success',
                        title: 'Job has been queued.',
                        message: 'Sending leads to dealerships CRM.'
                    })
                })
                .catch(err => {
                    console.log('err?', err)
                })
        }
    },

    watch: {
        'dealer': {
            immediate: true,
            handler: function (n) {
                if(n && n.hasOwnProperty('page_id')) {
                    localStorage.removeItem('selectedFbPage')
                    delete n.webhooks
                    localStorage.setItem(
                        'selectedFbPage',
                        JSON.stringify(n)
                    )
                    if(this.selectedRequestCount === 0) {
                        this.handleSelectedPage()
                    }
                }
            }
        },
    }
}
</script>
