<template>
    <el-dialog title="TikTok Subscription" @close="$emit('update:visible', false)" :visible.sync="isVisible" width="30%"
        :close-on-click-modal="false">
        <div class="form-company">
            <el-row>
                <el-col :span="24">
                    Page ID<br>
                    <el-input style="margin-top: 5px" v-model="subscription.page_id"></el-input>
                </el-col>
            </el-row>
        </div>
        <div slot="footer">
            <el-row>
                <el-col :span="24" style="text-align: right">
                    <el-button :loading="isProgress" @click="handleSaveForm" type="primary"><i class="el-icon-finished"></i> Save</el-button>
                </el-col>
            </el-row>
        </div>
    </el-dialog>
</template>
<style>
    .pull-right {
        float: right;
    }
    .row-template {
        padding: 10px 0;
    }
    .row-template a {
        color: inherit;
        padding: 0 2.5px;
    }
</style>
<script>
export default {
    props: ['company', 'visible'],
    data() {
        return {
            isVisible: false,
            isProgress: false,
            subscription: {
                subscription_type: 'page',
                page_id: '',
            },
        };
    },
    computed: {
    },
    mounted() {
        this.currentCompany = this.company
    },
    methods: {
        handleSaveForm() {
            this.axios
            .post(this.companyApiBaseUrl + '/tiktok/subscription', this.subscription)
            .then(response => {
                this.isProgress = false;
                if (response.data.success == true) {
                    this.$emit('onCloseModal', this.subscription);
                } else {
                    this.$notify({
                        title: "Error",
                        message: response.data.message,
                        type: "error"
                    });
                }
            })
            .catch(err => {
                console.log(err);
                this.isProgress = false;
                this.$root.handleErrors(err.response);
            });
        },
    },
    watch: {
        visible: function(val, oldVal) {
            this.isVisible = this.visible
        },
    }
};
</script>

<style>
    .form-company .el-form-item__label {
        width: 100%;
        text-align: left;
    }
</style>
