<template>
    <el-container style="height: 100vh">
        <el-row :gutter="20" style='width: 100%; height: 100%'>
            <el-col :span="8" style='background-color: #e4e6eb; height: 100%'>
                <h2 style='margin-left: 8px'>Messenger Lookup</h2>
                <template v-if='match_fb_user_aloware_contact.id'>
                    <el-alert
                        style='margin: 0 8px 22px; width: calc(100% - 8px);'
                        title="Already Matched"
                        type="success"
                        description="This contact is already matched."
                        :closable='false'
                        show-icon>
                    </el-alert>
                    <el-card shadow="none" class='user-card' style='margin: 0 8px 22px; width: calc(100% - 8px);'>
                        <img style="height: 80px; width: 80px; border-radius: 50%" :src="match_fb_user_aloware_contact.fb_user.profile_pic" alt='profile picture'>
                        <div class="msg-info">
                            <div class="msg-name">Firstname: <strong>{{ match_fb_user_aloware_contact.fb_user.first_name }}</strong></div>
                            <div class="msg-name">Lastname: <strong>{{ match_fb_user_aloware_contact.fb_user.last_name }}</strong></div>
                            <div class="msg-email" v-if='match_fb_user_aloware_contact.fb_user.email'>Email: <strong>{{ match_fb_user_aloware_contact.fb_user.email }}</strong></div>
                        </div>
                        <div class='action-buttons'>
                            <el-tooltip class="item" effect="dark" content="Match" placement="top">
                                <el-link
                                    title="Unmatch"
                                    class="btn-grad btn-grad-lead"
                                    type="secondary"
                                    @click='handleUnmatch'><i class="el-icon-connection"></i><span class="btn-name">Unmatch</span></el-link>
                            </el-tooltip>
                        </div>
                    </el-card>
                </template>
                <template v-else>
                    <el-form ref="form" :model="form" label-width="120px">
                        <fieldset :disabled='true' style='padding: 0; margin: 0; border: 0'>
                            <el-form-item label="Contact ID">
                                <el-input v-model="form.contact_id"></el-input>
                            </el-form-item>
                            <el-form-item label="First name">
                                <el-input v-model="form.firstname"></el-input>
                            </el-form-item>
                            <el-form-item label="Last name">
                                <el-input v-model="form.lastname"></el-input>
                            </el-form-item>
                            <el-form-item label="Email">
                                <el-input v-model="form.email"></el-input>
                            </el-form-item>
                            <!-- this migth be used -->
                            <!--el-form-item class='text-right'>
                                <el-button :disabled='is_searching' type="primary" @click="handleSearch">Search</el-button>
                            </el-form-item-->
                        </fieldset>
                    </el-form>
                </template>
            </el-col>
            <el-col :span="16" style='overflow-y: hidden; height: 100%;'>
                <!-- this migth be used -->
                <!--div style='margin: 8px 0'>
                    <el-input
                        placeholder="Type something"
                        prefix-icon="el-icon-search"
                        v-model="filter.search">
                    </el-input>
                </div-->
                <div v-if='!match_fb_user_aloware_contact.id' style='height: 100%; overflow: auto; padding: 8px 0'>
                    <div v-for="user in userLists" :key="user.id">
                        <el-card :key="user.id" shadow="none" class='user-card'>
                            <img style="height: 80px; width: 80px; border-radius: 50%" :src="user.profile_pic" alt='profile picture'>
                            <div class="msg-info">
                                <div class="msg-name">Firstname: <strong>{{ user.first_name }}</strong></div>
                                <div class="msg-name">Lastname: <strong>{{ user.last_name }}</strong></div>
                                <div class="msg-email" v-if='user.email'>Email: <strong>{{ user.email }}</strong></div>
                            </div>
                            <div class='action-buttons'>
                                <el-tooltip v-if='!match_fb_user_aloware_contact.id' class="item" effect="dark" content="Match" placement="top">
                                    <el-link
                                        title="Match"
                                        class="btn-grad btn-grad-lead"
                                        type="secondary"
                                        @click='handleMatch(user)'><i class="el-icon-connection"></i><span class="btn-name">Match</span></el-link>
                                </el-tooltip>
                            </div>
                        </el-card>
                    </div>
                    <el-card v-if="is_searching" shadow="none" class='user-card' style='height: 120px;'>
                        <div style="display: flex;width: 100%;height: 100%;align-items: center;">
                            <div class="text-center" style='margin: 0 auto;'>
                                <div class="cogs-container">
                                    <i class="fas fa-cog fa-spin custom-cog"></i>
                                    <i class="fas fa-cog fa-spin spin-reverse custom-cog-sm"></i>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </div>
            </el-col>
        </el-row>
    </el-container>
</template>
<script>
import store from "../store";

export default {
    components: {
    },
    data() {
        return {
            user: {},
            company: {},
            is_searching: false,
            form: {
                firstname: '',
                lastname: '',
                email: '',
                contact_id: '',
                paging_next: '',
            },
            filter: {
                search: ''
            },
            users: [],
            match_fb_user_aloware_contact: [],
        };
    },
    computed: {
        userLists() {
            let scope = this

            return this.users.filter(user => {
                let fullname = user.first_name + ' ' + user.last_name
                return fullname.toLowerCase().includes(scope.filter.search.toLowerCase())
            })
        }
    },
    methods: {
        loadPage: async function () {
            let scope = this
            await this.getAlowareContact().then(res => {
                if(res.success) {
                    scope.contact = res.data
                    scope.form.firstname = scope.contact.first_name;
                    scope.form.lastname = scope.contact.last_name;
                    scope.form.email = scope.contact.email;
                }
            })

            let match_fb_user_aloware_contact = []
            await this.getMatchFBUserAndAlowareContact().then(res => {
                if(res.success) {
                    match_fb_user_aloware_contact = res.data
                }
            })

            if(typeof match_fb_user_aloware_contact.id !== 'undefined') {
                await this.getMatchFbUserContact().then(res => {
                    scope.match_fb_user_aloware_contact = match_fb_user_aloware_contact
                    if(res.success) {
                        scope.match_fb_user_aloware_contact.fb_user = res.data
                    }
                })
                return
            }


            if(scope.form.firstname || scope.form.lastname || scope.form.email) {
                await this.handleSearch()
            }
        },
        search: function (form) {
            let scope = this

            return this.axios.get(this.companyApiBaseUrl + "/messenger-lookup", {
                params: form
            })
                .then(res => {
                    return Promise.resolve(res.data)
                })
                .catch((err) => {
                    console.log(err)
                    scope.is_searching = false
                    return Promise.reject(err)
                })
        },
        handleSearch: async function () {
            let scope = this
            scope.users = []
            scope.is_searching = true

            let eof = false
            while(!eof) {
                let res = await scope.search(scope.form)

                if(res.success) {
                    if(res.data.user.id) scope.users.push(res.data.user)

                    scope.users.sort(function(a, b) {
                        return b.points - a.points;
                    });

                    scope.form.paging_next = (res.data.paging_next) ? res.data.paging_next : ''
                    eof = res.data.eof
                } else {
                    eof = true
                }
            }

            scope.is_searching = false
        },
        handleUnmatch() {
            let scope = this

            this.axios
                .delete(`${this.companyApiBaseUrl}/messenger/unmatch/${scope.match_fb_user_aloware_contact.id}`)
                .then(res => {
                    if(res.data.success) {
                        scope.match_fb_user_aloware_contact = []
                        scope.handleSearch()
                    }
                })
        },
        handleMatch(user) {
            let scope = this

            scope.form.facebook_user_id = user.messenger_id
            let params = {
                aloware_contact_id: scope.contact.id,
                facebook_user_id: user.messenger_id,
                company_id: scope.company.id
            }

            this.axios
                .post(`${this.companyApiBaseUrl}/messenger/match`, params)
                .then(res => {
                    if(res.data.success) {
                        let match_fb_user_aloware_contact = res.data.data
                        scope.getMatchFbUserContact().then(res => {
                            scope.match_fb_user_aloware_contact = match_fb_user_aloware_contact
                            if(res.success) {
                                scope.match_fb_user_aloware_contact.fb_user = res.data
                            }
                            this.$notify({
                                title: "Success",
                                message: "Messenger user is match",
                                type: "success"
                            });
                        })
                    }
                })
        },
        getAlowareContact() {
            return this.axios.get(`${this.companyApiBaseUrl}/aloware-contact/` + this.form.contact_id)
                .then(res => {
                    return Promise.resolve(res.data)
                })
                .catch((err) => {
                    console.log(err)
                    return Promise.reject(err)
                })
        },
        getMatchFBUserAndAlowareContact() {
            return this.axios.get(`${this.companyApiBaseUrl}/match-fb-user-and-aloware-contact/` + this.form.contact_id)
                .then(res => {
                    return Promise.resolve(res.data)
                })
                .catch((err) => {
                    console.log(err)
                    return Promise.reject(err)
                })

        },
        getMatchFbUserContact() {
            return this.axios.get(`${this.companyApiBaseUrl}/messenger/match/` + this.contact.id)
                .then(res => {
                    return Promise.resolve(res.data)
                })
                .catch((err) => {
                    console.log(err)
                    return Promise.reject(err)
                })
        }
    },
    mounted() {
        let scope = this
        var url_string = window.location.href
        var url = new URL(url_string)

        this.form.contact_id = url.searchParams.get("contact_id")

        this.user = JSON.parse(store.state.currentUser);
        this.company = JSON.parse(store.state.currentCompany);

        if(this.form.contact_id) {
            this.loadPage()
        }
    }
};
</script>
<style scope>
    .user-card { margin-bottom: 8px; }
    .user-card .el-card__body { display: flex; align-items: flex-start; width: 100% }
    .user-card .el-card__body .msg-info { padding: 0 10px; }
    .user-card .el-card__body .msg-name { margin-bottom: 8px; }
    .user-card .el-card__body .action-buttons { align-self: center; margin-left: auto; margin-right: 40px; margin-top: 0; padding: 0; }

    .user-card .action-buttons .el-link {
        width: 115px;
    }
</style>
