<template>
    <div>
        <el-row>
            <el-row
                type="flex"
                :justify="withLogo ? 'space-between' : 'end'"
            >
                <el-col
                    :span="12"
                >
                    <el-row
                        v-if="withLogo"
                    >
                        <el-col :span="6">
                            <el-image v-if="company.logo" :src="company.logo" fit="cover"></el-image>
                        </el-col>
                        <el-col>
                            <h1>{{ company.name }}</h1>
                        </el-col>
                    </el-row>
                </el-col>

                <el-col
                    :span="12"
                >
                    <el-row
                        type="flex"
                        justify="end"
                    >
                        <el-select
                            v-model="filters.company"
                            filterable
                            clearable
                            placeholder="All Companies"
                            remote
                            :remote-method="filterCompanies"
                            @change="getCompany"
                        >
                            <el-option-group
                                v-for="(group, index) in companies"
                                :key="index"
                                :label="group.label"
                            >
                                <el-option
                                    v-for="item in group.options"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-option-group>
                        </el-select>
                    </el-row>
                </el-col>
            </el-row>
        </el-row>
    </div>
</template>

<script>

import store from "../../../store";

export default {
    name: "CompanyFilter",

    props: {
        withLogo: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            company: JSON.parse(store.state.currentCompany),
            companies: [
                {
                    label: 'Result',
                    options: []
                },
                {
                    label: 'Suspended',
                    options: []
                },
            ],
            filters: {
                company: '',
                // active_only: true,
                // ads_only: false,
                // active_ads_only: false,
            },
        }
    },

    watch: {},

    created() {
        this.setupComponent()
    },

    methods: {
        setupComponent() {
            this.company = JSON.parse(store.state.currentCompany)
            this.filters.company = this.company.name
        },

        async filterCompanies(data) {

            this.filters.company = data
            let qs = this.$qs.stringify(this.filters)

            await this.axios.get(`api/company-filter?${qs}`)
                .then(res => {
                    this.filterDefaults()
                    this.companies[0].options = res.data.result
                    this.companies[0].label = `Result: ${res.data.result.length}`
                    this.companies[1].options = res.data.suggestions
                    this.companies[1].label = `Suspended: ${res.data.suggestions.length}`
                })
                .catch(err => {
                    this.$notify({
                        type: 'error',
                        title: 'Error',
                        message: err
                    })
                })
        },

        async getCompany(data) {
            if(!data) return false
            await this.axios.get(`/api/company/simpid-${data}`)
                .then(res => {
                    this.company = res.data.data
                    store.commit(
                        "UpdateCurrentCompany",
                        this.company
                    );
                    this.$emit('change', this.company)
                })
                .catch(err => {
                    console.log('err?', err)
                    this.$notify({
                        type: 'error',
                        title: 'Error',
                        message: err
                    })
                })
        },

        filterDefaults() {
            this.companies = [
                {
                    label: 'Result: 0',
                    options: []
                },
                {
                    label: 'Suspended: 0',
                    options: []
                },
            ]
        }
    }
}
</script>
