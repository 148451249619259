<template>
    <div>
        <el-row
            type="flex"
            justify="space-between"
            style="align-content: start !important;"
        >
            <h3>{{ company.name }}</h3>

            <div>
                <el-row
                    type="flex"
                    justify="space-between"
                    :gutter="20"
                >
                    <el-col>
                        <div class="input-block">
                            <span class="input-block-label">Status</span>
                            <el-select
                                v-model="filters.secondary_status"
                                placeholder="Select status"
                                @change="handleApply"
                            >
                                <el-option
                                    v-for="item in statuses"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </el-col>

                    <el-col>
                        <div class="input-block">
                            <span class="input-block-label">Date</span>
                            <el-date-picker
                                v-model="filters.date_range"
                                type="daterange"
                                range-separator="To"
                                start-placeholder="Start date"
                                end-placeholder="End date"
                                unlink-panels
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MMM-dd"
                                :picker-options="pickerOptions"
                            >
                            </el-date-picker>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-row>
        <el-button
            size="mini"
            type="primary"
            style="float: right;"
            @click="handleApply"
            :disabled="!!!filters.date_range.length"
        >
            Apply
        </el-button>
    </div>

</template>

<script>

import moment from 'moment'
export default {
    name: "Filtering",
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            filters: {
                date_range: [],
                secondary_status: 'AD_STATUS_DELIVERY_OK'
            },
            pickerOptions: {
                shortcuts: [
                    {
                        text: 'Last week',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: 'Last month',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: 'Last 3 months',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                ]
            },
            statuses: [
                {
                    value: 'AD_STATUS_NOT_DELETE',
                    label: 'All'
                },
                {
                    value: 'AD_STATUS_DONE',
                    label: 'Completed'
                },
                {
                    value: 'AD_STATUS_DELIVERY_OK',
                    label: 'In Progress'
                }
            ]
        }
    },
    mounted() {
        this.handleSetupComponent()
        this.handleApply()
    },
    methods: {
        handleSetupComponent() {
            let end = moment().format('yyyy-MM-DD HH:mm:ss')
            let start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            start = moment(start).format('yyyy-MM-DD HH:mm:ss')
            this.filters.date_range = [start, end]
        },
        handleApply() {
            this.$emit('filter-apply', this.filters)
        },
    },
    watch: {
        'filters.date_range': {
            immediate: false,
            handler: function (n) {
                if(n === null) this.filters.date_range = []
            }
        }
    }
}
</script>
