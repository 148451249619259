<template>
    <el-row
        type="flex"
    >
        <el-col>
            <el-tooltip
                v-if="showInventoryBtn"
                effect="dark"
                content="Inventory"
                placement="top"
            >
                <el-button
                    size="mini"
                    title="Inventory"
                    @click="showInventory"
                >
                    <i class="fa fa-car" style="margin-right: 5px;width: 24px"></i>
                </el-button>
            </el-tooltip>
            <el-tooltip
                v-if="showAppointmentBtn"
                effect="dark"
                content="Appointment"
                placement="top"
            >
                <el-button
                    size="mini"
                    title="Appointment"
                    @click="showAppointment"
                >
                    <i class="fa fa-calendar-check" style="margin-right: 5px;width: 24px"></i>
                </el-button>
            </el-tooltip>
            <el-tooltip
                v-if="showCreditApplicationBtn"
                effect="dark"
                content="Credit Application"
                placement="top"
            >
                <el-button
                    size="mini"
                    title="Credit Application"
                    @click="showCreditApplication"
                    :disabled="disableCreditApplicationBtn"
                >
                    <i class="fa fa-edit" style="margin-right: 5px;width: 24px"></i>
                </el-button>
            </el-tooltip>
        </el-col>

    </el-row>
</template>
<script>
export default {
    name: 'EngageButtons',
    data() {
        return {
            showInventoryBtn: false,
            showAppointmentBtn: false,
            showCreditApplicationBtn: false,
            company: {},
            disableCreditApplicationBtn: false,
        }
    },
    mounted() {
        this.setupComponents()
    },
    methods: {
        showInventory() {
            return this.$emit('show-inventory', true)
        },
        setupComponents() {
            this.company = JSON.parse(this.$store.state.currentCompany)

            this.showInventoryBtn = true
            this.showCreditApplicationBtn = true
            this.showAppointmentBtn = true

            // const test_slugs = [
            //     'north-end-motors',
            //     'simpsocial-sandbox',
            // ]
            // if(test_slugs.includes(this.company.slug)) {}
        },
        showCreditApplication() {
            this.disableCreditApplicationBtn = true
            return this.$emit('send-credit-application-link')
        },
        showAppointment() {
            return this.$emit('show-appointment', true)
        },
    },
}
</script>
