<template>
    <div style="margin-bottom: 20px;">
        <el-row type="flex" justify="space-between">
            <el-col>
                <h4>
                    <em>{{tableName}}</em>
                </h4>
            </el-col>
            <el-col>
                <el-form @submit.prevent="false">
                    <el-form-item>
                        <el-input
                            v-model="search"
                            clearable
                            size="small"
                            placeholder="Search"
                            suffix-icon="el-icon-search"
                        ></el-input>
                    </el-form-item>
                </el-form>
                
            </el-col>
        </el-row>
       
        <el-table
            :data="entry.filter(data => !search || data.title.toLowerCase().includes(search.toLowerCase()))"
            height="225"
        >
            <el-table-column
                v-for="(head, i) in tableHeaders"
                :key="i"
                :prop="head.prop"
                :label="head.label"
                :sortable="head.sortable"
                :width="head.width || ''"
            >
                <template
                    v-slot="scope"
                    v-if="head.prop === 'status'"
                >
                    <el-tag
                        :type="scope.row.status ? 'success' : 'danger'"
                        disable-transitions
                        effect="plain"
                    >
                        {{`${scope.row.status ? 'Active' : 'Inactive'}`}}
                    </el-tag>
                </template>
                
                <template
                    v-slot="scope"
                    v-else-if="head.prop === 'actions'"
                >
                    <el-button
                        v-for="(action, i) in btnActions"
                        :key="i"
                        :type="action.type"
                        :icon="action.icon"
                        :title="action.text"
                        size="mini"
                        @click="handleAction(action, scope.row)"
                    >
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    name: "TemplatesTable",
    props: {
        tableData: {
            type: Array,
            required: true
        },
        name: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            search: '',
            tableHeaders: [
                {
                    prop: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    prop: 'status',
                    label: 'Status',
                    sortable: true,
                    width: '100%',
                },
                {
                    prop: 'actions',
                    label: 'Actions',
                    
                }
            ],
            entry: this.tableData,
            btnActions: [
                {
                    text: 'Edit',
                    action: 'edit',
                    type: 'primary',
                    icon: 'el-icon-edit'
                },
                {
                    text: 'Duplicate',
                    action: 'duplicate',
                    type: 'success',
                    icon: 'el-icon-copy-document'
                },
                {
                    text: 'Delete',
                    action: 'delete',
                    type: 'danger',
                    icon: 'el-icon-delete'
                },
            ]
        }
    },
    computed: {
        tableName() {
            return this.name.toUpperCase()
        }
    },
    methods: {
        handleAction(action, data) {
            if(action.action === 'delete') {
                return this.confirmDelete(action.action, data)
            }
            return this.emitAction(action.action, data)
        },
        emitAction(action, data) {
            return this.$emit('table-action', action, data)
        },
        confirmDelete(action, data) {
            this.$confirm(
                'This will permanently delete the template. Continue?',
                'Warning', 
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }
            )
                    .then(() => {
                        this.emitAction(action, data)
                    })
                    .catch(() => {
                        this.$message(
                            {
                                type: 'info', 
                                message: 'Delete canceled'
                            }
                        )
                    })
        }
    }
}

</script>
