<template>
    <el-card
        shadow="hover"
        style="margin-bottom: .5rem;"
    >
        <user-avatar :comment="comment"/>
        <div
            style="padding: .5rem"
        >
            <el-tag
                :type="comment.comment_status === 'PUBLIC' ? 'success' : 'warning'"
                plain
                disabled
                size="mini"
                style="float: right;"
            >
                {{ comment.comment_status }}
            </el-tag>

            <p>{{ comment.content }}</p>
        </div>
        <el-footer
            style="background-color: transparent; display: flex; padding-bottom: .5rem;"
            :style="[comment.replies > 0 ? {justifyContent: 'space-between'} : {justifyContent: 'end'}]"
        >
            <el-button
                v-if="comment.hasOwnProperty('replies') && comment.replies > 0"
                size="mini"
                type="text"
                @click="getRelatedComments"
            >
                Show {{ comment.replies === 1 ? 'Reply' : 'Replies' }}
            </el-button>

            <el-button
                size="mini"
                type="text"
                @click="toggleCommentEditor"
                :disabled="!comment.identity_id || !comment.identity_type"
            >
                {{ showCommentEditor ? 'Cancel Comment' : 'Add Comment' }}
            </el-button>
        </el-footer>

        <el-container
            v-if="relatedComments.length"
            style="padding-bottom: .1rem;"
        >
            <el-main
                style="overflow-y: scroll !important; min-height: .1rem; !important;"
            >
                <comment
                    v-for="comment in relatedComments"
                    :key="comment.comment_id"
                    :comment="comment"
                ></comment>
            </el-main>
        </el-container>
        <el-row
            v-if="showCommentEditor"
            element-loading-text="Sending..."
            element-loading-spinner="el-icon-loading"
            v-loading="postingComment"
            :gutter="20"
        >
           <el-col
            :span="22"
           >
                <comment-editor
                    @update="handleEditorUpdate"
                ></comment-editor>
           </el-col>
            <el-col
                :span="2"
            >
                <el-button
                    type="text"
                    @click="handlePostComment"
                    :disabled="!newComment"
                >
                    Post
                </el-button>
            </el-col>
        </el-row>
    </el-card>
</template>

<script>
import UserAvatar from "./components/UserAvatar";
import CommentEditor from "../CommentEditor";
export default {
    name: "Comment",
    components: {
        UserAvatar,
        CommentEditor
    },
    props: {
        comment: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            company: {},
            relatedComments: [],
            apiURL: '',
            showCommentEditor: false,
            newComment: '',
            postingComment: false,
        }
    },
    mounted() {
        this.setupComponent()
    },
    methods: {
        setupComponent() {
            this.company = JSON.parse(this.$store.state.currentCompany)
            this.apiURL = `api/company/${this.company.id}/social-listening`
        },
        async getRelatedComments(updateList = false) {
            if(updateList) this.relatedComments = []

            const qs = `platform=tiktok&comment_id=${this.comment.comment_id}&comment_type=${this.comment.comment_type}`
            await this.axios.get(
                `${this.apiURL}/get-tiktok-related-comments?${qs}`
            )
                .then(res => {
                    this.relatedComments = res.data.data.related_comments.data.comments
                    this.relatedComments = this.relatedComments.filter(comment => comment.comment_id !== this.comment.comment_id)
                })
                .catch(err => {
                    console.log('err?', err)
                    return this.$root.handleErrors(err.response)
                })
        },
        toggleCommentEditor() {
            this.showCommentEditor = !this.showCommentEditor
        },
        handleEditorUpdate(data) {
            this.newComment = data
        },
        async handlePostComment() {
            if(!this.newComment) return false

            this.startPostingSpinner()
            await this.axios.post(
                `${this.apiURL}/post-comment`,
                {
                    platform: 'tiktok',
                    ad_id: this.comment.ad_id,
                    tiktok_item_id: this.comment.tiktok_item_id,
                    comment_id: this.comment.comment_id,
                    comment_type: 'REPLY',
                    text: this.newComment,
                    identity_type: this.comment.identity_type,
                    identity_id: this.comment.identity_id,
                }
            )
                .then(res => {
                    this.newComment = ''
                    this.showCommentEditor = false
                    this.endPostingSpinner()
                    return this.getRelatedComments(true)
                })
                .catch(err => {
                    console.log('err?', err)
                    this.endPostingSpinner()
                    return this.$root.handleErrors(err.response)
                })
        },
        startPostingSpinner() {
            this.postingComment = true
        },
        endPostingSpinner() {
            this.postingComment = false
        }
    }
}
</script>

<style scoped>
div.el-card__body {
    padding-bottom: 0 !important;
}
</style>
