<template>
    <el-dialog title="Select Template" @close="$emit('update:visible', false)" :visible.sync="isVisible" :close-on-click-modal="false" width="30%">
        <div class="mail-template-selector">
            <el-row style="margin-top: 10px">
                <el-col :span="24">
                    <el-tabs type="border-card" :loading="isLoading">
                        <el-tab-pane>
                            <span slot="label">User Template</span>
                            <EmailTemplatesTable type="user" :templates="userEmailTemplates" @onTemplateSelect="handleOnSelectTemplate" />
                        </el-tab-pane>
                        <el-tab-pane>
                            <span slot="label">Dealership Template</span>
                            <EmailTemplatesTable type="company" :templates="companyEmailTemplates" @onTemplateSelect="handleOnSelectTemplate" />
                        </el-tab-pane>
                    </el-tabs>
                </el-col>
            </el-row>
        </div>
    </el-dialog>
</template>
<script>
import store from "../../../store";
import EmailTemplatesTable from './templateSelector/EmailTemplatesTable';
export default {
    name: 'TemplateSelector',
    components: {
        EmailTemplatesTable
    },
    props: ['visible'],
    data() {
        return {
            userEmailTemplates: [],
            companyEmailTemplates: [],
            isLoading: false,
            isVisible: false,
        };
    },
    computed: {
    },
    mounted() {
        this.company = JSON.parse(store.state?.currentCompany)
        this.user = JSON.parse(store.state?.currentUser)
    },
    methods: {
        getTemplates() {
            this.isLoading = true
            this.axios.get(`/api/company/${this.company.id}/user/${this.user.id}/email/templates`)
                .then(res => {
                    this.userEmailTemplates = res.data.data.user.data
                    this.companyEmailTemplates = res.data.data.company.data
                    this.isLoading = false
                })
                .catch(err => {
                    this.$root.handleErrors(err.response);
                })
        },
        handleOnSelectTemplate(template) {
            this.$emit('onTemplateSelect', template)
        }
    },
    watch: {
        visible: function(val, oldVal) {
            if( val == true ) {
                this.getTemplates()
            }
            this.isVisible = this.visible;
        },
    }
};
</script>
