<template>
    <div>
        <el-table
            :data="fbPageFormLeads"
            :total="fbPageFormLeads.length"
            stripe
            style="width: 100%"
        >
            <el-table-column
                type="index"
                width="50"
            >
            </el-table-column>
            <el-table-column
                prop="form_id"
                label="FB Form ID"
            >
            </el-table-column>
            <el-table-column
                prop="form_name"
                label="FB Form Name"
            ></el-table-column>
            <el-table-column
                prop="lead_count"
                label="Total Leads"
            >
            </el-table-column>
            <el-table-column
                prop="fb_webhook_count"
                label="Total Leads Pulled"
            >
                <template v-slot="scope">
                    <template>
                       <span
                       >{{ scope.row.fb_webhook_count }}</span>
                    </template>
                </template>
            </el-table-column>
            <el-table-column
                prop="in_process"
                label="Processing"
            >
                <template v-slot="scope">
                    <template>
                        {{ scope.row.in_process ? 'Yes' : 'Done' }}
                    </template>
                </template>
            </el-table-column>
            <el-table-column
                prop="exceptions"
                label="With Error"
            >
                <template v-slot="scope">
                    <template>
                       <span
                        :class="scope.row.exceptions ? 'text-red' : ''"
                       >{{ scope.row.exceptions ? 'Yes' : 'No' }}</span>
                    </template>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    name: "FbLeadgenSyncedLeadsTable",
    props: {
        fbPageFormLeads: {
            type: Array,
            required: true
        }
    },
    data() {
        return {}
    },
    mounted() {},
    methods: {
        handleCurrentChange(data) {
            return this.$emit('current-change', data)
        }
    }
}
</script>

<style scoped>
.text-red {
    color: red;
}
.text-green {
    color: green;
}
</style>
