<template>
    <el-container class="mail-sent">
        <div>
            <el-table
                :data="outgoingEmails"
                stripe
                style="width: 100%"
                @row-click="handleSelectedMail" >
                <el-table-column label="Recipients" prop="from">
                    <template slot-scope="scope">
                        <span>{{ scope.row.recipient }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Message" prop="subject">
                    <template slot-scope="scope">
                        <span>{{ scope.row.subject }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Date" prop="date_sent">
                    <template slot-scope="scope">
                        <span>{{ scope.row.date_sent | formatDate }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                background
                @current-change="handlePageChange"
                :current-page.sync="currentPage"
                :page-size="10"
                layout="prev, pager, next"
                :total="totalRecords"
            ></el-pagination>
        </div>
    </el-container>
</template>
<style>

</style>
<script>
import store from "../../store";
import MessageContent from "./inbox/MessageContent";

export default {
    components: {
        MessageContent
    },
    data() {
        return {
            outgoingEmails: [],
            company: {},
            user: {},
            selectedMessage: null,
            totalRecords: 0,
            currentPage: 1,
            perPage: 20,
            exportType: 'json'
        };
    },
    computed: {},
    mounted() {
        this.company = JSON.parse(store.state.currentCompany)
        this.user = JSON.parse(store.state.currentUser)
        this.getOutgoingEmails()
    },
    methods: {
        getOutgoingEmails() {
            let params = {
                page: this.currentPage,
                page_size: this.perPage,
                //q: this.filters.q,
                export_type: this.exportType,
            }
            this.axios
            .get('/api/company/' + this.company.aloware_id + '/user/' + this.user.id + '/emails-outgoing', params)
            .then(response => {
                if (response.data.success == true) {
                    this.outgoingEmails = response.data.data
                    this.totalRecords = response.data.pagination.total
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        handleSelectedMail(row) {
            this.selectedMessage = row
        },
        handleMessageIsRead(messageId) {
            if(this.selectedMessage.id == messageId) {
                this.selectedMessage.is_read = 1
            }
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.getOutgoingEmails();
        },
    }
};
</script>
