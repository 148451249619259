<template>
    <el-main>
        <CompanyFilter
          style="margin-bottom: 0.5rem;"
          with-logo
          @change="companyFilterChange"
        ></CompanyFilter>
        <el-tabs
            v-model="activeTab"
            type="border-card"
        >
            <el-tab-pane name="vinSolutions">
                <div slot="label" style="display: flex; align-items: center; justify-content: center; margin: .5rem 0;">
                    <img
                        alt="VinSolutions"
                        src="https://www.vinsolutions.com/wp-content/uploads/sites/2/2020/11/VinSolutions-logo.svg"
                        width="50%"
                        style="display: block; margin: auto;"
                    />
                </div>

                <VinSolutions
                    v-if="activeTab === 'vinSolutions'"
                    :key="integrationKey+1"
                />
            </el-tab-pane>
            <el-tab-pane name="eLeads">
                <div slot="label" style="display: flex; align-items: center; justify-content: center;">
                    ELead
                </div>
                <ELeads
                    v-if="activeTab === 'eLeads'"
                    :key="integrationKey+2"
                />
            </el-tab-pane>
            <el-tab-pane name="CDK">
                <div slot="label" style="display: flex; align-items: center; justify-content: center;">
                    CDK DMS
                </div>
                <CDK
                    v-if="activeTab === 'CDK'"
                    :key="integrationKey+3"
                />
            </el-tab-pane>
            <el-tab-pane name="dealerSocket">
                <div slot="label" style="display: flex; align-items: center; justify-content: center;">
                    DealerSocket
                </div>
                <DealerSocket
                    v-if="activeTab === 'dealerSocket'"
                    :key="integrationKey+4"
                />
            </el-tab-pane>
            <el-tab-pane name="proMax">
                <div slot="label" style="display: flex; align-items: center; justify-content: center;">
                    ProMax
                </div>
                <ProMax
                    v-if="activeTab === 'proMax'"
                    :key="integrationKey+5"
                />
            </el-tab-pane>
            <el-tab-pane name="PBS">
                <div slot="label" style="display: flex; align-items: center; justify-content: center;">
                    PBS
                </div>
                <PBS
                    v-if="activeTab === 'PBS'"
                    :key="integrationKey+6"
                />
            </el-tab-pane>
        </el-tabs>
    </el-main>
</template>
<script>

    import VinSolutions from "./VinSolutions/VinSolutions.vue";
    import ELeads from "./Fortellis/ELeads.vue";
    import CDK from "./Fortellis/CDK.vue";
    import DealerSocket from "./DealerSocket/DealerSocket";
    import ProMax from "./ProMax/ProMax"
    import CompanyFilter from "../admin/components/CompanyFilter.vue";
    import PBS from "./PBS/PBS.vue";

    export default {
        components: {
            ProMax,
            DealerSocket,
            VinSolutions,
            ELeads,
            CDK,
            CompanyFilter,
            PBS,
        },
        data() {
            return {
                activeTab: 'eLeads',
                integrationKey: 0,
            }
        },
        methods: {
            companyFilterChange(data) {
                this.$store.commit('UpdateCurrentCompany', data)
                this.integrationKey++
            }
        },
    }
</script>
