<template>
    <div>
        <h3>Templates</h3>
        <div>
            <temp-table
                v-for="(data, i) in tableData"
                :key="i"
                :name="i+''"
                :table-data="data.data"
                @table-action="handleAction"
            ></temp-table>
        </div>
    </div>
</template>

<script>
import TempTable from "./TempTable";

export default {
    name: "TemplatesTables",
    components: {
        TempTable
    },
    props: {
        templates: {
            type: Object|Array,
            required: true
        }
    },
    data() {
        return {
            tableHeaders: [
                {
                    prop: 'subject',
                    label: 'Subject',
                    sortable: true
                },
                {
                    prop: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    prop: '',
                    label: 'Actions',
                    
                }
            ],
            tableData: this.templates
        }
    },
    methods: {
        handleAction(action, data) {
            this.$emit('table-action', action, data)
        }
    }
}
</script>
