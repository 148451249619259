<template>
    <div class="search-contact">
        <el-row :gutter="20">
            <el-col :span="24" style="text-align: center">
                <el-button
                    type="primary"
                    @click="handleImportContacts"
                >
                    <i class="fa fa-plus"></i> Import Contacts
                </el-button>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="12">
                <el-select v-model="companyTag" placeholder="Select Import tag" @change="handleChangeImportTag">
                    <el-option
                        v-for="item in companyTags"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        <i class="fa fa-circle" aria-hidden="true"></i> {{item.name}}
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="12" style="text-align: right">
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="24">
                <el-card :body-style="{ padding: '0px', textAlign: 'center' }" style="display: inline-block;margin-top: 10px">
                    <div style="padding: 14px;">
                        <h3 v-if="loadingContacts">...</h3>
                        <h3 v-else>{{contactsCount}}</h3>
                        <div class="bottom clearfix" style="border-top: 1px solid #d7caca;padding-top: 10px;">
                            {{parseInt(contactsCount) | pluralize('Contact', 'Contacts')}}
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-dialog title="Import" :visible.sync="importFormVisible" :close-on-click-modal="false">
            <ImportContactsForm v-on:closeDialog="handleOnCloseImportForm"/>
        </el-dialog>
    </div>
</template>
<script>
import store from "../../../store";
import moment from 'moment';
import ImportContactsForm from "../../user/ImportContactsForm.vue";

export default {
    props: {
        tagId: {
            type: Number,
            default: 0
        }
    },
    components: {
        ImportContactsForm
    },
    data() {
        return {
            companyTags: [],
            companyTag: '',
            selectedContacts: [],
            loadingContacts: false,
            contactsCount: 0,
            importFormVisible: false,
        };
    },
    computed: {
    },
    mounted() {
        this.company = JSON.parse(store.state.currentCompany)
        this.user = JSON.parse(store.state.currentUser)
        this.getContactTags()
    },
    methods: {
        getContactTags() {
            this.loadingContacts = true
            this.axios
            .get('/api/company/' + this.company.id + '/contact-tags')
            .then(response => {
                if (response.data.success == true) {
                    //this.companyTags = response.data.data
                    let result = response.data.data

                    if(result.length > 0) {
                        result.forEach((e,i) => {
                            this.companyTags.push({
                                name: e.name,
                                id: e.id,
                            })

                            if(e.id == this.tagId) {
                                this.contactsCount = e.contacts_count
                            }
                        })
                    }
                    if(this.tagId > 0) {
                        this.companyTag = this.tagId
                        this.$emit('onSelectContacts', [`{import_tag:${this.tagId}}`])
                    }

                    this.loadingContacts = false
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        handleChangeImportTag() {
            this.loadingContacts = true
            this.axios.get(
                `api/company/${this.company.id}/contact-tags/${this.companyTag}`
            )
            .then((response) => {
                this.loadingContacts = false
                this.contactsCount = response.data.data.contacts_count
                this.$emit('onSelectContacts', [`{import_tag:${this.companyTag}}`])
            })
        },
        handleImportContacts() {
            this.importFormVisible = true
        },
        handleOnCloseImportForm() {
            this.importFormVisible = false;
            this.getContactTags();
        },
    }
};
</script>
