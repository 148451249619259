<template>
    <el-main>
        <div class="page-title">
            <el-row :gutter="20">
                <el-col :span="12">
                    <h1>Welcome to Dashboard</h1>
                </el-col>
                <el-col :span="12" style="text-align:right;color:#9a9a9a;">
                    <h6 v-if="free_space!==''">Server free space: {{free_space}}</h6>
                </el-col>
            </el-row>
        </div>
        <el-row :gutter="20">
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <div class="card card-stats">
                        <div class="card-body">
                            <el-row :gutter="20">
                                <el-col :span="4">
                                    <div class="icon-big text-center icon-warning">
                                        <i
                                            class="el-icon-data-line"
                                            style="color: rgb(103, 194, 58);"
                                        ></i>
                                    </div>
                                </el-col>
                                <el-col :span="20">
                                    <div class="numbers">
                                        <p class="card-category font-size-15">Cars</p>
                                        <h4 class="card-title">{{totalCars}}</h4>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="card-footer">
                            <hr />
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <div class="card card-stats">
                        <div class="card-body">
                            <el-row :gutter="20">
                                <el-col :span="4">
                                    <div class="icon-big text-center icon-warning">
                                        <i
                                            class="el-icon-user-solid"
                                            style="color: rgb(230, 162, 60);"
                                        ></i>
                                    </div>
                                </el-col>
                                <el-col :span="20">
                                    <div class="numbers">
                                        <p class="card-category font-size-15">Users</p>
                                        <h4 class="card-title">{{totalUsers}}</h4>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="card-footer">
                            <hr />
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <div class="card card-stats">
                        <div class="card-body">
                            <el-row :gutter="20">
                                <el-col :span="4">
                                    <div class="icon-big text-center icon-warning">
                                        <i
                                            class="el-icon-folder"
                                            style="color: rgb(18, 63, 121);"
                                        ></i>
                                    </div>
                                </el-col>
                                <el-col :span="20">
                                    <div class="numbers">
                                        <p class="card-category font-size-15">Company</p>
                                        <h4 class="card-title">{{totalCompanyWithCars}}</h4>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="card-footer">
                            <hr />
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <div class="card card-stats">
                        <div class="card-body">
                            <el-row :gutter="20">
                                <el-col :span="4">
                                    <div class="icon-big text-center icon-warning">
                                        <i
                                            class="el-icon-upload"
                                            style="color: rgb(18, 121, 90);"
                                        ></i>
                                    </div>
                                </el-col>
                                <el-col :span="20">
                                    <div class="numbers">
                                        <p class="card-category font-size-15">Pending for FTP Upload</p>
                                        <h4 class="card-title">{{totalPendingFtp}}</h4>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="24" v-if="pendingFtp.length>0">
                                    <ul class="pending-list">
                                        <li v-for="(item, index) in pendingFtp" :key="index">
                                            {{ index + 1 }}. {{ item }}
                                        </li>
                                    </ul>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="card-footer">
                            <hr />
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <div class="card card-stats">
                        <div class="card-body">
                            <el-row :gutter="20">
                                <el-col :span="4">
                                    <div class="icon-big text-center icon-warning">
                                        <i
                                            class="el-icon-download"
                                            style="color: rgb(121, 18, 34);"
                                        ></i>
                                    </div>
                                </el-col>
                                <el-col :span="20">
                                    <div class="numbers">
                                        <p class="card-category font-size-15">Pending for Import</p>
                                        <h4 class="card-title">{{totalPendingImport}}</h4>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="24" v-if="pendingImport.length>0">
                                    <ul class="pending-list">
                                        <li v-for="(item, index) in pendingImport" :key="index">
                                            {{ index + 1 }}. {{ item.company_name }}
                                        </li>
                                    </ul>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="card-footer">
                            <hr />
                        </div>
                    </div>
                </div>
            </el-col>

            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <div class="card card-stats">
                        <div class="card-body">
                            <el-row :gutter="20">
                                <el-col :span="4">
                                    <div class="icon-big text-center icon-warning">
                                        <i class="el-icon-warning"
                                            style="color: red;"
                                        ></i>
                                    </div>
                                </el-col>
                                <el-col :span="20">
                                    <div class="numbers">
                                        <p class="card-category font-size-15">Currently importing or with errors</p>
                                        <h4 class="card-title">{{companies_with_import_errors.length>0 ? companies_with_import_errors.length : 0}}</h4>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="24" v-if="companies_with_import_errors.length>0">
                                    <ul class="pending-list">
                                        <li v-for="(item, index) in companies_with_import_errors" :key="index">
                                            {{ index + 1 }}. {{ item.name }}
                                        </li>
                                    </ul>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="card-footer">
                            <hr />
                        </div>
                    </div>
                </div>
            </el-col>
<!--            <el-col :span="8">-->
<!--                <div class="grid-content bg-purple">-->
<!--                    <div class="card card-stats">-->
<!--                        <div class="card-body">-->
<!--                            <el-row :gutter="20">-->
<!--                                <el-col :span="4">-->
<!--                                    <div class="icon-big text-center icon-warning">-->
<!--                                        <i class="el-icon-s-release"-->
<!--                                            style="color: green;"-->
<!--                                        ></i>-->
<!--                                    </div>-->
<!--                                </el-col>-->
<!--                                <el-col :span="20">-->
<!--                                    <div class="numbers">-->
<!--                                        <p class="card-category font-size-15">Dealervault Unassigned Directories</p>-->
<!--                                        <el-button-->
<!--                                            size="mini"-->
<!--                                            type="default"-->
<!--                                            @click="handleViewDealervaultUnassignedDirectories()"-->
<!--                                        >-->
<!--                                            Fetch-->
<!--                                        </el-button>-->
<!--                                    </div>-->
<!--                                </el-col>-->
<!--                            </el-row>-->
<!--                            <el-row :gutter="20">-->
<!--                                <el-col :span="24" v-if="dealervault_unassigned_directories.length>0">-->
<!--                                    <ul class="pending-list">-->
<!--                                        <li v-for="(item, index) in dealervault_unassigned_directories" :key="index">-->
<!--                                            {{ index + 1 }}. "{{ item }}"-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </el-col>-->
<!--                            </el-row>-->
<!--                        </div>-->
<!--                        <div class="card-footer">-->
<!--                            <hr />-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </el-col>-->

            <el-col :span="6">
                <div class="grid-content bg-purple"></div>
            </el-col>
        </el-row>
    </el-main>
</template>
<style>
.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    border-radius: 4px;
    background-color: #ffffff;
    margin-bottom: 30px;
}
.card-stats .card-body {
    padding: 15px 15px 0px;
}
.card .card-footer {
    padding: 0px 15px 10px 15px;
    background-color: transparent;
    line-height: 30px;
    border-top: none !important;
    font-size: 14px;
}
.card-stats .icon-big {
    font-size: 3em;
    min-height: 64px;
}
.card-stats .card-body .numbers {
    text-align: right;
}
.card .card-category,
.card label {
    font-size: 0.875rem;
    font-weight: 400;
    color: #9a9a9a;
    margin-bottom: 0px;
    margin-top: 0;
}
.card .card-title {
    margin: 0;
    color: #333333;
    font-weight: 300;
    font-size: 1.375rem;
}
.card .stats {
    color: #a9a9a9;
}
.pending-list {
    height: 250px;
    overflow: auto;
    list-style: none;
    margin: 0;
    padding: 0;
}
.pending-list li {
    padding: 5px 10px;
    color: #333333;
    border: 1px solid rgba(0,0,0,0.1);
}
.font-size-15 {
    font-size: 15px !important;
}

</style>
<script>
import store from "../../store";

export default {
    data() {
        return {
            isProgress: false,
            totalCars: 0,
            totalUsers: 0,
            totalCompanyWithCars: 0,
            pendingImport: [],
            pendingFtp: [],
            totalPendingFtp: 0,
            totalPendingImport: 0,
            companies_with_import_errors: [],
            free_space: '',
            dealervault_unassigned_directories:[],
            vin_solutions: {}
        };
    },
    computed: {},
    mounted() {
        this.loadSummaries();
    },
    methods: {
        loadSummaries() {
            this.axios
                .get("api/summaries", {
                    params: {}
                })
                .then(response => {
                    if (response.data.success == true) {
                        this.totalCars = response.data.data.total_cars;
                        this.totalUsers = response.data.data.total_users;
                        this.totalCompanyWithCars = response.data.data.total_company_with_car;

                        this.totalPendingFtp = response.data.data.pending_ftp.length;
                        this.pendingFtp = response.data.data.pending_ftp;

                        this.totalPendingImport = response.data.data.pending_import.length;
                        this.pendingImport = response.data.data.pending_import;
                        this.companies_with_import_errors = response.data.data.companies_with_import_errors;
                        this.free_space = response.data.data.free_space;
                        this.vin_solutions = response.data.data.hasOwnProperty('vin_solutions') ? response.data.data.vin_solutions : {}
                    } else {
                        this.$notify({
                            title: "Error",
                            message:
                                "There has been an error getting summaries",
                            type: "error"
                        });
                    }
                });
        },
        handleViewDealervaultUnassignedDirectories() {
            this.axios
                .get("api/summaries/dealervault_unassigned_dirs", {
                    params: {}
                })
                .then(response => {
                    if (response.data.success == true) {
                        this.dealervault_unassigned_directories = response.data.data.unassigned_dirs;
                    } else {
                        this.$notify({
                            title: "Error",
                            message:
                                "There has been an error getting summaries",
                            type: "error"
                        });
                    }
                });
        }

    },
    watch: {

    }
};
</script>
