<template>
    <div
        v-loading="loading"
    >
        <el-row type="flex" class="row-bg" justify="center">
            <!--            <el-link-->
            <!--                v-if="!validOrWithUserToken"-->
            <!--                class="el-button el-button&#45;&#45;secondary"-->
            <!--                type="primary"-->
            <!--                target="_parent"-->
            <!--                :href="`/auth/facebook/user/${currentUser.id}`"-->
            <!--            >-->
            <!--                <i class="el-icon-top-right"></i> Connect to Facebook-->
            <!--            </el-link>-->
            <p id="profile"></p>
            <el-button @click="loginToFacebook" v-if="!fbBusinessUser || !validOrWithUserToken">Login with Facebook</el-button>
            <div v-if="userData">
                <p>User logged in: {{ userData.name }}</p>
                <button @click="getBusinessData">Get Business Data</button>
                <pre>{{ businessData }}</pre>
            </div>
        </el-row>

        <el-row>
            <el-col v-if="validOrWithUserToken && !showAddSubscription">
                <el-row type="flex" class="row-bg" justify="center">
                    <el-button
                        type="success"
                        @click="toggleAddSubscription"
                    >Select FB Page</el-button>
                </el-row>
            </el-col>
            <el-col
                v-if="showAddSubscription"
                :span="24"
            >
                <select-fb-page
                    :fb-data="fbMeData"
                    :associated="associated"
                    :fb-api-endpoint="fbApiEndpoint"
                    :fb-user-info="fbUserInfo"
                    @loading-fb-add-subs="handleLoading"
                    @close-add-subscription="toggleAddSubscription"
                    @error-permission="validOrWithUserToken = false"
                    @reauthenticate="handleReauthenticate"
                ></select-fb-page>
            </el-col>
        </el-row>
    </div>

</template>

<script>
import SelectFbPage from "./SelectFbPage"
import store from "../../store"

export default {
    name: "FBLeadGen",
    components: {
        SelectFbPage,
    },
    props: {
        company: {
            type: Object,
            required: true
        },
        user: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: true,
            selectedCompany: {},
            currentUser: {},
            validOrWithUserToken: false,
            storedFBUserToken: false,
            fbMeData: {},
            showAddSubscription: false,
            fbApiEndpoint: '',
            associated: [],
            fbUserInfo: {},
            userData: null,
            businessData: null,
        }
    },
    computed: {
        fbBusinessUser: function() {
            return this.$store.getters.getFbBusinessUser
        },
    },
    created() {
        if(!document.getElementById('fb-business-sdk')) {
            const script = document.createElement('script');
            script.src = 'https://connect.facebook.net/es_LA/sdk.js';
            script.async = true;
            script.defer = true;
            script.crossOrigin = 'anonymous';
            script.id = 'fb-business-sdk';
            document.head.appendChild(script);
        }
    },
    mounted() {
        this.selectedCompany = this.company
        this.currentUser = this.user
        this.fbApiEndpoint = `api/company/${this.selectedCompany.id}/facebook/${this.currentUser.id}`
        this.loadFacebookSDK()
    },
    methods: {
        async checkUserToken() {
            if(this.fbBusinessUser?.response?.authResponse?.accessToken) {
                await this.axios.get(
                    // `api/company/${this.selectedCompany.id}/facebook/${this.currentUser.id}/me?fb-token=${this.fbBusinessUser.response.authResponse.accessToken}`
                    `api/company/${this.selectedCompany.id}/facebook/${this.currentUser.id}/me`
                )
                    .then(res => {
                        this.fbMeData = res.data.data.fb_user
                        this.associated = res.data.data.associated
                        this.fbUserInfo = res.data.data.fb_info
                        this.validOrWithUserToken = true
                        this.toggleAddSubscription()
                        return this.handleLoading(false)
                    })
                    .catch(err => {
                        if (err.response.data.message && err.response.data.message === 'missing user') {
                            return console.log('need to show log in button')
                        }
                        console.log('err?', err)
                        return this.handleLoading(false)
                    })
            }
        },

        async updateUsersFBUserToken(token, user_id) {
            await this.axios.post(
                `/api/company-user/${this.currentUser.id}/update`,
                {
                    'fb_user_access_token': token,
                    'fb_token_update': true,
                    'fb_user_id': +user_id,
                }
            )
                .then(res => {

                    const storedUser = JSON.parse(localStorage.currentUser)
                    localStorage.removeItem('currentUser')

                    const newStoredUser = {
                        ...storedUser,
                        fb_user_access_token: res.data.data.user.fb_user_access_token
                    }
                    localStorage.setItem('currentUser', JSON.stringify(newStoredUser))

                    // this.$notify({
                    //     title: 'Success:',
                    //     type: "success",
                    //     message: "Log in successful"
                    // })
                    this.validOrWithUserToken = true
                    this.checkUserToken()
                })
                .catch(err => {
                    console.log('errUser', err)
                    return this.$notify({
                        title: 'Error:',
                        type: "error",
                        message: err.response.data.message
                    })
                })
        },
        toggleAddSubscription() {
            this.showAddSubscription = !this.showAddSubscription
        },
        handleLoading(state = undefined) {
            if(state !== undefined) {
                this.loading = state
                return
            }
            this.loading = !this.loading
        },
        handleReauthenticate() {
            this.toggleAddSubscription()
            this.validOrWithUserToken = false
        },

        loadFacebookSDK() {
            if(FB) {
                FB.getLoginStatus(function (response) {
                    if (response.status === 'connected') {
                        store.commit('UpdateFbBusinessUser', {
                            response: response,
                            source: 'get-logged-in',
                        })
                    } else {
                        console.log('not logged in - leadgen', response)
                        store.commit('UpdateFbBusinessUser', null)
                    }
                });
            } else {
                window.fbAsyncInit = () => {
                    const fbAppId = this.$fbAppId
                    FB.init({
                        appId: fbAppId,
                        autoLogAppEvents: true,
                        xfbml: true,
                        version: process.env.MIX_FACEBOOK_APP_VERSION || 'v20.0',
                    });

                    const selfThis = this

                    FB.getLoginStatus(function (response) {
                        FB.api('/me', function (response) {
                            console.log(JSON.stringify(response), 'me!!!');
                        });

                        if (response.status === 'connected') {
                            console.log('login connected', response)
                            store.commit('UpdateFbBusinessUser', response)
                        } else {
                            console.log('not logged in', response)
                            store.commit('UpdateFbBusinessUser', null)
                        }
                    });
                };
                ((d, s, id) => {
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) return;
                    js = d.createElement(s);
                    js.id = id;
                    js.src = 'https://connect.facebook.net/en_US/sdk.js';
                    fjs.parentNode.insertBefore(js, fjs);
                })(document, 'script', 'facebook-jssdk');
            }
        },

        loginToFacebook() {
            FB.login((response) => {
                if (response.authResponse && response.status === "connected") {
                    // console.log('Logged in successfully:', response);
                    const meSelfThis = this
                    FB.api('/me', function(me_response) {
                        store.commit('UpdateFbBusinessMe', me_response)
                        meSelfThis.$notify({
                            title: "SimpSocial FB App Connected",
                            message: `Thank you, ${me_response.name}!`,
                            type: "success"
                        })
                    });
                    store.commit('UpdateFbBusinessUser', {
                        response: response,
                        source: 'login',
                    })
                } else if(response.status === "unknown") {
                    this.$notify({
                        title: "SimpSocial FB App Connection Failed",
                        message: "You opted not to connect the SimpSocial Facebook app to your Facebook account.",
                        type: "error"
                    })
                    store.commit('UpdateFbBusinessUser', {})
                }
                else {
                    console.log('User cancelled login or did not fully authorize.');
                }
            }, {scope: 'pages_show_list,pages_manage_ads,leads_retrieval,pages_read_engagement,ads_read,ads_management,pages_manage_metadata,email,pages_read_user_content,pages_messaging,business_management'});
        },
        logoutToFacebook() {

            FB.logout((response) => {
                if (response.authResponse) {
                    console.log('Logged in successfully:', response);
                } else {
                    console.log('User logged out, cancelled login or did not fully authorize.', response);
                    store.commit('UpdateFbBusinessUser', {})
                }
            });
        },
        getBusinessData() {
            FB.api('/me/adaccounts', 'GET', {}, (response) => {
                if (!response || response.error) {
                    console.log('Error fetching ad accounts', response.error);
                } else {
                    console.log('Ad Accounts:', response);
                }
            });
        }
    },
    watch: {
        'fbBusinessUser': function(val) {
            if(val && val?.response?.authResponse &&
                val?.response?.authResponse?.accessToken && val?.source === 'login'
            ) {
                return this.updateUsersFBUserToken(
                    val.response.authResponse.accessToken,
                    val.response.authResponse.userID
                )
            }

            if (val === null || !val)
                this.loading = false
            else
                return this.checkUserToken()
        },
    }
}
</script>
