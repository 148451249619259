<template>
    <el-main>
        <el-collapse
            v-model="activeName"
            accordion
            @change="handleChange"
        >
            <el-collapse-item title="Digital Showroom" name="digital-showroom">
                <DigitalShowroom
                    v-if="activeName === 'digital-showroom'"
                ></DigitalShowroom>
            </el-collapse-item>
            <el-collapse-item title="Call Tracking" name="call-tracking">
                <CallTracking
                    v-if="activeName === 'call-tracking'"
                ></CallTracking>
            </el-collapse-item>
            <el-collapse-item title="Connect Event Service" name="connect-event-service">
                <ConnectEventService
                    v-if="activeName === 'connect-event-service'"
                ></ConnectEventService>
            </el-collapse-item>
        </el-collapse>
    </el-main>
</template>
<script>

import ConnectEventService from "./integrations/ConnectEventService"
import CallTracking from "./integrations/CallTracking"
import DigitalShowroom from "./integrations/DigitalShowroom"

export default {
    name: 'VinSolutions',
    components: {
        DigitalShowroom,
        CallTracking,
        ConnectEventService,
    },
    data() {
        return {
            activeName: ''
        }
    },
    methods: {
        handleChange(data) {
            this.activeName = data
        }
    }
}
</script>
