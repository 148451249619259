<template>
    <div class="search-contact">
        <el-row :gutter="20">
            <el-col :span="24">
                <el-date-picker
                    v-model="daterange"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="-"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    format="MM-dd-yyyy"
                    @change="handleDaterangeChange"
                    :clear="handleDaterangeChange"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px">
            <el-col :span="24">
                <el-select v-model="leadSource" placeholder="Select Lead Source" @change="handleChangeLeadSource" clearable>
                    <el-option
                        v-for="item in leadSources"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>

                <el-select v-model="tag" placeholder="Select Tag" @change="handleChangeTag" style="margin-left: 10px" clearable>
                    <el-option
                        v-for="item in tags"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        <i class="fa fa-circle" aria-hidden="true" :style="'color:' + item.color"></i> {{item.name}}
                    </el-option>
                </el-select>

                <el-select v-model="dispositionStatus" placeholder="Select Disposition Status" @change="handleChangeDisposition" style="margin-left: 10px" clearable>
                    <el-option
                        v-for="item in dispositionStatuses"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        <i class="fa fa-circle" aria-hidden="true" :style="'color:' + item.color"></i> {{item.name}}
                    </el-option>
                </el-select>
            </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px">
            <el-col :span="24">
                <span>
                    Has Appointments
                    <el-switch
                    v-model="filters.has_appointments"
                    @change="handleHasAppointmentsChange"
                    active-color="#13ce66"
                    inactive-color="#818387"
                    :disabled="daterange == null"></el-switch>
                </span>

                <span v-if="filters.has_appointments">
                    <el-date-picker
                        v-model="appointmentDaterange"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="-"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                        format="MM-dd-yyyy"
                        @change="handleAppointmentDateChange"
                        :clear="handleAppointmentDateChange"
                        :picker-options="pickerOptions" style="margin-left: 10px">
                    </el-date-picker>
                </span>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="24">
                <el-card :body-style="{ padding: '0px', textAlign: 'center' }" style="display: inline-block;margin-top: 10px">
                    <div style="padding: 14px;">
                        <h3 v-if="loadingContacts">...</h3>
                        <h3 v-else>{{contactsCount}}</h3>
                        <div class="bottom clearfix" style="border-top: 1px solid #d7caca;padding-top: 10px;">
                            {{parseInt(contactsCount) | pluralize('Contact', 'Contacts')}}
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import store from "../../../store";

export default {
    data() {
        return {
            leadSources: [],
            tags: [],
            dispositionStatuses: [],
            leadSource: '',
            tag: '',
            dispositionStatus: '',
            selectedContacts: [],
            loadingContacts: false,
            contactsCount: 0,
            daterange: null,
            appointmentDaterange: null,
            filters: {
                start_date: '',
                end_date: '',
                date_field: 'created_at',
                has_appointments: 0,
                event_range: [],
                tags: [],
                lead_source: [],
                disposition_statuses: []
            },
            pickerOptions: {
                shortcuts: [{
                    text: 'Last week',
                    onClick(picker) {
                        const now = new Date();
                        //previous sat
                        const end = new Date(new Date().setDate(now.getDate() - (now.getDay() == 0 ? 7 : now.getDay() + 1)));
                        //last sunday before sat
                        const start = new Date(end.getTime() - (6 * 24 * 60 * 60 * 1000));

                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last Month',
                    onClick(picker) {
                        const now = new Date();
                        const start = new Date(now.getFullYear(),now.getMonth() - 1,1);
                        const end = new Date(now.getFullYear(),now.getMonth(),0);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'This Month',
                    onClick(picker) {
                        const date = new Date();
                        const start = new Date(date.getFullYear(), date.getMonth(), 1);
                        const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last 3 months',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
        };
    },
    computed: {
    },
    mounted() {
        this.company = JSON.parse(store.state.currentCompany)
        this.user = JSON.parse(store.state.currentUser)

        this.getCompanyleadSources()
        this.getCompanyTags()
        this.getCompanyDispositionStatuses()
    },
    methods: {
        getContactsCount() {
            this.loadingContacts = true

            this.axios
            .get('/api/company/' + this.company.aloware_id + '/aloware-contacts-count', {params: this.filters})
            .then(response => {
                if (response.data.success == true) {
                    this.contactsCount = response.data.data
                    this.$emit('onSelectContacts', [JSON.stringify({filters: this.filters})])
                    this.loadingContacts = false
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        getCompanyleadSources() {
            this.loadingContacts = true
            this.axios
            .get('/api/company/' + this.company.aloware_id + '/aloware-lead-sources')
            .then(response => {
                if (response.data.success == true) {
                    this.leadSources = response.data.data

                    this.loadingContacts = false
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        handleChangeLeadSource() {
            this.loadingContacts = true
            this.filters.lead_source = [this.leadSource]
            this.getContactsCount()

        },
        handleSubmit() {
            this.$emit('onSelectContacts', this.selectedContacts)
        },
        handleDaterangeChange() {
            this.filters.start_date = this.daterange !== null ? this.$options.filters.toSqlDate(this.daterange[0]) : '',
            this.filters.end_date = this.daterange !== null ? this.$options.filters.toSqlDate(this.daterange[1]) : '',

            this.getContactsCount()
        },
        getCompanyTags() {
            this.loadingContacts = true
            this.axios
            .get('/api/company/' + this.company.aloware_id + '/aloware-tags')
            .then(response => {
                if (response.data.success == true) {
                    this.tags = response.data.data

                    this.loadingContacts = false
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        handleChangeTag() {
            this.filters.tags = [this.tag]
            this.getContactsCount()
        },
        getCompanyDispositionStatuses() {
            this.loadingContacts = true
            this.axios
            .get('/api/company/' + this.company.aloware_id + '/aloware-disposition-statuses')
            .then(response => {
                if (response.data.success == true) {
                    this.dispositionStatuses = response.data.data

                    this.loadingContacts = false
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        handleChangeDisposition() {
            this.filters.disposition_statuses = [this.dispositionStatus]
            this.getContactsCount()
        },
        handleHasAppointmentsChange() {
            //console.log(this.appointmentDaterange)
            if(this.filters.has_appointments == true && this.appointmentDaterange !== null) {
                this.getContactsCount()
            } else if( this.filters.has_appointments == false ) {
                this.getContactsCount()
            }
        },
        handleAppointmentDateChange() {
            this.filters.event_range = [this.$options.filters.toSqlDate(this.appointmentDaterange[0]),this.$options.filters.toSqlDate(this.appointmentDaterange[1])],

            this.getContactsCount()
        },
    }
};
</script>
