<template>
    <el-drawer
        :visible="true"
        direction="rtl"
        :size="windowWidth < 1200 ? '100%' : '50%'"
        :before-close="handleBeforeClose"
    >
        <div>
            <ul style=" list-style-type: none;">
<!--                <li>-->
<!--                    {{ ad.ad_id }}-->
<!--                </li>-->
                <li>
                    Ad Name: <strong>{{ ad.ad_name }}</strong>
                </li>
                <li>
                    <span>{{ ad.ad_text }}</span>
                </li>
            </ul>
        </div>
            <el-container style="padding-bottom: .1rem;">
                <el-main
                    style="overflow-y: scroll !important; height: 75vh;"
                >
                    <comment
                        v-for="comment in comments"
                        :key="comment.comment_id"
                        :comment="comment"
                    ></comment>
                </el-main>
            </el-container>
    </el-drawer>
</template>

<script>
import Comment from "./Comment";

export default {
    name: "Comments",
    components: {
        Comment
    },
    props: {
        comments: {
            type: Array,
            required: true
        },
        ad: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            windowWidth: 0,
        }
    },
    mounted() {
        this.setupComponent()
    },
    methods: {
        setupComponent(start = true) {
            if(!start) {
                return window.removeEventListener('resize', () => {
                    this.windowWidth = window.innerWidth
                })
            }
            this.windowWidth = window.innerWidth
            window.addEventListener('resize', () => {
                this.windowWidth = window.innerWidth
            })
        },
        handleBeforeClose() {
            this.$emit('close-comments', false)
        }
    },
    beforeDestroy() {
        this.setupComponent(false)
    }
}
</script>

<style scoped>
.el-drawer__body, .el-tab-pane {
    height: 100vh !important;
    overflow-y: scroll !important;
}
</style>
