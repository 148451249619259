<template>
    <el-card
        style="margin-top: 1rem;"
    >
        <div slot="header">
            <span>Connected User Information</span>
        </div>
        <el-row
            type="flex"
            justify="start"
        >
            <el-col
                :span="12"
            >
                <el-avatar
                    :src="user.picture.data.url"
                    :title="user.short_name"
                    :size="60"
                    :alt="user.short_name"
                ></el-avatar>
                <h5>{{ user.name }}</h5>
                <h6>{{ user.email }}</h6>
            </el-col>

        </el-row>
    </el-card>
</template>

<script>
export default {
    name: "FbUserInfo",
    props: {
        user: {
            type: Object,
            required: true
        },
    },
}
</script>
