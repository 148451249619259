<template>
    <div>
        <el-row class="composer-btns">
            <el-col :span="24" :gutter="0">
                <el-dropdown @command="handleHeader">
                    <el-button size="small">
                        {{ headLabel.label }}
                    </el-button>
                    <el-dropdown-menu slot="dropdown" size="small">
                        <el-dropdown-item
                            v-for="item in headings"
                            :key="item.value"
                            :class="headActive === item.value ? 'el-button--primary' : ''"
                            :command="item.value"
                        >{{item.label}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-button
                    :type="boldActive ? 'primary' : ''"
                    size="small"
                    @click="handleBold">
                    B
                </el-button>
                <el-button
                    :type="underlineActive ? 'primary' : ''"
                    size="small"
                    @click="handleUnderline">
                    U
                </el-button>
                <el-button
                    size="small"
                    @click="editor.chain().focus().undo().run()"
                    :disabled="!canUndo">
                    <i class="el-icon-refresh-left"></i>
                </el-button>
                <el-button
                    size="small"
                    @click="editor.chain().focus().redo().run()"
                    :disabled="!canRedo">
                    <i class="el-icon-refresh-right"></i>
                </el-button>
                <el-dropdown @command="handleTextAlign">
                    <el-button size="small">
                        Align
                    </el-button>
                    <el-dropdown-menu slot="dropdown" size="small">
                        <el-dropdown-item
                            v-for="item in alignments"
                            :key="item"
                            :class="alignActive === item ? 'el-button--primary' : ''"
                            :command="item"
                        >{{item}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-col>
        </el-row>
        <bubble-menu
            class="bubble-menu"
            :tippy-options="{ animation: true }"
            :editor="editor"
            v-if="editor"
            v-show="editor.isActive('custom-image')"
        >
            <span style="color: #aaa">Width: </span>
            <button @click="handleImageResize($event, 25)"
                :class="{'is-active': editor.isActive('custom-image', {size: '25'})}">
                25%
            </button>
            <button @click="handleImageResize($event, 50)"
                :class="{'is-active': editor.isActive('custom-image', {size: '50'})}">
                50%
            </button>
            <button @click="handleImageResize($event, 75)"
                :class="{'is-active': editor.isActive('custom-image', {size: '75'})}">
                75%
            </button>
            <button @click="handleImageResize($event, 100)"
                :class="{'is-active': editor.isActive('custom-image', {size: '100'})}">
                100%
            </button>
            <span style="color: #aaa">|</span>
            <button @click="handleTextAlignment($event, 'left')"
                :class="{'is-active': editor.isActive('custom-image', {float: 'left'})}">
                Left
            </button>
            <button
                @click="handleTextAlignment($event, 'center')"
                :class="{'is-active': editor.isActive('custom-image', {float: 'none'})}">
                Center
            </button>
            <button
                @click="handleTextAlignment($event, 'right')"
                :class="{'is-active': editor.isActive('custom-image', {float: 'right'})}">
                Right
            </button>
        </bubble-menu>
        <editor-content
            :editor="editor"
            ref="composer-editor"
            class="composer-editor"
            @keydown.native="handleKeydown"
        ></editor-content>
        <el-row v-if="attachedFileList.length > 0"
            justify="start"
            :gutter="20" >
            <el-col :span="24">
                Attachment(s):
                <el-tag
                    v-for="(item, i) in attachedFileList"
                    :key="i"
                    type="info">
                    {{item.name}}

                    <i
                        class="el-icon-circle-close"
                        style="cursor: pointer"
                        @click="handleRemoveAttachedItem(item, i)">
                    </i>
                </el-tag>
            </el-col>
        </el-row>
        <el-row style="margin-top: 15px" v-if="!hideActions">
            <el-col :span="15">
                <el-tooltip
                    class="item"
                    effect="dark"
                    content="Attach Files"
                    placement="top-start">
                    <el-upload
                        ref="imageUpload"
                        size="mini"
                        style="display: inline-block;"
                        :http-request="handleAttachFile"
                        action="/"
                        :show-file-list="false"
                        :file-list="attachedFileList"
                        multiple>
                        <el-button
                            type="text"
                            slot="trigger"
                            icon="el-icon-paperclip"
                            size="medium"
                            round
                            style="background-color: rgb(18,18,18);">
                        </el-button>
                    </el-upload>
                </el-tooltip>
                <el-tooltip
                    class="item"
                    effect="dark"
                    content="Insert Image"
                    placement="top-start"
                >
                    <el-upload
                        ref="imageUpload"
                        size="mini"
                        style="display: inline-block;"
                        :http-request="handleInsertImage"
                        action="/"
                        :show-file-list="false"
                        multiple
                    >
                        <el-button
                            type="text"
                            slot="trigger"
                            icon="el-icon-picture-outline"
                            size="medium"
                            round
                            style="background-color: rgb(18,18,18);"
                        >
                        </el-button>
                    </el-upload>
                </el-tooltip>
                <el-tooltip
                    v-if="!hideTemplateBtn"
                    class="item"
                    effect="dark"
                    content="Use Template"
                    placement="top-start"
                >
                    <el-button
                        type="text"
                        icon="far fa-file-code"
                        size="medium"
                        round
                        @click="handleUseTemplate"
                        style="background-color: rgb(18,18,18);"
                    >
                    </el-button>
                </el-tooltip>

                <el-tooltip
                    class="item"
                    effect="dark"
                    content="Insert Variable"
                    placement="top-start">
                    <el-button
                        type="text"
                        icon="fa fa-code"
                        @click="handleInsertVariable"
                        round
                        size="medium"
                        style="background-color: rgb(18,18,18);"
                    >
                    </el-button>
                </el-tooltip>

                <el-tooltip
                    class="item"
                    effect="dark"
                    content="Insert Inventory"
                    placement="top-start">
                    <el-button
                        type="text"
                        icon="fa fa-car"
                        size="medium"
                        round
                        @click="handleInsertInventory"
                        style="background-color: rgb(18,18,18);"
                    >
                    </el-button>
                </el-tooltip>

                <el-tooltip
                    class="item"
                    effect="dark"
                    content="Credit Application"
                    placement="top-start">
                    <el-button
                        type="text"
                        icon="el-icon-edit-outline"
                        size="medium"
                        round
                        @click="handleInsertCreditApplication"
                        style="background-color: rgb(18,18,18);"
                    >
                    </el-button>
                </el-tooltip>
            </el-col>
        </el-row>
        <TemplateSelectorDialog :visible.sync="templateSelectorDialogVisible" @onTemplateSelect="handleOnSelectTemplate" />
        <InventorySelectorDialog :visible.sync="inventorySelectorDialogVisible" @onInventorySelect="handleOnSelectInventory" />
        <VariableSelectorDialog :visible.sync="variableSelectorDialogVisible" @onVariableSelect="handleOnSelectVariable" />
    </div>
</template>
<script>
import store from "../../../store"
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import TextAlign from '@tiptap/extension-text-align'
import ImageLink from './extensions/ImageLink'
import MessageTree from "./extensions/MessageTree"
import ResizableImage from "./extensions/ResizableImage"
import Table from '@tiptap/extension-table'
import TableHeader from '@tiptap/extension-table-header'
import TableCell from '@tiptap/extension-table-cell'
import TableRow from '@tiptap/extension-table-row'

import TemplateSelectorDialog from "../dialogs/TemplateSelectorDialog"
import InventorySelectorDialog from "../dialogs/InventorySelectorDialog"
import VariableSelectorDialog from "../dialogs/VariableSelectorDialog"

export default {
    name: "ComposerEditor",
    components: {
        EditorContent,
        BubbleMenu,
        TemplateSelectorDialog,
        InventorySelectorDialog,
        VariableSelectorDialog,
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        carDetail: {
            type: Object,
            default: () => ({})
        },
        replyContent: {
            type: HTMLDivElement,
            default: null
        },
        message: {
            type: Object,
            default: () => ({})
        },
        hideActions: {
            type: Boolean,
            default: false
        },
        hideTemplateBtn: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            company: {},
            user: {},
            extensions: [
                StarterKit.configure({
                    heading: {
                        levels: [2, 3, 4]
                    },
                    history: true,
                }),
                Underline,
                Link,
                ResizableImage.configure({
                    HTMLAttributes: {
                        class: 'custom-image'
                    }
                }),
                TextAlign.configure({
                    types: ['heading', 'paragraph', 'image'],
                }),
                ImageLink,
                MessageTree,
                Table,
                TableHeader,
                TableCell,
                TableRow
            ],
            editor: null,
            editorExists: false,
            editorKey: 0,
            boldActive: false,
            underlineActive: false,
            headings: [
                { value: 0, label: 'Normal text' },
                { value: 2, label: 'Heading 1' },
                { value: 3, label: 'Heading 2' },
                { value: 4, label: 'Heading 3' },
            ],
            headActive: 0,
            alignments: [
                'left',
                'center',
                'right'
            ],
            alignActive: 'left',
            canRedo: false,
            canUndo: false,
            attachedFileList: [],
            creditAppUrl: '',
            showVariables: false,
            editorLoadTxt: 'Attaching image...',
            uploadingAttachment: false,
            templateSelectorDialogVisible: false,
            inventorySelectorDialogVisible: false,
            variableSelectorDialogVisible: false
        }
    },
    computed: {
        showButton() {
            return this.editor?.state?.selection?.node?.type?.name === 'ResizableImage';
        },
        headLabel() {
            return this.headings.find( ({value}) => value === this.headActive)
        }
    },
    mounted() {
        this.company = JSON.parse(store.state.currentCompany)
        this.user = JSON.parse(store.state.currentUser)

        this.setUpEditor()
        this.setReplyContent()
        this.getCreditAppurl()
    },
    methods: {
        setUpEditor() {
            this.editor = new Editor({
                element: document.querySelector('.composer-editor'),
                content: this.value,
                extensions: this.extensions,
                autofocus: 'start',
                onUpdate: (e) => {
                    this.handleUpdate(e)
                },
            })
        },
        setReplyContent() {
            if(this.replyContent) {
                this.editor.chain().focus().enter()
                    .setMessageTree({
                        content: this.replyContent,
                        message: this.message
                    }).run()
            }
        },
        handleKeydown(e) {
            if(e.keyCode === 13) {
                this.headActive = 0
                const chain = this.editor.chain()
                    .focus()
                    .setParagraph()

                if(this.boldActive) {
                    this.boldActive = false
                    chain.toggleBold()
                }

                if(this.underlineActive) {
                    this.underlineActive = false
                    chain.toggleUnderline()
                }

                this.alignActive = 'left'

                return chain.run()
            }
        },
        handleUpdate(e) {
            this.canRedo = this.editor.can().redo()
            this.canUndo = this.editor.can().undo()
            return this.$emit('update', this.editor.getHTML())
        },
        handleBold() {
            this.boldActive = !this.editor.isActive('bold')
            return this.editor.chain().focus().toggleBold().run()
        },
        handleUnderline() {
            this.underlineActive = !this.editor.isActive('underline')
            return this.editor.chain().focus().toggleUnderline().run()
        },
        handleHeader(num) {
            this.headActive = num
            if (num === 0) {
                const selectedLevel = this.editor.getAttributes('heading').level
                return this.editor
                    .chain().focus()
                    .toggleHeading({ level: selectedLevel })
                    .setParagraph()
                    .run()
            }
            return this.editor.chain().focus().setHeading({ level: num }).run()
        },
        handleTextAlign(position){
            this.alignActive = position
            this.editor.chain().focus().setTextAlign(position).run()
        },
        async imageHandler(imageDataUrl, type, imageData, ext = false, anchor = false) {
            const formData = new FormData()
            const image = ext ? imageData : imageData.toFile()
            formData.append('image', image)
            this.uploadingAttachment = true
            await this.axios.post(
                `/api/company/${this.company.id}/webmail/upload-embed`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            .then(res => {
                this.editor.commands.setContent(this.editor.getHTML() + '<img src="'+ res.data.image_url +'" alt="image">', false)
                this.uploadingAttachment = false
                this.handleUpdate(1)
            })
            .catch(err => {
                this.uploadingAttachment = false
                this.$notify({
                    title: `Error`,
                    message: 'Unable to attach file',
                    type: 'error'
                })
            })
        },
        getCreditAppurl() {
            this.axios.get(
                '/api/company/' + this.company.aloware_id + '/user/' + this.user.aloware_id + '/get-credit-application-link'
            ).then(res => {
                this.creditAppUrl = res.data
            })
        },
        handleInsertCreditApplication() {
            let creditAppHtml = 'To apply for credit using our secure application please click this link: <a href="'+ this.creditAppUrl +'" target="_blank">' + this.creditAppUrl + '</a>';
            this.editor.commands.setContent(this.editor.getHTML() + creditAppHtml, false)
        },
        async handleAttachFile(upload) {

            const fileSize = upload.file.size / 1024 / 1024
            if(fileSize >= 2) {
                return this.$notify({
                    title: 'File size too large.',
                    message: `${upload.file.name} file size is more than 2MB.`,
                    type: 'error'
                })
            }

            const formData = new FormData()
            formData.append('attachment', upload.file)
            this.uploadingAttachment = true

            await this.axios.post(
                `/api/company/${this.company.id}/webmail/upload-attachment`,
                formData,
                {headers: {'Content-Type': 'multipart/form-data'}}
            )
                .then(res => {
                    const item = {
                        name: upload.file.name,
                        url: res.data.attachment_url
                    }
                    this.attachedFileList = [
                        ...this.attachedFileList,
                        item
                    ]
                })
                .catch(err => {
                    this.uploadingAttachment = false
                    this.$notify({
                        title: `Error`,
                        message: 'Unable to attach file',
                        type: 'error'
                    })
                })

            /*if(upload.file.type.includes('image')) {
                return this.imageHandler('/', upload.file.type, upload.file, true)
            }*/
        },
        async handleInsertImage(upload) {
            const fileSize = upload.file.size / 1024 / 1024
            if(fileSize >= 2) {
                return this.$notify({
                    title: 'File size too large.',
                    message: `${upload.file.name} file size is more than 2MB.`,
                    type: 'error'
                })
            }

            if(upload.file.type.includes('image')) {
                return this.imageHandler('/', upload.file.type, upload.file, true)
            }
        },
        handleRemoveAttachedItem(item, i) {
            this.attachedFileList.splice(i, 1)
        },
        handleUseTemplate() {
            this.templateSelectorDialogVisible = true
        },
        handleInsertVariable() {
            this.variableSelectorDialogVisible = true
        },
        handleInsertInventory() {
            this.inventorySelectorDialogVisible = true
        },
        handleOnSelectTemplate(template) {
            this.editor.commands.setContent(template.message, false)
            this.$emit('onSelectTemplate', template)
            this.templateSelectorDialogVisible = false
        },
        handleOnSelectInventory(car) {
            let carImageUrl = ''
            if( car.galleries.length > 0 ) {
                carImageUrl = car.galleries[0].url
            }

            let carHTML = '<table border="1" cellspacing="0" cellspadding="5" class="car-details">'
            carHTML += '<tr>'
            if( carImageUrl !== '' ) {
                carHTML +=      '<td>'
                carHTML +=          '<img src="'+ carImageUrl +'" alt="car-image" />'
                carHTML +=      '</td>'
            }
            carHTML +=      '</tr><tr><td>'
            carHTML +=          car.year + ' ' + car.make + ' ' + car.model + '<br />'
            carHTML +=          '<b>'+ this.$options.filters.currency(car.price) +'</b><br /><br />'
            carHTML +=          '<a href="'+ car.car_link +'" target="_blank">Click here for more details</a>'
            carHTML +=      '</td>'
            carHTML += '</tr>'
            carHTML += '</table>'

            this.editor.commands.setContent(this.editor.getHTML() + carHTML, false)
            this.inventorySelectorDialogVisible = false
        },
        handleOnSelectVariable(variable) {
            this.editor.commands.setContent(this.editor.getHTML() + variable, false)
            this.variableSelectorDialogVisible = false
        },
        handleImageResize(e, sizeVal) {
            e.preventDefault()
            this.editor.chain().focus().setAttributes({ size: sizeVal }).run()
        },
        handleTextAlignment(e, alignmentVal) {
            e.preventDefault()
            this.editor.chain().focus().setAttributes({ align: alignmentVal }).run()
        }
    },
    watch: {
        value(value) {
            const isSame = this.editor.getHTML() === value

            if (isSame) {
                return
            }

            this.editor.commands.setContent(value, false)
        },
        'attachedFileList' : {
            handler: function(val) {
                this.$emit('attached-file-list', val)
            }
        },
    },
    beforeDestroy() {
        this.editor.destroy()
    },
}
</script>

<style>
    .composer-editor .ProseMirror{
        background-color: #fff;
        color: #000;
        margin: .1rem .1rem;
        padding: .1rem .1rem;
        /*line-height: .5rem !important;*/
    }
    .ProseMirror:focus {
        outline: none !important;
    }
    .ProseMirror {
        border: .1rem solid #000 !important;
    }
    .composer-editor > div.ProseMirror {
        min-height: 200px;
    }
</style>
