<template>
    <el-container class="mail-templates"
        v-loading.service.lock="loading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >
        <el-row :gutter="20">
            <el-col :span="12">
                <div class="grid-content">
                    <temp-form
                        :key="formKey"
                        :action="action"
                        :template="template"
                        :errors="errors"
                        @submit-template="handleSaveTemplate"
                        @reset-form="handleResetForm"
                    >
                    </temp-form>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="grid-content" style="max-height: 100vh">
                    <templates-tables
                        :templates="templates"
                        :key="templateKey"
                        @table-action="handleTableAction"
                    ></templates-tables>
                </div>
            </el-col>
        </el-row>
    </el-container>
</template>
<style>

</style>
<script>
import store from "../../store";
import TemplatesTables from "./templates/TemplatesTables";
import TempForm from "./templates/TempForm";

export default {
    name: 'WebMailTemplates',
    components: {
        TemplatesTables,
        TempForm
    },
    data() {
        return {
            loading: true,
            company: {},
            user: {},
            templates: [],
            template: {},
            templateKey: 0,
            formKey: 0,
            errors: {},
            action: 'new'
        };
    },
    computed: {
        endpoint() {
            return `/api/company/${this.company.id}/user/${this.user.id}/email/templates`
        }
    },
    mounted() {
        this.company = JSON.parse(store.state?.currentCompany)
        this.user = JSON.parse(store.state?.currentUser)
        this.getVariables()
        this.getTemplates()
    },
    methods: {
        startLoader() {
            this.loading = true
        },
        
        endLoader() {
            this.loading = false
        },

        async getTemplates() {
            this.startLoader()
            await this.axios.get(this.endpoint)
                .then(res => {
                    this.handleResetForm()
                    this.templates = res.data.data
                    this.templateKey++
                    return this.endLoader()
                })
                .catch(err => {
                    this.notifier('Error', err, 'error')
                    return this.endLoader()
                })
        },
        
        async handleSaveTemplate(action = 'new', data) {
            let url = this.endpoint
            
            if (action === 'delete') {
                return await this.axios.delete(
                    `${url}/${data.id}`
                )
                    .then(res => {
                        this.getTemplates()
                        return this.notifier(
                            res.data.message,
                            'Template has been delete.',
                            'success'
                        )
                    })
                    .catch(err => {
                        this.notifier('Error', err, 'error')
                    })
            }

            if(action === 'edit') {
                return await this.axios.put(`${url}/${data.id}`, data)
                    .then(res => {
                        this.getTemplates()
                        return this.notifier(
                            'Success',
                            res.data.message,
                            'success'
                        )
                    })
                    .catch(err => {
                        this.notifier('Error', err, 'error')
                        this.errors = JSON.parse(err.request.response)
                    }) 
            }
            
            if(action === 'duplicate') {
                delete data.id
                delete data.created_at
                delete data.updated_at
            }
            
            await this.axios.post(url, data)
                .then(res => {
                    this.getTemplates()
                    this.action = 'new'
                    return this.notifier(
                        'Success',
                        res.data.message,
                        'success'
                    )
                })
                .catch(err => {
                    this.notifier('Error', err, 'error')
                    this.errors = JSON.parse(err.request.response)
                })
        },
        
        handleTableAction(action, data) {
            if(action === 'delete') {
                return this.handleSaveTemplate(action, data)
            }
            this.template = data
            this.action = action
        },
        
        handleResetForm() {
            this.action = 'new'
            this.formKey++
        },

        notifier(title, message, type) {
            return this.$notify({
                title,
                message,
                type
            })
        },

        async getVariables() {
            await this.axios.get(
                '/api/variables'
            )
                .then(res => {
                    return this.$store.commit('UpdateEmailVariables', res.data.data)
                })
        }
    }
};
</script>
