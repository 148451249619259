<template>
    <el-container
        style="display: flex; justify-content: center; overflow: hidden;"
    >
        <el-card
            class="mobile-box"
            v-if="isEngaged || isWithSequence"
            style="width: 20rem; text-align: center;"
        >
            <h3><em>{{ contactName }}</em></h3>
            <el-row
                type="flex"
                justify="center"
            >
                <el-col :span="12">
                    <p style="color: #A5A7AD">
                        Disengage Sarah AI
                    </p>
                    <el-button
                        type="danger"
                        icon="el-icon-switch-button"
                        circle
                        size="medium"
                        style="font-size: 2rem;"
                        :disabled="engagement.is_engage_paused && !isWithSequence"
                        @click.prevent="handleEngagement"
                    ></el-button>

<!--                    <el-switch-->
<!--                        v-model="engage"-->
<!--                        :active-text="engage ? 'AI Engaged' : 'Engage'"-->
<!--                        :inactive-text="engage ? 'Pause' : 'AI Paused'"-->
<!--                        active-color="#67C23A"-->
<!--                        inactive-color="#ff4949"-->
<!--                        @change="handleEngagement"-->
<!--                    >-->
<!--                    </el-switch>-->
                </el-col>
            </el-row>
        </el-card>
        <el-empty
            v-else
            description="No action needed."
        ></el-empty>
    </el-container>
</template>

<script>
export default {
    name: 'EngageActionDisenroll',
    props: {
        contact: {
            required: true,
            type: Object
        },
        engagement: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            disenrolled: false,
            engage: false,
        }
    },
    mounted() {
        this.engage = this.engagement?.is_engaged_active
    },
    methods: {
        handleEngagement() {
            return this.$emit('engagement-update')
        }
    },
    computed: {
        contactName() {
            if(this.contact.name) {
                return this.contact.name
            }
            if(this.contact.phone_number) {
                return this.contact.phone_number
            }
            return 'The Contact'
        },
        isEngaged() {
            return typeof this.engagement === 'object' && this.engagement.hasOwnProperty('is_engage_paused') && !this.engagement.is_engage_paused
        },
        isWithSequence() {
            return typeof this.contact === 'object' && this.contact.hasOwnProperty('sequence_id') && this.contact.sequence_id !== null
        }
    }
}
</script>
