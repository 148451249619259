<template>
    <div class="search-contact">
        <el-row :gutter="20">
            <el-col :span="12">
                <el-row>
                    <el-col>
                        <el-input
                            placeholder="Search Contact"
                            suffix-icon="el-icon-search"
                            v-model="q"
                            @input="handleSearch"
                            clearable>
                        </el-input>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-row>
                            <el-col v-loading="loadingContacts">
                                <div class="list-contacts">
                                    <div v-for="contact in contacts" v-bind:key="contact.id">
                                        <div class="item-contact" v-if="selectedContacts.indexOf(contact) < 0">
                                            <el-row>
                                                <el-col :span="18">
                                                    <div class="item-contact-name">{{contact.name}}</div>
                                                    <div class="item-contact-email">{{contact.email}}</div>
                                                </el-col>
                                                <el-col :span="6" style="text-align: right">
                                                    <el-link icon="el-icon-circle-plus-outline"
                                                        :underline="false"
                                                        @click="handleAddContact(contact)"
                                                        ></el-link>
                                                </el-col>
                                            </el-row>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12" style="text-align:right">
                                <el-button v-if="hasPrev" @click="handlePrevPage" size="small"><i class="el-icon-arrow-left"></i> Previous</el-button>
                                <el-button v-else disabled size="small"><i class="el-icon-arrow-left"></i> Previous</el-button>
                            </el-col>
                            <el-col :span="12" style="text-align:left">
                                <el-button v-if="hasNext" @click="handleNextPage" size="small">Next <i class="el-icon-arrow-right"></i></el-button>
                                <el-button v-else disabled size="small">Next <i class="el-icon-arrow-right"></i></el-button>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <p>Selected contacts</p>
                <div class="list-contacts">
                    <div v-for="contact in selectedContacts" v-bind:key="contact.id" class="item-contact">
                        <el-row>
                            <el-col :span="18">
                                <div class="item-contact-name">{{contact.name}}</div>
                                <div class="item-contact-email">{{contact.email}}</div>
                            </el-col>
                            <el-col :span="6" style="text-align: right">
                                <el-link icon="el-icon-remove-outline"
                                    :underline="false"
                                    @click="handleRemoveContact(contact)"
                                    ></el-link>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import store from "../../../../store";

export default {
    props: ['visible'],
    data() {
        return {
            q: '',
            currentPage: 1,
            perPage: 20,
            contacts: [],
            selectedContacts: [],
            hasNext: false,
            hasPrev: false,
            prevPageValue: 0,
            nextPageValue: 0,
            loadingContacts: false
        };
    },
    computed: {
    },
    mounted() {
        this.company = JSON.parse(store.state.currentCompany)
        this.user = JSON.parse(store.state.currentUser)
        this.getContacts()
    },
    methods: {
        getContacts() {
            let params = {
                page: this.currentPage,
                page_size: this.perPage,
                q: this.q,
            }
            this.loadingContacts = true
            this.axios
            .get('/api/company/' + this.company.aloware_id + '/aloware-contact', {params: params})
            .then(response => {
                if (response.data.success == true) {
                    this.contacts = response.data.data
                    this.hasNext = response.data.pagination.has_next_page
                    this.hasPrev = response.data.pagination.has_prev_page
                    this.prevPageValue = response.data.pagination.prev_page_value
                    this.nextPageValue = response.data.pagination.next_page_value
                    this.loadingContacts = false
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        handleSearch() {
            this.currentPage = 1
            this.getContacts()
        },
        handlePrevPage() {
            this.currentPage = this.prevPageValue
            this.getContacts()
        },
        handleNextPage() {
            this.currentPage = this.nextPageValue
            this.getContacts()
        },
        handleAddContact(contact) {
            this.selectedContacts.push(contact)
            this.$emit('onSelectContacts', this.selectedContacts)
        },
        handleRemoveContact(contact) {
            this.selectedContacts.splice(this.selectedContacts.indexOf(contact), 1)
            this.$emit('onSelectContacts', this.selectedContacts)
        }
    }
};
</script>
