<template>
    <div class="search-contact">
        <el-row :gutter="20">
            <el-col :span="24">
                <el-select v-model="dmsExport" placeholder="Select Export" @change="handleChangeDmsExport">
                    <el-option
                        v-for="item in dmsExports"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        <i class="fa fa-circle" aria-hidden="true" :style="'color:' + item.color"></i> {{item.name}}
                    </el-option>
                </el-select>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="24">
                <el-card :body-style="{ padding: '0px', textAlign: 'center' }" style="display: inline-block;margin-top: 10px">
                    <div style="padding: 14px;">
                        <h3 v-if="loadingContacts">...</h3>
                        <h3 v-else>{{contactsCount}}</h3>
                        <div class="bottom clearfix" style="border-top: 1px solid #d7caca;padding-top: 10px;">
                            {{parseInt(contactsCount) | pluralize('Contact', 'Contacts')}}
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import store from "../../../store";
import moment from 'moment';

export default {
    props: {
        dmsExportId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            dmsExports: [],
            dmsExport: '',
            selectedContacts: [],
            loadingContacts: false,
            contactsCount: 0,
        };
    },
    computed: {
    },
    mounted() {
        this.company = JSON.parse(store.state.currentCompany)
        this.user = JSON.parse(store.state.currentUser)
        this.getDmsExports()
    },
    methods: {
        getDmsExports() {
            this.loadingContacts = true
            this.axios
            .get('/api/company/' + this.company.aloware_id + '/dms-exports')
            .then(response => {
                if (response.data.success == true) {
                    //this.dmsExports = response.data.data
                    let result = response.data.data

                    if(result.length > 0) {
                        result.forEach((e,i) => {
                            this.dmsExports.push({
                                name: this.getOptionLabel(e),
                                id: e.id,
                            })

                            if(e.id == this.dmsExportId) {
                                this.contactsCount = e.total_records
                            }
                        })
                    }
                    if(this.dmsExportId > 0) {
                        this.dmsExport = this.dmsExportId
                        this.$emit('onSelectContacts', [`{dms_export:${this.dmsExport}}`])
                    }

                    this.loadingContacts = false
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        handleChangeDmsExport() {
            this.loadingContacts = true
            this.axios.get(
                `api/company/${this.company.id}/dms-export/${this.dmsExport}`
            )
            .then((response) => {
                this.loadingContacts = false
                this.contactsCount = response.data.total_records
                //console.log(response.data.total_records)
                this.$emit('onSelectContacts', [`{dms_export:${this.dmsExport}}`])
            })
        },
        getOptionLabel(item) {
            let optionLabel = 'DMS Equity Export'
            if(item.component == 'dms-equity-bulkmail') {
                optionLabel = 'DMS Equity Export'
            }

            if(item.component === 'digital-lead-wars-bulkmail') {
                optionLabel = 'Digital Lead Wars'
            }

            //console.log(moment(item.created_at).format('lll'))
            optionLabel += ' ' + this.$options.filters.formatDate(moment(item.created_at).format('YYYY-MM-DD'))
            optionLabel += ' (' + item.total_records + ' contacts)'
            return optionLabel
        }
    }
};
</script>
