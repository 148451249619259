<template>
    <el-tabs
        v-model="activeTab"
        type="border-card"
    >
        <el-tag
            v-if="showEnv"
            :type="isProduction ? 'success' : 'warning'"
        >{{isProduction ? 'Production' : 'Sandbox'}}</el-tag>
        <el-tab-pane
            label="Manage Subscriptions"
            name="manage-subscriptions"
        >
            <manage-subscriptions
                v-if="activeTab === 'manage-subscriptions'"
                @ces-production="cesProduction"
            ></manage-subscriptions>
        </el-tab-pane>
<!--        <el-tab-pane-->
<!--            label="Partner Dealers"-->
<!--            name="partner-dealers"-->
<!--        >-->
<!--            <PartnerDealers-->
<!--                v-if="activeTab === 'partner-dealers'"-->
<!--                :partners="[]"-->
<!--            >-->
<!--            </PartnerDealers>-->
<!--        </el-tab-pane>-->
    </el-tabs>
</template>
<script>
import ManageSubscriptions from "./connect-event-service/ManageSubscriptions"
import PartnerDealers from "../components/PartnerDealers"

export default {
    name: 'ConnectEventService',
    components: {
        PartnerDealers,
        ManageSubscriptions,
    },
    data() {
        return {
            activeTab: 'manage-subscriptions',
            isProduction: false,
            showEnv: false,
            company: {},
        }
    },
    mounted() {
        this.setupComponent()
    },
    methods: {
        cesProduction(data) {
            this.isProduction = data
            this.showEnv = true
        },
        setupComponent() {
            this.company = JSON.parse(this.$store.state.currentCompany)
            // this.handleGetPartnerDealers()
        },
        async handleGetPartnerDealers() {
            this.loading = true
            const url = `api/company/${this.company.id}/crm-dms-integrations/vs-dealer/integrations/partner-dealers?product=ces`
            await this.axios.get(url)
                .then(res => {
                    this.partners = res.data.data.partners.Items
                    this.isProduction = res.data.data.production
                    this.showEnv = true

                    this.partners = this.partners.sort((a, b) => {
                        const partnerA = a.Name.toLowerCase()
                        const partnerB = b.Name.toLowerCase()

                        if (partnerA < partnerB) {
                            return -1
                        }
                        if (partnerA > partnerB) {
                            return 1
                        }
                        return 0
                    })
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    return this.$root.handleErrors(err.response)
                })
        },
    },
    watch: {}
}
</script>
