<template>
        <el-table
            :data="features"
            stripe
            style="width: 100%">
            <el-table-column
                label="Feature Name"
                prop="name"
            ></el-table-column>
            <el-table-column
                label="Status"
                align="center"
            >
                <template v-slot="props">
                    <el-switch
                        v-model="props.row.status"
                        :value="props.row.status"
                        v-if="props.row.type === 'switch'"
                        :active-text="`${props.row.status ? 'Enabled' : 'Disabled'}`"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        @change="confirmAction(props.row)"
                    >
                    </el-switch>
                    <el-select
                        v-model="props.row.status"
                        :value="props.row.status"
                        value-key="id"
                        v-if="props.row.type === 'select'"
                        placeholder="Select a Sequence"
                        clearable
                        filterable
                        @change="confirmAction(props.row)"
                    >
                        <el-option
                            v-for="sequence in sequences"
                            :key="sequence.id"
                            :label="`${sequence.name} #${sequence.id}`"
                            :value="sequence"
                        ></el-option>
                    </el-select>
                </template>
            </el-table-column>
        </el-table>
</template>
<script>
export default {
    name: 'FeaturesTable',
    props: {
        integration: {
            type: Object,
            required: true,
        },
        sequences: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            features: [],
            old_integration: {},
        }
    },
    mounted() {
        this.setupComponent()
    },
    methods: {
        setupComponent() {
            this.features = [
                {
                    name: 'To send Contacts DNC request to VinSolutions',
                    status: this.integration.to_send_contact_dnc,
                    field: 'to_send_contact_dnc',
                    action_desc: 'Sending contact DNC to VinSolutions',
                    type: 'switch',
                },
                {
                    name: 'To retrieve daily leads data from VinSolutions',
                    status: this.integration.to_retrieve_daily_leads,
                    field: 'to_retrieve_daily_leads',
                    action_desc: 'Retrieving daily leads data',
                    type: 'switch',
                },
                {
                    name: 'Call Tracking - To send call logs to VinSolutions',
                    status: this.integration.to_call_tracking,
                    field: 'to_call_tracking',
                    action_desc: 'Sending call logs to VinSolutions',
                    type: 'switch',
                },
                {
                    name: 'To enroll new daily leads data from VinSolutions to this Sequence',
                    status: this.integration.new_contact_to_sequence,
                    field: 'new_contact_to_sequence',
                    action_desc: 'Enroll new daily leads to this sequence',
                    type: 'select',
                },
            ]
            this.old_integration = JSON.parse(JSON.stringify(this.integration))
        },
        async confirmAction(data) {
            let status = '';
            if(data.hasOwnProperty('status') && typeof data.status === 'boolean') {
                status = data.status ? 'Enable ' : 'Disable '
            }

            await this.$confirm(`Are you sure to ${status}${data.action_desc}?`, 'Confirm Action')
                .then(res => {
                    return this.$emit('update-integration', data)
                })
                .catch(err => {

                    const feature = this.features.findIndex(feature => {
                        return feature.field === data.field
                    })

                    if(typeof data.status == 'boolean') {
                        this.features[feature].status = !data.status
                    }

                    if(typeof data.status == 'object') {
                        this.features[feature].status = this.old_integration[data.field]
                    }

                    return this.$message({
                        type: 'info',
                        message: 'Action canceled'
                    });
                })
        }
    },
}
</script>
