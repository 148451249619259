<template>
    <el-main>
        <h6 style="text-align: end">Files to import: {{filesToImport.length}}</h6>
        <el-table
            :data="filesToImport"
            stripe
        >
            <el-table-column
                label="ID"
                prop="id"
            />
            <el-table-column
                label="File Name"
                prop="file_name"
            />
            <el-table-column
                label="Uploaded At"
            >
                <template v-slot="scope">
                    {{ scope.row.created_at | timestampFormatDateTime }}
                </template>
            </el-table-column>
            <el-table-column
                label="Start Processing"
            >
                <template v-slot="scope">
                    {{ scope.row.start_import | timestampFormatDateTime }}
                </template>
            </el-table-column>
            <el-table-column
                label="Action"
                align="center"
            >
                <template v-slot="props">
                    <el-row
                        type="flex"
                        justify="center"
                    >
                        <el-progress
                            v-if="progressData(props.row.id)"
                            :percentage="Math.round((progressData(props.row.id).current / progressData(props.row.id).total) * 100)"
                            type="circle"
                            :width="50"
                        ></el-progress>

                        <el-button
                            v-else
                            size="mini"
                            :type="props?.row?.is_processed > 0 ? 'info' : !!props?.row?.start_import ? 'warning' : 'primary'"
                            :disabled="props?.row?.is_processed > 0 || !!props?.row?.start_import"
                            round
                            @click.prevent="handleProcessAction(props)"
                        >
                            {{  !!props?.row?.start_import ? 'Processing' : 'Process' }}
                        </el-button>
                    </el-row>
                </template>
            </el-table-column>
        </el-table>
    </el-main>
</template>
<script>
export default {
    name: 'ImportList',
    props: {
        filesToImport: {
            type: Array,
            required: true,
        },
    },
    mounted() {
        this.setupComponent()
    },
    methods: {
        setupComponent() {},
        handleProcessAction({row}){
            return this.$emit('process-report', row)
        },
        progressData(id){
            return this.withProgress.find(file => {
                return file.id === id
            })
        }
    },
    computed: {
        withProgress() {
            return this.$store.getters.getSalesReportsProgress ?? []
        },
    }
}
</script>
