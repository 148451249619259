<template>
    <el-container
        class="mail-compose-1"
    >
        <el-row :gutter="10" style="width: 100%">
            <el-col :span="24">
                <el-form
                    ref="formCompose"
                    :model="template"
                    :rules="rules"
                    label-width="120px"
                    @submit.prevent="submitForm"
                >
                    <el-form-item
                        label="Title"
                        prop="title"
                    >
                        <el-row>
                            <el-col :span="20">
                                <div style="max-height: 100px;overflow: auto;">
                                    <el-input
                                        v-model="template.title"
                                        name="title"
                                    >
                                    </el-input>
                                </div>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item>
                        <div class="sub-title">Status</div>
                        <el-switch
                            v-model="template.status"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                        />
                    </el-form-item>

                    <el-form-item label="Content" prop="content">
                        <composer-editor
                            ref="composerEditor"
                            :value="template.content"
                            @update="handleEditorUpdate"
                            element-loading-spinner="el-icon-loading"
                            element-loading-background="rgba(0, 0, 0, 0.8)"
                            @focus="activeInput = 'content'"
                            @blur="activeInput = ''"
                            :hideTemplateBtn="true"
                        ></composer-editor>
                        <span v-if="invalidInputs.content" class="el-form-item__error">{{ invalidInputs.content[0] }}</span>
                    </el-form-item>
                    <el-form-item>
                        <el-col :span="24" style="text-align: right">
                            <el-button
                                type="success"
                                size="small"
                                @click="submitForm"
                                :loading="isSaving"
                                :disabled="!disableSave"
                            >
                                {{ template.hasOwnProperty('id') ? 'Update' : 'Add' }}
                            </el-button>
                            <el-button
                                type="warning"
                                @click="handleResetForm(true)"
                                size="small"
                            >
                                Reset
                            </el-button>
                            <el-button
                                v-if="template.hasOwnProperty('id')"
                                type="danger"
                                @click="deleteTemplate()"
                                size="small"
                            >
                                Delete
                            </el-button>

                        </el-col>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </el-container>
</template>

<script>
import ComposerEditor from "../../webmail/composer/ComposerEditor.vue";
export default {
    name: "SequencesEmailTemplatesUpsert",
    components: {
        ComposerEditor,
    },
    props: {
        selectedTemplate: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            loading: false,
            isSaving: false,
            template: {
                title: '',
                content: null,
                status: true,
            },
            rules: {},
            activeInput: '',
            invalidInputs: {
                content: []
            },
            disableSave: true
        }
    },
    mounted() {
        this.checkSelectedTemplate()
    },
    methods: {
        checkSelectedTemplate() {
            this.handleResetForm()
            if(this.selectedTemplate.hasOwnProperty('id')) {
                this.template = JSON.parse(JSON.stringify(this.selectedTemplate))
            }
        },
        submitForm() {
            return this.$emit('submit-form', this.template)
        },
        handleEditorUpdate(value) {
            this.template.content = value
            this.activeInput = 'message'
        },
        handleResetForm(btn) {
            this.template = null
            this.isSaving = false
            this.template = {
                title: '',
                content: null,
                status: true,
            }
            this.rules = {}
            this.activeInput = ''
            this.invalidInputs = {
                content: []
            }

            if(btn) this.$emit('reset-form')
            this.loading = false
        },
        deleteTemplate() {
            this.$confirm(`This will delete the ${this.template.title}. Continue?`, 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning',
            })
                .then(() => {
                    this.$emit('delete-template', this.template)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    })
                })
        }
    },
}
</script>
