<template>
    <el-tabs
        type="border-card"
        @tab-click="tabClicked"
    >
        <el-tab-pane label="Facebook Leadgen">
            <FbLeadgen
                v-if="activeTab === 'Facebook Leadgen'"
                :user="user"
                :company="company"
                :apiEndpoint="fbApiEndpoint"
            />
        </el-tab-pane>
        <el-tab-pane label="FB Synced Leads">
            <FbLeadgenSyncedLeads
                v-if="activeTab === 'FB Synced Leads'"
                :user="user"
                :company="company"
                :apiEndpoint="fbApiEndpoint"
            >
            </FbLeadgenSyncedLeads>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import FbLeadgen from "./leads/FbLeadgen";
import FbLeadgenSyncedLeads from "./leads/FbLeadgenSyncedLeads";
export default {
    name: "LeadsPage",
    components: {
        FbLeadgen,
        FbLeadgenSyncedLeads,
    },
    props: {
        user: {
            type: Object,
            required: true
        },
        company: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            fbApiEndpoint: `api/company/${this.company.id}/facebook/${this.user.id}`,
            activeTab: 'Facebook Leadgen'
        }
    },
    methods: {
        tabClicked(data) {
            this.activeTab = data.label
        }
    }
}
</script>

<style scoped>

</style>
