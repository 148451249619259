<template>
    <el-main
        v-loading="loading"
    >

        <el-table
            v-if="report.length && !loading"
            :data="report"
        >
            <el-table-column
                label="Name"
            >
                <template
                    v-slot="scope"
                >
                    {{`${scope.row.name} ${ scope.row.hasOwnProperty('aloware_id') ? '#' + scope.row.aloware_id : ''}`}}
                </template>
            </el-table-column>
            <el-table-column
                prop="dealervault_sales_entries_count"
                label="Sales Entries"
                :min-width="30"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="dealervault_service_entries_count"
                label="Service Entries"
                :min-width="30"
                align="center"
            >
            </el-table-column>
            <el-table-column
                label="Total Entries"
                :min-width="30"
                align="center"
            >
                <template
                    v-slot="scope"
                >
                    {{`${scope.row.dealervault_sales_entries_count + scope.row.dealervault_service_entries_count}`}}
                </template>
            </el-table-column>
            <el-table-column
                prop="dealervault_last_import_date"
                label="Last Import Date"
            >
            </el-table-column>
        </el-table>

        <el-empty
          v-if="!report.length && !loading"
        ></el-empty>

        <el-pagination
            layout="prev, pager, next"
            :total="pageData.total"
            :page-size="pageData.per_page"
            :hide-on-single-page="true"
            @current-change="handleGetAuditReport"
        ></el-pagination>
    </el-main>
</template>

<script>
export default {
    name: "AuditReport",
    data() {
        return {
            loading: false,
            report: [],
            pageData: {
                per_page: 0,
                total: 0,
            },
        }
    },
    mounted() {
        this.setupComponent()
    },
    methods: {
        setupComponent() {
            this.handleGetAuditReport()
        },
        async handleGetAuditReport(page = 1) {
            this.loading = true
            await this.axios.get(`/api/dealervault/audit-report?page=${page}`)
                .then(res => {
                    const report = res.data.data.report
                    this.report = report.data
                    this.pageData.per_page = report.per_page
                    this.pageData.total = report.total
                    this.loading = false
                })
                .catch(err => {
                    console.log('err?', err)
                    this.loading = false
                    return this.$root.handleErrors(err.response)
                })
        },
    }
}
</script>
