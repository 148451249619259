<template>
    <el-main>
        <Integrate
            v-loading="loading"
            :integration-data="integrationData"
            :key="integrateKey"
            @integration-submit="handleSubmit"
        ></Integrate>
    </el-main>
</template>
<script>
import Integrate from "./Integrate"

export default {
    name: 'DealerSocket',
    components: {
        Integrate,
    },
    data() {
        return {
            loading: false,
            company: {},
            platform: 'dealersocket',
            integrationData: {},
            integrateKey: 0,
        }
    },
    mounted() {
        this.setupComponent()
    },
    methods: {
        setupComponent() {
            this.company = JSON.parse(this.$store.state.currentCompany)
            this.handleGet()
        },
        async handleGet() {
            this.loading = true
            await this.axios.get(`/api/company/${this.company.id}/integration/${this.platform}?get_contact_disposition=true&get_sequences=true`)
                .then(res => {
                    this.integrationData = res.data
                    this.loading = false
                    this.integrateKey++
                })
                .catch(err => {
                    this.loading = false
                    return this.$root.handleErrors(err.response)
                })
        },
        async handleSubmit(data) {
            const integration_data = {
                ...data,
                id: this.company.dealersocket_integration && this.company.dealersocket_integration.hasOwnProperty('id') ? this.company.dealersocket_integration.id : null
            }

            await this.axios.post(
                `/api/company/${this.company.id}/integration/${this.platform}`,
                integration_data
            )
                .then(res => {
                    this.$notify({
                        type: 'success',
                        message: 'Success'
                    })
                    return this.handleGet()
                })
                .catch(err => {
                    return this.$root.handleErrors(err.response)
                })
        },
    },
}
</script>
