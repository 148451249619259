<template>
    <el-dialog title="Select Inventory" @close="$emit('update:visible', false)" :visible.sync="isVisible" :close-on-click-modal="false" width="30%">
        <div class="mail-inventory-selector">
            <el-row>
                <el-form @submit.prevent="false">
                    <el-input
                        v-model="search"
                        clearable
                        placeholder="Search"
                        suffix-icon="el-icon-search"
                        @input="handleSearch"
                    ></el-input>
                </el-form>
            </el-row>
            <el-row style="margin-top: 10px">
                <div :style="'height: '+heightVal+'px; overflow-x: auto;'" :loading="isLoading">
                    <el-card class="box-card" v-for="car in cars" v-bind:key="car.id" style="margin-bottom: 5px;padding: 10px;">
                        <div class="text item">
                            <el-row :gutter="5">
                                <el-col :span="18" :offset="3">
                                    <el-row
                                        :id="`car-${car.vin}`"
                                        style="background-color: #fff; color: #000;"
                                    >
                                        <el-col :span="24">
                                            <img
                                                :src="car.galleries[0].url"
                                                style="width: 100%; margin: 0;"
                                                :alt="car.vin"
                                            >
                                        </el-col>
                                        <el-col :span="24">
                                            <div style="display: flex; justify-content: center;">
                                                <ul style="font-size: 11px; margin: 0; padding: 5px 0 5px 10px;">
                                                    <li>VIN: {{car.vin}}</li>
                                                    <li>Year: {{car.year}}</li>
                                                    <li>Model: {{car.model}}</li>
                                                    <li style="font-size: 16px;">
                                                        {{car.price | currency}}
                                                    </li>
                                                </ul>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <div style="text-align: center; margin-top: 5px">
                                        <el-button
                                            size="mini"
                                            type="success"
                                            @click="handleOnSelectInventory(car)"
                                        >
                                            Use
                                        </el-button>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-card>
                    <div v-if="cars.length <=0">No inventory found</div>
                    <el-row v-if="totalRecords > perPage">
                        <el-col :span="24">
                            <el-pagination
                                background
                                @current-change="handlePageChange"
                                :current-page.sync="currentPage"
                                :page-size="10"
                                layout="prev, next"
                                :total="totalRecords"
                            ></el-pagination>
                        </el-col>
                    </el-row>
                </div>
            </el-row>
        </div>
    </el-dialog>
</template>
<style scoped>
    div > ul > li {
        list-style: none;
    }
</style>
<script>
import store from "../../../store";
export default {
    name: 'InventorySelector',
    props: {
        heightVal: {
            type: Number,
            default: 400,
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {
    },
    data() {
        return {
            userEmailTemplates: [],
            isLoading: false,
            search: '',
            cars: '',
            totalRecords: 0,
            currentPage: 1,
            perPage: 10,
            isVisible: false,
        };
    },
    computed: {
    },
    mounted() {
        this.company = JSON.parse(store.state?.currentCompany)
        this.user = JSON.parse(store.state?.currentUser)
        this.getInventoryList()
    },
    methods: {
        getInventoryList() {
            this.isLoading = true

            let params = {
                page: this.currentPage,
                page_size: this.perPage,
                q: this.search,
                aloware_user_id: this.user.aloware_id
            }

            this.axios.get(`/api/company/${this.company.aloware_id}/cars`, {params: params}).then(res => {
                this.cars = res.data.data
                this.isLoading = false
                this.totalRecords = res.data.pagination.total;
            }).catch(err => {
                this.$root.handleErrors(err.response);
            })
        },
        handleOnSelectInventory(inventory) {
            this.$emit('onInventorySelect', inventory)
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.getInventoryList();
        },
        handleSearch() {
            this.currentPage = 1;
            this.getInventoryList();
        }
    },
    watch: {
        visible: function(val, oldVal) {
            this.isVisible = this.visible;
        },
    }
};
</script>
