<template>
    <el-main>
        <RestrictedScreen
            v-if="false"
            :enable_click="false"
        />
        <el-tabs
            v-if="!loading"
            v-model="socialTab"
        >
            <el-tab-pane
                v-if="false"
                name="facebook"
                label="Facebook"
            >
                <RestrictedScreen
                    :enable_click="false"
                />
            </el-tab-pane>
            <el-tab-pane
                name="tiktok"
                label="Tiktok"
            >
                <RestrictedScreen
                    v-if="tiktokLoginFailed"
                    :enable_click="true"
                />
                <tiktok
                    v-else
                    :company="company"
                    :user="user"
                    :api-url="apiURL"
                    @login-failed="handleLoginFailed"
                ></tiktok>
            </el-tab-pane>
        </el-tabs>
    </el-main>
</template>
<style>

</style>
<script>
import RestrictedScreen from './widgets/RestrictedScreen';
import Tiktok from './social-listening/Tiktok';
import store from "../store";
export default {
    components: {
        RestrictedScreen,
        Tiktok,
    },
    data() {
        return {
            loading: true,
            socialTab: 'tiktok',
            tiktokLoginFailed: false,
            company: {},
            user: {},
            apiURL: ''
        }
    },
    mounted() {
        this.handleSetupComponent()
    },
    methods: {
        handleSetupComponent() {
            this.company = JSON.parse(store.state.currentCompany)
            this.user = JSON.parse(store.state.currentUser)
            this.apiURL = `/api/company/${this.company.id}/social-listening`
            this.loading = false
        },
        handleLoginFailed() {
            this.tiktokLoginFailed = true
        }
    }
};
</script>
