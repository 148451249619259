<template>
    <div class="row" style="margin:2% auto; width:520px;margin-bottom:30px;">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>Forgot Password</span>
            </div>
            <el-form ref="form" label-width="180px">
                <span v-if="formData.token !== undefined">
                    <el-form-item label="New Password">
                        <el-input type="password" v-model="formData.password"></el-input>
                    </el-form-item>
                    <el-form-item label="Confirm New Password">
                        <el-input type="password" v-model="formData.password_confirmation"></el-input>
                    </el-form-item>
                </span>
                <span v-else>
                    <el-form-item label="Email">
                        <el-input v-model="formData.email"></el-input>
                    </el-form-item>
                </span>
                <el-row style="text-align: center">
                    <el-button type="primary" @click="handleSubmit()" :loading="isProgress">Submit</el-button>
                    <el-button type="secondary" @click="$router.push({ name: 'login' })">Go to Login</el-button>
                </el-row>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import store from "../store";

export default {
    data() {
        return {
            formData: {
                token: '',
                password: '',
                password_confirmation: '',
                email: "",
            },
            isProgress: false,
        };
    },
    created() {
        this.formData.token = this.$route.query.t
    },
    mounted() {
    },
    methods: {
        handleSubmit() {
            this.isProgress = true
            this.axios
                .post("api/auth/forgot-password", this.formData)
                .then(response => {
                    this.isProgress = false
                    if (response.data.success == true) {
                        this.isProgress = false
                        this.formData.email = ''
                        this.formData.password = ''
                        this.formData.password_confirmation = ''
                        this.$notify({
                            title: "Success",
                            message: response.data.message,
                            type: "success"
                        });
                    } else {
                        this.isProgress = false;
                        this.$notify({
                            title: "Error",
                            message: response.data.message,
                            type: "error"
                        });
                    }
                })
                .catch(err => {
                    this.isProgress = false
                    this.$root.handleErrors(err.response)
                });
        },
        login() {
            this.loginError = false;
            
        },
        handleForgotPassword() {
            this.$router.push({ path: 'forgot-password' });
        }
    }
};
</script>
