<template>
    <div>
        <h3>{{formAction}} Template</h3>
        <el-container class="mail-compose">
            <el-form
                ref="form"
                :model="templateForm"
                label-width="120px"
                @submit.prevent="submitForm"
                :rules="rules"
            >
                <el-row>
                    <el-col>
                        <el-form-item label="Category">
                            <el-select v-model="templateForm.types">
                                <el-option
                                    v-for="(opt, i) in types"
                                    :key="i"
                                    :label="opt.label"
                                    :value="opt.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="Status">
                            <el-switch
                                v-model="templateForm.status"
                                active-text="Active"
                                inactive-text="Inactive"
                                active-color="#13ce66"
                                inactive-color="#ff4949"
                                :active-value="1"
                                :inactive-value="0"
                            ></el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="Title" prop="title">
                    <el-input
                        v-model="templateForm.title"
                        @focus="activeInput = 'title'"
                    ></el-input>
                    <span v-if="formErrors.title" class="el-form-item__error">{{ formErrors.title[0] }}</span>
                </el-form-item>
                <el-form-item label="Subject" prop="subject">
                    <el-input
                        v-model="templateForm.subject"
                        @focus="activeInput = 'subject'"
                    ></el-input>
                    <span v-if="formErrors.subject" class="el-form-item__error">{{ formErrors.subject[0] }}</span>
                </el-form-item>
                <el-form-item label="Message" prop="message">
                    <composer-editor
                        ref="composerEditor"
                        :value="templateForm.message"
                        :key="editorKey"
                        @update="handleEditorUpdate"
                        v-on:onSelectTemplate="handleTemplateSelect"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.8)"
                        @focus="activeInput = 'message'"
                    ></composer-editor>
                    <span v-if="formErrors.message" class="el-form-item__error">{{ formErrors.message[0] }}</span>
                </el-form-item>
                <el-row type="flex" justify="space-between">
                    <el-col :span="16">
                        <el-form-item style="text-align: right">
                            <el-button
                                type="danger"
                                @click="resetForm"
                                size="medium"
                            >
                                Reset <i class="el-icon-refresh-right"></i>
                            </el-button>
                            <el-button
                                type="success"
                                @click="submitForm"
                                size="medium"
                            >
                                Save <i class="el-icon-check"></i>
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-container>
    </div>
    
</template>

<script>
import ComposerEditor from "../composer/ComposerEditor";

export default {
    name: "TempForm",
    components: {
        ComposerEditor
    },
    props: {
        action: {
            type: String,
            default: 'new',
        },
        template: {
            type: Object,
            required: false
        },
        errors: {
            type: Object,
            required: false
        },
        success: {
            type: Boolean,
            required: false,
            default: false
        },
        reset: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            templateForm: {
                status: 1,
                types: 'company',
                title: '',
                subject: '',
                message: '',
            },
            types: [
                {
                    label: 'Company',
                    value: 'company',
                },
                {
                    label: 'User',
                    value: 'user',
                },
            ],
            rules: {
                title: [
                    { required: true, message: 'The title field is required.', trigger: 'blur' }
                ],
                subject: [
                    { required: true, message: 'The subject field is required.', trigger: 'blur' }
                ],
                message: [
                    { required: true, message: 'The message field is required.', trigger: 'blur' }
                ],
            },
            formErrors: [],
            activeInput: null,
            editorKey: 0,
        }
    },
    computed: {
        formAction: {
            get() {
                return `${this.action.charAt(0).toUpperCase()}${this.action.slice(1)}`
            },
            set() {
                return this.action
            }
        }
    },
    mounted() {},
    methods: {
        submitForm(e) {
            e.preventDefault()
            this.$refs.form.validate(valid => {
                if(valid) {
                    return this.$emit('submit-template', this.action, this.templateForm)
                }
            })
        },
        resetForm() {
            this.$emit('reset-form', true)
        },
        handleEditorUpdate(value) {
            this.templateForm.message = value
        },
        handleTemplateSelect(template) {
            this.templateForm.subject = template.subject
        },
    },

    watch: {
        errors: {
            handler: function(n) {
                if(n.hasOwnProperty('errors')) {
                    this.formErrors = n.errors
                }
            }
        },
        reset: {
            handler: function (n) {
                if(n) {
                    this.resetForm()
                } 
            }
        },
        action: {
            handler: function(n) {
                if(n === 'edit' || n === 'duplicate') {
                    this.templateForm = JSON.parse(JSON.stringify(this.template))
                    if(n === 'duplicate') {
                        this.templateForm = {
                            ...this.templateForm,
                            title: `Duplicated - ${this.templateForm.title || ''}`
                        }
                    }
                }
            }
        }
    }
}
</script>
