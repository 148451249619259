<template>
    <el-dialog title="Templates" @close="$emit('update:visible', false)" :visible.sync="isVisible" width="30%">
        <div class="form-company">
            <el-form>
                <el-row>
                    <el-col :span="24">
                        <div class="filter-header text-muted">
                            Agent Templates
                            <div class="pull-right">
                                <el-button size="mini" @click="handleAddAgentTemplate"><i class="el-icon-plus"></i> New</el-button>
                            </div>
                        </div>
                        <el-divider></el-divider>
                        <el-row v-for="template in agentTemplates" :key="template.id" class="row-template">
                            <el-col :span="12">
                                <span>{{template.name}}</span>
                            </el-col>
                            <el-col :span="12" style="text-align: right">
                                <el-popover
                                    placement="left"
                                    width="200"
                                    trigger="click"
                                    :content="template.body">
                                    <a href="javascript:void(0)" slot="reference"><i class="el-icon-view"></i></a>
                                </el-popover>
                                <a href="javascript:void(0)" @click="handleCopyTemplate(template)"><i class="el-icon-copy-document"></i></a>
                                <el-popconfirm
                                    title="This operation will remove template. Continue?"
                                    @onConfirm="handleDeleteTemplate(template)">
                                    <a href="javascript:void(0)" slot="reference"><i class="el-icon-delete-solid"></i></a>
                                </el-popconfirm>
                            </el-col>
                        </el-row>
                        <div class="filter-header text-muted" style="margin-top: 25px">
                            Account Templates
                            <div class="pull-right">
                                <el-button size="mini" @click="handleAddCompanyTemplate"><i class="el-icon-plus"></i> New</el-button>
                            </div>
                        </div>
                        <el-divider></el-divider>
                        <el-row v-for="template in companyTemplates" :key="template.id" class="row-template">
                            <el-col :span="12">
                                <span>{{template.name}}</span>
                            </el-col>
                            <el-col :span="12" style="text-align: right">
                                <el-popover
                                    placement="left"
                                    width="200"
                                    trigger="click"
                                    :content="template.body">
                                    <a href="javascript:void(0)" slot="reference"><i class="el-icon-view"></i></a>
                                </el-popover>
                                <a href="javascript:void(0)" @click="handleCopyTemplate(template)"><i class="el-icon-copy-document"></i></a>
                                <el-popconfirm
                                    title="This operation will remove template. Continue?"
                                    @onConfirm="handleDeleteTemplate(template)">
                                    <a href="javascript:void(0)" slot="reference"><i class="el-icon-delete-solid"></i></a>
                                </el-popconfirm>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </el-form>
            <el-dialog title="Create Message template" :visible.sync="messageTemplateFormVisible" append-to-body width="30%">
                <div>
                    <el-row>
                        <el-col :span="24">
                            Name<br>
                            <el-input style="margin-top: 5px" v-model="selectedTemplate.name"></el-input>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 15px">
                        <el-col :span="24">
                            Template Body<br>
                            <el-input type="textarea" style="margin-top: 5px" v-model="selectedTemplate.body"></el-input>
                        </el-col>
                    </el-row>
                </div>
                <div slot="footer" id="templateformfooter">
                    <el-row>
                        <el-col :span="12" style="text-align: left">
                            <el-popover
                                placement="top"
                                width="200"
                                trigger="click"
                                @show="handleVariableShown">
                                <el-select
                                    v-model="selectedVariable"
                                    :automatic-dropdown="true"
                                    ref="variableSelector"
                                    @change="handleVariableSelectorChange">
                                    <el-option
                                        v-for="item in variableOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                        <label for="" style="width: 100%">{{item.value}}</label>
                                        <small>{{item.label}}</small>
                                    </el-option>
                                </el-select>
                                <el-button slot="reference"><i class="el-icon-plus"></i> Add Variable</el-button>
                            </el-popover>
                        </el-col>
                        <el-col :span="12" style="text-align: right">
                            <el-button @click="handleSaveTemplateForm" type="primary"><i class="el-icon-finished"></i> Save</el-button>
                        </el-col>
                    </el-row>
                </div>
            </el-dialog>
        </div>
    </el-dialog>
</template>
<style>
    .pull-right {
        float: right;
    }
    .row-template {
        padding: 10px 0;
    }
    .row-template a {
        color: inherit;
        padding: 0 2.5px;
    }
</style>
<script>
export default {
    props: ['company', 'visible'],
    data() {
        return {
            agentTemplates: [],
            companyTemplates: [],
            selectedTemplate: {
                name: '',
                body: '',
                template_scope: 'user'
            },
            messageTemplateFormVisible: false,
            isVisible: false,
            variableOptions: [{
                    'label': 'to include contact’s first name',
                    'value': '[FirstName]',
                },{
                    'label': 'to include contact’s last name',
                    'value': '[LastName]',
                },{
                    'label': 'to include contact’s full name',
                    'value': '[FullName]'
                },{
                    'label': 'to include contact’s company name',
                    'value': '[AccountName]'
                },{
                    'label': 'to include contact’s agent name',
                    'value': '[AgentName]'
                }],
            selectedVariable: ''
        };
    },
    computed: {
    },
    mounted() {
        this.currentCompany = this.company
        this.getTemplates()
    },
    methods: {
        getTemplates() {
            this.axios
            .get(`${this.companyApiBaseUrl}/messenger/template`)
            .then(response => {
                if (response.data.success == true) {
                    this.agentTemplates = [];
                    this.companyTemplates = [];

                    let templates = response.data.data
                    if(templates.length > 0) {
                        templates.forEach((template)=>{
                            if(template.is_on_user) {
                                this.agentTemplates.push(template)
                            } else {
                                this.companyTemplates.push(template)
                            }
                        });
                    }

                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        handleAddAgentTemplate() {
            this.selectedTemplate.template_scope = 'user';
            this.messageTemplateFormVisible = true;
        },
        handleAddCompanyTemplate() {
            this.selectedTemplate.template_scope = 'company';
            this.messageTemplateFormVisible = true;
        },
        handleCopyTemplate(template) {
            this.copyToClipboard(template.body);

            this.$notify({
                title: "Success",
                message: "Template has been copied to clipboard",
                type: "success"
            });
        },
        copyToClipboard(val){
            var dummy = document.createElement("input");
            dummy.style.height = '0px';
            dummy.style.width = '1px';
            document.body.appendChild(dummy);

            dummy.setAttribute("id", "dummy_id");
            document.getElementById("dummy_id").value = val;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
        },
        handleAddTemplateVariable() {

        },
        handleDeleteTemplate(template) {
            this.axios
            .delete(`${this.companyApiBaseUrl}/messenger/template/${template.id}`)
            .then(response => {
                if (response.data.success == true) {
                    this.messageTemplateFormVisible = false;
                    this.getTemplates();
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        handleSaveTemplateForm() {
            this.axios
            .post(`${this.companyApiBaseUrl}/messenger/template`, this.selectedTemplate)
            .then(response => {
                if (response.data.success == true) {
                    this.messageTemplateFormVisible = false;
                    this.getTemplates();
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        handleVariableShown() {
            this.$nextTick(() => this.$refs.variableSelector.focus())
        },
        handleVariableSelectorChange(value, event) {
            this.copyToClipboard(value);

            this.$notify({
                title: "Success",
                message: "Variable has been copied to clipboard",
                type: "success"
            });
            //templateformfooter
            document.getElementById('templateformfooter').click()
        }
    },
    watch: {
        visible: function(val, oldVal) {
            this.isVisible = this.visible
        },
    }
};
</script>

<style>
    .form-company .el-form-item__label {
        width: 100%;
        text-align: left;
    }
</style>
