<template>
    <el-main>
        <el-form>
            <el-form-item
                label="SimpSocial Dealer"
            >
                <el-select
                    v-model="form.company_id"
                    placeholder="SimpSocial Dealer"
                    disabled
                >
                    <el-option
                        :key="company.id"
                        :value="company.id"
                        :label="company.name"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                label="VinSolutions Dealer"
            >
                <el-select
                    v-model="form.vs_dealer_id"
                    placeholder="Select Dealer"
                    @change="getVinSolutionsDealerUsers"
                    :loading="load_dealers_select"
                    loading-text="Fetching dealers list..."
                >
                    <el-option
                        v-for="dealer in vin_sol_dealers"
                        :key="dealer.id"
                        :value="dealer.id"
                        :label="dealer.name"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                v-if="form.vs_dealer_id"
                :label="`${selected_vin_sol.name} users`"
            >
                <el-select
                    v-model="form.vs_user_id"
                    placeholder="Select User"
                    filterable
                    :loading="load_dealer_users_select"
                    loading-text="Fetching users list..."
                >
                    <el-option
                        v-for="user in vin_sol_dealer_users"
                        :key="user.UserId"
                        :value="user.UserId"
                        :label="`${user.FullName} - ${user.IlmAccess}`"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-row
                    style="float: right;"
                >
                    <el-button
                        type="success"
                        @click="submitForm"
                        :disabled="!submittable"
                    >
                        Submit
                    </el-button>
                    <el-button
                        type="danger"
                        @click="resetForm"
                    >Reset</el-button>
                </el-row>

            </el-form-item>
        </el-form>
    </el-main>
</template>
<script>

export default {
    name: 'Integrate',
    data() {
        return {
            company: {},
            form: {
                company_id: null,
                vs_dealer_id: null,
                vs_user_id: null,
            },
            vin_sol_dealers: [],
            vin_sol_dealer_users: [],
            load_dealers_select: false,
            load_dealer_users_select: false,
            load_submit: false,
        }
    },
    mounted() {
        this.company = JSON.parse(this.$store.state.currentCompany)
        this.form.company_id = this.company.id

        this.getVinSolutionsDealers()
    },
    methods: {
        async getVinSolutionsDealers() {
            this.load_dealers_select = true
            await this.axios.get(`api/company/${this.company.id}/crm-dms-integrations/vs-get-dealers`)
                .then(res => {
                    this.vin_sol_dealers = res.data.data.vin_solutions_dealers.sort((a,b) => a.name.localeCompare(b.name))
                    this.load_dealers_select = false
                })
                .catch(err => {
                    this.load_dealers_select = false
                    return this.$root.handleErrors(err.response)
                })
        },
        async getVinSolutionsDealerUsers() {
            this.load_dealer_users_select = true
            this.form.vs_user_id = null
            await this.axios.get(`api/company/${this.company.id}/crm-dms-integrations/vs-get-dealer-${this.form.vs_dealer_id}-users`)
                .then(res => {
                    this.vin_sol_dealer_users = res.data.data.vin_solutions_dealer_users.sort((a,b) => a.FullName.localeCompare(b.FullName))
                    this.load_dealer_users_select = false
                })
                .catch(err => {
                    this.load_dealer_users_select = false
                    return this.$root.handleErrors(err.response)
                })
        },
        async submitForm() {
            await this.axios.put(
                `api/company/${this.company.id}/crm-dms-integrations/vs-dealer/integrate`,
                this.form
            )
                .then(res => {
                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: `${this.company.name} has been integrated.`
                    })

                    this.$store.commit('UpdateCurrentCompany', res.data.data.company)
                    this.$emit('integration-success')
                    return this.resetForm()
                })
                .catch(err => {
                    return this.$root.handleErrors(err.response)
                })
        },
        resetForm() {
            this.form = {
                ...this.form,
                vs_dealer_id: null,
                vs_user_id: null,
            }
        }
    },
    computed: {
        selected_vin_sol() {
            return this.vin_sol_dealers.find(dealer => dealer.id === this.form.vs_dealer_id)
        },
        submittable() {
            return !!(this.form.company_id && this.form.vs_dealer_id && this.form.vs_user_id)
        },
    },
}
</script>
