<template>
    <div class="form-user">
        <el-form>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="Name">
                        <el-input v-model="localUser.name"></el-input>
                    </el-form-item>
                    <el-form-item label="Email">
                        <el-input v-model="localUser.email"></el-input>
                    </el-form-item>
                    <el-form-item label="Phone">
                        <el-input v-model="localUser.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="Password">
                        <div v-if="localUser.id > 0">
                            <el-input v-model="password" show-password></el-input>
                            <el-button size="mini" @click="handleGeneratePassword">Generate Password</el-button>

                            <span v-if="generatedPassword !== ''">Generated password: <span style="color: green">{{generatedPassword}}</span></span>
                            <el-button v-if="generatedPassword !== ''" size="mini" type="success" @click="handleUseGeneratePassword">Use</el-button>
                        </div>
                        <span v-else><em>Password will be emailed to user</em></span>
                    </el-form-item>
                    <el-form-item label="Role">
                        <el-select v-model="localUser.role"
                            placeholder="Select Role"
                            style="width: auto">
                            <el-option
                                v-for="item in role_names"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-row style="margin-top: 15px">
            <el-col :span="12">
                <el-button @click="$emit('closeDialog')">Cancel</el-button>
            </el-col>
            <el-col :span="12" style="text-align: right">
                <el-button type="primary" @click="handleSubmit">Submit</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>

export default {
    props: {
        user : {
            type: Object,
            default(rawProps) {
                return {
                    id: 0,
                    name: '',
                    email: '',
                    phone: '',
                    password: '',
                    role: '',
                }
            }
        }
    },
    components: {
    },
    data() {
        return {
            role_names: [],
            generatedPassword: '',
            password: '',
            localUser: {
                id: 0,
                name: '',
                email: '',
                phone: '',
                password: '',
                role: '',
            }
        };
    },
    computed: {},
    mounted() {
        if(this.user.id > 0) {
            this.localUser = { ...this.user }
            this.localUser.role = this.localUser.role_names[0]
        }
        this.getRoleNames()
    },
    methods: {
        handleSubmit() {
            let url = ''
            let action = ''

            if(this.user.id > 0) {
                url += '/api/user-update/' + this.localUser.id
                action = 'updated'
            } else {
                url += '/api/user/create'
                action = 'added'
            }

            this.localUser.password = this.password

            this.axios
                .post(url, this.localUser)
                .then(response => {
                    if (response.data.success == true) {
                        this.$notify({
                            title: "Success",
                            message: "User has been successfully " + action,
                            type: "success"
                        });
                        this.$emit('closeDialog')
                    } else {
                        let errors = response.data.errors.message;
                        let message = "";
                        for (let error in errors) {
                            message = errors[error] + "\n";
                        }
                        this.$notify({
                            title: "Error",
                            message: message,
                            type: "error"
                        });
                    }
                })
                .catch(err => {
                    this.$root.handleErrors(err.response);
                });
        },
        getRoleNames() {
            this.axios
            .get('/api/role')
            .then(response => {
                if (response.data.success == true) {
                    this.role_names = response.data.data
                }
            })
            .catch(err => {
                this.$root.handleErrors(err.response);
            });
        },
        handleGeneratePassword() {
            this.generatedPassword = Math.random().toString(36).slice(-10);
        },
        handleUseGeneratePassword() {
            this.password = this.generatedPassword
        }
    },
    watch: {
        user: function(val, oldVal) {
            this.localUser = { ...val }
            this.localUser.role = typeof this.localUser.role_names !== 'undefined' ? this.localUser.role_names[0] : ''
            this.password = ''
            this.generatedPassword = ''
        }
    }
};
</script>

<style>
    .form-company .el-form-item__label {
        width: 100%;
        text-align: left;
    }
</style>
