<template>
    <div
        v-if="contact"
    >
        <a
            :href="`https://app.simpsocial.com/contacts/${contact.id}`"
            style="text-decoration: none; color: inherit;"
            target="_blank"
        >
            <strong>{{ contact.name }}</strong>
        </a>
        <strong>{{ leadSource }}</strong>
        <br>
        <i style="font-size: small;">{{ cFields }}</i>
    </div>
</template>
<script>
export default {
    name: 'EngageHeader',
    props: {
        contact: {
            type: Object,
            required: true
        }
    },
    methods: {
        getCustomField(field) {
            try {
                return this.contact.customized_fields[field]
            }catch (e) {
                return ' '
            }
        }
    },
    computed: {
        leadSource() {
            return this.contact?.lead_source ? ` - ${this.contact.lead_source}` : ''
        },
        cFields() {
            let str = ''
            const fields = ['Year', 'Make', 'Model']

            fields.forEach(field => {
                if(this.contact.hasOwnProperty('customized_fields')) {
                    str += this.contact.customized_fields[field] ? ` ${this.contact.customized_fields[field]}` : ''
                    str = str.trim()
                }
            })


            return str;
        }
    },
}
</script>
