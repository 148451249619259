<template>
    <el-main style="overflow: hidden;">
        <el-row>
            <el-col :span="12">
                <el-input
                    v-model="search"
                    size="small"
                    placeholder="Search for company"
                    clearable
                /> <p>Showing {{totalRecords}} Records</p>
            </el-col>
            <el-col :span="12" style="text-align: right">
                <el-button type="primary" @click="handleNewRequest">
                    <i class="fa fa-plus"></i> Submit Request
                </el-button>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24" :offset="0" :sm="{offset: 0}" :md="{offset: 0}" :xl="{offset: 3}">
                <keep-alive>
                    <el-table :data="records">
                        <el-table-column label="Name" prop="company.name" width="300%"></el-table-column>
                        <el-table-column
                            label="Dominion ID"
                            prop="dealer_id"
                            width="120"
                        ></el-table-column>
                        <el-table-column
                            label="File Type"
                            prop="file_type"
                            width="100"
                        ></el-table-column>
                        <el-table-column
                            label="Type"
                            prop="type"
                            width="100%"
                        ></el-table-column>
                        <el-table-column label="Last Import" width="200%">
                            <template slot-scope="scope">
                                <span v-if="scope.row.file_type == 'SL'">{{scope.row.company.dominion_last_import_date}}</span>
                                <span v-else>{{scope.row.company.dominion_last_service_import_date}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Requests" width="300%">
                            <template v-slot="scope">
                                <el-row
                                    type="flex"
                                    align="middle"
                                    style="align-content: space-between;"
                                >
                                        <el-button
                                            size="mini"
                                            type="info"
                                            @click="handleRequestImport(scope.$index, scope.row)"
                                            style="align-self: flex-start;"
                                        >
                                            Request Import
                                        </el-button>
                                        <el-button
                                            size="mini"
                                            type="success"
                                            @click="handleViewCImportDetails(scope.$index, scope.row)"
                                            style="align-self: flex-start;"
                                        >
                                            View Import Details
                                        </el-button>
                                </el-row>
                            </template>
                        </el-table-column>
                    </el-table>
                </keep-alive>
            </el-col>
        </el-row>
        <el-pagination
            background
            @current-change="handlePageChange"
            :current-page.sync="currentPage"
            :page-size="10"
            layout="prev, pager, next"
            :total="totalRecords"
        ></el-pagination>

        <DominionRequestDialog
            :visible.sync="dominionRequestDialogVisible"
            v-on:onSubmit="handleOnRequestSubmission" />

        <ImportDetails
            v-if="companyDetailsVisible"
            :selected-company="selectedCompany"
            @close-import-details="closeViewImportDetails"
        ></ImportDetails>
    </el-main>
</template>
<style>

</style>
<script>
import DominionRequestDialog from './dominion/DominionRequestDialog'
import ImportDetails from "./dominion/ImportDetails.vue";

export default {
    data() {
        return {
            uploading: false,
            errors: {},
            selectedCompany: {},
            isProgress: false,
            records: [],
            search: "",
            totalRecords: 0,
            currentPage: 1,
            isSearching: false,
            dominionRequestDialogVisible: false,
            companyDetailsVisible: false,
        };
    },
    components: {
        DominionRequestDialog,
        ImportDetails,
    },
    mounted() {
        this.loadRecords();
    },
    methods: {
        loadRecords() {
            this.axios
                .get("/api/dominion/request", {
                    params: {
                        page: this.currentPage,
                        q: this.search
                    }
                })
                .then(response => {
                    if (response.data.success == true) {
                        this.records = response.data.data;
                        this.totalRecords = response.data.pagination.total;
                    } else {
                        this.$notify({
                            title: "Error",
                            message: "There has been an error getting companies list",
                            type: "error"
                        });
                    }
                });
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.loadRecords();
        },
        currencyFormat(row, column) {
            return this.$options.filters.currency(row.price);
        },
        handleNewRequest() {
            this.dominionRequestDialogVisible = true
        },
        handleViewCImportDetails(index, row) {
            this.selectedCompany = row
            this.companyDetailsVisible = true
        },
        async handleRequestImport(index, row) {
            await axios.post('/api/dominion/manual-request', {
                the_manual: true,
                request_id: row.id
            })
                .then(res => {
                    return this.$notify({
                        type: 'success',
                        title: 'Queued',
                        message: res.data.message
                    })
                })
                .catch(err => {
                    console.log('err?', err)
                    return this.$root.handleErrors(err.response);
                })
        },
        handleCompanySuccessfullyCreated() {
            this.newCompanyDealershipVisible = false
            this.currentPage = 1
            this.isSearching = true
            this.loadRecords()
        },
        handleOnRequestSubmission() {
            this.loadRecords()
        },
        closeViewImportDetails() {
            this.selectedCompany = {}
            this.companyDetailsVisible = false
        }
    },
    watch: {
        search: function(val, oldVal) {
            this.currentPage = 1
            this.isSearching = true
            this.loadRecords()
        }
    }
};
</script>
