<template>
    <div>
        <el-row :gutter="20" style="margin-bottom: 10px;">
            <el-col :span="20" class="text-left">
                <el-date-picker
                    v-model="filters.daterange"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="-"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    size="small"
                    @change="handleDaterangeChange"
                    :clear="handleDaterangeChange"
                    :picker-options="pickerOptions"
                    format="MM-dd-yyyy"
                    popper-class="datepicker-custom"
                    ref="startEndDate"
                    name="se">
                </el-date-picker>
                <el-select v-model="filters.make"
                    multiple
                    collapse-tags
                    filterable
                    placeholder="Select Makes"
                    size="small"
                    clearable
                    @change="handleMakeChange"
                    :clear="handleMakeChange">
                    <el-option
                        v-for="item in makeOptions"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                </el-select>
                <el-select v-model="filters.model"
                    multiple
                    collapse-tags
                    filterable
                    placeholder="Select Models"
                    size="small"
                    clearable
                    @change="handleModelChange"
                    :clear="handleModelChange">
                    <el-option
                        v-for="item in modelOptions"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                </el-select>
                <el-button-group>
                    <el-button
                        type="primary"
                        title="Start DMS Campaign Now"
                        @click="handleDMSCampaignNow"
                        style="font-weight: bold;"
                        size="small"
                        :loading="btnDMSIsLoading">
                        <span v-if="btnDMSIsLoading">Processsing...</span>
                        <span v-else>Text Campaign Now</span>
                        </el-button>
                </el-button-group>
                <el-button-group>
                    <el-button
                        type="primary"
                        title="Start Email Campaign Now"
                        @click="handleEmailCampaignNow"
                        style="font-weight: bold;"
                        size="small"
                        :loading="btnEmailIsLoading">
                        <span v-if="btnEmailIsLoading">Processsing...</span>
                        <span v-else>Email Campaign Now</span>
                        </el-button>
                </el-button-group>
            </el-col>
            <el-col :span="4" style="text-align: right;">
                <el-button-group>
                        <el-button
                            type="primary"
                            size="small"
                            title="Export to Excel"
                            @click="handleExport('excel')"
                            :loading="btnExcelIsLoading">Excel</el-button>
                        <el-button
                            type="primary"
                            size="small"
                            title="Export to PDF"
                            @click="handleExport('pdf')"
                            :loading="btnPDFIsLoading">PDF</el-button>
                    </el-button-group>
            </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-bottom: 10px;">
            <el-col :span="6" class="text-left">
                <el-radio-group v-model="filters.sale_type" size="small" @change="handleSaleTypeChange">
                    <el-radio-button label="All Sale Types"></el-radio-button>
                    <el-radio-button label="Leased"></el-radio-button>
                    <el-radio-button label="Financed"></el-radio-button>
                </el-radio-group>
            </el-col>
            <el-col :span="7" class="text-left">
                <div v-if="filters.sale_type.toLowerCase() == 'leased' || filters.sale_type.toLowerCase() == 'financed'">
                    <div style="margin-bottom: 5px;">
                        <small>Select term range remaining (Current selection: {{filters.term_range[0]}}-{{filters.term_range[1]}})</small>
                    </div>
                    <el-slider
                        v-model="filters.term_range"
                        range
                        show-stops
                        :max="max_term"
                        @change="handleTermChange">
                    </el-slider>
                </div>
                <div v-else>&nbsp;</div>
            </el-col>
            <el-col :span="7" class="text-left">
                <div v-if="filters.sale_type.toLowerCase() == 'financed'">
                    <div style="margin-bottom: 5px;">
                        <small>Select Interest Rate range (Current selection: {{filters.apr_range[0]}}%-{{filters.apr_range[1]}}%)</small>
                    </div>
                    <el-slider
                        v-model="filters.apr_range"
                        range
                        show-stops
                        :max="max_apr"
                        @change="handleAprChange">
                    </el-slider>
                </div>
                <div v-else>&nbsp;</div>
            </el-col>
            <el-col :span="4" class="text-right">
                <el-button
                    type="primary"
                    plain
                    size="small"
                    @click="gotoBroadcastPage()"
                    :loading="btnPDFIsLoading">Got to broadcast page</el-button>
                    <br>
                <small>Found <strong>{{totalRecords}}</strong> {{parseInt(totalRecords) | pluralize('record', 'records')}}</small>
            </el-col>
        </el-row>
        <el-row v-if="pendingExports.length > 0">
            <el-col :span="10">
                <div style="background-color: #ffffff;border-radius: 5px;padding: 5px; margin-bottom: 10px;">
                    <small>Export in progress. This runs in the background. Feel free to close the page</small>
                    <ul class="list">
                        <li v-for="pendingExport in pendingExports" v-bind:key="pendingExport.id">
                            <small>Contacts: <span>{{pendingExport.processed_records}}/{{pendingExport.total_records}}</span><br>Tag: <strong>{{pendingExport.notes}}</strong></small>
                            <el-progress :percentage="getPercentage(pendingExport)" color="#f2225d"></el-progress>
                        </li>
                    </ul>
                    <div style="font-size: 10px; text-align: right">*Contacts without phone numbers will be skipped</div>
                </div>
            </el-col>
        </el-row>

        <el-table v-loading="isSearching" :data="reports" class="dms-table">
            <el-table-column label="Full name" prop="full_name" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.full_name}}
                </template>
            </el-table-column>
            <el-table-column label="Phone Number" prop="home_phone" width="120px" header-align="center" align="center">
                <template slot-scope="scope">
                    <strong>{{scope.row.home_phone}}</strong>
                </template>
            </el-table-column>
            <el-table-column label="Vin" prop="vin" header-align="center" align="center">
                <template slot-scope="scope">
                    {{scope.row.vin}}
                </template>
            </el-table-column>
            <el-table-column label="Make" prop="make" width="100px" header-align="center" align="center">
                <template slot-scope="scope">
                    {{formatMakeField(scope.row.make)}}
                </template>
            </el-table-column>
            <el-table-column label="Model" prop="model" header-align="center" align="center">
                <template slot-scope="scope">
                    {{scope.row.model}}
                </template>
            </el-table-column>
            <el-table-column label="Sold Date" prop="delivery_date" width="100px" header-align="center" align="center">
                <template slot-scope="scope">
                    {{soldDate(scope.row) | formatDate}}
                </template>
            </el-table-column>
            <el-table-column label="Term" prop="term" width="60px" header-align="center" align="center">
                <template slot-scope="scope">
                    {{scope.row.term}}
                </template>
            </el-table-column>
            <el-table-column label="Months Remaining" prop="remaining_terms" header-align="center" align="center">
                <template slot-scope="scope">
                    {{scope.row.remaining_terms >= 0 ? scope.row.remaining_terms : '-'}}
                </template>
            </el-table-column>
            <el-table-column label="APR" prop="apr" v-if="filters.sale_type.toLowerCase()=='financed'" width="60px" header-align="center" align="center">
                <template slot-scope="scope">
                    {{scope.row.apr}}%
                </template>
            </el-table-column>
            <el-table-column label="Estimated Current Payoff" prop="current_payoff" v-if="filters.sale_type.toLowerCase()=='financed'" header-align="center" align="center">
                <template slot-scope="scope">
                    {{scope.row.current_payoff | currency}}
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            background
            @current-change="handlePageChange"
            :current-page.sync="currentPage"
            :page-size="10"
            layout="prev, pager, next"
            :total="totalRecords"
        ></el-pagination>
    </div>
</template>
<style>
.el-table.dms-table {
    font-size: 10.5px;
}
.el-table.dms-table thead {
    color: #ffffff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 11.5px;
}
.el-table.dms-table th>.cell {
    padding: 0 5px;
}
.dms-campaign-container .el-checkbox {
    margin-right: 0;
}
.dms-campaign-container .el-checkbox.is-bordered.is-checked {
    border-color: #FFFFFF;
}
.dms-campaign-container .el-slider__runway {
    margin: 6px 0;
}
.dms-campaign-container .el-slider__button {
    width: 10px;
    height: 10px;
}
.dms-campaign-container ul.list {
    padding: 0;
}
.dms-campaign-container ul.list li {
    list-style: none;
    padding: 0;
}
.dms-table th {
    font-family: monospace;
}
</style>
<script>
import moment from 'moment';

export default {
    props: [
        'selectedCompany'
    ],
    data() {
        return {
            errors: {},
            isProgress: false,
            reports: [],
            filters: {
                start_date: '',
                end_date: '',
                daterange: '',
                q: '',
                make: '',
                model: '',
                sale_type: 'All Sale Types',
                tag_id: '',
                term_range: [0, 300],
                apr_range: [0, 30],
                export_type: 'json'
            },
            max_term: 300,
            min_apr: 0,
            max_apr: 50,
            totalRecords: 0,
            currentPage: 1,
            perPage: 10,
            isSearching: false,
            btnDMSIsLoading: false,
            btnExcelIsLoading: false,
            btnPDFIsLoading: false,
            saletypeleased: true,
            makeOptions: [],
            modelOptions: [],
            company: this.selectedCompany,
            pickerOptions: {
                shortcuts: [{
                    text: 'This month',
                    onClick(picker) {
                        const date = new Date();
                        const start = new Date(date.getFullYear(), date.getMonth(), 1);
                        const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last 3 mos',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '18-28 mos',
                    onClick(picker) {
                        const start = moment().subtract(28, 'months');
                        const end = moment().subtract(18, 'months');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '29-39 mos',
                    onClick(picker) {
                        const now = new Date();
                        const start = moment().subtract(36, 'months');
                        const end = moment().subtract(29, 'months');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: this.getDateByFormat('year', 1),
                    onClick(picker) {
                        const now = new Date();
                        const start = new Date(now.getFullYear() - 1, 0, 1);
                        const end = new Date(now.getFullYear() - 1, 11, 31);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: this.getDateByFormat('year', 2),
                    onClick(picker) {
                        const now = new Date();
                        const start = new Date(now.getFullYear() - 2, 0, 1);
                        const end = new Date(now.getFullYear() - 2, 11, 31);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: this.getDateByFormat('year', 3),
                    onClick(picker) {
                        const now = new Date();
                        const start = new Date(now.getFullYear() - 3, 0, 1);
                        const end = new Date(now.getFullYear() - 3, 11, 31);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: this.getDateByFormat('year', 4),
                    onClick(picker) {
                        const now = new Date();
                        const start = new Date(now.getFullYear() - 4, 0, 1);
                        const end = new Date(now.getFullYear() - 4, 11, 31);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            percentageProcessing: '0%',
            btnEmailIsLoading: false,
            pendingExports: []
        };
    },
    mounted() {
        this.init();

        //listen to manually enterred dates
        if(window.document.getElementsByName('s').length > 0) {
            window.document.getElementsByName('s')[0].addEventListener('keyup', (event) => {
                let fromDate = moment(event.target.value, 'MM-DD-YYYY');
                if(fromDate.isValid()) {
                    this.handleDaterangeChange();
                }
            });
            window.document.getElementsByName('e')[0].addEventListener('keyup', (event) => {
                let toDate = moment(event.target.value, 'MM-DD-YYYY');
                if(toDate.isValid()) {
                    this.handleDaterangeChange();

                }
            });
        }
    },
    methods: {
        init() {
            this.setDefaultDaterange();
            this.loadReport();
            this.getMakes();
            this.getModels();
            this.getMaxTerm();
            this.getMinAndMaxApr();
            this.getPendingExports()
        },
        listAllProperties(o) {
            var objectToInspect;
            var result = [];

            for(objectToInspect = o; objectToInspect !== null;
                objectToInspect = Object.getPrototypeOf(objectToInspect)) {
                result = result.concat(
                    Object.getOwnPropertyNames(objectToInspect)
                );
            }

            return result;
        },
        loadReport(exportType = 'json') {
            let responseType = 'json';
            if (exportType == 'alowareimport') {
                this.btnDMSIsLoading = true;
            } else if (exportType == 'excel') {
                this.btnExcelIsLoading = true;
                responseType = 'blob';
            } else if(exportType == 'pdf') {
                this.btnPDFIsLoading = true;
                responseType = 'blob';
            } else if(exportType == 'bulkmail') {
                this.btnEmailIsLoading = true;
            } else {
                this.isSearching = true
            }

            let params = {
                page: this.currentPage,
                page_size: this.perPage,
                q: this.filters.q,
                export_type: exportType,
                model: this.filters.model,
                make: this.filters.make,
                tag_id: this.filters.tag_id,
                start_date: this.filters.daterange !== null ? this.$options.filters.toSqlDate(this.filters.daterange[0]) : '',
                end_date: this.filters.daterange !== null ? this.$options.filters.toSqlDate(this.filters.daterange[1]) : '',
            }

            if(params.make.includes('MERCEDES BENZ')) {
                let dictionary = new this.$ssDictionary
                dictionary = dictionary.carMakes()['Mercedes Benz']
                params.make = params.make.concat(dictionary)
            }

            if(params.make.includes('LAND ROVER')) {
                let dictionary = new this.$ssDictionary
                dictionary = dictionary.carMakes()['Land Rover']
                params.make = params.make.concat(dictionary)
            }

            if(this.filters.sale_type.toLowerCase() == 'leased') {
                params.sale_type = 'L';
            } else if(this.filters.sale_type.toLowerCase() == 'financed') {
                params.sale_type = 'R';
            }

            if(params.sale_type == 'L' || params.sale_type == 'R') {
                params.term_min = this.filters.term_range[0];
                params.term_max = this.filters.term_range[1];
            }
            if(params.sale_type == 'R') {
                params.apr_min = this.filters.apr_range[0];
                params.apr_max = this.filters.apr_range[1];
            }

            if ( exportType == 'alowareimport' ) {
                setTimeout(() => {
                    this.isSearching = false;
                    this.btnDMSIsLoading = false;
                    this.getPendingExports()
                }, 20000);
            }

            this.axios({
                    url: "/api/company/" + this.company.id + "/dms-leads",
                    method: 'GET',
                    params: params,
                    responseType: responseType
                })
                .then(response => {
                    if(exportType == 'pdf' || exportType == 'excel') {
                        this.btnPDFIsLoading = false;
                        this.btnExcelIsLoading = false;

                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', response.headers.filename);
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    } else if (response.data.success == true) {

                        if ( exportType == 'alowareimport' ) {

                            if(response.data.tag_id !== '') {
                                this.filters.tag_id = response.data.tag_id;
                            }
                            let currentPage = parseInt(response.data.pagination.current_page);
                            let totalPages = parseInt(response.data.pagination.total_pages);
                            if( currentPage <= totalPages) {

                                this.percentageProcessing = Math.round((100 * (currentPage / totalPages))) + '%';
                                this.currentPage = currentPage + 1;
                                this.loadReport('alowareimport');

                            } else {
                                this.$notify({
                                    title: "Success",
                                    message: response.data.message,
                                    type: "success"
                                });

                                setTimeout(() => {
                                    window.parent.location.href = 'https://app.simpsocial.com/broadcast/bulk-message';
                                }, 3000);
                            }

                        } else if( exportType == 'bulkmail' ) {
                            this.$emit('doBulkMail', response.data.dms_export_id)
                        } else {
                            this.isSearching = false;
                            this.btnDMSIsLoading = false;
                            this.reports = response.data.data;
                            this.totalRecords = response.data.pagination.total;
                        }

                    } else {
                        this.isSearching = false;
                        this.$notify({
                            title: "Error",
                            message: response.data.message,
                            type: "error"
                        });
                    }
                });
        },
        gotoBroadcastPage() {
            console.log('broadcast')
            window.parent.location.href = 'https://app.simpsocial.com/broadcast/bulk-message';
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.loadReport();
        },
        setDefaultDaterange() {
            // set default range: last 3 months
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);

            this.filters.daterange = [start, end];
        },
        handleDaterangeChange() {
            this.currentPage = 1;
            this.isSearching = true;
            this.filters.tag_id = '';
            this.perPage = 10;
            this.loadReport();
        },
        handleSaleTypeChange() {
            this.currentPage = 1;
            this.isSearching = true;
            this.filters.tag_id = '';
            this.perPage = 10;
            this.loadReport();
        },
        handleTermChange() {
            this.currentPage = 1;
            this.isSearching = true;
            this.filters.tag_id = '';
            this.perPage = 10;
            this.loadReport();
        },
        handleAprChange() {
            this.currentPage = 1;
            this.isSearching = true;
            this.filters.tag_id = '';
            this.perPage = 10;
            this.loadReport();
        },
        handleExport(exportType) {
            this.loadReport(exportType);
        },
        getMakes() {
            this.axios.get("/api/company/" + this.company.id + "/dms-makes", {})
            .then(response => {
                if (response.data.success === true) {
                    return this.setMakes(response.data.data)
                }
            });
        },
        setMakes(data) {
            let dictionary = new this.$ssDictionary
            dictionary = dictionary.carMakes()
            let dicMakes = []
            let makes = []

            Object.entries(dictionary).forEach((eMakes) => {
                eMakes[1].forEach(eMake => {
                    dicMakes.push(eMake)
                })
                makes.push(eMakes[0].toUpperCase())
            })

            makes = data.filter(make => {
                return !dicMakes.includes(make)
            })

            const itemsToRemove = ['ME/E', 'MW/BE', 'NE/BE'] // should be arranged alphabetically
            const indexToRemove = []
            itemsToRemove.forEach(item => {
                indexToRemove.push(makes.indexOf(item))
            })

            if(indexToRemove.length > 1) {
                indexToRemove.reverse().forEach(index => {
                    makes.splice(index, 1)
                })
            }

            this.makeOptions = [...new Set(makes.sort())]
        },
        formatMakeField(str) {
            const dictionary = new this.$ssDictionary
            const mBenz = dictionary.carMakes()['Mercedes Benz']
            const lROver = dictionary.carMakes()['Land Rover']

            if(mBenz.includes(str)) {
                return 'MERCEDES BENZ'
            }

            if(lROver.includes(str)) {
                return 'LAND ROVER'
            }
            return str
        },
        getModels() {
            this.axios.get("/api/company/" + this.company.id + "/dms-models?" +  decodeURIComponent( $.param( {
                make: this.filters.make,
                start_date: this.filters.daterange !== null ? this.$options.filters.toSqlDate(this.filters.daterange[0]) : '',
                end_date: this.filters.daterange !== null ? this.$options.filters.toSqlDate(this.filters.daterange[1]) : '',
            })))
            .then(response => {
                if (response.data.success == true) {
                    this.modelOptions = response.data.data;
                }
            });
        },
        handleModelChange() {
            this.currentPage = 1;
            this.filters.tag_id = '';
            this.perPage = 10;
            this.loadReport();
        },
        handleMakeChange() {
            this.currentPage = 1;
            this.filters.tag_id = '';
            this.perPage = 10;
            this.getModels();
            this.loadReport();
        },
        handleDMSCampaignNow() {
            this.isSearching = true;
            this.filters.tag_id = '';
            this.perPage = -1;

            this.loadReport('alowareimport');
        },
        handleEmailCampaignNow() {
            this.isSearching = true;
            this.filters.tag_id = '';
            this.perPage = -1;

            this.loadReport('bulkmail');
        },
        handleExport(exportType) {
            this.loadReport(exportType);
        },
        getMaxTerm() {
            this.axios.get("/api/company/" + this.company.id + "/dms-maxterm", {})
            .then(response => {
                if (response.data.success == true) {
                    this.max_term = response.data.data;
                }
            });
        },
        getMinAndMaxApr() {
            this.axios.get("/api/company/" + this.company.id + "/dms-minmaxapr", {})
            .then(response => {
                if (response.data.success == true) {
                    this.min_apr = Math.floor(response.data.data.min);
                    this.max_apr = Math.ceil(response.data.data.max);
                    this.filters.apr_range[this.min_apr, this.max_apr];
                }
            });
        },
        getPendingExports() {
            this.axios
            .get("/api/company/" + this.company.id + "/dms-pending-exports?"+  decodeURIComponent( $.param( {
                component: 'dms-equity-text-campaign'
            })))
            .then(response => {
                this.pendingExports = response.data

                if(response.data.length > 0) {
                    setTimeout(() => {
                        this.getPendingExports()
                    }, 5000)
                }
            });
        },
        getPercentage(pendingExport) {
            let percentage = (pendingExport.processed_records / pendingExport.total_records) * 100
            return Math.ceil(percentage)
        },
        soldDate(data) {
            return moment(data.delivery_date).get('year') > 1990 ? data.delivery_date : data.contract_date
        }
    },
    watch: {
        'filters.q': function(val, oldVal) {
            this.currentPage = 1;
            this.isSearching = true;
            this.filters.tag_id = '';
            this.perPage = 10;
            this.loadReport();
        },
    }
};
</script>
