<template>
    <div>
        <div v-if="company.dms_campaign_enabled == '1'">
            <el-row :gutter="20" style="margin-bottom: 10px;">
                <el-col :span="20" class="text-left">
                    <div style="margin-bottom: 5px;">
                        <small>Date range of Last Closed Repair Order:</small>
                    </div>
                    <el-date-picker
                        v-model="filters.daterange"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="-"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                        size="small"
                        @change="handleDaterangeChange"
                        :clear="handleDaterangeChange"
                        :picker-options="pickerOptions"
                        format="MM-dd-yyyy"
                        popper-class="datepicker-custom"
                        ref="startEndDateService"
                        name="rt">
                    </el-date-picker>
                    <el-select v-model="filters.make"
                        multiple
                        collapse-tags
                        filterable
                        placeholder="Select Makes"
                        size="small"
                        clearable
                        @change="handleMakeChange"
                        :clear="handleMakeChange">
                        <el-option
                            v-for="item in makeOptions"
                            :key="item"
                            :label="item"
                            :value="item">
                        </el-option>
                    </el-select>
                    <el-select v-model="filters.model"
                        multiple
                        collapse-tags
                        filterable
                        placeholder="Select Models"
                        size="small"
                        clearable
                        @change="handleModelChange"
                        :clear="handleModelChange">
                        <el-option
                            v-for="item in modelOptions"
                            :key="item"
                            :label="item"
                            :value="item">
                        </el-option>
                    </el-select>
                    <el-button-group>
                        <el-button
                            type="primary"
                            title="Start DMS Campaign Now"
                            @click="handleDMSCampaignNow"
                            style="font-weight: bold;"
                            size="small"
                            :loading="btnDMSIsLoading">
                            <span v-if="btnDMSIsLoading">Processsing... ({{percentageProcessing}})</span>
                            <span v-else>Campaign now</span>
                            </el-button>
                    </el-button-group>
                </el-col>
                <el-col :span="4" style="text-align: right;">
                    <el-button-group>
                        <el-button
                            type="primary"
                            size="small"
                            title="Export to Excel"
                            @click="handleExport('excel')"
                            :loading="btnExcelIsLoading">Excel</el-button>
                        <el-button
                            type="primary"
                            size="small"
                            title="Export to PDF"
                            @click="handleExport('pdf')"
                            :loading="btnPDFIsLoading">PDF</el-button>
                    </el-button-group><br>
                    <el-row>
                        <el-col :span="24" class="text-right" style="padding-top: 7px">
                            <small>Found <strong>{{totalRecords}}</strong> {{parseInt(totalRecords) | pluralize('record', 'records')}}</small>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-table v-loading="isSearching" :data="reports" class="dms-service-table">
                <el-table-column label="Full name">
                    <template slot-scope="scope">
                        <strong>{{scope.row.first_name}} {{scope.row.last_name}}</strong>
                    </template>
                </el-table-column>
                <el-table-column label="Phone Number" prop="cell_phone">
                    <template slot-scope="scope">
                        {{scope.row.cell_phone}}
                    </template>
                </el-table-column>
                <el-table-column label="Vin" prop="vin">
                    <template slot-scope="scope">
                        {{scope.row.vin}}
                    </template>
                </el-table-column>
                <el-table-column label="Make" prop="make">
                    <template slot-scope="scope">
                        {{scope.row.make}}
                    </template>
                </el-table-column>
                <el-table-column label="Model" prop="model">
                    <template slot-scope="scope">
                        {{scope.row.model}}
                    </template>
                </el-table-column>
                <el-table-column label="Close Date" prop="close_date">
                    <template slot-scope="scope">
                        {{scope.row.close_date | formatDate}}
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                background
                @current-change="handlePageChange"
                :current-page.sync="currentPage"
                :page-size="10"
                layout="prev, pager, next"
                :total="totalRecords"
            ></el-pagination>
        </div>
        <div v-else>
            <RestrictedScreen />
        </div>
    </div>
</template>
<style>
.el-table.dms-service-table {
    font-size: 10.5px;
}
.el-table.dms-service-table thead {
    color: #ffffff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 11.5px;
}
.el-table.dms-service-table th>.cell {
    padding: 0 5px;
}
</style>
<script>
import RestrictedScreen from '../widgets/RestrictedScreen';
import moment from 'moment';
export default {
    props: [
        'selectedCompany',
        'activeTab'
    ],
    components: {
        RestrictedScreen
    },
    data() {
        return {
            errors: {},
            isProgress: false,
            reports: [],
            filters: {
                start_date: '',
                end_date: '',
                daterange: '',
                q: '',
                make: '',
                model: '',
                export_type: 'json'
            },
            totalRecords: 0,
            currentPage: 1,
            isSearching: false,
            company: this.selectedCompany,
            btnDMSIsLoading: false,
            btnExcelIsLoading: false,
            btnPDFIsLoading: false,
            makeOptions: [],
            modelOptions: [],
            pickerOptions: {
                shortcuts: [{
                    text: 'This month',
                    onClick(picker) {
                        const date = new Date();
                        const start = new Date(date.getFullYear(), date.getMonth(), 1);
                        const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last 3 mos',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '18-28 mos',
                    onClick(picker) {
                        const now = new Date();
                        const start = new Date(now.setMonth(now.getMonth() - 28));
                        const end = new Date(now.setMonth(now.getMonth() - 18));
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '29-39 mos',
                    onClick(picker) {
                        const now = new Date();
                        const start = new Date(now.setMonth(now.getMonth() - 39));
                        const end = new Date(now.setMonth(now.getMonth() - 29));
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: this.getDateByFormat('year', 1),
                    onClick(picker) {
                        const now = new Date();
                        const start = new Date(now.getFullYear() - 1, 0, 1);
                        const end = new Date(now.getFullYear() - 1, 11, 31);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: this.getDateByFormat('year', 2),
                    onClick(picker) {
                        const now = new Date();
                        const start = new Date(now.getFullYear() - 2, 0, 1);
                        const end = new Date(now.getFullYear() - 2, 11, 31);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: this.getDateByFormat('year', 3),
                    onClick(picker) {
                        const now = new Date();
                        const start = new Date(now.getFullYear() - 3, 0, 1);
                        const end = new Date(now.getFullYear() - 3, 11, 31);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: this.getDateByFormat('year', 4),
                    onClick(picker) {
                        const now = new Date();
                        const start = new Date(now.getFullYear() - 4, 0, 1);
                        const end = new Date(now.getFullYear() - 4, 11, 31);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            percentageProcessing: '0%'
        };
    },
    computed: {
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.setDefaultDaterange();
            this.loadReport();
            this.getMakes();
            this.getModels();
            this.reloadCompany();
        },
        reloadCompany() {
            this.axios.get("/api/company/" + this.company.aloware_id, { })
            .then(response => {
                this.company = response.data.data
            });
        },
        getLastWeekday( date , weekday ) {
            var d = new Date(date);
            d.setDate( d.getDate() + weekday - d.getDay() );
            return d;
        },
        loadReport(exportType = 'json') {
            let responseType = 'json';
            if (exportType == 'alowareimport') {
                this.btnDMSIsLoading = true;
            } else if (exportType == 'excel') {
                this.btnExcelIsLoading = true;
                responseType = 'blob';
            } else if(exportType == 'pdf') {
                this.btnPDFIsLoading = true;
                responseType = 'blob';
            } else {
                this.isSearching = false
            }

            this.axios.get("/api/company/" + this.company.id + "/dms-services", {
                    params: {
                        page: this.currentPage,
                        q: this.filters.q,
                        model: this.filters.model,
                        make: this.filters.make,
                        tag_id: this.filters.tag_id,
                        export_type: exportType,
                        start_date: this.filters.daterange !== null ? this.$options.filters.toSqlDate(this.filters.daterange[0]) : '',
                        end_date: this.filters.daterange !== null ? this.$options.filters.toSqlDate(this.filters.daterange[1]) : '',
                    },
                    responseType: responseType
                })
                .then(response => {
                    if(exportType == 'pdf' || exportType == 'excel') {
                        this.btnPDFIsLoading = false;
                        this.btnExcelIsLoading = false;

                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', response.headers.filename);
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    } else if (response.data.success == true) {
                        if ( exportType == 'alowareimport' ) {
                            if(response.data.tag_id !== '') {
                                this.filters.tag_id = response.data.tag_id;
                            }

                            let currentPage = parseInt(response.data.pagination.current_page);
                            let totalPages = parseInt(response.data.pagination.total_pages);
                            if( currentPage <= totalPages) {

                                this.percentageProcessing = Math.round((100 * (currentPage / totalPages))) + '%';
                                this.currentPage = currentPage + 1;
                                this.loadReport('alowareimport');

                            } else {
                                this.$notify({
                                    title: "Success",
                                    message: response.data.message,
                                    type: "success"
                                });

                                setTimeout(() => {
                                    window.parent.location.href = 'https://app.simpsocial.com/broadcast/bulk-message';
                                }, 3000);
                            }

                        } else {
                            this.isSearching = false;
                            this.btnDMSIsLoading = false;
                            this.reports = response.data.data;
                            this.totalRecords = response.data.pagination.total;
                        }
                    } else {
                        this.isSearching = false;
                        this.$notify({
                            title: "Error",
                            message: response.data.message,
                            type: "error"
                        });
                    }
                });
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.loadReport();
        },
        currencyFormat(row, column) {
            return this.$options.filters.currency(row[''+ column.property +'']);
        },
        setDefaultDaterange() {
            // set default range: last 3 months
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);

            this.filters.daterange = [start, end];
        },
        handleDaterangeChange() {
            this.currentPage = 1;
            this.isSearching = true;
            this.loadReport();
        },
        handleSaleTypeChange() {
            this.currentPage = 1;
            this.isSearching = true;
            this.loadReport();
        },
        getMakes() {
            this.axios.get("/api/company/" + this.company.id + "/dms-services-makes", {})
            .then(response => {
                if (response.data.success == true) {
                    this.makeOptions = response.data.data;
                }
            });
        },
        getModels() {
            this.axios.get("/api/company/" + this.company.id + "/dms-services-models?" +  decodeURIComponent( $.param( {
                make: this.filters.make,
                start_date: this.filters.daterange !== null ? this.$options.filters.toSqlDate(this.filters.daterange[0]) : '',
                end_date: this.filters.daterange !== null ? this.$options.filters.toSqlDate(this.filters.daterange[1]) : '',
            })))
            .then(response => {
                if (response.data.success == true) {
                    this.modelOptions = response.data.data;
                }
            });
        },
        handleModelChange() {
            this.currentPage = 1;
            this.filters.tag_id = '';
            this.loadReport();
        },
        handleMakeChange() {
            this.currentPage = 1;
            this.filters.tag_id = '';
            this.getModels();
            this.loadReport();
        },
        handleDMSCampaignNow() {
            this.isSearching = true;
            this.filters.tag_id = '';
            this.loadReport('alowareimport');
        },
        handleExport(exportType) {
            this.loadReport(exportType);
        },
    },
    watch: {
        'filters.q': function(val, oldVal) {
            this.currentPage = 1;
            this.isSearching = true;
            this.loadReport();
        },
        'activeTab': function(val, oldVal) {
            if(val=='service') {
                if(window.document.getElementsByName('r').length <= 0) {
                    return
                }
                //listen to manually enterred dates
                window.document.getElementsByName('r')[0].addEventListener('keyup', (event) => {
                    let fromDate = moment(event.target.value, 'MM-DD-YYYY');
                    //let toDate = moment(this.filters.daterange[1]);

                    if(fromDate.isValid()) {
                        this.$refs.startEndDateService.hidePicker();
                        this.filters.daterange[0] = fromDate.toDate();
                        this.handleDaterangeChange();
                    }
                });
                window.document.getElementsByName('t')[0].addEventListener('keyup', (event) => {
                    let toDate = moment(event.target.value, 'MM-DD-YYYY');
                    if(toDate.isValid()) {
                        this.$refs.startEndDateService.hidePicker();
                        this.filters.daterange[1] = toDate.toDate();
                        this.handleDaterangeChange();
                    }
                });
            }
        },
    }
};
</script>
