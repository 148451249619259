<template>
    <el-dialog title="New Dealervault Request" @close="$emit('update:visible', false)" :visible.sync="isVisible" width="30%"
        :close-on-click-modal="false">
        <el-form>
            <div class="form-company">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="Select Company">
                            <el-select v-model="request.company_id"
                                remote
                                filterable
                                clearable
                                :remote-method="loadCompanies"
                                @change="handleCompanyChange">
                                <el-option
                                    v-for="item in companies"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="Dealervault Dealer ID / Aloware ID">
                            <el-input v-model="request.dealer_id"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="Dealervault File Type">
                            <el-radio-group v-model="request.file_type">
                                <el-radio-button label="SL">Sales</el-radio-button>
                                <el-radio-button label="SV">Services</el-radio-button>
                                <el-radio-button label="OPENRO">RO</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="Request Type">
                            <el-select v-model="request.type"
                                remote
                                filterable
                                clearable
                                :remote-method="loadCompanies">
                                <el-option
                                    v-for="item in request_types"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    :disabled="!['Daily', 'Historical'].includes(item.label)"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="request.type == 'Catchup'">
                    <el-col :span="24">
                        <el-form-item label="Select Daterange">
                            <el-date-picker
                                v-model="catchup_daterange"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="-"
                                start-placeholder="Start date"
                                end-placeholder="End date"
                                format="MM-dd-yyyy"
                                @change="handleCatchupDaterangeChange"
                                :clear="handleCatchupDaterangeChange">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="request.type == 'Historical'">
                    <el-col :span="24">
                        <el-form-item label="Select Historical Years">
                            <el-input-number v-model="historical_years" @change="handleHistoricalYearsChange" :min="1" :max="10" label="Historical Years"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
        </el-form>
        <div slot="footer">
            <el-row>
                <el-col :span="24" style="text-align: right">
                    <el-button @click="$emit('update:visible', false)">Cancel</el-button>
                    <el-button :loading="isProgress" @click="handleSaveForm" type="primary"><i class="el-icon-finished"></i> Save</el-button>
                </el-col>
            </el-row>
        </div>
    </el-dialog>
</template>
<style>
    .pull-right {
        float: right;
    }
    .row-template {
        padding: 10px 0;
    }
    .row-template a {
        color: inherit;
        padding: 0 2.5px;
    }
</style>
<script>
export default {
    props: ['company', 'visible'],
    data() {
        return {
            isVisible: false,
            isProgress: false,
            request: {
                program_id: '',
                dealer_id: '',
                file_type: 'SL',
                type: 'Daily',
                catchup_start_date: '',
                catchup_end_date: '',
                historical_months: '',
            },
            companies: [],
            request_types: [
                {label: 'Daily', value: 'Daily'},
                {label: 'Catchup', value: 'Catchup'},
                {label: 'Historical', value: 'Historical'},
            ],
            catchup_daterange: null,
            historical_years: 7,
        };
    },
    computed: {
    },
    mounted() {
        this.loadCompanies()
    },
    methods: {
        handleSaveForm() {
            if(this.request.type == 'Catchup') {
                this.request.catchup_start_date = this.catchup_daterange !== null ? this.$options.filters.toSqlDate(this.catchup_daterange[0]) : ''
                this.request.catchup_end_date = this.catchup_daterange !== null ? this.$options.filters.toSqlDate(this.catchup_daterange[1]) : ''
            }

            this.axios
            .post('/api/dealervault/request', this.request)
            .then(response => {
                this.isProgress = false;
                if (response.data.success == true) {
                    this.$emit('onSubmit', true)
                    return this.$emit('update:visible', false)
                } else {
                    this.$notify({
                        title: "Error",
                        message: response.data.message,
                        type: "error"
                    });
                }
            })
            .catch(err => {
                console.log(err);
                this.isProgress = false;
                this.$root.handleErrors(err.response);
            });
        },
        loadCompanies(query) {
            this.axios.get("/api/company-search", {
                params: {
                    page: 1,
                    q: query
                }
            })
            .then(response => {
                if (response.data.success == true) {
                    this.companies = response.data.data;
                }
            })
        },
        setDefaultDaterange() {
            // set default range: last 3 months
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);

            this.catchup_daterange = [start, end];
        },
        handleCatchupDaterangeChange() {

        },
        handleHistoricalYearsChange() {
            this.request.historical_months = this.historical_years * 12
        },
        handleCompanyChange(selected) {
            // console.log(this.request.company_id)
            let selectedCompany = this.companies.filter(company => company.id == selected)
            this.request.dealer_id = selectedCompany[0].aloware_id
        }
    },
    watch: {
        visible: function(val, oldVal) {
            this.isVisible = this.visible
        },
        'request.type': {
            immediate: false,
            handler: function (n) {
                if(typeof n === 'string' && n === 'Historical') {
                    return this.handleHistoricalYearsChange()
                }
                this.request.historical_months = 0
            }
        }
    }
};
</script>

<style>
    .form-company .el-form-item__label {
        width: 100%;
        text-align: left;
    }
</style>
