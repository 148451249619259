<template>
    <div class="form-user">
        <el-form>
            <el-row>
                <el-col :span="24" style="text-align: center">
                    <el-button size="mini" type="link" @click="handleDownloadSampleCsv">Download sample import template</el-button>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="Upload File">
                        <el-upload
                            class="upload-demo"
                            drag
                            :action="companyApiBaseUrl + '/aws_fileupload?token=' + this.$store.state.token"
                            :show-file-list="true"
                            :on-success="handleSuccess"
                            :before-upload="handleBeforeUpload"
                        >
                            <i class="el-icon-upload" style="margin-top: 20px"></i>
                            <div class="el-upload__text">Drop csv file here or <em>click to upload</em>
                                <br /><small>Please make sure your uploaded csv file matches the template</small>
                                <br />
                            </div>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-row style="margin-top: 15px">
            <el-col :span="12">
                <el-button @click="$emit('closeDialog')">Cancel</el-button>
            </el-col>
            <el-col :span="12" style="text-align: right">
                <el-button type="primary" :disabled="disableSubmit" @click="handleSubmit">Import</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>

export default {
    props: {
    },
    components: {
    },
    data() {
        return {
            file_to_import: '',
            company: {},
            disableSubmit: true,
        };
    },
    computed: {},
    mounted() {
        this.company = Object.assign(this.company, JSON.parse(this.$store.state.currentCompany));
    },
    methods: {
        handleSubmit() {
            let url = ''

            this.axios
                .post(`api/company/${this.company.id}/contact/import`, {file_to_import: this.file_to_import})
                .then(response => {
                    if (response.data.success == true) {
                        this.$notify({
                            title: "Success",
                            message: response.data.message,
                            type: "success"
                        });
                        this.$emit('closeDialog')
                    } else {
                        let errors = response.data.errors.message;
                        let message = "";
                        for (let error in errors) {
                            message = errors[error] + "\n";
                        }
                        this.$notify({
                            title: "Error",
                            message: message,
                            type: "error"
                        });
                    }
                })
                .catch(err => {
                    this.$root.handleErrors(err.response);
                });
        },
        handleDownloadSampleCsv() {
            this.axios({
                url: `api/company/${this.company.id}/contact/download-sample-import-csv`,
                method: 'GET',
                responseType: 'blob',
            }).then(response => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', response.headers.filename);
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        },
        handleSuccess(res, file) {
            this.file_to_import = res.url;
            this.disableSubmit = false;

        },
        handleBeforeUpload() {},
    },
    watch: {
    }
};
</script>

<style>
    .form-company .el-form-item__label {
        width: 100%;
        text-align: left;
    }
</style>
