<template>
    <el-row
        type="flex"
        justify="space-between"
        :gutter="20"
    >
        <el-col
            :span="12"
            style="display: flex; flex-direction: row; align-items: center;"
        >
            <el-avatar
                :src="comment.user_avatar_url"
                :title="comment.user_name"
            >
                {{ comment.user_name }}
            </el-avatar>
            <strong style="margin-left: .75rem;">{{ comment.user_name }}</strong>
        </el-col>

        <el-col
            :span="12"
        >
            <small style="float: right;">{{ comment.create_time | formatDate }}</small>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "UserAvatar",
    props: {
        comment: {
            type: Object,
            required: true,
        }
    }
}
</script>
