<template>
    <el-main>
        <el-container class="page-user" v-if="userHasRole(['developer', 'admin', 'staff'])">
            <el-header height="50px" style="background-color: transparent">
                <el-row type="flex" class="row-bg">
                    <el-col :span="12">
                        <img :src="S3_URL + '/assets/img/simpsocial-logo.png'" style="width: 175px" />
                    </el-col>
                    <el-col :span="12" style="text-align: right">
                        <el-link type="info" index="0" @click="logout()">
                            <i class="el-icon-switch-button"></i>
                            <span>Logout</span>
                        </el-link>
                    </el-col>
                </el-row>
            </el-header>
            <el-container>
                <el-aside width="200px">
                    <el-menu :default-active="activePage"
                        :collapse="isCollapse">
                        <el-menu-item index="user-dashboard" @click="handleNavClick('user-dashboard')" v-if="userHasRole(['developer'])">
                            <i class="fas fa-eye" style="margin-right: 5px;width: 24px"></i>
                            <span slot="title">Dashboard</span>
                        </el-menu-item>
                        <el-menu-item index="user-dealerships" @click="handleNavClick('user-dealerships')">
                            <i class="fas fa-city" style="margin-right: 5px;width: 24px"></i>
                            <span slot="title">Dealerships</span>
                        </el-menu-item>
                        <el-menu-item index="user-dealerprofile" @click="handleNavClick('user-dealerprofile')">
                            <i class="fa fa-building" style="margin-right: 5px;width: 24px"></i>
                            <span slot="title">Dealer Profile</span>
                        </el-menu-item>
                        <el-menu-item index="user-dmsequity" @click="handleNavClick('user-dmsequity')">
                            <i class="el-icon-data-line" style="margin-right: 5px;width: 24px; color: black"></i>
                            <span slot="title">DMS Equity</span>
                        </el-menu-item>
                        <el-menu-item index="user-inventory" @click="handleNavClick('user-inventory')">
                            <i class="fa fa-car" style="margin-right: 5px;width: 24px"></i>
                            <span slot="title">Inventory</span>
                        </el-menu-item>
                        <el-menu-item index="user-contacts" @click="handleNavClick('user-contacts')">
                            <i class="fa fa-address-book" style="margin-right: 5px;width: 24px"></i>
                            <span slot="title">Contacts</span>
                        </el-menu-item>
                        <el-menu-item index="user-leads" @click="handleNavClick('user-leads')">
                            <i class="fa fa-chart-area" style="margin-right: 5px;width: 24px"></i>
                            <span slot="title">Leads</span>
                        </el-menu-item>
                        <el-menu-item index="user-emaildelivery" @click="handleNavClick('user-emaildelivery')">
                            <i class="far fa-paper-plane" style="margin-right: 5px;width: 24px"></i>
                            <span slot="title">Email Delivery</span>
                        </el-menu-item>
                        <el-menu-item index="user-dealervault" @click="handleNavClick('user-dealervault')">
                            <i class="fa fa-briefcase" style="margin-right: 5px;width: 24px"></i>
                            <span slot="title">Dealervault</span>
                        </el-menu-item>
                        <el-menu-item index="user-dominion" @click="handleNavClick('user-dominion')">
                            <i class="fa fa-briefcase" style="margin-right: 5px;width: 24px"></i>
                            <span slot="title">Dominion DMS</span>
                        </el-menu-item>
                        <el-menu-item index="user-webchat" @click="handleNavClick('user-webchat')">
                            <i class="fa fa-comment" style="margin-right: 5px;width: 24px"></i>
                            <span slot="title">Webchat</span>
                        </el-menu-item>
                        <el-menu-item v-if="permitted" index="user-crm-dms-integrations" @click="handleNavClick('user-crm-dms-integrations')">
                            <i class="fas fa-plug" style="margin-right: 5px;width: 24px"></i>
                            <span slot="title">CRM/DMS</span>
                        </el-menu-item>
                        <el-menu-item index="user-users" @click="handleNavClick('user-users')" v-if="userHasRole(['developer', 'admin'])">
                            <i class="fa fa-users" style="margin-right: 5px;width: 24px"></i>
                            <span slot="title">Users</span>
                        </el-menu-item>
                    </el-menu>
                </el-aside>
                <el-main style="padding-top: 0">
                    <Dashboard v-if="activePage=='user-dashboard'" />
                    <CompanyProfile :showDealershipSelector="true" v-if="activePage=='user-dealerprofile'" />
                    <Companies v-if="activePage=='user-dealerships'" />
                    <CarInventory v-if="activePage=='user-inventory'" :company_id="company.id" />
                    <CompanyContacts :showDealershipSelector="true" v-if="activePage=='user-contacts'" :company_id="company.id" />
                    <Users v-if="activePage=='user-users'" />
                    <Leads v-if="activePage === 'user-leads'" :user="user" :company="company"/>
                    <EmailDelivery v-if="activePage === 'user-emaildelivery'" />
                    <Dealervault v-if="activePage === 'user-dealervault'" />
                    <Dominion v-if="activePage === 'user-dominion'" />
                    <Webchat v-if="activePage === 'user-webchat'" />
                    <div class="dark-mode">
                        <DmsCampaigns :showDealershipSelector="true" v-if="activePage === 'user-dmsequity'" />
                    </div>
                    <CrmDmsIntegrations v-if="activePage === 'user-crm-dms-integrations'"/>
                </el-main>
            </el-container>
        </el-container>
        <el-container v-else>
            Nothing to show
        </el-container>
    </el-main>
</template>

<script>
import store from "../store";
import CompanyProfile from './CompanyProfile';
import CarInventory from './admin/Cars';
import Companies from './admin/Companies';
import CompanyContacts from './user/CompanyContacts';
import Dashboard from "./admin/Dashboard";
import Users from "./user/Users";
import Leads from "./Leads";
import EmailDelivery from "./EmailDelivery";
import DmsCampaigns from "./DmsCampaigns";
import Dealervault from "./user/Dealervault";
import Dominion from "./user/Dominion";
import Webchat from "./user/Webchat";
import CrmDmsIntegrations from "./CrmDmsIntegrations";

export default {
    components: {
        CrmDmsIntegrations,
        CompanyProfile,
        CarInventory,
        Companies,
        CompanyContacts,
        Dashboard,
        Users,
        Leads,
        EmailDelivery,
        DmsCampaigns,
        Dealervault,
        Dominion,
        Webchat,
    },
    data() {
        return {
            user: {},
            company: {},
            isCollapse: false,
            activePage: 'user-dealerships',
            S3_URL: process.env.MIX_AWS_S3_URI,
        };
    },
    computed: {
        permitted() {
            const user = JSON.parse(this.$store.state.currentUser)
            const permitted = ['developer', 'admin']

            return permitted.some(permit => user.role_names.includes(permit))
        }
    },
    mounted() {
        this.activePage = typeof this.$route.meta.subPage !== 'undefined' ? this.$route.meta.subPage : 'user-dealerships'

        this.company = JSON.parse(store.state.currentCompany)
        this.user = JSON.parse(store.state.currentUser)
    },
    methods: {
        handleNavClick(pageName) {
            this.activePage = pageName
            this.$router.push({ name: pageName });
        },
        logout() {
            this.axios
            .get("api/auth/logout?token=" + this.$store.state.token)
            .then(response => {
                if (response.data.success == true) {
                    // login user, store the token and redirect to dashboard
                    this.$store.commit("LogoutUser");
                    this.$router.push({ name: "login" });
                }
            })
            .catch(error => {});
        },
    }
};
</script>
