<template>
    <el-card
        v-loading="loading"
        :body-style="{ padding: '0px' }"
    >
        <iframe
            v-if="video.hasOwnProperty('preview_url')"
            :src="video.preview_url | replaceHttp"
            style="display: block; width: 100%"
            :height="video.height * 0.30"
            sandbox="allow-scripts allow-same-origin allow-presentation"
        ></iframe>
        <img
            v-else
            :style="{width: '100%', height: `${video.height * 0.75}px`}"
            :src="video.video_cover_url"
        >
        <div style="padding: 14px;">
            <el-avatar
                :size="100"
                :src="ad.profile_image_url"
                :title="ad.display_name"
                shape="circle"
            >{{ ad.display_name }}</el-avatar>
            <ul style=" list-style-type: none;">
                <li>
                    {{ ad.adgroup_name }}
                </li>
                <li>
                    {{ ad.campaign_name }}
                </li>
                <li>
                    {{ ad.ad_name }}
                </li>
                <li
                    @mouseover="toggleDescription(ad.ad_text, true)"
                    @mouseleave="toggleDescription(ad.ad_text, false)"
                >
                    <span>{{ adDescription }}</span>
                </li>
            </ul>
        </div>
        <comment-button
            v-if="ad.hasOwnProperty('ad_id')"
            :ad="ad"
        ></comment-button>
    </el-card>

</template>

<script>
import CommentButton from "./CommentButton";

export default {
    name: "Ad",
    components: {
        CommentButton
    },
    props: {
        ad: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            loading: true,
            company: {},
            video: {},
            adDescription: ''
        }
    },
    mounted() {
        this.company = JSON.parse(this.$store.state.currentCompany)
        this.toggleDescription(this.ad.ad_text)
        this.getVideoInfo()
    },
    methods: {
        async getVideoInfo() {
            if(this.ad && this.ad.hasOwnProperty('video_id') && this.ad.video_id) {
                await this.axios.get(
                    `api/company/${this.company.id}/social-listening/get-tiktok-video?video_id=${this.ad.video_id}`
                )
                    .then(res => {
                        console.log(res.data.data.video.data)
                        this.video = res.data.data.video.data.list[0]
                        this.loading = false
                    })
                    .catch(err => {
                        console.log('err?', err)
                        return this.$root.handleErrors(err.response)
                    })
            }
            if((!this.ad.hasOwnProperty('video_id') || !this.ad.video_id) && !Object.keys(this.video).length) {
                this.loading = false
            }
        },
        toggleDescription(text, long = false) {
            if(long){
                this.adDescription = text
                return
            }
            this.adDescription = `${text.slice(0, 16)}...`
        },
    }
}
</script>

