<template>
    <img v-if='user' style="height: 50px; width: 50px; border-radius: 50%" :src="user.profile_pic">
</template>

<script>
import store from "../store";

export default {
    components: {},
    data() {
        return {
            user: {},
            aloware_contact_id: null,
            ajaxMessageCount: null,
            is_init: true
        }
    },
    beforeMount() {
        var url_string = document.location.href
        var url = new URL(url_string)

        var contact_id = url.searchParams.get("contact_id")
        if(contact_id) {
            this.aloware_contact_id = contact_id
        }
    },
    mounted() {
        if(this.aloware_contact_id) {
            this.getMatchFbUserContact().then(res => {
                console.log(res)
                this.user = res.data
            })
        }
    },
    methods: {
        getMatchFbUserContact() {
            return this.axios.get(`${this.companyApiBaseUrl}/messenger/match/` + this.aloware_contact_id)
                .then(res => {
                    return Promise.resolve(res.data)
                })
                .catch((err) => {
                    console.log(err)
                    return Promise.reject(err)
                })
        }
    }
}
</script>

<style scoped>
b, strong {
    font-weight: 700;
}
.el-button {
    font-weight: 400!important;
    font-size: 1rem!important;
}
.el-button--default:not(.el-button--primary):hover {
    background-color: rgba(0,191,80,.1)!important;
    color: #00bf50!important;
    border-color: rgba(0,191,80,.4)!important;
}
.el-button--default:not(.el-button--primary):focus {
    background-color: #fff!important;
    color: #00bf50!important;
    border-color: rgba(0,191,80,.5)!important;
}
.text-sm {
    font-size: .9rem!important;
}
.badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
}
.badge-danger {
    color: #fff;
    background-color: #dc3545;
}
</style>
